import { Circle, GoogleMap } from "@react-google-maps/api";
import React, { useEffect, useMemo, useState } from "react";
import { useWindowSize } from "react-use";
import { useQuery } from "../../../mobile/hooks";
import { mapStyle } from "./MapStyle";
// import { useWindowSize } from "../../../hooks/useWindowSize";

export default function GMap({
  coordinates,
  // id,
  containerStyle = {
    width: "100%",
    height: "100%",
  },
  children,
  // zoom = 10,
  // initRadius = RADIUS_BY_ZOOM[10],
  // isSearchMap = false,
  setRootRadius,
  setRootCoordinates,
  // zoomRoot,
  isLoaded,
  onChange = () => {},
}) {
  const { width } = useWindowSize();
  const isMobile = width < 400;
  const { query } = useQuery(["location"]);
  const [map, setMap] = useState();
  // const [mapZoom, setMapZoom] = useState();
  const [mapCenter, setMapCenter] = useState(coordinates);

  // const RADIUS_BY_ZOOM_FINAL = useMemo(
  //   () => (isMobile ? RADIUS_BY_ZOOM_MOBILE : RADIUS_BY_ZOOM),
  //   [isMobile]
  // );

  // const [radius, setRadius] = useState(
  //   RADIUS_BY_ZOOM_FINAL[query?.location?.zoom]
  // );

  // useEffect(() => {
  //   if (query?.location?.zoom) {
  //     setMapZoom(query?.location?.zoom);
  //     setRadius(query?.location?.radius);
  //     return;
  //   }
  //   // if (!!zoomRoot && zoomRoot !== mapZoom) {
  //   //   setMapZoom(zoomRoot);
  //   //   setRadius(RADIUS_BY_ZOOM_FINAL[zoomRoot]);
  //   // }
  // }, [query?.location?.zoom, query?.location?.radius]);

  // const handleUseEffect = useCallback(() => {
  //   onChange(mapCenter, radius);
  // }, [JSON.stringify({ mapCenter, radius })]);

  // useEffect(() => {
  //   handleUseEffect();
  // }, [JSON.stringify({ mapCenter, radius })]);

  const getRadius = (type) => {
    let resp = 0;
    if (type === "+") {
      // const newRadius = query?.location?.radius * 2;
      resp = query?.location?.radius * 2;
      // setRadius((prev) => {
      //   // if (setRootRadius) setRootRadius(prev * 2);
      //   return newRadius;
      // });
    }
    if (type === "-") {
      // const newRadius = query?.location?.radius / 2;
      resp = query?.location?.radius / 2;

      // setRadius((prev) => {
      //   // if (setRootRadius) setRootRadius(prev / 2);
      //   return newRadius;
      // });
    }
    return resp;
  };

  useEffect(() => {
    if (coordinates && coordinates.lat !== 0 && coordinates.lng !== 0) {
      setMapCenter(coordinates);
    }
  }, [coordinates]);

  const center = useMemo(
    () => ({
      lat: coordinates?.latitude || 9.6301892,
      lng: coordinates?.longitude || -84.2541809,
    }),
    [coordinates]
  );

  // const { isLoaded } = useJsApiLoader({
  //   id: "f741cf2a77767045",
  //   googleMapsApiKey: `${process.env.REACT_APP_GOOGLEMAPS_KEY}`,
  // });

  // OPTIMIZE ON MOBILE AND DESKTOP

  // const bounds = !map ? "" : map?.getBounds();

  // const latLngBounds = useMemo(() => {
  //   if (!bounds) return;
  //   if (bounds) {
  //   }
  // }, [bounds]);

  // console.log("BOUNDS", bounds);
  // console.log("BOUNDS VERTICES", !bounds ? "NaN" : bounds?.getVertices());
  // console.log("BOUNDS SW LAT", !bounds ? "NaN" : bounds?.getSouthWest().lat());
  // console.log("BOUNDS SW LNG", !bounds ? "NaN" : bounds?.getSouthWest().lng());
  // console.log("BOUNDS NE LAT", !bounds ? "NaN" : bounds?.getNorthEast().lat());
  // console.log("BOUNDS NE LNG", !bounds ? "NaN" : bounds?.getNorthEast().lng());
  // console.log("BOUNDS NE LNG", !bounds ? "NaN" : bounds?.getNorthEast().lng());

  // const polygonOnBounds = new google.maps.Polygon({
  //   paths: triangleCoords,
  //   strokeColor: "#FF0000",
  //   strokeOpacity: 0.8,
  //   strokeWeight: 2,
  //   fillColor: "#FF0000",
  //   fillOpacity: 0.35,
  // });

  const [showCircle, setShowCircle] = useState(false);

  if (!mapCenter?.lat) return <></>;
  if (!isLoaded) return <></>;

  return (
    <>
      <GoogleMap
        // mapId={"f741cf2a77767045"}
        options={{
          // mapId: "554538b46d6b70fe",
          styles: mapStyle,
          backgroundColor: "#101729",
          disableDefaultUI: true,
        }}
        mapContainerStyle={containerStyle}
        zoom={query?.location?.zoom}
        center={mapCenter || center}
        onTiltChanged={() => {}}
        onZoomChanged={(item) => {
          // if (map?.getZoom()) {
          //   setMapZoom(map?.getZoom());
          // }
          // const compareZoom = query?.location?.zoom || zoom;
          let newRadius = 0;
          if (map?.getZoom() < query?.location?.zoom) {
            newRadius = getRadius("+");
          }
          if (map?.getZoom() > query?.location?.zoom) {
            newRadius = getRadius("-");
          }
          if (setRootRadius && newRadius && map?.getZoom()) {
            setRootRadius(newRadius, map?.getZoom());
          }
          if (map) {
            // if (setRootCoordinates)
            // setRootCoordinates({
            //   lat: map?.center?.lat(),
            //   lng: map?.center?.lng(),
            // });
            setMapCenter({
              lat: map?.center?.lat(),
              lng: map?.center?.lng(),
            });
          }
        }}
        onDragEnd={() => {
          if (setRootCoordinates)
            setRootCoordinates({
              lat: map?.center?.lat(),
              lng: map?.center?.lng(),
            });

          if (map)
            setMapCenter({
              lat: map?.center?.lat(),
              lng: map?.center?.lng(),
            });
        }}
        onLoad={(m) => {
          setMap(m);
          // if (!zoomRoot) {
          //   setMapZoom(m?.getZoom());
          // }
        }}
      >
        {children}
        {showCircle && (
          <Circle
            center={mapCenter}
            radius={query?.location?.radius}
            options={{ fillColor: "red", strokeColor: "red" }}
          />
        )}
      </GoogleMap>
      {/* <div
        className="absolute bg-[#ff5959] top-[7em] z-[100000000] text-nebula"
        onClick={() => setShowCircle((prev) => !prev)}
      >
        <p>{"SHOW CIRCLE"}</p>
      </div> */}
    </>
  );
}
