import React from "react";
import L from "react-loader-spinner";
import { Transition, SwitchTransition } from "react-transition-group";
import styled from "styled-components";

const timeout = 250;
const transitionStyle = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

function LoadingWrapper({
  isLoading,
  error,
  children,
  Loader = L,
  refetch,
  style,
}) {
  return (
    <SwitchTransition mode="out-in">
      <Transition key={isLoading || error} timeout={timeout}>
        {(state) => (
          <LoadingWrapperStyled
            style={{
              transition: `all ${timeout}ms linear`,
              ...transitionStyle[state],
              ...style,
            }}
          >
            {isLoading ? (
              <div className="loader-wrapper">
                <Loader
                  color="#00BFFF"
                  height={120}
                  width={120}
                  type="Rings"
                  className="flex justify-center"
                />
              </div>
            ) : error ? (
              <div className="error-wrapper">
                <div className="error-container">
                  <p className="error-message">
                    Oops! An error occurred while loading the information.
                  </p>
                  {refetch && (
                    <div
                      className="refresh-button"
                      role="button"
                      onClick={refetch}
                    >
                      Refresh
                    </div>
                  )}
                </div>
              </div>
            ) : (
              children
            )}
          </LoadingWrapperStyled>
        )}
      </Transition>
    </SwitchTransition>
  );
}

const LoadingWrapperStyled = styled.div`
  height: 100%;
  .error-wrapper {
    display: grid;
    place-items: center;
    height: 100%;
    font-size: 0.8em;
    text-align: center;
    .refresh-button {
      display: inline-block;
      margin-top: 10px;
      padding: 5px 10px;
      border-radius: 12px;
      font-size: 1em;
      font-weight: 500;
      background-color: #fff;
      color: #1c2c49;
    }
  }
  .loader-wrapper {
    display: grid;
    place-items: center;
    height: 100%;
  }
`;

export default LoadingWrapper;
