import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { RailComponent, Handle, Track } from "./utils";
import BarChart from "./BarChart";
import { useQuery } from "./../../../../../hooks";

const dummyData = new Array(150).fill(1000);
// const dummyData = new Array(500).fill(100);

for (let i = 0; i < 50; i++) {
  dummyData.push(Math.floor(Math.random() * 2000) + 1);
}

const RangeSlider = ({ data = dummyData, setFilterPrices }) => {
  const { query } = useQuery(["dates", "guests", "location", "filter"]);

  const [isDragging, setIsDragging] = useState(false);
  const [handleId, setHandleId] = useState(null);

  const [state, setState] = useState(() => {
    const sortedData = data.slice().sort((a, b) => a - b);
    // const range = [sortedData[0], sortedData[sortedData.length - 1]];

    const range = [sortedData[0], 1000];

    return {
      domain: range,
      update: range,
      values: range,
      inputValues: range,
    };
  });

  const formatValue = (value) => (value > 999 ? "999+" : value);

  const handleInputChange = (index, newValue) => {
    const numericValue = newValue === "999+" ? 999 : Number(newValue);
    const newInputValues = [...state.inputValues];
    newInputValues[index] = numericValue;
    setState((prevState) => ({
      ...prevState,
      inputValues: newInputValues,
      update:
        index === 0
          ? [numericValue, prevState.values[1]]
          : [prevState.values[0], numericValue],
      values:
        index === 0
          ? [numericValue, prevState.values[1]]
          : [prevState.values[0], numericValue],
    }));
  };

  useEffect(() => {
    const sortedData = data.slice().sort((a, b) => a - b);
    // const range = [sortedData[0], sortedData[sortedData.length - 1]];
    const range = [sortedData[0], 1000];
    const queryRange = query?.filter?.price
      ? [query?.filter?.price.min, query?.filter?.price.max]
      : range;
    setState((prevState) => ({
      ...prevState,
      domain: range,
      update: queryRange,
      values: queryRange,
      inputValues: queryRange,
    }));
  }, [data]);

  useEffect(() => {
    setFilterPrices({ min: state.inputValues[0], max: state.inputValues[1] });
  }, [state.inputValues[0], state.inputValues[1]]);

  return (
    <GridContainer>
      <GridItem item xs={12}>
        <div style={{ margin: "0 5%", width: "90%" }}>
          <BarChart
            data={data}
            highlight={state.update}
            domain={state.domain}
          />
          {!!data.length && (
            <Slider
              mode={3}
              step={1}
              domain={state.domain}
              rootStyle={{
                position: "relative",
                width: "100%",
              }}
              onUpdate={(update) => {
                setState((prevState) => ({
                  ...prevState,
                  update,
                  inputValues: update,
                }));
              }}
              onSlideStart={() => setIsDragging(true)}
              onSlideEnd={() => setIsDragging(false)}
              values={state.values}
            >
              <Rail>
                {({ getRailProps }) => (
                  <RailComponent getRailProps={getRailProps} />
                )}
              </Rail>
              <Handles>
                {({ handles, getHandleProps }) => (
                  <div className="slider-handles">
                    {handles.map((handle) => (
                      <Handle
                        key={handle.id}
                        handle={handle}
                        domain={state.domain}
                        isDragging={isDragging}
                        handleId={handleId}
                        getHandleProps={getHandleProps}
                      />
                    ))}
                  </div>
                )}
              </Handles>
              <Tracks left={false} right={false}>
                {({ tracks, getTrackProps, activeHandleID }) => (
                  <div className="slider-tracks">
                    {tracks.map(({ id, source, target }) => (
                      <Track
                        key={id}
                        source={source}
                        target={target}
                        getTrackProps={getTrackProps}
                        activeHandleID={activeHandleID}
                        setHandleId={setHandleId}
                      />
                    ))}
                  </div>
                )}
              </Tracks>
            </Slider>
          )}
        </div>

        <div className="price-wrapper">
          <div className="price-item">
            <label htmlFor="min-item">Minimum</label>
            <div className="value-item">
              <span>$</span>
              <TextField
                id="min-item"
                value={formatValue(state.inputValues[0] || 0)}
                onChange={(evt) => handleInputChange(0, evt.target.value)}
              />
            </div>
          </div>
          <div style={{ opacity: 0.2 }}>—</div>
          <div className="price-item">
            <label htmlFor="max-item">Maximum</label>
            <div className="value-item">
              <span>$</span>
              <TextField
                id="max-item"
                value={formatValue(state.inputValues[1] || 0)}
                onChange={(evt) => handleInputChange(1, evt.target.value)}
              />
            </div>
          </div>
        </div>
      </GridItem>
    </GridContainer>
  );
};

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.alignItems || "stretch"};
  margin-top: ${(props) => props.mt || "0"};
  .price-wrapper {
    display: flex;
    align-items: center;
    margin: 30px 3% 20px;
    gap: 25px;
    .price-item {
      width: 50%;
      border: 2px solid rgba(255, 255, 255, 0.2);
      padding: 2px 15px 6px;
      margin: 10px 0;
      border-radius: 12px;
      label {
        font-size: 0.7em;
        color: rgba(255, 255, 255, 0.6);
      }
      .value-item {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 0.9em;
      }
    }
  }
`;

const GridItem = styled.div`
  flex: ${(props) => (props.xs ? `0 0 ${(props.xs / 12) * 100}%` : "auto")};
  max-width: ${(props) => (props.xs ? `${(props.xs / 12) * 100}%` : "auto")};
  text-align: ${(props) => props.textAlign || "left"};
`;

const TextField = styled.input`
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #fff;
`;

export default RangeSlider;
