import React, { useEffect, useState } from "react";
import useTranslate from "../../../mobile/hooks/useTranslate";
import Accordion, { SimpleAccordion } from "../../../commons/Accordion";
import HowToConnect from "./CustomeFAQ/HowToConnect";
import useQuery from "../../../mobile/hooks/useQuery";

const FAQS = [
  {
    title: `Who we are`,
    height: {
      default: "250px",
      md: "200px",
    },
    description:
      "BBOP is the world's first fee-free home sharing platform. BBOP leverages the power of cryptocurrencies and smart contracts to take out the middleman. You now have the full power of traveling the world and sharing your home completely in your hands.",
    id: "who",
  },
  {
    title: `What type of smart contract`,
    height: {
      default: "320px",
      md: "200px",
    },
    description:
      "BBOP uses a modified 2 of 3 signature smart contract to allow guests and hosts to directly interact with each other. This removes the need for a middleman and the associated costs. When the guest and host are both happy with a completed trip, they will both sign the BBOP smart contract. The host will be paid. The guest's security deposit will be returned. All of this happens seamlessly without any intervention from the BBOP team.",
    id: "contract",
  },
  {
    title: `When will BBOP use its 3rd signature`,
    height: {
      default: "250px",
      md: "200px",
    },
    description:
      "BBOP reserves the 3rd signature in the smart contract only for the times when there is a dispute between the guest and the host. If the guest and host cannot agree on a payout distribution of the funds in the smart contract (payment and security deposit), then BBOP will help both parties try to resolve the problem.",
    id: "signature",
  },
  {
    title: `What is the security deposit`,
    height: {
      default: "250px",
      md: "200px",
    },
    description:
      "BBOP's security deposit feature is a novel solution to assuring your guests treat your home with the utmost respect and care. The security deposit is handled seamlessly by the smart contract and is returned to the guest once the stay is complete and the host and guest are happy with the stay.",
    id: "security",
  },
  {
    title: `What is the purpose of BBOP`,
    height: {
      default: "250px",
      md: "200px",
    },
    description:
      "BBOP was built to save the travelers and hosts in the home sharing industry money. That's it. Our founder felt it was outrageous that other platforms would take up to 20% of the money on a single stay. All this for what? Running a Website? Come on now!!! It is a predatory business fee and BBOP has provided a solution.",
    id: "purpose",
  },
  {
    title: `What about fees`,
    height: {
      default: "250px",
      md: "200px",
    },
    description:
      "The BBOP platform and the BBOP smart contract are open to use and free of middleman fees. When you pay, and when you get paid, BBOP will never take a fee. The crypto networks will have transaction fees, but they should be minuscule. The only way the BBOP team earns money is if you like the platform so much that you donate to us here.",
    id: "fees",
  },
  {
    title: `How to connect my wallet`,
    height: {
      default: "1400px",
      md: "1000px",
    },
    custome: <HowToConnect />,
    id: "connect",
  },
];

const FooterFaq = () => {
  const { t } = useTranslate();
  const [isOpen, setIsOpen] = useState("");
  const { query } = useQuery(["focus"]);

  useEffect(() => {
    if (query?.focus && FAQS.find((item) => item.id === query?.focus)) {
      onChangeFaq(query.focus);
    }
  }, []);

  const onChangeFaq = (newFaqOpen) => {
    if (isOpen === newFaqOpen) {
      setIsOpen("");
      return;
    }
    const element = document.getElementById(newFaqOpen);
    if (element)
      element.scrollIntoView({ block: "center", behavior: "smooth" });
    setIsOpen(newFaqOpen);
  };

  return (
    <div className="flex mx-auto justify-center items-center">
      <div className="w-full h-full flex flex-col gap-6 items-start justify-center">
        {FAQS &&
          FAQS?.map((faq) => (
            <SimpleAccordion
              title={`${t(faq.title)} ?`}
              titleClassName={
                "font-bold text-xl md:text-xl text-nebula justify-between"
              }
              height={faq.height}
              key={faq.id}
              id={faq.id}
              openIsFromRoot
              openRoot={isOpen === faq.id}
              setOpenRoot={() => onChangeFaq(faq.id)}
              className={"bg-[#1c2641] p-5 rounded-lg"}
            >
              {faq.custome ? (
                faq.custome
              ) : (
                <div className={``}>
                  <p className="text-nebula text-start text-md md:text-xl">
                    {t(faq.description)}
                  </p>
                </div>
              )}
            </SimpleAccordion>
          ))}
      </div>
    </div>
  );
};

export default FooterFaq;
