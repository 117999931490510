import React, { useEffect, useMemo, useState } from "react";
import { useQuery, useTranslate } from "../../../hooks";
import { EditGuests } from "../../commons";

const EditGuestsModal = ({ listing, close }) => {
  const { t } = useTranslate();
  const { changeQuery, query } = useQuery(["dates", "id", "guests"]);
  const [guests, setGuests] = useState(query.guests);

  useEffect(() => {
    if (query?.guests) {
      setGuests(query?.guests);
    }
  }, []);

  const totalGuests = useMemo(() => {
    if (!guests) return;
    let res = 0;
    for (let k of Object.keys(guests)) {
      const value = guests[k];
      res = res + value;
    }
    return res;
  }, [guests]);

  const saveChanges = () => {
    if (!totalGuests) return;
    changeQuery({ guests });
    close();
  };

  const guestsInitialValue = useMemo(() => {
    if (!listing?.guests) return;
    return Object.keys(listing?.guests)
      .map((item) => ({ key: item, value: 0 }))
      .reduce((a, v) => ({ ...a, [v.key]: v.value }), {});
  }, [listing?.guests]);

  return (
    <div className="w-full h-full flex flex-col justify-between ">
      <div className="w-full">
        <p className="text-nebula opacity-50 text-lg pb-5">{`Max amount of guests ${listing.number_of_guests}`}</p>
        <EditGuests
          guests={guests || guestsInitialValue}
          onChange={(v) => setGuests(v)}
          max={listing?.guests}
        />
      </div>
      <div className="w-full flex justify-between items-center pt-10">
        <p
          onClick={close}
          className="text-nebula text-xl underline underline-offset-2 cursor-pointer"
        >
          {t("Cancel")}
        </p>
        <div
          onClick={saveChanges}
          className={` border-2 border-nebula py-3 px-8 text-2xl rounded-lg cursor-pointer transition-opacity ${
            !totalGuests ? "opacity-50" : ""
          }`}
        >
          {t("Save")}
        </div>
      </div>
    </div>
  );
};

export default EditGuestsModal;
