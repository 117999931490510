import useAxios, { mapConfig } from "./useAxios";

const useBooking = () => {
  const { handleAxios } = useAxios();

  const sign = async (id, signed_by) => {
    const body = {
      booking_id: id,
      [signed_by]: 1,
    };
    await handleAxios(mapConfig("/booking/sign", "put", { body }));
  };

  const signCancel = async (id, signed_by) => {
    const body = {
      booking_id: id,
      [signed_by]: 1,
    };
    await handleAxios(mapConfig("/booking/signCancel", "put", { body }));
  };

  const create = async (booking, p) => {
    const payment = JSON.stringify(
      p,
      (key, value) => (typeof value === "bigint" ? value.toString() : value) // return everything else unchanged
    );
    await handleAxios(
      mapConfig("/booking/create", "post", {
        body: { booking, payment },
        toast: false,
      })
    );
  };

  // const new_booking_body = {
  //   listingId: listing?.id,
  //   user_id: userState?.user?.id,
  //   start: start ? format(start, "yyyy-MM-dd") : "",
  //   end: end ? format(end, "yyyy-MM-dd") : "",
  //   amount: pricePerDaysResult,
  //   amount_total: total,
  //   amount_per_unit: listing?.price,
  //   duration: days,
  //   guests,
  // };

  // end: reserveProps.endTime,
  // start: reserveProps.startTime,
  // listingId: reserveProps.listingId,
  // amount_per_unit: reserveProps.amount_per_unit,
  // duration: reserveProps.duration,
  // guests: reserveProps.guests,
  // amount: reserveProps.price,
  // amount_total: reserveProps.pice + reserveProps.sd,
  // user_id: userStore?.id

  const status = async (id, status) => {
    await handleAxios(
      mapConfig(`/booking/${status}Booking`, "put", { body: { id } })
    );
  };

  return { sign, status, signCancel, create };
};

export default useBooking;
