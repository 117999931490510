export const CURRENT_SIDE_MENU_PERCETNAGE = {
  PRE: 0,
  AT: 20,
  ME: 40,
  CRA: 60,
  CA: 70,
  ADD: 80,
  DONE: 100,
};

export const CURRENT_SIDE_MENU_KEY = {
  0: "PRE",
  20: "AT",
  40: "ME",
  60: "CRA",
  70: "CA",
  80: "ADD",
  100: "DONE",
};

export const REJECTION_TEXT = {
  Presentation: ["title", "description"],
  Prices: ["price"],
  Images: ["listingImages"],
  Conditions: ["security_deposit_percentage"],
  Location: ["location"],
  Billing: ["no billing uploaded"],
};

export const LISTING_STATUS = {
  ENBALE: "ENABLE",
  REJECTED: "REJECTED",
  REVIEW: "REVIEW",
  DRAFT: "DRAFT",
  DISABLE: "DISABLE",
  PRE_CONTRACT: "PRE_CONTRACT",
};
export const LISTING_STATUS_COLOR = {
  ENABLE: "lighGreen",
  REJECTED: "cancel",
  REVIEW: "yellowBbop",
  DRAFT: "blueBbop",
  DISABLE: "cancel",
  PRE_CONTRACT: "[#507306]",
};

export const LISTING_COMPLETED_SECTION = {
  0: {
    presentation: true,
    attributes: false,
    media: false,
    policy: false,
    price: false,
    address: false,
    completion: 20,
    menu: "PRE",
    message: "Complete the Presentation section to continue with the process",
  },
  20: {
    presentation: true,
    attributes: true,
    media: false,
    policy: false,
    price: false,
    address: false,
    completion: 20,
    menu: "AT",
    message: "Complete the Attibutes section to continue with the process",
  },
  40: {
    presentation: true,
    attributes: true,
    media: true,
    policy: false,
    price: false,
    address: false,
    completion: 40,
    menu: "ME",
    message: "Complete the Photos section to continue with the process",
  },
  60: {
    presentation: true,
    attributes: true,
    media: true,
    policy: true,
    price: false,
    address: false,
    completion: 60,
    menu: "CRA",
    message:
      "Complete the Cancelation Policy section to continue with the process",
  },
  70: {
    presentation: true,
    attributes: true,
    media: true,
    policy: true,
    price: true,
    address: false,
    completion: 70,
    menu: "CA",
    message: "Save the Listing Price to continue with the process",
  },
  80: {
    presentation: true,
    attributes: true,
    media: true,
    policy: true,
    price: true,
    address: true,
    completion: 80,
    menu: "ADD",
    message: "Complete the Address section to continue with the process",
  },
  100: {
    presentation: true,
    attributes: true,
    media: true,
    policy: true,
    price: true,
    address: true,
    completion: 100,
    message:
      "Click on publish to finish the process and make your listing visible",
  },
};
