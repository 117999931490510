import { AiOutlineClockCircle } from "react-icons/ai";

const KycPending = ({ appFileType }) => {
  return (
    <>
      <AiOutlineClockCircle className="text-yellowBbop text-3xl mr-2" />
      <p className="text-lg text-yellowBbop ">
        {appFileType} under verification, wait for approbal
      </p>
    </>
  );
};

export default KycPending;
