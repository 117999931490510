import React from "react";
import { Link } from "react-router-dom";

const LogoNav = ({ show, h }) => {
  return (
    <>
      <div className={`${show || ""} w-fit`}>
        {/* <Link to="/"> */}
        <img
          className={`cursor-pointer sm:w-auto ${h}`}
          src={"https://bbop-app-dev.s3.amazonaws.com/assets/logo.png"}
          // src={"https://bbop-app-dev.s3.amazonaws.com/assets/Logo.png"}
          // src={Logo}
          alt="logo"
        />
        {/* </Link> */}
      </div>
    </>
  );
};

export default LogoNav;
