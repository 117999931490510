import React, { useState } from "react";
import Button from "../../../../commons/Button";
import { useTranslate } from "../../../hooks";
import EditUserProfileLogic from "../../../../features/EditUserProfile/logic/EditUserProfileLogic";
import Form from "../../../../commons/Form";
import { EditProfileImage } from "../../../../commons/EditProfileImage";
import ManageLoading from "../../../../commons/ManageLoading";

const ModalEditProfile = ({ Modal, open, ...modalPropsRest }) => {
  const { t } = useTranslate();
  const {
    notify,
    preview,
    fileRef,
    setSelectedImage,
    values,
    formInputs,
    work,
    handleFormOnSubmit,
    isLoaded,
  } = EditUserProfileLogic();

  const [loading, setLoading] = useState(false);
  return (
    <Modal
      id="edit-profile-modal"
      bodyScroll={false}
      defaultCloseButton
      transitionType="botToTop"
      type="largeBox"
      title={"Personal Information"}
      modalHeight="100%"
      {...modalPropsRest}
    >
      <div className="w-full h-full relative overflow-y-scroll scrollbarNone">
        <EditProfileImage
          info={values}
          widthHeigth="200"
          setSelectedImage={setSelectedImage}
          fileRef={fileRef}
          preview={preview}
          notify={notify}
          className={
            values?.rejections?.includes("profile_image_url") &&
            "border-2 rounded-lg border-cancel"
          }
        />
        <Form inputs={formInputs} />
        <div className="w-full flex justify-end sticky bg-blueContrast pb-5 pt-3 -bottom-5 gap-2">
          <Button
            type="dark"
            onClick={() => modalPropsRest.close()}
            className="px-6 text-xl py-3 rounded-full"
          >
            {t("Cancel")}
          </Button>
          <Button
            type="light"
            onClick={async (e) => {
              setLoading(true);
              await handleFormOnSubmit(e);
              setLoading(false);
              modalPropsRest.close();
            }}
            className="px-6 text-xl py-3 rounded-full flex justify-center items-center"
          >
            <ManageLoading loading={loading} size={20} className="px-2">
              {t("Save")}
            </ManageLoading>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalEditProfile;
