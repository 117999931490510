import { create } from "zustand";

export const useStore = create((set) => ({
  loadingContractInteraction: false,
  setLoadingContractInteraction: (v) =>
    set((state) => ({ loadingContractInteraction: v })),
  zusUser: null,
  setUser: (v) => set((state) => ({ zusUser: { ...state.zusUser, ...v } })),
  setUserNull: () => set((state) => ({ zusUser: null })),
  errorEditListing: false,
  setErrorEditListing: (v) => set((state) => ({ errorEditListing: v })),
  lang: window.sessionStorage.getItem("lang") || 2,
  newLang: false,
  setLang: (v) =>
    set((state) => {
      window.sessionStorage.setItem("lang", v);
      return { lang: v };
    }),
  setNewLang: (v) => set((state) => ({ newLang: v })),
  transalte: {},
  setTranslate: (v) => set((state) => ({ translate: v })),
  notRead: [],
  setNotRead: (v) => set((state) => ({ notRead: v })),
  msgNotRead: [],
  setMsgNotRead: (v) => set((state) => ({ msgNotRead: v })),
  notifications: [],
  setNotifications: (v) => set((state) => ({ notifications: v })),
}));
