import React from "react";
import styled from "styled-components";
import MultipleChoiceByGroup from "../../../MultipleChoice/ChoiceByGroup";
import { services } from "./data";

function Services({ filters }) {
  const { filter, setMultipleChoice } = filters;

  return (
    <ServicesStyled className="px-5">
      <p className="headline">Services</p>
      <MultipleChoiceByGroup
        data={services}
        state={filter.amenities}
        setState={setMultipleChoice("amenities")}
      />
    </ServicesStyled>
  );
}

const ServicesStyled = styled.div`
  .headline {
    margin-bottom: 30px;
  }
`;

export default Services;
