import { DraggerBox, LargeBox, MiddleBox, SmallBox } from "./Modals";

export const transitionTypes = {
  overlay: {
    onEnter: {
      duration: 0.35,
      backdropFilter: "blur(2px)",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
    onExit: {
      duration: 0.35,
      backdropFilter: "blur(0px)",
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  },
  botToTop: (isDesktop) => ({
    onEnter: {
      duration: 0.6,
      transform: isDesktop ? "translate(-50%, 50%)" : "translate(0, 0)",
      ease: "power3.out",
    },
    onExit: {
      duration: 0.4,
      y: isDesktop ? "160%" : "100%",
      ease: "power3.out",
    },
  }),
  scale: () => ({
    onEnter: {
      duration: 0.3,
      scale: 1,
      opacity: 1,
      ease: "power3.out",
    },
    onExit: {
      duration: 0.3,
      scale: 0.7,
      opacity: 0,
      ease: "power3.out",
    },
  }),
};

export const types = {
  largeBox: {
    Component: LargeBox,
    transition: transitionTypes.botToTop,
  },
  middleBox: {
    Component: MiddleBox,
    transition: transitionTypes.botToTop,
  },
  smallBox: {
    Component: SmallBox,
    transition: transitionTypes.scale,
  },
  draggerBox: {
    Component: DraggerBox,
    transition: transitionTypes.botToTop,
  },
};

export const getModalType = (type) => {
  if (typeof type === "undefined" || type === null) return types.largeBox;
  return types[type];
};
