import React from "react";
import Loader from "react-loader-spinner";
import useTranslate from "../../mobile/hooks/useTranslate";

const WaitingConfirmation = ({
  waitingConfirmation,
  approvalStatus = false,
}) => {
  const { t } = useTranslate();
  return waitingConfirmation || approvalStatus ? (
    <div className="flex-row justify-center">
      <p className="py-5 px-10 w-full text-nebula text-center">
        {approvalStatus
          ? t("Waiting for approval")
          : t("Waiting for confirmation")}
      </p>
      <Loader
        color="#00BFFF"
        height={30}
        width={30}
        type="ThreeDots"
        className="flex justify-center"
      />
    </div>
  ) : null;
};

export default WaitingConfirmation;
