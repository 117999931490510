import React from "react";
import styled from "styled-components";

function Rules({ listing, setListing }) {
  const onChange = (e) =>
    setListing({ ...listing, [e.target.name]: e.target.value });

  return (
    <RulesStyled className="h-full px-6">
      <div className="price-wrapper">
        <label htmlFor="price" className="title">
          Price ($)
        </label>
        <input
          type="number"
          placeholder="0"
          autoComplete="off"
          name="price"
          id="price"
          min={0}
          value={listing.price}
          onChange={onChange}
        />
      </div>
      <div className="rules-wrapper">
        <label htmlFor="rules" className="title">
          Rules and conditions
        </label>
        <textarea
          id="rules"
          name="rules_and_conditions"
          placeholder="Here rules and conditions"
          value={listing.rules_and_conditions}
          onChange={onChange}
        ></textarea>
      </div>
    </RulesStyled>
  );
}

const RulesStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  label {
    display: block;
  }
  input,
  textarea {
    width: 100%;
    padding: 12px 18px;
    border-radius: 14px;
    border: none;
    background-color: #1c2641;
    outline: none;
    overflow: overlay;
  }
  textarea {
    resize: none;
    min-height: 100px;
  }
  .rules-wrapper,
  .price-wrapper {
  }
`;

export default Rules;
