import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "./useDebounceEffect";
import { canvasPreview } from "./canvasPreview";
import { useModalTemplate } from "../ModalTemplate";
import { AiOutlineRotateRight } from "react-icons/ai";
import styled from "styled-components";
import { FaPlus } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { MdUpload } from "react-icons/md";
import useUserState from "./../../../hooks/useUserState";
import useApiUrl from "./../../../hooks/useApiUrl";
import Loader from "react-loader-spinner";
import Axios from "axios";
import { useTranslate } from "../../../hooks";

const fixedCropSize = { width: 100, height: 100 };

const ImageUploaderCrop = ({ imagesUpload, add, remove }) => {
  const [images, setImages] = useState([]);
  const { t } = useTranslate();
  const [crop, setCrop] = useState({
    unit: "px",
    width: fixedCropSize.width,
    height: fixedCropSize.height,
    aspect: 1,
    x: 0,
    y: 0,
  });
  const [activeImage, setActiveImage] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [rotate, setRotate] = useState(0);
  const [description, setDescription] = useState("");
  const [isUpload, setIsUpload] = useState(false);

  const previewCanvasRef = useRef(null);
  const imageRef = useRef(null);
  const { jwt } = useUserState();
  const url = useApiUrl();

  const {
    Modal: ImageCrop,
    open: openImageCrop,
    ...imageCropModalPropsRest
  } = useModalTemplate();

  const { close: closeImageCrop, isOpen: isOpenImageCrop } =
    imageCropModalPropsRest;

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        setImages((prevImages) => [
          ...prevImages,
          { src: reader.result, id: file.name, file },
        ]);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const resetDimensions = () => {
    setRotate(0);
  };

  const handleRotate = () => {
    setRotate((prev) => {
      if (rotate >= 360) return 0;
      return prev + 90;
    });
  };

  const handleRemoveImage = (id) => {
    setImages(images.filter((image) => image.id !== id));
    if (activeImage && activeImage.id === id) {
      setActiveImage(null);
      setCompletedCrop(null);
    }
  };

  const handleSetActiveImage = (image) => {
    openImageCrop();
    setActiveImage(image);

    const img = new Image();
    img.onload = () => {
      const crop = {
        unit: "px",
        width: fixedCropSize.width,
        height: fixedCropSize.height,
        x: 0,
        y: 0,
      };
      setCrop(crop);
      setCompletedCrop(crop);
    };
    img.src = image.src;
  };

  const handleUploadButton = async () => {
    try {
      setIsUpload(true);
      const previewCanvas = previewCanvasRef.current;
      if (!previewCanvas || !completedCrop) {
        throw new Error("Crop canvas does not exist");
      }

      const offscreen = new OffscreenCanvas(
        completedCrop.width,
        completedCrop.height
      );
      const ctx = offscreen.getContext("2d");
      if (!ctx) {
        throw new Error("No 2d context");
      }

      ctx.drawImage(
        previewCanvas,
        0,
        0,
        previewCanvas.width,
        previewCanvas.height,
        0,
        0,
        offscreen.width,
        offscreen.height
      );
      // You might want { type: "image/jpeg", quality: <0 to 1> } to
      // reduce image size
      const blob = await offscreen.convertToBlob({
        type: "image/png",
      });

      const file = new File([blob], activeImage.id, {
        type: blob.type,
        lastModified: Date.now(),
      });

      const formData = new FormData();
      formData.append("fileToUpload", file);

      const { data } = await Axios.post(
        `${url}/listing/createListingImage`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      add({ path: data.images[0], description });
      handleRemoveImage(activeImage.id);
      closeImageCrop();
    } catch (e) {
      throw e;
    } finally {
      setIsUpload(false);
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imageRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imageRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          rotate
        );
      }
    },
    100,
    [completedCrop, rotate]
  );

  useEffect(() => {
    if (!isOpenImageCrop) {
      setDescription("");
      resetDimensions();
    }
  }, [isOpenImageCrop]);

  return (
    <ImageUploaderCropStyled>
      <div className="preload-wrapper">
        <p>
          {t("Click or drag a photo from your files into the box with dotted edges.")}
        </p>
        <div className="preload-container">
          <div className="preloader" {...getRootProps()}>
            <div className="plus-trigger">
              <FaPlus color="#1C2C49" />
            </div>
            <input {...getInputProps()} />
          </div>
          {images.map((image) => (
            <div key={image.id} className="preloaded-image">
              <img
                src={image.src}
                alt="Preview"
                style={{ filter: "grayscale(100%)" }}
              />
              <button
                className="remove"
                onClick={() => handleRemoveImage(image.id)}
              >
                <IoMdClose />
              </button>
              <button
                className="upload"
                onClick={() => handleSetActiveImage(image)}
              >
                <MdUpload />
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="upload-wrapper">
        <p className="title-uploaded">{t("Photos uploaded for the listing")}</p>
        <div className="uploaded-container">
          {imagesUpload.map((image) => (
            <div key={image.path} className="uploaded-image">
              <img src={image.path} alt="Uploaded" />
              <button className="remove" onClick={() => remove(image)}>
                <IoMdClose />
              </button>
            </div>
          ))}
        </div>
      </div>

      <ImageCrop
        id="image-crop-modal"
        title="Image Crop"
        defaultCloseButton
        type="smallBox"
        notChildrenWrapper
        style={{ paddingTop: 10 }}
        {...imageCropModalPropsRest}
      >
        {activeImage && (
          <ActiveImageStyled className="px-5 pt-5 pb-8">
            <div className="crop-wrapper">
              <div className="crop-container">
                <ReactCrop
                  src={activeImage.src}
                  crop={crop}
                  ruleOfThirds
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={1}
                  style={{
                    position: "relative",
                    maxWidth: 200,
                    maxHeight: 200,
                    borderRadius: 12,
                  }}
                >
                  <img
                    ref={imageRef}
                    src={activeImage.src}
                    alt={activeImage.id}
                    style={{
                      transform: `rotate(${rotate}deg)`,
                      objectFit: "contain",
                    }}
                  />
                </ReactCrop>
                <div className="icon-wrapper">
                  <AiOutlineRotateRight
                    color="#1c2c49"
                    onClick={handleRotate}
                  />
                </div>
              </div>
            </div>
            {!!completedCrop && (
              <div className="preview-wrapper">
                <p className="">{t("Preview")}</p>
                <div className="preview-container">
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      backgroundColor: "#000",
                      objectFit: "cover",
                      width: 200,
                      height: 200,
                    }}
                  />
                  <textarea
                    placeholder="Brief description for the image"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <button className="upload" onClick={handleUploadButton}>
                  {isUpload ? (
                    <Loader
                      type="TailSpin"
                      color="#00BFFF"
                      height={25}
                      width={25}
                    />
                  ) : (
                      t("Upload Image")
                  )}
                </button>
              </div>
            )}
          </ActiveImageStyled>
        )}
      </ImageCrop>
    </ImageUploaderCropStyled>
  );
};

const ImageUploaderCropStyled = styled.div`
  p {
    margin: 10px 0;
    font-size: 0.9em;
    color: rgba(255, 255, 255, 0.3);
  }

  .preload-wrapper,
  .upload-wrapper {
    .title-uploaded {
      margin-top: 30px;
      padding-top: 10px;
      border-top: 2px dashed rgba(255, 255, 255, 0.3);
    }
    .preload-container,
    .uploaded-container {
      display: flex;
      flex-wrap: wrap;
      .preloader {
        position: relative;
        display: grid;
        place-items: center;
        width: 100px;
        height: 100px;
        margin: 5px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 12px;
        border: 2px dashed #fff;
        .plus-trigger {
          padding: 10px;
          border-radius: 50%;
          background-color: #fff;
          cursor: pointer;
        }
      }
    }
    .preloaded-image,
    .uploaded-image {
      position: relative;
      margin: 5px;
      border-radius: 12px;
      overflow: hidden;
      button {
        position: absolute;
        display: grid;
        place-items: center;
        padding: 2px;
        border-radius: 50%;
        cursor: pointer;
        &.upload {
          top: 50%;
          left: 50%;
          padding: 6px;
          background: #1c2c49;
          color: #fff;
          transform: translate(-50%, -50%);
        }
        &.remove {
          top: 5px;
          right: 5px;
          color: #1c2c49;
          background: #fff;
          font-size: 0.8em;
        }
      }

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
`;

const ActiveImageStyled = styled.div`
  .crop-wrapper {
    display: grid;
    place-items: center;
    .crop-container {
      position: relative;
      .icon-wrapper {
        position: absolute;
        top: 5px;
        left: 5px;
        padding: 3px;
        border-radius: 50%;
        background-color: #fff;
        cursor: pointer;
      }
    }
  }

  .preview-wrapper {
    display: grid;
    place-items: center;
    p {
      font-size: 1.2em;
      margin: 25px 0 10px;
    }
    .preview-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      textarea {
        width: 200px;
        height: 200px;
        padding: 10px;
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: rgba(255, 255, 255, 1);
        background-color: transparent;
        line-height: 1;
        resize: none;
        &:placeholder {
          color: rgba(255, 255, 255, 0.2);
        }
      }
      canvas {
        border-radius: 12px;
      }
    }

    .upload {
      display: grid;
      place-items: center;
      min-width: 121px;
      min-height: 41px;
      margin-top: 30px;
      /* padding: 10px 15px; */
      border-radius: 12px;
      color: #1c2c49;
      background-color: #fff;
      font-size: 0.9em;
      font-weight: 500;
    }
  }
`;

export default ImageUploaderCrop;
