import React from "react";
import { FiEdit } from "react-icons/fi";
import Button from "./Button";

export default function FileInput({
  fileRef,
  setSelectedImage = () => {},
  className,
  notify,
  upload,
  icon = false,
  classNameRoot = "",
}) {
  const handleFileInput = (e) => {
    e.preventDefault();
    const image = e.target?.files[0];
    if (image?.size > 5242880) {
      // display.func("Please select image? size less than 5 MB");
      notify.e("Please select image? size less than 5 MB");
      return;
    }
    if (image?.height > 500 || image?.width > 500) {
      // display.func("The image? size is too long");
      notify.e("The image? size is too long");
      return;
    }
    if (image) {
      setSelectedImage(image);
    } else {
      setSelectedImage(null);
    }
  };

  return (
    <div className={`relative ${classNameRoot}`}>
      {!upload ? (
        <FiEdit
          style={{ width: "100%", height: "100%" }}
          className={`text-nebula text-2xl p-1 rounded-md`}
        />
      ) : !icon ? (
        <Button
          className="rounded-full w-40 py-1 px-8 !bg-blueDark !text-nebula shadow-xl"
          type="light"
        >
          Browse Files
        </Button>
      ) : (
        icon
      )}
      <input
        ref={fileRef}
        className={`absolute cursor-pointer top-0 opacity-0 ${className || ""}`}
        accept=".png, .jpg, .jpeg, .pdf"
        type="file"
        style={{ width: "100%", height: "100%", fontSize: "0px" }}
        onChange={(e) => handleFileInput(e)}
      />
    </div>
  );
}
