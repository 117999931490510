import { useEffect, useState } from "react";
import { setMessageAction } from "../../../../../../app/actions";
import useAxios from "../../../../../../hooks/useAxios";
import useToast from "../../../../../../hooks/useToast";
import useUserState from "../../../../../../mobile/hooks/useUserState";
import { MESSAGE_STATUS } from "../../../../../../utils/Utils";

const LiveChatLogic = ({ chatSelected }) => {
  const { userStore } = useUserState();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chat, setChat] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const { handleAxios } = useAxios();

  const listingOwner = chatSelected?.listingRes?.user_id;
  const user = userStore?.id;

  const { notify } = useToast([
    {
      position: "top-center",
      type: "error",
      key: "e",
    },
  ]);
  useEffect(() => {}, [loading]);
  /**
   * It takes the message from the user and adds it to the thread of the message
   */
  const handleResponseMsg = async (chatToRes, callback) => {
    //This object is the structure of the thread of the message
    const resData = {
      content: chat,
      send_by_userid: user,
      send_to_userid: "",
      docId: chatToRes?.chatId,
    };
    const configAxiosGuest = {
      path: "/messages/replyMessage",
      method: "post",
      toast: false,
      body: {
        ...resData,
        send_to_userid: chatToRes?.userRes?.id,
        guest_receiver_status:
          listingOwner === user ? MESSAGE_STATUS.UNREAD : MESSAGE_STATUS.READ,
        host_receiver_status:
          listingOwner === user ? MESSAGE_STATUS.READ : MESSAGE_STATUS.UNREAD,
      },
      postErrorFetch: (err) => {
        notify.e(err?.message);
      },
    };
    await handleAxios(configAxiosGuest);
    setRefreshData(!refreshData);
    if (callback) callback();
  };

  /**
   * It gets the messages from the database and sets the state of the messages
   */
  const getMsg = () => {
    if (!chatSelected?.chatId) return;
    const configAxios = {
      path: `/messages/messageById?docId=${chatSelected?.chatId}`,
      method: "get",
      postFetch: (res) => {
        handlesMsgRes(res);
      },
      postErrorFetch: (err) => {
        notify.e(err?.message);
      },
    };
    handleAxios(configAxios);
  };

  const handlesMsgRes = (res) => {
    const msg = [res];
    if (msg[0]?.thread?.length > 0) {
      const mergeMsg = [...msg[0]?.thread];
      setMessages(mergeMsg);
      setChat("");
    } else {
      setMessages(msg);
    }
  };

  useEffect(() => {
    if (!chatSelected?.chatId) return;
    getMsg();
    updateMsgStatus();
  }, [refreshData, chatSelected]);

  /**
   * It updates the message status to read
   */
  const updateMsgStatus = async () => {
    const messageUpdate = {
      host_receiver_status: MESSAGE_STATUS.READ,
      guest_receiver_status: MESSAGE_STATUS.READ,
      docId: chatSelected?.chatId,
      send_to_userid: chatSelected?.userRes?.id,
    };
    const configAxios = {
      path: "/messages/updateMessage",
      method: "put",
      body: {
        ...messageUpdate,
      },
      toast: false,
    };
    handleAxios(configAxios);
  };

  return {
    handleResponseMsg,
    setLoading,
    setChat,
    messages,
    chat,
    loading,
    refreshData,
    setRefreshData,
    setMessageAction,
  };
};

export default LiveChatLogic;
