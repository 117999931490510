import "rc-slider/assets/index.css";
import React, { useMemo, useState } from "react";
import QRCode from "react-qr-code";
import Button from "../../commons/Button";
import DonationSlider from "../../commons/DonationSlider";
import GenerateSvg from "../../commons/GenerateSvg";
import Select from "../../commons/Select";
import TransactionCompleted from "../../commons/web3/TransactionCompleted";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import DonationLogic from "./DonationLogic";
import { FaRegCopy } from "react-icons/fa";
import useTranslate from "../../mobile/hooks/useTranslate";
import { useAccount, useSwitchChain } from "wagmi";
import { useDonate, useWeb3 } from "../../mobile/hooks";
import { IoChevronBack } from "react-icons/io5";
import useTruncatedAddress from "../../hooks/useTruncatedAddress";

const Donation = () => {
  const { copyToClipboard } = useCopyToClipboard();
  const [hash, setHash] = useState("");
  const { isConnected: active, chainId } = useAccount();
  const { switchChain } = useSwitchChain();
  const { t } = useTranslate();
  const { confirm, loading, execute, setConfirm } = useDonate((r) =>
    setHash(r?.transactionHash)
  );

  const backToDonate = () => {
    setHash("");
    setConfirm(false);
  };

  const {
    wallets,
    handleChangeSelectCurrency,
    walletSelected,
    setDonationAmount,
    donationAmount,
  } = DonationLogic();

  const truncatedAddress = useTruncatedAddress(walletSelected?.address);

  const isWalletSelectedEqualToYourNetwork = useMemo(
    () => Number(walletSelected?.chain_id) === chainId,
    [chainId, walletSelected?.chain_id]
  );

  const firstWallet = useMemo(() => {
    if (wallets?.length > 1) return [];
    if (wallets?.length === 0) return [];
    if (!wallets) return [];
    return wallets[0];
  }, [wallets]);

  const { toWeiDonate } = useWeb3();

  return (
    <>
      <div className="flex flex-col justify-center items-center  w-full h-full">
        <div className="h-fit w-fit max-w-[40em] p-5 py-14 flex-col flex justify-center items-center relative">
          <p className="text-lighGreen text-4xl lg:text-5xl font-extrabold text-center w-full mb-14">
            {t("Donate to BBOP")}
          </p>
          <p className=" text-white text-xl font-medium text-left md:text-justify">
            {t(
              "BBOP is a homesharing platform committed to re-think the homesharing economy."
            )}
            <br />
            <br />
            {t("Our")} <span className="text-lighGreen ">{t("ZERO FEE")}</span>{" "}
            {t("platform keeps your money where it belongs, in your pocket.")}
            <br />
            <br />
            {t("Your donation will help")}{" "}
            <span className="text-lighGreen ">{t("support")}</span>{" "}
            {t(
              "further development efforts and will go towards the infrastructure and maintenance of the project."
            )}
          </p>
        </div>
        <div className="w-full h-fit max-w-[50em] p-5 flex justify-center items-center md:h-full">
          {!!confirm && hash && (
            <div className="w-full md:w-4/6 bg-white h-fit rounded-xl py-10 px-5">
              <IoChevronBack
                onClick={backToDonate}
                className="cursor-pointer text-2xl"
              />
              <TransactionCompleted
                transactionHash={!!confirm ? hash : null}
                isDonation
                chainId={walletSelected?.chain_id}
              />
            </div>
          )}
          {!confirm && !hash && (
            <div className=" w-full md:w-4/6 bg-white h-fit rounded-xl py-5 px-5">
              {wallets?.length > 1 ? (
                <Select
                  id="select-wallet"
                  name="select-wallet"
                  multiple={false}
                  icon={walletSelected?.icon || ""}
                  onChange={handleChangeSelectCurrency}
                  label="Select a Cryptocurrency"
                  options={wallets.map((wallet) => ({
                    label: wallet.name,
                    value: wallet.token,
                    icon: wallet.icon,
                  }))}
                  className="bg-[#ebebeb] pl-1 w-full"
                  classNameRoot="w-full text-buttonDark items-center flex justify-start bg-[#ebebeb] drop-shadow-md"
                />
              ) : (
                <div className="w-full text-buttonDark items-center flex justify-center bg-[#ebebeb] py-2 drop-shadow-md  ">
                  <GenerateSvg
                    svg={firstWallet?.icon}
                    className="w-[30px] h-[30px] mr-2"
                  />
                  {firstWallet?.name}
                </div>
              )}
              <p className="text-buttonDark text-lg w-full my-5 flex items-center justify-center">
                <span
                  className="cursor-pointer flex items-center justify-center gap-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      walletSelected?.address,
                      "Address copied successfully"
                    )
                  }
                >
                  {`${truncatedAddress}`}
                  <FaRegCopy />
                </span>
              </p>
              <QRCode
                size={220}
                className="w-full flex justify-center"
                value={walletSelected?.address || "none"}
                viewBox={`0 0 220 220`}
              />
              <p className="text-buttonDark text-lg w-full text-center my-5 break-all">
                {walletSelected?.address?.value}
              </p>
              {active && (
                <div className="flex justify-center py-4 px-2 mb-3">
                  <DonationSlider
                    className=""
                    setDonation={setDonationAmount}
                    donation={donationAmount}
                  />
                </div>
              )}

              {active && (
                <Button
                  disabled={!!loading}
                  onClick={(e) => {
                    if (!isWalletSelectedEqualToYourNetwork) {
                      switchChain({
                        chainId: Number(walletSelected?.chain_id),
                      });
                      return;
                    }
                    if (!donationAmount) return;
                    const amountToWei = toWeiDonate(
                      donationAmount,
                      walletSelected
                    );
                    execute(
                      walletSelected?.address,
                      amountToWei,
                      walletSelected?.currency_abi
                    );
                  }}
                  className="text-center py-2 text-lg !bg-[#ebebeb] rounded-full mt-2"
                  type="light"
                >
                  {isWalletSelectedEqualToYourNetwork
                    ? !donationAmount
                      ? t("Insert Amount")
                      : `${t(`Donate ${donationAmount}`)} ${
                          walletSelected?.token
                        }`
                    : t("Change Network")}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Donation;
