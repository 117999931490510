import React from "react";
import { Main, ReserveComp } from "../components";

const Reserve = () => {
  return (
    <Main>
      <ReserveComp />
    </Main>
  );
};

export default Reserve;
