import React from "react";
import { ExploreComp, Main } from "../components";

const Explore = () => {
  return (
    <Main>
      <ExploreComp />
    </Main>
  );
};

export default Explore;
