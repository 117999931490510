import React, { useEffect, useMemo, useRef, useState } from "react";
import { BackButton } from "../commons";
import { Navigate } from "react-router-dom";
import { useQuery, useTranslate } from "../../hooks";
import {
  EDIT_LISTING_MENUS,
  editListingMenus,
  externalSourceDisableEditMenus,
} from "./EditListingMenus";
import {
  Amenities,
  Billing,
  Calendar,
  Categories,
  Conditions,
  Images,
  Location,
  Presentation,
  Prices,
  RoomsGuests,
} from "./edits";
import useSimpleAxios from "../../../hooks/useSimpleAxios";
import { mapConfig } from "../../hooks/useAxios";
import { IoChevronForward } from "react-icons/io5";
import Button from "../../../commons/Button";
import ManageLoading from "../../../commons/ManageLoading";
import useListing from "../../hooks/useListing";
import Loading from "../../../commons/Loading";
import { useStore } from "../../hooks/useStore";
import useAppFiles from "../../../hooks/useAppFiles";
import useRejection from "../../../hooks/useRejection";

const EditListingComp = () => {
  const { query, changeQuery } = useQuery(["id", "menu"]);
  const [changeRejections, setChangeRejections] = useState([]);
  const { t } = useTranslate();
  const { save, loading } = useListing();
  const { errorEditListing } = useStore();
  const appFile = useAppFiles();

  const {
    value: listing,
    setValue,
    isLoaded,
    recall,
  } = useSimpleAxios(
    mapConfig(`/listing/getListingEdit?id=${query?.id}`, "get")
  );
  const { handleRejection } = useRejection(listing);

  const Component = useMemo(() => {
    if (!query?.id) return <></>;
    if (query?.menu?.id === EDIT_LISTING_MENUS.PRE) return <Presentation />;
    if (query?.menu?.id === EDIT_LISTING_MENUS.CAT) return <Categories />;
    if (query?.menu?.id === EDIT_LISTING_MENUS.AME) return <Amenities />;
    if (query?.menu?.id === EDIT_LISTING_MENUS.IMG) return <Images />;
    if (query?.menu?.id === EDIT_LISTING_MENUS.PRI) return <Prices />;
    if (query?.menu?.id === EDIT_LISTING_MENUS.CAL) return <Calendar />;
    if (query?.menu?.id === EDIT_LISTING_MENUS.CON) return <Conditions />;
    if (query?.menu?.id === EDIT_LISTING_MENUS.RG) return <RoomsGuests />;
    if (query?.menu?.id === EDIT_LISTING_MENUS.LOC) return <Location />;
    if (query?.menu?.id === EDIT_LISTING_MENUS.BILL) return <Billing />;
    return <></>;
  }, [listing, query?.menu?.id, query?.id]);

  const onChange = (key, value) => {
    setValue((prev) => ({ ...prev, [key]: value }));
  };

  if (!query?.id) return <Navigate to="/listings" />;

  if (!isLoaded) return <Loading size={160} />;

  return (
    <div className="bg-blueContrast md:rounded-[20px] h-screen overflow-y-auto scrollbarNone">
      <BackButton
        to="/listings"
        onClick={
          !!query?.menu
            ? () => {
                changeQuery({ menu: "" });
                recall();
              }
            : ""
        }
      />
      <p className="w-full absolute top-0 text-center text-nebula text-3xl py-4 md:rounded-[20px] z-[100] bg-blueContrast">
        {query?.menu ? t(query?.menu?.label) : ""}
      </p>
      {!query?.menu && (
        <div className="w-full pt-[3em] pb-[5em] flex flex-col justify-center items-start gap-5 px-5 text-nebula text-2xl">
          {editListingMenus.map((m) => {
            const disabledMenus =
              externalSourceDisableEditMenus[listing?.source];
            const isDisabled = disabledMenus?.includes(m?.id);
            const isRejected = handleRejection(m.label);
            return (
              <div key={m.id} className="flex justify-start w-full ">
                <div
                  className={`w-fit flex items-center cursor-pointer gap-5 pr-5 ${
                    isDisabled ? "opacity-50" : ""
                  } ${isRejected ? "text-cancel" : ""}`}
                  onClick={() => {
                    if (!isDisabled) {
                      changeQuery({ menu: m });
                    }
                  }}
                >
                  <IoChevronForward className="ml-5" />
                  <p>{t(m.label)}</p>
                </div>
              </div>
            );
          })}
          {!!listing && listing?.source && (
            <p className="text-[15px] w-full opacity-80 text-center">{`${t(
              "This listing is from"
            )} ${listing?.source} ${t(
              "you will have most sections disabled due to this integration"
            )}`}</p>
          )}
        </div>
      )}
      {!!query?.menu && (
        <div className="pt-[7em] text-nebula px-5 h-screen overflow-y-scroll scrollbarNone">
          {React.cloneElement(Component, {
            listing,
            onChange,
            setChangeRejections: (v) => {
              if (changeRejections.find((i) => i.id === v.id)) return;
              setChangeRejections((p) => {
                if (p.find((i) => i.id === v.id)) return p;
                return [...p, v];
              });
            },
          })}
        </div>
      )}
      {query?.menu && (
        <div className="bg-blueContrast py-5 px-5 absolute bottom-[5em] md:rounded-[20px] w-full flex justify-end gap-2">
          <Button
            className="px-5 py-2 rounded-full"
            type="dark"
            onClick={() => {
              changeQuery({ menu: "" });
              recall();
            }}
          >
            {t("Cancel")}
          </Button>
          <Button
            disable={errorEditListing}
            className="px-8 py-2 rounded-full"
            type="light"
            onClick={async () => {
              await save({ ...listing, changeRejections }, async () => {
                changeQuery({ menu: "" });
                setChangeRejections([]);
                if (!!listing?.app_files?.new) {
                  await appFile.create(
                    [
                      {
                        value: listing?.app_files?.new,
                        typeFile: "listing_billing",
                      },
                    ],
                    listing?.id,
                    recall()
                  );
                  return;
                }
                recall();
              });
            }}
          >
            <ManageLoading loading={loading} size={23}>
              {t("Save")}
            </ManageLoading>
          </Button>
        </div>
      )}
    </div>
  );
};

export default EditListingComp;
