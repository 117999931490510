import * as React from "react";

function Lodgix(props) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="225.000000pt"
      height="225.000000pt"
      viewBox="0 0 225.000000 225.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          fill={props.color}
          d="M0 1125 l0 -1125 1125 0 1125 0 0 1125 0 1125 -1125 0 -1125 0 0
-1125z m1389 783 c-132 -209 -321 -371 -414 -354 -38 7 -58 34 -53 69 2 18 12
23 55 29 69 10 143 40 211 87 59 40 175 154 207 204 10 15 20 25 23 23 2 -3
-10 -29 -29 -58z m-142 -103 c-27 -24 -33 -25 -179 -25 -165 0 -206 -10 -227
-56 -7 -16 -11 -93 -11 -210 l0 -186 29 -29 29 -29 186 0 c122 0 194 4 211 12
48 22 55 51 55 243 0 144 3 182 17 208 15 29 17 30 24 12 4 -11 8 -109 8 -218
1 -222 -5 -253 -61 -287 -31 -19 -46 -20 -255 -18 l-223 3 -32 33 -33 32 0
234 c0 316 -11 305 295 305 l195 0 -28 -24z m-99 -1017 l2 -247 -37 -8 c-21
-4 -70 -8 -110 -8 -57 0 -77 4 -100 21 -46 33 -63 79 -63 167 0 138 44 191
157 192 l63 0 0 61 c0 33 3 64 7 68 4 4 23 6 43 4 l35 -3 3 -247z m562 206 l0
-45 -42 3 c-39 3 -43 6 -46 31 -6 46 4 57 48 57 l40 0 0 -46z m-1480 -166 c0
-106 5 -200 10 -208 7 -11 33 -16 95 -19 l86 -3 -3 -31 c-3 -27 -8 -32 -43
-40 -56 -12 -157 -3 -187 17 -44 28 -48 54 -48 271 l0 205 45 0 45 0 0 -192z
m468 62 c33 -15 47 -29 62 -62 27 -60 27 -167 -1 -224 -53 -110 -225 -110
-278 0 -29 58 -29 164 0 222 36 76 130 104 217 64z m821 0 c23 -12 23 -13 19
-208 -4 -221 -11 -248 -70 -273 -35 -15 -119 -17 -180 -5 -34 6 -38 10 -38 36
l0 30 90 0 c88 0 91 1 100 26 14 37 14 37 -31 30 -93 -15 -161 33 -181 125
-22 109 10 215 75 245 38 17 180 14 216 -6z m191 -175 l0 -185 -45 0 -45 0 0
185 0 185 45 0 45 0 0 -185z m201 129 l37 -56 33 56 c33 55 34 56 81 56 l48 0
-56 -85 c-31 -48 -53 -92 -50 -100 3 -8 28 -48 56 -90 27 -41 50 -79 50 -85 0
-5 -21 -10 -49 -10 l-48 0 -37 60 c-21 33 -41 58 -46 55 -5 -3 -23 -30 -40
-60 -31 -54 -33 -55 -76 -55 -24 0 -44 3 -44 8 0 4 27 47 59 96 l60 89 -60 88
-60 89 53 0 53 0 36 -56z"
        />
        <path
          fill={props.color}
          d="M946 808 c-11 -15 -16 -45 -16 -93 0 -95 14 -115 81 -115 l49 0 0
115 0 115 -49 0 c-40 0 -53 -4 -65 -22z"
        />
        <path
          fill={props.color}
          d="M571 804 c-30 -38 -30 -140 0 -178 28 -35 70 -35 98 0 35 44 26 170
-13 196 -24 17 -65 8 -85 -18z"
        />
        <path
          fill={props.color}
          d="M1330 815 c-18 -21 -24 -126 -10 -169 13 -39 34 -50 91 -44 l34 3 3
113 3 112 -54 0 c-36 0 -59 -5 -67 -15z"
        />
      </g>
    </svg>
  );
}

export default Lodgix;
