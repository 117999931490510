import React from "react";
import { FavoritesComp } from "../components";
import { Main } from "../components/commons";

const Favorites = () => {
  return (
    <Main>
      <FavoritesComp />
    </Main>
  );
};

export default Favorites;
