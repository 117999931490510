export const editListingMenus = [
  {
    id: "PRE",
    label: "Presentation",
  },
  {
    id: "CAT",
    label: "Categories",
  },
  {
    id: "AME",
    label: "Amenities",
  },
  {
    id: "IMG",
    label: "Images",
  },
  {
    id: "PRI",
    label: "Prices",
  },
  {
    id: "CAL",
    label: "Calendar",
  },
  {
    id: "CON",
    label: "Conditions",
  },
  {
    id: "RG",
    label: "Rooms and Guests",
  },
  {
    id: "LOC",
    label: "Location",
  },
  {
    id: "BILL",
    label: "Billing",
  },
];

export const externalSourceDisableEditMenus = {
  lodgix: ["PRE", "CAT", "AME", "PRI", "IMG", "CAL", "RG", "LOC"],
};

export const EDIT_LISTING_MENUS = {
  PRE: "PRE",
  CAT: "CAT",
  AME: "AME",
  IMG: "IMG",
  PRI: "PRI",
  CAL: "CAL",
  CON: "CON",
  RG: "RG",
  LOC: "LOC",
  BILL: "BILL",
};
