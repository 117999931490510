import React, { useMemo } from "react";
import StarRating from "../StarRating";
import { ProfileImage } from "../ShowUser";
import { useTranslate } from "../../../hooks";
import useSimpleAxios from "../../../../hooks/useSimpleAxios";
import { mapConfig } from "../../../../hooks/useAxios";
import Loading from "../../../../commons/Loading";
import { format } from "date-fns";
import Divider from "../Divider";

const ReviewsModal = ({ id, to, rating }) => {
  const { value: reviews } = useSimpleAxios(
    mapConfig(`/review/get_all?to=${to}&id=${id}`, "get")
  );

  if (!reviews) {
    return <Loading size={160} type="normal" />;
  }
  return (
    <div className="w-full flex flex-col gap-10 items-center">
      <StarRating
        routeRating={rating}
        disabled={true}
        showRating={true}
        oneStar={true}
        totalReviews={reviews?.count}
        classNameRating="text-[2em]"
        classNameReviews="text-[2em]"
        circlePadding="4"
      />
      <div className="w-full flex flex-col gap-2">
        {reviews?.data &&
          reviews?.data?.map((item) => (
            <>
              <ReviewModalCard
                {...item}
                key={item.id}
                date={format(new Date(item.created_at), "MMMM yyyy")}
                user={{
                  full_name: item?.full_name,
                  profile_image_url: item?.profile_image_url,
                  created_at: item?.user_created_at,
                }}
              />
              <Divider px="0" color="nebula" opacity />
            </>
          ))}
      </div>
    </div>
  );
};

export default ReviewsModal;

const ReviewModalCard = ({ date, comment, user, rating }) => {
  const { t } = useTranslate();
  const memberSince = useMemo(
    () =>
      !user
        ? ""
        : `Since: ${format(new Date(user?.created_at), "MMM dd")} of ${format(
            new Date(user?.created_at),
            "yyyy"
          )}`,
    [user]
  );
  return (
    <div className="border-2 border-blueContrast rounded-lg p-2 gap-2 flex flex-col justify-between">
      <div className="flex flex-col gap-2 w-full">
        <div className="w-full flex gap-3">
          <ProfileImage url={user?.profile_image_url} size="3" />
          <div className="flex flex-col justify-center items-start">
            <span
              style={{ lineHeight: "15px" }}
              className="text-nebulaOpac font-semibold text-md"
            >{` ${user?.full_name}`}</span>
            <span
              style={{ lineHeight: "20px" }}
              className="text-nebulaOpac opacity-50 text-md"
            >
              {memberSince}
            </span>
          </div>
        </div>
        <StarRating
          routeRating={rating}
          disabled={true}
          date={date}
          classNameStars="1em"
        />
        <p className="m-0 text-nebulaOpac text-lg w-full">{comment}</p>
      </div>
    </div>
  );
};
