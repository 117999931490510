export const setUserAction = (data) => {
  return {
    type: "SET_CURRENT_USER",
    payload: data,
  };
};

export const setPaymentOptionsAction = (data) => {
  return {
    type: "SET_PAYMENT_OPTIONS",
    payload: data,
  };
};

export const setTransactionAction = (data) => {
  return {
    type: "SET_TRANSACTION",
    payload: data,
  };
};

export const updateUserAvatarAction = (data) => {
  return {
    type: "UPDATE_USER_AVATAR",
    payload: data,
  };
};

export const setListingAction = (data) => {
  return {
    type: "SET_LISTING",
    payload: data,
  };
};

export const setListingSearchAction = (data) => {
  return {
    type: "SET_LISTING_SEARCH",
    payload: data,
  };
};

export const resetListingAction = () => {
  return {
    type: "RESET_LISTING",
    payload: {},
  };
};

export const setListingImagesAction = (data) => {
  return {
    type: "SET_LISTING_IMAGES",
    payload: data,
  };
};

export const resetListingImagesAction = () => {
  return {
    type: "RESET_LISTING_IMAGES",
    payload: [],
  };
};

export const updateUserAction = (data) => {
  return {
    type: "UPDATE_CURRENT_USER",
    payload: data,
  };
};

export const setListingToEditAction = (data) => {
  return {
    type: "SET_LISTING_TO_EDIT",
    payload: data,
  };
};

export const resetListingToEditAction = () => {
  return {
    type: "RESET_LISTING_TO_EDIT",
    payload: {},
  };
};

export const setListingCategoryAction = (data) => {
  return {
    type: "SET_LISTING_CATEGORY",
    payload: data,
  };
};

export const resetListingCategoryAction = () => {
  return {
    type: "RESET_LISTING_CATEGORY",
    payload: 0,
  };
};

export const setListingAttributesAction = (data) => {
  return {
    type: "SET_LISTING_ATTRIBUTES",
    payload: data,
  };
};

export const resetListingAttributesAction = () => {
  return {
    type: "RESET_LISTING_ATTRIBUTES",
    payload: [],
  };
};

export const setListingAddressAction = (data) => {
  return {
    type: "SET_LISTING_ADDRESS",
    payload: data,
  };
};

export const resetListingAddressAction = () => {
  return {
    type: "RESET_LISTING_ADDRESS",
    payload: {},
  };
};

export const setCurrentListingAction = (data) => {
  return {
    type: "SET_CURRENT_LISTING",
    payload: data,
  };
};

export const resetCurrentListingAction = () => {
  return {
    type: "RESET_CURRENT_LISTING",
    payload: {},
  };
};

export const setCurrentListingActionId = (data) => {
  return {
    type: "SET_CURRENT_LISTING_ID",
    payload: data,
  };
};

export const resetCurrentListingActionId = () => {
  return {
    type: "RESET_CURRENT_LISTING_ID",
    payload: 0,
  };
};

export const setValueLocation = (data) => {
  return {
    type: "SET_VALUE_LOCATION",
    payload: data,
  };
};

export const setListingAttributes = (data) => {
  return {
    type: "SET_LISTING_ATTRIBUTES",
    payload: data,
  };
};

export const resetListingAttributes = () => {
  return {
    type: "RESET_LISTING_ATTRIBUTES",
    payload: [],
  };
};

export const setListingCompletedSection = (data) => {
  return {
    type: "SET_LISTING_COMPLETED_SECTION",
    payload: data,
  };
};

export const resetListingCompletedSection = () => {
  return {
    type: "RESET_LISTING_COMPLETED_SECTION",
    payload: {},
  };
};

export const setListingStatusAction = (data) => {
  return {
    type: "SET_LISTING_STATUS",
    payload: data,
  };
};

export const resetListingStatusAction = () => {
  return {
    type: "RESET_LISTING_STATUS",
    payload: "",
  };
};

export const setMessageAction = (data) => {
  return {
    type: "SET_MESSAGE",
    payload: data,
  };
};

export const resetMessageAction = () => {
  return {
    type: "RESET_MESSAGE",
    payload: {},
  };
};

export const setLanguageAction = (data) => {
  return {
    type: "SET_LANGUAGE",
    payload: data,
  };
};
export const setTranslateAction = (data) => {
  return {
    type: "SET_TRANSLATE",
    payload: data,
  };
};

export const setSearchDateRange = (data) => {
  return {
    type: "SEARCH_DATE_RANGE",
    payload: data,
  };
};

export const setSearchGuests = (data) => {
  return {
    type: "SEARCH_GUESTS",
    payload: data,
  };
};

export const resetSearchGuests = () => {
  return {
    type: "RESET_SEARCH_GUESTS",
    payload: 1,
  };
};

export const setIsSearchByRadius = (data) => {
  return {
    type: "IS_SEARCH_BY_RADIUS",
    payload: data,
  };
};

export const setShowSearchOnNavbar = (data) => {
  return {
    type: "SHOW_SEARCH_ON_NAVBAR",
    payload: data,
  };
};

export const resetSearchListingAction = () => {
  return {
    type: "RESET_SEARCH_LISTING",
    payload: {},
  };
};

export const setNotifications = (data) => {
  return {
    type: "NOTIFICATIONS",
    payload: data,
  };
};

export const setNotificationsNotRead = (data) => {
  return {
    type: "NOTIFICATION_NOT_READ",
    payload: data,
  };
};

export const setWallets = (data) => {
  return {
    type: "WALLETS",
    payload: data,
  };
};

export const setNewUserState = (data) => {
  return {
    type: "CHANGE_USER_STATE",
    payload: data,
  };
};
