import React from "react";
import { ListingComp, Main } from "../components";

const Listing = () => {
  return (
    <Main className={"!pb-[12em]"}>
      <ListingComp />
    </Main>
  );
};

export default Listing;
