import React, { useMemo } from "react";
import { BackButton } from "../commons";
import { useQuery, useUserState } from "../../hooks";
import useSimpleAxios from "../../../hooks/useSimpleAxios";
import { mapConfig } from "../../hooks/useAxios";
import { DisputesLogic } from "../../../features/disputes/DisputesLogic";
import { useDisputes } from "../../../hooks/useDisputes";
import { DisputeInfo } from "../../../features/disputes/components/DisputeInfo";
import OfferCard from "../../../features/disputes/components/OfferCard";
import { DisputeCardAction } from "../../../features/disputes/components/DisputeCardAction";
import { DisputeCard } from "../../../features/disputes/components/DisputeCard";
import { Navigate } from "react-router-dom";
import { ROLES } from "../../const";

const DisputeComp = ({ value: dispute, recall }) => {
  const { query } = useQuery(["id"]);
  const { disputeMessage } = useDisputes();
  const { userStore } = useUserState();
  const { value: users } = useSimpleAxios(
    mapConfig(`/disputes/get_booking_users?booking_id=${query?.id}`, "get"),
    true,
    true
  );

  const { cards } = DisputesLogic({ dispute });

  const bookingStatus = dispute?.booking?.status;
  const booking = dispute?.booking;
  const isHost = dispute?.host_id === userStore?.id;
  const finalOfferId = dispute?.final_offer_id;
  const hostSigned = booking?.signed_by_host === 1;
  const guestSigned = booking?.signed_by_guest === 1;
  const adminSigned = booking?.signed_by_admin === 1;
  const findFinalOffer = dispute?.offers?.find(
    (offer) => offer.id === finalOfferId
  );

  const owner = useMemo(() => {
    if (adminSigned && findFinalOffer?.user_id === dispute?.guest_id) {
      return "guest";
    }
    if (adminSigned && findFinalOffer?.user_id === dispute?.host_id) {
      return "host";
    }
    if (findFinalOffer?.user_id === dispute?.host_id) return "host";
    if (findFinalOffer?.user_id === dispute?.guest_id) return "guest";
  }, [adminSigned, findFinalOffer, dispute]);

  const iSign = useMemo(() => {
    if (finalOfferId && guestSigned && hostSigned) {
      return true;
    }

    if (finalOfferId && hostSigned && isHost) {
      return true;
    }
    if (finalOfferId && hostSigned && !isHost) {
      return false;
    }
    if (finalOfferId && guestSigned && isHost) {
      return false;
    }
    if (finalOfferId && guestSigned && !isHost) {
      return true;
    }
    return false;
  }, [finalOfferId, hostSigned, guestSigned, isHost]);

  if (!query?.id)
    return (
      <Navigate to={userStore?.role === ROLES.GUEST ? "/bookings" : "/today"} />
    );

  return (
    <div className="pt-[5em]">
      <BackButton />
      <p className="text-white text-3xl font-semibold w-full text-center mb-10">
        {disputeMessage[dispute?.dispute_status_id || 0]}
      </p>
      <DisputeInfo dispute={dispute} users={users} />
      <div className="pt-5 px-5">
        {findFinalOffer ? (
          <div className="flex flex-col items-center">
            <OfferCard
              offer={findFinalOffer}
              owner={owner}
              admin={dispute?.booking?.signed_by_admin}
              hostSigned={hostSigned}
              guestSigned={guestSigned}
              isHost={isHost}
              id={findFinalOffer?.id}
            />
            {(hostSigned && guestSigned) ||
            dispute?.booking?.signed_by_admin ? null : (
              <DisputeCardAction
                dispute={dispute}
                bookingId={query?.id}
                recall={recall}
                users={users}
                isHost={isHost}
                {...findFinalOffer}
                showAcceptSign={!iSign}
                contractAddress={dispute?.listing?.user?.contract_address}
              />
            )}
          </div>
        ) : (
          bookingStatus !== "complete" && (
            <div className="flex flex-col md:flex-row justify-center md:items-start w-full gap-5">
              {cards.map((card) => (
                <DisputeCard
                  {...card}
                  contractAddress={dispute?.listing?.user?.contract_address}
                  users={users}
                  recall={recall}
                  bookingId={query?.id}
                  dispute={dispute}
                  isHost={isHost}
                  offer={!!card.offer_status_id}
                />
              ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default DisputeComp;
