import React, { useMemo } from "react";
import * as IconsMd from "react-icons/md";
import * as IconsAi from "react-icons/ai";
import * as IconsFa from "react-icons/fa";
import * as IconsBs from "react-icons/bs";
import * as IconsRi from "react-icons/ri";
import * as IconsIo from "react-icons/io5";
import * as IconsBi from "react-icons/bi";

//this is to use icons from React Icon that are saved on backend
const DynamicIcon = ({ name, className = "" }) => {
  const IconComponent = useMemo(() => {
    const first2 = name.slice(0, 2);
    switch (first2) {
      case "Md":
        return IconsMd[name];
      case "Ai":
        return IconsAi[name];
      case "Fa":
        return IconsFa[name];
      case "Bs":
        return IconsBs[name];
      case "Ri":
        return IconsRi[name];
      case "Io":
        return IconsIo[name];
      case "Bi":
        return IconsBi[name];
      default:
        return <IconsMd.MdInsertEmoticon />;
    }
  }, [name]);

  if (!IconComponent) return <></>;

  return (
    <>
      <IconComponent className={className} />;
    </>
  );
};

export default DynamicIcon;
