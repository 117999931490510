import React, { useEffect, useState } from "react";
import useSimpleAxiosPaginated from "../../../hooks/useSimpleAxiosPaginated";
import { mapConfig } from "../../hooks/useAxios";
import {
  useCheckRole,
  useHistory,
  useTranslate,
  useUserState,
} from "../../hooks";
import { LISTING_STATUS, LISTING_STATUS_SELECT } from "../../../const/Listing";
import { useDebounceAuto } from "../../../hooks/useDebounce";
import useFilter from "../../../hooks/useFilter";
import { Select } from "../commons/Inputs";
import ManageLoading from "../../../commons/ManageLoading";
import Button from "../../../commons/Button";
import ListingStatusIcon from "../../../commons/ListingStatusIcon";
import ListingsActions from "./ListingsActions";
import { ShowMoreButton } from "../commons";

const options = [
  { label: "All", value: "ALL" },
  ...Object.keys(LISTING_STATUS_SELECT).map((item) => ({
    label: item.charAt(0) + item.slice(1).toLowerCase(),
    value: item,
  })),
  { label: "Pre Contract", value: "PRE_CONTRACT" },
];

const ListingsComp = () => {
  useCheckRole("host", "/settings");
  const [status, setStatus] = useState("ALL");
  const { userStore } = useUserState();
  const { t } = useTranslate();
  const history = useHistory();

  const filter = (
    <>
      <div className="w-full flex items-center">
        <Select
          values={options}
          initValue={options[0]}
          onChange={(v) => setStatus(v.value)}
        />
      </div>
    </>
  );

  const { input, search, sort } = useFilter({
    filter,
    placeholder: "Name",
    sortDefault: "created_at",
    sortDefaultDirection: "DESC",
    isAuto: true,
    isSort: false,
  });

  const searchDebounce = useDebounceAuto(search, 800);

  const {
    value: listings,
    recall,
    work,
    res,
    callMore,
    hasMore,
    notFound,
    isLoaded,
  } = useSimpleAxiosPaginated(
    mapConfig(`/listing/getListingByUser?userId=${userStore.id}`, "post", {
      body: {
        limit: 9,
        offset: 0,
        sort,
        filter: {
          listing: {
            and: [
              { key: "status", op: "not", value: LISTING_STATUS.DELETED },
              { key: "status", op: "", value: status === "ALL" ? "" : status },
            ],
            name: { value: searchDebounce, op: "like" },
          },
        },
      },
      toast: false,
    })
  );

  useEffect(() => {
    recall();
  }, [searchDebounce, status, sort.value, sort.direction]);

  return (
    <div className="text-nebula w-full px-2">
      <ListingsActions listings={listings} recall={recall} />
      {input}
      <div className="flex flex-col pt-5 gap-2 w-full justify-center items-center">
        <ManageLoading loading={work && !!isLoaded}></ManageLoading>
        <ManageLoading loading={!isLoaded}>
          {listings &&
            listings?.map((list) => (
              <div
                key={list.id}
                onClick={() => history(`/edit/listing?id=${list?.id}`)}
                className="w-full border border-blueContrast rounded-lg p-2 flex justify-start items-center gap-3 relative"
              >
                <div
                  style={{
                    backgroundImage: `url(${
                      list.listing_images?.length > 0
                        ? list.listing_images[0].path
                        : ""
                    })`,
                  }}
                  className={`w-[5em] h-[5em] bg-cover bg-center rounded-lg `}
                ></div>
                <p className="w-1/2 opacity-80">{list.name}</p>
                <div className="absolute top-3 right-2">
                  <ListingStatusIcon status={list.status} haveText={false} />
                </div>
                {list.source && (
                  <div className="absolute bottom-1 right-2">
                    <p className="text-blueLight">{list.source}</p>
                  </div>
                )}
              </div>
            ))}
          <ShowMoreButton hasMore={hasMore} callMore={callMore} />
        </ManageLoading>
      </div>
    </div>
  );
};

export default ListingsComp;
