import React, { useMemo } from "react";
import {
  useCurrentNetwork,
  useHistory,
  useQuery,
  useReserve,
  useTranslate,
  useUserState,
} from "../../hooks";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useSwitchChain } from "wagmi";
import useSimpleAxios from "../../../hooks/useSimpleAxios";
import { mapConfig } from "../../../hooks/useAxios";
import Loader from "react-loader-spinner";
import { useStore } from "../../hooks/useStore";

const queryKeys = ["dates", "id", "guests"];

const PayButton = ({ prices, listing }) => {
  const { loadingContractInteraction } = useStore();
  const { t } = useTranslate();
  const { query, queryToPath } = useQuery(queryKeys);
  const currentNetwork = useCurrentNetwork();
  const { isConnected } = useAccount();
  const { switchChain } = useSwitchChain();
  const { open } = useWeb3Modal();
  const { userStore } = useUserState();
  const history = useHistory();

  const doesUserHaveNetworkConnected = useMemo(() => {
    const networksAv = Object.keys(listing?.user?.contract_address);
    return networksAv.includes(String(currentNetwork.chainId));
  }, [listing?.user?.contract_address, currentNetwork]);

  const {
    value: checkDates,
    work,
    recall,
  } = useSimpleAxios(
    mapConfig(`/booking/check${queryToPath}`, "get"),
    true,
    queryToPath
  );

  const contract = useMemo(
    () =>
      !listing || !currentNetwork
        ? ""
        : listing?.user.contract_address[currentNetwork.chainId],
    [listing, currentNetwork]
  );

  const totalGuests = useMemo(() => {
    if (!query.guests) return;
    let res = 0;
    for (let k of Object.keys(query.guests)) {
      const value = query.guests[k];
      res = res + value;
    }
    return res;
  }, [query.guests]);

  const reserveProps = useMemo(
    () =>
      !listing || !prices
        ? ""
        : {
            listingId: listing?.id,
            amount_per_unit: listing?.price,
            duration: prices.days,
            guests: totalGuests,
            price: prices.pricePerDays || 0,
            sd: listing?.security_deposit_percentage,
            startTime: query?.dates?.startDate,
            endTime: query?.dates?.endDate,
            cancelationId:
              listing.listing_cancellation_policies[0].cancellation_policy_id,
          },
    [prices, listing, totalGuests]
  );

  const { reserve, confirm } = useReserve(contract, reserveProps);

  const buttonText = useMemo(() => {
    if (userStore.kyc_status === "pending") return t("Kyc pending");
    if (userStore.kyc_status === "unverified") return t("Please verify kyc");
    if (checkDates?.blocked) return t("Dates are blocked");
    if (!isConnected) return t("Connect Wallet");
    if (!doesUserHaveNetworkConnected) return t("Switch network");
    if (!totalGuests) return t("Select Guests");
    if (confirm) return t("Reservation Made");
    return t("Pay Reservation");
  }, [
    confirm,
    checkDates,
    isConnected,
    totalGuests,
    doesUserHaveNetworkConnected,
  ]);

  if (work || loadingContractInteraction)
    return (
      <div className="w-full flex justify-center">
        <Loader
          className="ml-2"
          type="Rings"
          color="#00BFFF"
          height={90}
          width={90}
        />
      </div>
    );

  return (
    <div className="w-full flex justify-center py-8">
      <div
        className="bg-blueContrast text-nebula text-2xl rounded-lg py-3 px-10 cursor-pointer"
        onClick={async () => {
          if (
            userStore.kyc_status === "pending" ||
            userStore.kyc_status === "unverified"
          ) {
            history("/kyc");
            return;
          }
          if (checkDates?.blocked) return;
          if (!isConnected) {
            open();
          }
          if (!doesUserHaveNetworkConnected) {
            switchChain({
              chainId: Number(Object.keys(listing?.user?.contract_address)[0]),
            });
            return;
          }
          if (!totalGuests) return;
          if (confirm) return;

          if (isConnected) {
            const check = await recall(true);
            if (!!check.data.blocked) return;
            reserve();
          }
        }}
      >
        {buttonText}
      </div>
    </div>
  );
};

export default PayButton;
