import React, { useMemo } from "react";
import { RangeDatePicker } from "../../../commons/RangeDatePicker";
import { useQuery } from "../../hooks";
import useDate from "../../../hooks/useDate";

const ShowCalendar = ({ listing, specialPriceDates, title, disableDates }) => {
  const { changeQuery, query } = useQuery(["dates", "id", "guests"]);

  const { formatChecksInOut, tomorrow } = useDate();

  const resetDates = () => {
    const datesFormated = formatChecksInOut(listing, new Date(), tomorrow);
    changeQuery({
      dates: {
        startDate: datesFormated.startDate,
        endDate: datesFormated.endDate,
      },
    });
  };

  const formatDates = useMemo(() => {
    if (!query?.dates) return;
    return {
      start: new Date(query?.dates.startDate),
      end: new Date(query?.dates.endDate),
    };
  }, [query?.dates]);

  return (
    <RangeDatePicker
      changeDates={(dates) => changeQuery({ dates })}
      startDate={formatDates?.start || tomorrow}
      endDate={formatDates?.end || tomorrow}
      direction="vertical"
      title={title}
      resetDates={resetDates}
      className={`p-3 md:p-5 w-full flex flex-col items-center justify-start`}
      specialDates={specialPriceDates}
      disableDates={disableDates}
      checkTimes={{
        check_in: listing?.check_in,
        check_out: listing?.check_out,
      }}
    />
  );
};

export default ShowCalendar;
