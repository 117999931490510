import React, { useMemo, useState } from "react";
import Button from "../../../commons/Button";
import useTranslate from "../../../mobile/hooks/useTranslate";
import useUserState from "../../../mobile/hooks/useUserState";
import SignTermsConds from "./SignTermsConds";
import { useSelector } from "react-redux";
import { useLogin } from "../../../hooks/useLogin";
import useToast from "../../../hooks/useToast";
import useChainId from "../../../hooks/useChainId";

export const ModalSignWallet = ({
  connectFromRoot,
  onClose,
  isFromWallet,
  isFromEditProfile,
}) => {
  const { t } = useTranslate();
  const [termsConds, setTermsConds] = useState(false);
  const [falseClick, setFalseClick] = useState(false);
  const { userState } = useUserState();
  const [isSaveNewWallet, setIsSaveNewWallet] = useState(false);

  const { notify } = useToast([
    {
      position: "top-center",
      type: "success",
      key: "s",
    },
    {
      position: "top-center",
      type: "error",
      key: "e",
    },
  ]);

  const chain = useChainId();

  return (
    <div className="w-full max-w-[35em]">
      <div className="flex w-full justify-start gap-10 items-center">
        <img
          className={`cursor-pointer sm:w-auto h-[3em] `}
          src={"https://bbop-app-dev.s3.amazonaws.com/assets/logo.png"}
          alt="logo"
        />
        <span className="text-greenDark font-semibold text-3xl">
          {t("Connect Wallet!")}
        </span>
      </div>
      <p className="text-md text-greenDark text-justify mt-5">
        {t(
          "If you want to get all the juice out of BBOP you need to connect your web3 wallet (Metamask, Trust, etc).  Once connected, you will be free to book trips and host fellow travelers."
        )}
      </p>
      <p className="text-md text-greenDark text-justify mt-5">
        {t(
          "This step will save the wallet address you've provided via your web3 wallet to your BBOP profile information.  There is no fee to do this so no need to worry!"
        )}
      </p>
      <p className="text-md text-greenDark text-justify mt-5 mb-10">
        {t(
          "You can also connect your wallet later if you simply feel like browsing the site.  When you're ready, just click on the little wallet in the top right corner and you'll be right back here."
        )}
      </p>
      {!userState?.user?.address && (
        <SignTermsConds
          onchange={(value) => {
            setFalseClick(false);
            setTermsConds(value);
          }}
          value={termsConds}
          falseClick={falseClick}
        />
      )}
      <div className="h-full w-full md:px-10 flex gap-2 justify-center ">
        <Button
          onClick={onClose}
          type="light"
          className="rounded-full text-lg w-full text-center py-2 drop-shadow-lg"
        >
          {t("Do it later")}
        </Button>
        <Button
          onClick={() => {
            if (!userState?.user?.address && !termsConds) {
              setFalseClick(true);
              return;
            }
            if (!chain) {
              notify.e("You don't have Metamask integrated");
              return;
            }
            const userHaveAddress = !userState?.user?.address[chain];
            if (connectFromRoot) {
              connectFromRoot(userHaveAddress);
              return;
            }
          }}
          className="rounded-full text-lg w-full text-center py-2 drop-shadow-lg"
        >
          {t("Connect")}
        </Button>
      </div>
    </div>
  );
};
