import React from "react";
import WaitingConfirmation from "../commons/web3/WaitingConfirmation";
import { useStore } from "../mobile/hooks/useStore";

const LoadingContractInteraction = () => {
  const { loadingContractInteraction: loading } = useStore();
  if (!loading) return <></>;
  return (
    <div
      style={{
        zIndex: "100000",
      }}
      className="fixed w-full h-full bg-[#00000095] flex justify-center items-center AQUI MIRAME "
    >
      <WaitingConfirmation waitingConfirmation={loading} />
    </div>
  );
};

export default LoadingContractInteraction;
