import React, { useEffect, useState } from "react";
import useFavorites from "../../hooks/useFavorites";
import { IoHeartOutline, IoHeartSharp } from "react-icons/io5";

export default function FavButtonDetails({ listing }) {
  const [favorite, setFavorite] = useState(false);

  useEffect(() => {
    if (listing?.fav) {
      setFavorite(listing?.fav);
    }
  }, [listing?.fav]);

  const { handleFavListing, handleDeleteFav } = useFavorites({
    setFavorite,
  });

  return (
    <>
      <button
        onClick={async (e) => {
          if (favorite) {
            await handleDeleteFav(e, listing?.id);
          } else {
            await handleFavListing(e, listing?.id);
          }
        }}
        className={`text-3xl`}
      >
        {favorite ? (
          <IoHeartSharp className="transition-all duration-300 text-[#ff5959] " />
        ) : (
          <IoHeartOutline className="transition-all duration-300 text-[#ff5959] " />
        )}
      </button>
    </>
  );
}
