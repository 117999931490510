import React from "react";
import { ComponentTitle, Main } from "../components/commons";
import { TodayComp } from "../components";

const Today = () => {
  return (
    <Main>
      <TodayComp />
    </Main>
  );
};

export default Today;
