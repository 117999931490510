import React, { useMemo } from "react";
import { useHistory, useTranslate, useUserState } from "../../hooks";
import { format } from "date-fns";
import { IoBook, IoChevronForward, IoFlag } from "react-icons/io5";
import { MdVerified } from "react-icons/md";
import { FaMedal } from "react-icons/fa";
import { useSelector } from "react-redux";
import MessageUser from "./MessageUser";

const ShowUser = ({
  user,
  type = "normal",
  contactHost = false,
  title,
  listing,
}) => {
  const history = useHistory();
  const { t } = useTranslate();
  const { userStore } = useUserState();

  const info = useMemo(
    () =>
      !user
        ? ""
        : [
            {
              descrp: `${user?.nb_bookings_offerer || 0} ${t("Bookings")}`,
              icon: <FaMedal />,
            },
            {
              descrp:
                user?.kyc_status === "verified"
                  ? // user?.id_card_verified &&
                    // user?.phone_verified &&
                    // user?.email_verified
                    t("Verified")
                  : t("Not verified"),
              icon: <MdVerified />,
            },
            // {
            //   descrp: `${t("Profession")} ${
            //     user?.profession || t("not added")
            //   }`,
            //   icon: <IoBook />,
            // },
            {
              descrp: `${t("Nationality")} ${
                user?.nationality || t("not added")
              }`,
              icon: <IoFlag />,
            },
          ],
    [user]
  );

  const memberSince = useMemo(
    () =>
      !user.created_at
        ? ""
        : `${t("Since")}: ${format(new Date(user?.created_at), "MMM dd")} ${t(
            "of"
          )} ${format(new Date(user?.created_at), "yyyy")}`,
    [user]
  );

  const goToUser = () => history(`/user?id=${user?.id}`);

  if (!user) return <></>;

  if (type === "big") {
    return (
      <div className="flex flex-col gap-5 p-5 w-full">
        {title && (
          <span className="text-nebula font-medium text-3xl ">{t(title)}</span>
        )}
        <div
          onClick={goToUser}
          style={{
            borderRadius: "18px",
          }}
          className="flex flex-col justify-center items-center py-8 px-5 shadow-lg shadow-blueContrast border border-blueContrast"
        >
          <ProfileImage url={user?.profile_image_url} size={7} />
          <span className="text-nebula font-bold text-3xl pt-5">{` ${user?.first_name} ${user?.last_name}`}</span>
          <span className="text-nebulaOpac opacity-50">{memberSince}</span>
        </div>
        <div className="flex flex-col items-start justify-center gap-4 pl-2 pt-5">
          {info &&
            info?.map((item, index) => (
              <div key={index} className="flex items-center gap-5">
                <div className="text-nebulaOpac text-3xl">{item?.icon}</div>
                {typeof item.descrp === "string" ? (
                  <span className="text-nebulaOpac text-lg">{item.descrp}</span>
                ) : (
                  item.descrp
                )}
              </div>
            ))}
        </div>
        {contactHost && userStore?.id !== user?.id && (
          <div className="w-full flex justify-center items-center pt-5">
            {/* <MessageUser id={user?.id} listingId={listing?.id} /> */}
            <MessageUser user={user} listing={listing} />
          </div>
        )}
      </div>
    );
  }
  if (type === "settings") {
    return (
      <div
        className="w-full justify-between items-center flex"
        onClick={goToUser}
      >
        <div className="flex items-center gap-5">
          <ProfileImage url={user?.profile_image_url} />
          <div className="flex flex-col justify-center items-start">
            <span className="text-nebula font-semibold text-xl">{` ${user?.first_name} ${user?.last_name}`}</span>
            <span className="text-nebulaOpac opacity-50">{memberSince}</span>
          </div>
        </div>
        <IoChevronForward className="text-3xl text-nebula" />
      </div>
    );
  }
  // NORMAL
  return (
    <div className="w-full flex gap-5" onClick={goToUser}>
      <ProfileImage url={user?.profile_image_url} />
      <div className="flex flex-col justify-center items-start">
        <span className="text-nebula font-semibold text-xl">{` ${user?.first_name} ${user?.last_name}`}</span>
        <span className="text-nebulaOpac opacity-50">{memberSince}</span>
      </div>
    </div>
  );
};

export const ProfileImage = ({ url, size = "4" }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${url})`,
        width: `${size}em`,
        height: `${size}em`,
      }}
      className={`rounded-full bg-cover bg-center ${
        !url ? "bg-[#a0a0a0]" : ""
      }`}
    ></div>
  );
};

export default ShowUser;
