import React, { useMemo, useState } from "react";
import {
  useCheckContractInteraction,
  useHistory,
  useTranslate,
} from "../../hooks";
import CreateReview from "../../../features/bookingDetails/components/CreateReview";
import { utils } from "web3";
import { useFinishReservation } from "../../hooks/web3Interactions";
import useConfetti from "../../../hooks/confetti/useConfetti";
import useWeb3TxDB from "../../../hooks/web3/useWeb3TxDB";
import useBooking from "../../../hooks/useBooking";
import { WrongAddress } from "../commons";

const BookingContractInteraction = ({ booking, recall, paidStatus }) => {
  const { t } = useTranslate();
  const [reviewSent, setReviewSent] = useState(false);
  const { confetti, fireworks } = useConfetti();
  const web3txDB = useWeb3TxDB();
  const { sign } = useBooking();
  const history = useHistory();

  const { canInteract, isConnected, isHost } = useCheckContractInteraction({
    booking: booking,
    listing: booking?.listing,
  });

  const { execute } = useFinishReservation(
    booking?.listing?.user?.contract_address,
    [booking?.reservationId ? utils.toBN(Number(booking?.reservationId)) : 0],
    async (receipt) => {
      const isFirstSign = !booking.signed_by_guest && !booking.signed_by_host;
      const isSecondSign =
        !!booking.signed_by_guest || !!booking.signed_by_host;
      let txType = "";
      if (isFirstSign) txType = "signature";
      if (isSecondSign) txType = "payout";
      await sign(booking?.id, isHost ? "host" : "guest");
      const saveReceipt = JSON.stringify(
        receipt,
        (key, value) => (typeof value === "bigint" ? value.toString() : value) // return everything else unchanged
      );

      await web3txDB.add(saveReceipt, txType, booking?.id);
      recall();
      if (isFirstSign) confetti();
      if (isSecondSign) fireworks();
    }
  );

  const youHaveNotSign = useMemo(() => {
    if (isHost && !booking.signed_by_host && booking.signed_by_guest) {
      return true;
    }
    if (!isHost && !booking.signed_by_guest && booking.signed_by_host) {
      return true;
    }
  }, [booking, isHost]);

  if (!booking || paidStatus === "t" || booking?.transactions?.length === 0)
    return <></>;

  if (
    (isHost && booking.signed_by_host) ||
    (!isHost && booking.signed_by_guest)
  )
    return <AlreadySignInfo booking={booking} isHost={isHost} />;

  return (
    <div>
      {!reviewSent && (
        <CreateReview
          booking={booking}
          setReviewSentRoot={setReviewSent}
          isHost={isHost}
        />
      )}
      {!!reviewSent && (
        <div className="w-full flex justify-center items-center py-5 px-3 flex-col">
          {canInteract?.type === "address" && (
            <WrongAddress {...canInteract?.info} className="pb-3" />
          )}
          {youHaveNotSign && isConnected && (
            <div className="w-full pb-3 flex flex-col items-center">
              <p className="text-nebulaOpac text-sm px-1 opacity-70 m-0">
                {`${t("The")} ${isHost ? "guest" : "host"} ${t(
                  "has already sign"
                )}`}
              </p>
              <p className="text-nebulaOpac text-sm px-1 opacity-70 m-0">
                {`${t("Please sign to release the payment")}`}
              </p>
            </div>
          )}
          <div
            className="bg-nebulaOpac rounded-lg py-3 text-2xl text-blueDark w-full text-center"
            onClick={() => {
              if (canInteract?.error) {
                if (!!canInteract?.onClick) canInteract?.onClick();
                return;
              }
              execute();
            }}
          >
            {!canInteract?.error ? t("Sign Release") : canInteract?.text}
          </div>
          <p className="text-nebulaOpac text-sm px-1 opacity-70 m-0 w-full text-center pt-2">
            {t("Was there an issue with your stay?")}
            {"  "}
            <strong
              className="underline cursor-pointer"
              onClick={() => history(`/dispute?id=${booking.id}`)}
            >
              {t("click here")}
            </strong>{" "}
            {t("To propose new payouts to the host")}
          </p>
        </div>
      )}
    </div>
  );
};

export default BookingContractInteraction;

const AlreadySignInfo = ({ booking, isHost }) => {
  const { t } = useTranslate();
  const whoDoesNotSign = useMemo(() => {
    if (!booking) return;
    if (isHost && booking.signed_by_host && !booking.signed_by_guest) {
      return "guest";
    }
    if (!isHost && booking.signed_by_guest && !booking.signed_by_host) {
      return "host";
    }
  }, [booking, isHost]);

  if (!booking) return <></>;

  return (
    <div className="w-full flex justify-center flex-col items-center">
      <p className="text-nebulaOpac text-lg px-1 m-0">
        {t("You already sign this reservation")}
      </p>
      {whoDoesNotSign && (
        <p className="text-nebulaOpac text-sm px-1 opacity-70 m-0">
          {`${t("Waiting on the")} ${whoDoesNotSign} ${t("to sign")}`}
        </p>
      )}
    </div>
  );
};
