import useToast from "./useToast";

const useCopyToClipboard = () => {
  const { notify } = useToast([
    {
      position: "bottom-center",
      type: "success",
      key: "s",
    },
  ]);

  //function to copy  the address
  function copyToClipboard(e, copy, message) {
    if(e) e.preventDefault();
    navigator.clipboard.writeText(copy);
    notify.s(message);
  }
  return {copyToClipboard};
};

export default useCopyToClipboard;
