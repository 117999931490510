import React from "react";
import Tooltip from "react-simple-tooltip";
import { css } from "styled-components";
import useHistory from "../../../../../../../mobile/hooks/useHistory";

const ToolTip = ({
  to = "#",
  text,
  children,
  placement = "right",
  className = "",
  onClick = () => {},
}) => {
  const history = useHistory();
  return (
    <>
      <div
        className={className}
        onClick={(e) => {
          if (to !== "#") {
            history(to);
          } else {
            onClick(e);
          }
        }}
      >
        {!text ? (
          children
        ) : (
          <Tooltip
            trigger={["click"]}
            content={text}
            color="#000"
            radius={6}
            placement={placement}
            background={"#fff"}
            fadeDuration={500}
            arrow={8}
            customCss={css`
              white-space: nowrap;
            `}
            padding={2}
            zIndex={2}
            border="white"
            fontSize={"14px"}
          >
            {children}
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default ToolTip;
