import { useDispatch } from "react-redux";
import { setWallets } from "../../app/actions";
import useAxios, { mapConfig } from "../../hooks/useAxios";

const useWalletAuth = () => {
  const { handleAxios } = useAxios();

  const handleAuthentication = (hash, callback) => {
    const configAxios = {
      path: `/secure/auth/web3/user`,
      method: "post",
      body: {
        hash,
      },
      postFetch: (res) => callback(res),
      toast: false,
    };
    handleAxios(configAxios);
  };

  const canSaveWallet = async (address, specialAuth, callback) => {
    let res = {};
    const configAxios = {
      path: `/wallets/auth?address=${address}`,
      method: "get",
      postFetch: (response) => {
        if (callback) callback(res);
        res = response;
      },
      toast: false,
      specialAuth,
    };
    await handleAxios(configAxios);
    return res;
  };

  const dispatch = useDispatch();

  const getWallets = async () => {
    await handleAxios(
      mapConfig(
        "/wallets/getWallets?wallet_type=payment&status=1&enabled=1&from=web",
        "get",
        {
          postFetch: (res) => {
            dispatch(setWallets(res));
          },
        }
      )
    );
  };

  return { handleAuthentication, canSaveWallet, getWallets };
};

export default useWalletAuth;
