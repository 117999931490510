import React from "react";
import styled from "styled-components";
import Item from "./Item";

const itemNames = [
  "bedroom",
  "bathroom",
  "beds",
  "half bathroom",
  "common space",
];
function Details({ filters }) {
  const { filter, setRooms } = filters;

  return (
    <DetailsStyled>
      <h2 className="headline px-5">Rooms</h2>
      {filter.rooms.map((item, i) => {
        return (
          <Item
            key={itemNames[i]}
            name={itemNames[i]}
            value={item.amount}
            setter={setRooms(i)}
          />
        );
      })}
      <div className="px-5 mt-5">
        <div className="separator" />
      </div>
    </DetailsStyled>
  );
}

const DetailsStyled = styled.div``;

export default Details;
