import React from "react";
import { Main, SupportComp } from "../components";

const Support = () => {
  return (
    <Main>
      <SupportComp />
    </Main>
  );
};

export default Support;
