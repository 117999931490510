import React from "react";
import styled from "styled-components";
import { useTranslate } from "../../../hooks";

function Header() {
  const { t } = useTranslate();
  return (
    <HeaderStyled className="px-[20px] mt-5 mb-8">
      <div className="option-button" role="button">
        {t("Save & exit")}
      </div>
      <div className="option-button" role="button">
        {t("Questions?")}
      </div>
    </HeaderStyled>
  );
}

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .option-button {
    padding: 5px 10px;
    border-radius: 20px;
    border: solid 2px rgba(255, 255, 255, 0.6);
    font-size: 0.8em;
    font-weight: 600;
  }
`;

export default Header;
