import React, { useMemo } from "react";
import useTranslate from "../mobile/hooks/useTranslate";
const validateICalUrl = (value, type) => {
  let error = false;
  if (!value) return false;
  if (type === "calendar") {
    const valueSplit1 = value?.split(".");
    if (valueSplit1?.length >= 1) {
      if (valueSplit1[0] !== "https://www") error = true;
      if (valueSplit1[1] !== "airbnb") error = true;
      const valueSplit2 = valueSplit1[2]?.split("/");
      if (valueSplit2?.length >= 2) {
        if (valueSplit2[1] !== "calendar") error = true;
        if (valueSplit2[2] !== "ical") error = true;
      }
    }
  }
  if (type === "listing") {
    const valueSplit1 = value?.split(".");
    if (valueSplit1?.length >= 1) {
      if (valueSplit1[0] !== "https://www") error = true;
      if (valueSplit1[1] !== "airbnb") error = true;
      const valueSplit2 = valueSplit1[3]?.split("/");
      if (valueSplit2?.length >= 2) {
        if (valueSplit2[1] !== "rooms") error = true;
      }
    }
  }
  return error;
};

export const useValidation = ({ values, validate }) => {
  const VALIDATIONS = {
    "special-character": /[^\w]/,
    email:
      /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi,
  };
  const { t } = useTranslate();
  const validation = (keyValue, key, value, custome) => {
    switch (key) {
      case "ical_url_calendar":
        const errorICal = validateICalUrl(values[keyValue], "calendar");
        return {
          error: errorICal,
          message: t("Must be a valid url for importing the calendar"),
        };
      case "ical_url_listing":
        const errorICalListing = validateICalUrl(values[keyValue], "listing");
        return {
          error: errorICalListing,
          message: t("Must be a valid url for importing the listing"),
        };
      case "text-min":
        return {
          error: values[keyValue]?.length < value,
          message: `${t("Must have more than ")} ${value} ${t("characters")}`,
        };
      case "text-max":
        return {
          error: values[keyValue]?.length >= value,
          message: `${t("Must have less than ")} ${value} ${t("characters")}`,
        };
      case "special-character":
        return {
          error: !values[keyValue]?.match(VALIDATIONS[key]),
          message: t("Muest have one special character"),
        };
      case "email":
        return {
          error: !values[keyValue]?.match(VALIDATIONS[key]),
          // error: VALIDATIONS[key].test(values[key]),
          message: t("Must be a valid email"),
        };
      case "blacklist":
        return {
          error: value.includes(values[keyValue]),
          message: custome
            ? `${custome} ${t("is not allowed")}`
            : "This is not allowed",
        };
      case "equals":
        return {
          error: values[keyValue] !== value.value,
          message: custome
            ? `${custome} ${t("must be equals to")} ${value.message}`
            : `${t("Must be equals to")} ${value.message}`,
        };
      case "required-number":
        return {
          error: isNaN(values[keyValue]),
          message: custome
            ? `${custome} ${t("must be only numbers")}`
            : t("Must be only numbers"),
        };
      case "string-not-only-number":
        return {
          error: /^\d+$/.test(values[keyValue]),
          message: t("Can't be a only numbers"),
        };
      case "string-not-only-letters":
        return {
          error: /^[A-Za-z]+$/.test(values[keyValue]),
          message: t("Can't be a only text"),
        };
      case "string-not-spaces":
        return {
          error: /\s/.test(values[keyValue]),
          message: t("Can't contain whitespaces"),
        };
      case "required":
        return {
          error: !values[keyValue],
          message: custome
            ? `${custome} ${t("is required")}`
            : t("This field is required"),
        };
      default:
        return false;
    }
  };
  const errors = useMemo(() => {
    let error = {};
    for (let i in validate) {
      const validateValue = validate[i];
      const conditional = validateValue.valid.find(
        (item) => item.key === "required" || item.key === "required-custome"
      )
        ? true
        : values[validateValue.keyValue];
      if (conditional) {
        for (let index in validateValue.valid) {
          const validValue = validateValue.valid[index];
          const isValid = validation(
            validateValue.keyValue,
            validValue.key,
            validValue?.value,
            validateValue.custome
          );
          if (isValid.error) {
            error[validateValue.keyValue] = isValid.message;
          }
        }
      }
    }
    return error;
  }, [validate, values]);

  const hasErrors = useMemo(() => {
    let error = false;
    for (let key of Object.keys(values)) {
      if (errors[key]) {
        error = true;
      }
    }
    return error;
  }, [errors]);

  return { errors, hasErrors };
};
