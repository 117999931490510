import React from "react";
import MainMessages from "../../../features/userdashboard/Host/components/Messages/MainMessages";
import { ComponentTitle, Main } from "../commons";
const Msgcomp = () => {
  return (
    <Main>
      <ComponentTitle title={"Messages"} />
      <MainMessages />
    </Main>
  );
};

export default Msgcomp;
