import React from "react";
import Lodgix from "../../../../SVGComponents/Lodgix";

const INTEGRATIONS = {
  LODGIX: 1,
};

const style = {
  borderRadius: "10px",
};
const IconsIntegrations = ({ id, ...props }) => {
  if (id === INTEGRATIONS.LODGIX)
    return <Lodgix style={style} color="#fff" {...props} />;
  return <></>;
};

export default IconsIntegrations;
