import React, { useMemo } from "react";
import StarRating from "../StarRating";
import ShowUser, { ProfileImage } from "../ShowUser";
import { useTranslate } from "../../../hooks";
import { format } from "date-fns";

const ReviewCard = ({ date, text, user, rating, seeMore = () => {} }) => {
  const { t } = useTranslate();
  const memberSince = useMemo(
    () =>
      !user
        ? ""
        : `Since: ${format(new Date(user?.created_at), "MMM dd")} of ${format(
            new Date(user?.created_at),
            "yyyy"
          )}`,
    [user]
  );
  return (
    <div
      style={{
        width: "20em",
        height: "15em",
        flex: "0 0 auto",
      }}
      className="border-2 border-blueContrast rounded-lg p-2 gap-2 flex flex-col justify-between"
    >
      <div className="flex flex-col gap-2">
        <StarRating
          routeRating={rating}
          disabled={true}
          date={date}
          classNameStars="1em"
        />
        {text?.length > 150 ? (
          <>
            <p className="m-0 text-nebulaOpac text-md">
              {`${text.slice(0, 150)}...`}
            </p>
            <span
              onClick={seeMore}
              className="underline text-nebulaOpac text-md"
            >
              {t("See more")}
            </span>
          </>
        ) : (
          <p className="m-0 text-nebulaOpac text-lg">{text}</p>
        )}
      </div>
      <div className="w-full flex gap-3 px-2">
        <ProfileImage url={user?.profile_image_url} size="3" />
        <div className="flex flex-col justify-center items-start">
          <span
            style={{ lineHeight: "15px" }}
            className="text-nebulaOpac font-semibold text-md"
          >{` ${user?.full_name}`}</span>
          <span
            style={{ lineHeight: "20px" }}
            className="text-nebulaOpac opacity-50 text-md"
          >
            {memberSince}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
