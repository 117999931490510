import React from "react";
import { CardSlider } from "../../../features/Home/CardSlider";
import { useTranslate } from "../../hooks";

const Badges = ({ options, value, onSelect }) => {
  const { t } = useTranslate();
  return (
    <CardSlider gap="2">
      {options &&
        options?.map((o) => (
          <div
            onClick={() => onSelect(o)}
            className={`border-2 ${
              value?.id === o.id ? "border-nebula" : "border-blueContrast"
            } flex justify-center items-center w-fit px-5 py-3 rounded-full transition-all cursor-pointer`}
          >
            {t(o.label)}
          </div>
        ))}
    </CardSlider>
  );
};

export default Badges;
