import React, { useEffect, useRef, useState } from "react";
import useTranslate from "../mobile/hooks/useTranslate";
import ManageLoading from "./ManageLoading";

function useOutsideAlerter(callback, ref) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const PlacesAutocompleteNew = ({
  options,
  onSelect,
  value,
  placeholder,
  search,
  onChange,
  loading,
  selectHeight = "20em",
}) => {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useOutsideAlerter(() => {
    setIsOpen(false);
  }, wrapperRef);
  const { t } = useTranslate();

  return (
    <div ref={wrapperRef} className="relative inline-block text-left w-full">
      <div className="flex justify-between">
        <input
          onClick={() => setIsOpen(true)}
          type="text"
          className="inline-flex text-lg focus-visible:outline-none border-b border-blueDark  w-full justify-center gap-x-1.5 rounded-fill bg-blueContrast px-3 py-2 text-gray-900 hover:bg-gray-50"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          placeholder={placeholder}
          onChange={({ target }) => onChange(target.value, false)}
          value={search}
          autoComplete={"off"}
        />
        <ManageLoading size={25} loading={loading}>
          {""}
        </ManageLoading>
      </div>
      <div
        className={`${
          !isOpen ? "hidden" : ""
        } absolute w-full overflow-y-scroll ${
          options?.length > 0 ? "border border-blueDark mt-2" : ""
        } right-0 z-10 origin-top-right rounded-lg bg-blueContrast shadow-lg`}
        role="menu"
        style={{
          maxHeight: selectHeight,
          boxShadow: "0 0 0 1px rgb(0 0 0 / 4%), 0 6px 20px rgb(0 0 0 / 20%)",
        }}
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex="-1"
      >
        <div className={`${options?.length > 0 ? "py-1" : ""}`} role="none">
          {options?.length === 0 && (
            <span
              className={`text-nebula block px-4 py-2 text-sm`}
              role="menuitem"
              tabIndex="-1"
              id={`menu-item-0`}
            >
              {t("No Results")}
            </span>
          )}
          {options &&
            options.map((option, index) => (
              <span
                key={index}
                onClick={() => {
                  if (option.id === -1) return;
                  onSelect(option);
                  onChange(option.label, true);
                  setIsOpen(false);
                }}
                className={`${
                  option?.id === value?.id
                    ? "bg-blueContrast text-nebula rounded-full"
                    : "text-nebula"
                } block px-4 py-2 ${
                  option.id !== -1 ? "cursor-pointer" : "!cursor-auto"
                } text-sm`}
                role="menuitem"
                tabIndex="-1"
                id={`menu-item-${index}`}
              >
                {t(option.label)}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PlacesAutocompleteNew;
