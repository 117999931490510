import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessageAction } from "../../../../../../app/actions";
import { mapConfig } from "../../../../../../hooks/useAxios";
import useSimpleAxios from "../../../../../../hooks/useSimpleAxios";
import useUserState from "../../../../../../mobile/hooks/useUserState";

const MainMessagesLogic = () => {
  const disPatch = useDispatch();
  const { userState } = useUserState();
  const [isLoading, setIsLoading] = useState(false);
  const [radio, setRadio] = useState("A");
  const options = [
    {
      value: "H",
      label: "Hosted",
    },
    {
      value: "G",
      label: "Traveled",
    },
    {
      value: "A",
      label: "All",
    },
  ];
  //example useSimpleAxios
  const {
    value: messages,
    work,
    recall,
  } = useSimpleAxios(mapConfig("/messages/getMessages", "get"));

  const renderMessages = useMemo(() => {
    if (messages && work === false) {
      if (radio === "H") {
        return messages.filter((item) => item.type === "hosted");
      }
      if (radio === "G") {
        return messages.filter((item) => item.type === "travaled");
      }
      if (radio === "A") {
        return messages;
      }
    } else {
      return [];
    }
  }, [radio, messages, work]);

  return {
    isLoading,
    radio,
    messageWork: work,
    options,
    setIsLoading,
    setRadio,
    userState,
    disPatch,
    renderMessages,
    setMessageAction,
    recall,
  };
};

export default MainMessagesLogic;
