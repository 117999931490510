import React, { useMemo } from "react";

export default function Button({
  onClick,
  className = "px-5 py-2 rounded-full",
  children,
  type,
  disable = false,
  onClickDisable = () => {},
}) {
  const color = useMemo(() => {
    switch (type) {
      case "light":
        return {
          bg: "bg-nebula",
          text: "text-greenDark",
          shadow: "drop-shadow-lg",
        };
      case "dark":
        return { bg: "bg-blueDark", text: "text-white" };
      case "medium":
        return { bg: "bg-greenDark", text: "text-white" };
      case "cancel":
        return { bg: "bg-[#ff595990]", text: "text-white" };
      default:
        return { bg: "bg-blueContrast", text: "text-nebula" };
    }
  }, [type]);

  return (
    <div
      className={`
      ${color.bg + " " + color.text + " " + color.shadow || ""} 
      tansition-all duration-300 cursor-pointer
      ${disable ? "!bg-nebula opacity-50 !text-greenDark" : ""}  
      ${className}`}
      onClick={(e) => {
        if (disable) onClickDisable();
        if (!disable) onClick(e);
      }}
    >
      {children}
    </div>
  );
}
