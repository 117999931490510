import React from "react";
import useAxios, { mapConfig } from "./useAxios";
import useChainId from "../../hooks/useChainId";

const useSaveContract = () => {
  const { handleAxios } = useAxios();
  const chain_id = useChainId();
  const saveContract = (contract_address, wallet_address, callback) =>
    handleAxios(
      mapConfig("/users/save_contract", "put", {
        body: {
          contract_address,
          wallet_address,
          chain_id,
        },
        postFetch: (res) => {
          if (callback) callback(res);
        },
      })
    );

  return saveContract;
};

export default useSaveContract;
