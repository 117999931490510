export const RADIUS_BY_ZOOM = {
  7: 256000,
  8: 128000,
  9: 64000,
  10: 32000,
  11: 1600,
  12: 8000,
  13: 4000,
  14: 2000,
  15: 1000,
  16: 500,
  17: 250,
};
export const RADIUS_BY_ZOOM_MOBILE = {
  7: 192000,
  8: 96000,
  9: 48000,
  10: 24000,
  11: 12000,
  12: 6000,
  13: 3000,
  14: 1500,
  15: 750,
  16: 375,
  17: 187,
};
