import React from "react";

export default function MaxWidthDiv({ children, className, classNameInner }) {
  return (
    <div
      className={"flex w-full justify-center items-center" + className || ""}
    >
      <div className={`w-full flex flex-col max-w-7xl ${classNameInner || ""}`}>
        {children}
      </div>
    </div>
  );
}
