import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";
import useLoadAllData from "../../../../../hooks/useLoadAllData";
import LoadingWrapper from "../../../commons/LoadingWrapper";
import { useTranslate } from "../../../../hooks";

function Categories({ listing, setListing }) {
  const {
    data: categories,
    isLoading,
    error,
    refetch,
  } = useLoadAllData("category/");

  const categoriesRef = useRef();
  const { t } = useTranslate();

  const [imagesLoaded, setImagesLoaded] = useState({ 8: true, 25: true });

  const selectCategory = (id) =>
    setListing((prev) => ({ ...prev, category: id }));

  useEffect(() => {
    if (!!categories && !isLoading && categoriesRef.current) {
      const items = categoriesRef.current.children;
      gsap.to([...items], {
        duration: 0.8,
        scale: 1,
        opacity: 1,
        stagger: { from: "random", each: 0.03, ease: "sine.out" },
        ease: "back.out(2.5)",
      });
    }
  }, [categories, isLoading, categoriesRef.current]);

  return (
    <CategoriesStyled className="relative px-[20px]">
      <h2 className="title">{t("Which of these best describes your place?")}</h2>
      <LoadingWrapper
        isLoading={isLoading}
        error={error}
        refetch={refetch}
        style={{
          flexGrow: 1,
          overflowY: "auto",
        }}
      >
        <div ref={categoriesRef} className="categories">
          {categories?.map(
            ({ id, category_code, category_name, category_image }) => {
              const hasImageLoaded = imagesLoaded[id] || false;

              return (
                <div
                  key={category_code}
                  className={`category ${
                    listing.category === id ? "selected" : ""
                  }`}
                  onClick={() => selectCategory(id)}
                >
                  {!hasImageLoaded && <div className="skeleton" />}
                  {category_image ? (
                    <img
                      className="category-image"
                      src={category_image}
                      alt={category_code}
                      onLoad={() =>
                        setImagesLoaded((prevState) => ({
                          ...prevState,
                          [id]: true,
                        }))
                      }
                    />
                  ) : (
                    <div
                      className="category-image"
                      style={{
                        backgroundColor: "rgba(255,255,255,.7)",
                      }}
                    />
                  )}

                  <span>{category_name}</span>
                </div>
              );
            }
          )}
        </div>
      </LoadingWrapper>
    </CategoriesStyled>
  );
}

const CategoriesStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;

  .categories {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
    gap: 10px;
    padding: 10px 0 20px;
    overflow-x: hidden;

    @media (max-width: 750px) {
      grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    }
    @media (max-width: 500px) {
      grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    }
    @media (max-width: 350px) {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }

    .category {
      position: relative;
      height: 80px;
      padding: 0px 10px 10px;
      border-radius: 14px;
      font-weight: 600;
      user-select: none;
      opacity: 0;
      transform: scale(0.7);
      overflow: hidden;
      cursor: pointer;
      &:hover {
        .category-image {
          opacity: 0.5;
          filter: grayscale(80%);
        }
      }
      &.selected {
        .category-image {
          opacity: 1;
          filter: grayscale(0);
        }

        span {
          color: #1c2c49;
          background-color: #fff;
        }
      }

      .skeleton {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: 200% 100% !important;
        background: linear-gradient(90deg, #ddd 0px, #fff 50px, #ddd 100px);
        animation: load 2s linear infinite;
        @keyframes load {
          0% {
            background-position-x: -100px;
          }
          100% {
            background-position-x: -100%;
          }
        }
      }

      .category-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        filter: grayscale(100%);
        opacity: 0.2;
        transition: all 0.25s linear;
      }

      span {
        position: absolute;
        bottom: 10px;
        display: inline-block;
        max-width: 85px;
        padding: 5px;
        border-radius: 12px;
        color: #fff;
        background-color: transparent;
        font-size: 0.8em;
        line-height: 1;
        transition: color 0.15s linear, background-color 0.25s linear;
      }
    }
  }
`;

export default Categories;
