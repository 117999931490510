import React, { useMemo } from "react";
import { useTranslate } from "../../hooks";

const ShowCancelationPolicy = ({ policy }) => {
  const { t } = useTranslate();

  const can = useMemo(
    () => (policy?.length > 0 ? policy[0].cancellation_policy : ""),
    [policy]
  );

  return (
    <div className="p-5 flex flex-col justify-start items-start w-full">
      <span className="text-nebula font-medium text-3xl pb-5">
        {t("Cancellation Policy")}
      </span>
      <span className="text-nebulaOpac opacity-50 text-2xl pb-1">
        {`${t("Type:")} ${t(can.policy_name)}`}
      </span>
      <span className="text-nebulaOpac opacity-50">{t(can.description)}</span>
    </div>
  );
};

export default ShowCancelationPolicy;
