import { useWeb3React } from "@web3-react/core";
import useSocket from "./useSocket";
import useHistory from "../mobile/hooks/useHistory";
import { useDisconnect } from "wagmi";
import { useStore } from "../mobile/hooks/useStore";

const useLogOut = () => {
  const history = useHistory();
  // const { deactivate } = useWeb3React();
  const { disconnect: disWallet } = useDisconnect();
  const { disconnect } = useSocket();
  const { setUserNull } = useStore();

  const logout = (redirect) => {
    disWallet();
    window.sessionStorage.removeItem("userState");
    setUserNull();
    disconnect("noti");
    localStorage.removeItem("previouslyConnected");
    localStorage.removeItem("isModalProviderShown");
    if (redirect) {
      history("/");
    }
  };

  return { logout };
};

export default useLogOut;
