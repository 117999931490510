
export const TYPES = {
  GENERAL: "general",
  BOOKING_CREATED: "booking_created",
  BOOKING_SIGN: "booking_sign",
  BOOKING_PAYOUT: "booking_payout",
  KYC: "kyc",
  LISTING_PRE_CONTRACT: "listing_pre_contract",
  MARKETING: "marketing",
  MESSAGE: "message",
  BOOKING_CANCEL: "booking_cancel",
  USER_KYC_FILES_REJECTED: "user_kyc_files_rejected",
  USER_REJECTED: "user_rejected",
  USER_VALIDATED: "user_validated",
  LISTING_REJECTED: "listing_rejected",
  LISTING_BILLING_REJECTED: "listing_billing_rejected",
  LISTING_VALIDATED: "listing_validated",
  DONATION_GRATITUDE: "donation_gratitude",
  DISPUTE_STARTED: "dispute_started",
  DISPUTE_SIGNED: "dispute_signed",
  DISPUTE_COMPLETED: "dispute_completed",
  OFFER_CREATED: "offer_created",
  DISPUTE_SOLVING_BY_COSTUMER_SERVICE: "dispute_solving_by_costumer_service",
  DISPUTE_SIGNED_BY_ADMIN: "dispute_signed_by_admin",
};

export const STATUS = {
  READ: 1,
  UNREAD: 0,
  DELETED: 3,
};

export const NOTIFICATIONS = {
  general: {
    title: "Test",
    type: "general",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  booking_created: {
    type: "booking_created",
    title: "Reservation",
    title2: "You made a new reservation",
    content: "Made by ",
  },
  booking_sign: {
    type: "booking_sign",
    title: "Signature",
    content: "Made by ",
  },
  booking_payout: {
    type: "booking_payout",
    title: "Funds Released",
    content: "Reservation is finished",
  },
  kyc: {
    type: "kyc",
    title: "Complete your KYC",
    content: "Please complete your kyc to start using bbop",
  },
  listing_pre_contract: {
    type: "listing_pre_contract",
    title: "Listing on pre-contract",
    content:
      "Your listing have been validated, you need to create your contract",
  },
  marketing: {
    type: "marketing",
    title: "Marketing",
    content: "We have a new offer for you",
  },
  message: {
    type: "message",
    title: "Message",
    content: "You have a new message",
  },
  booking_cancel: {
    type: "booking_cancel",
    title: "Reservation",
    content: "Reservation is canceled",
  },
  user_kyc_files_rejected: {
    type: "user_kyc_files_rejected",
    title: "KYC",
    content: "Your kyc files are rejected",
  },
  user_rejected: {
    type: "user_rejected",
    title: "User",
    content: "Your account was rejected by kyc",
  },
  user_validated: {
    type: "user_validated",
    title: "User",
    content: "Your account is validated",
  },
  listing_rejected: {
    type: "listing_rejected",
    title: "Listing",
    content: "Your listing was rejected",
  },
  listing_billing_rejected: {
    type: "listing_billing_rejected",
    title: "Listing",
    content: "Your billing was rejected",
  },
  listing_validated: {
    type: "listing_validated",
    title: "Listing",
    content: "Your listing was validated",
  },
  donation_gratitude: {
    type: "donation_gratitude",
    title: "Donation",
    content: "Thank you for your donation",
  },
  dispute_started: {
    type: "dispute_started",
    title: "Dispute",
    content: "Dispute is started",
  },
  dispute_signed: {
    type: "dispute_signed",
    title: "Dispute",
    content: "Dispute is signed",
  },
  dispute_completed: {
    type: "dispute_completed",
    title: "Funds Released",
    content: "Dispute is completed",
  },
  offer_created: {
    type: "offer_created",
    title: "Offer",
    content: "You have a new offer",
  },
  dispute_solving_by_costumer_service: {
    type: "dispute_solving_by_costumer_service",
    title: "Dispute",
    content: "Dispute is being solved by costumer service",
  },
  dispute_signed_by_admin: {
    type: "dispute_signed_by_admin",
    title: "Funds Released",
    content: "Dispute is signed by admin",
  },
};
