import React from "react";
import { useTranslate } from "../../hooks";

const ComponentTitle = ({ title, px = "5", center }) => {
  const { t } = useTranslate();
  return (
    <div className={`w-full text-nebula py-5 `}>
      <p
        className={`m-0 text-4xl text-nebulaOpac tracking-wider px-${px} ${
          center ? "text-center" : ""
        }`}
      >
        {t(title)}
      </p>
    </div>
  );
};

export default ComponentTitle;
