import { useState } from "react";
import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { BiFilterAlt } from "react-icons/bi";
import Accordion from "../commons/Accordion";
import Button from "../commons/Button";
import { InputSelect, InputText } from "../commons/Form";
import { IoIosSearch } from "react-icons/io";

const useFilter = ({
  filter,
  apply,
  placeholder = "Search",
  sortOptions,
  sortDefault,
  sortDefaultDirection = "ASC",
  isAccordion = false,
  isAuto = false,
  isSort = true,
}) => {
  const [search, setSearch] = useState("");
  const [sortDirection, setSortDirection] = useState(sortDefaultDirection);
  const [sort, setSort] = useState(sortDefault);

  const generateFilterParams = (props) => {
    return `?${props
      .map((item) =>
        item.search
          ? `${item.key}=${search || null}`
          : `${item.key}=${item.value}`
      )
      .join("&")}`;
  };

  const handleSortDirectionOnChange = () => {
    setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
  };

  const classNameSortDirection = "text-3xl cursor-pointer my-2 mx-4 text-teal";

  return {
    generateFilterParams,
    search,
    sort: { value: sort, direction: sortDirection },
    input: (
      <>
        <div className="flex flex-col w-full items-end">
          <div className="flex flex-col w-full md:flex-row md:px-5 pt-2 gap-1">
            <InputText
              onChange={(e) => setSearch(e.target.value)}
              placeholder={placeholder}
              value={search}
              className="w-full h-full"
              classNameInput="w-full h-full"
              classNameRoot="w-full h-12 md:ml-1 my-1"
              id="label"
              type="text"
              inputPrompt={<IoIosSearch className="text-xl" />}
            />
            {filter && !isAccordion && filter}
          </div>
          {sortOptions && isSort && (
            <div className="w-fit flex justify-end items-center mt-2 md:m-2">
              <InputSelect
                className="w-[8em]"
                options={sortOptions}
                value={sort}
                onChange={(e) => setSort(e.target.value)}
              />
              {sortDirection === "ASC" ? (
                <AiOutlineSortAscending
                  onClick={() => handleSortDirectionOnChange()}
                  className={classNameSortDirection}
                />
              ) : (
                <AiOutlineSortDescending
                  onClick={() => handleSortDirectionOnChange()}
                  className={classNameSortDirection}
                />
              )}
              {!isAccordion && !isAuto && (
                <Button
                  className="rounded-full py-2 px-5 cursor-pointer"
                  onClick={() => apply()}
                >
                  Apply
                </Button>
              )}
            </div>
          )}
          {filter && isAccordion && (
            <div className="w-full flex items-center">
              <Accordion
                icon={<BiFilterAlt className="text-3xl" />}
                className="justify-end"
                text="Filter"
              >
                <div className="w-full flex justify-end">
                  <Button onClick={() => apply()}>Apply</Button>
                </div>
                {filter}
              </Accordion>
            </div>
          )}
        </div>
      </>
    ),
  };
};

export default useFilter;
