import React, { useEffect, useMemo } from "react";
import {
  AvailableNetworks,
  BackButton,
  Divider,
  InfoToModal,
} from "../commons";
import {
  useCalculatePrice,
  useCalculateSpecialPrice,
  useGetData,
  useHistory,
  useQuery,
  useTranslate,
  useUserState,
} from "../../hooks";
import ListingInfo from "./ListingInfo";
import ReserveInfo from "./ReserveInfo";
import PayButton from "./PayButton";
import ReserveEditData from "./ReserveEditData";
import ShowCancelationPolicy from "../listing/ShowCancelationPolicy";

const queryKeys = ["dates", "id", "guests"];

const ReserveComp = () => {
  const { query, queryToPath } = useQuery(queryKeys);
  const { userStore } = useUserState();
  const { t } = useTranslate();
  const history = useHistory();

  const listing = useGetData(
    `/listing/getListingById?id=${query?.id}${
      userStore?.id ? `&userId=${userStore?.id}` : ""
    }`,
    false,
    !!query?.id || !!query?.dates
  );

  const dates = useMemo(() => {
    if (!query?.dates) return;
    return {
      start: new Date(query?.dates.startDate),
      end: new Date(query?.dates.endDate),
    };
  }, [query?.dates]);

  const { specialPriceDays, specialPriceDates } = useCalculateSpecialPrice({
    start: dates?.start,
    end: dates?.end,
    listing: { listingSpecialPrices: listing?.listingSpecialPrices },
  });

  const prices = useCalculatePrice({
    dates,
    config: false,
    listing: listing,
    specialPriceDays,
  });

  useEffect(() => {
    if (!query?.dates || !query?.id || !query?.guests) {
      history("/explore");
    }
  }, []);

  if (!query?.dates || !query?.id || !query?.guests || !listing) return <></>;

  return (
    <div className="w-full">
      <BackButton to={`/listing${queryToPath}`} />
      <p className="text-nebula text-2xl w-full text-center pt-[3em] m-0">
        {t("Pay and Reserve")}
      </p>
      <Divider />
      <ListingInfo listing={listing} />
      <Divider />
      <ReserveEditData
        listing={listing}
        specialPriceDates={specialPriceDates}
      />
      <Divider />
      <ReserveInfo listing={listing} prices={prices} />
      <PayButton prices={prices} listing={listing} />
      <Divider />
      <AvailableNetworks contracts={listing?.user?.contract_address} />
      <Divider />
      <ShowCancelationPolicy policy={listing?.listing_cancellation_policies} />
      {listing?.rules_and_conditions && (
        <>
          <Divider />
          <InfoToModal
            info={listing?.rules_and_conditions}
            title={"House Rules"}
            className="opacity-50"
            showLength={250}
            maxLength={255}
          />
        </>
      )}
    </div>
  );
};

export default ReserveComp;
