import React from "react";
import Loader from "react-loader-spinner";

export default function Loading({
  type = "",
  className = " h-screen",
  size = 25,
  loadingText = "Loading...",
}) {
  return (
    <>
      {!type ? (
        <div
          className={`w-full absolute flex justify-center items-center bg-blueDark ${className}`}
        >
          <Loader
            className="ml-2"
            type="Rings"
            color="#00BFFF"
            height={size}
            width={size}
          />
        </div>
      ) : (
        <div className={`w-full flex justify-center items-center h-full`}>
          <Loader
            className="ml-2"
            type="Rings"
            color="#00BFFF"
            height={size}
            width={size}
          />
        </div>
      )}
    </>
  );
}
