import { useDispatch } from "react-redux";
import { useStore } from "../mobile/hooks/useStore";

const useGlobalComp = () => {
  const dispatch = useDispatch();
  const { setLoadingContractInteraction } = useStore();

  const loginModal = (v) => {
    dispatch({
      type: "IS_MODAL_LOGIN_OPEN",
      payload: v,
    });
  };
  const signUpModal = (v) => {
    dispatch({
      type: "IS_MODAL_SIGN_UP_OPEN",
      payload: v,
    });
  };

  const loadContractInteraction = (v) => {
    setLoadingContractInteraction(v);
    // dispatch({
    //   type: "LOADING_CONTRACT_INTERACTION",
    //   payload: v,
    // });
  };
  const signWalletModal = (v) => {
    dispatch({
      type: "IS_MODAL_SIGN_WALLET_OPEN",
      payload: v,
    });
  };

  const handleContractModal = (v) => {
    dispatch({
      type: "IS_MODAL_CONTRACT",
      payload: v,
    });
  };

  return {
    loginModal,
    signUpModal,
    loadContractInteraction,
    signWalletModal,
    handleContractModal,
  };
};

export default useGlobalComp;
