import { useState, useEffect } from "react";
import axios from "axios";
import useApiUrl from "../mobile/hooks/useApiUrl";

function useLoadAllData(url) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const base = useApiUrl();

  const fetchData = async () => {
    try {
      const response = await axios.get(`${base}/${url}`);
      setData(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const refetch = () => {
    setIsLoading(true);
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  return { data, isLoading, error, refetch };
}

// function useLoadAllData(url) {
//   const [data, setData] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(url);
//         if (!response.ok) {
//           throw new Error("Error fetching data");
//         }
//         const data = await response.json();
//         setData(data);
//       } catch (error) {
//         setError(error.message);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchData();
//   }, [url]);

//   return { data, isLoading, error };
// }

export default useLoadAllData;
