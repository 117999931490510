import React, { useEffect } from "react";
import styled from "styled-components";
import { useQuery } from "./../../../../../hooks";

function Footer({ filterPrices, filters, places, close }) {
  const { filter, setInitialFilter } = filters;

  const { changeQuery } = useQuery(["dates", "guests", "location"]);

  const onShowPlaces = () => {
    if (!places) return;

    changeQuery({
      filter: {
        ...filter,
        price: {
          min: Number(filterPrices.min),
          max: Number(filterPrices.max),
        },
      },
    });
    close();
  };

  return (
    <FooterStyled>
      <div className="cleaner" role="button" onClick={setInitialFilter}>
        Remove all
      </div>
      <div
        className={`show-places ${!places ? "disabled" : ""}`}
        role="button"
        onClick={onShowPlaces}
      >
        Show {places} places
      </div>
    </FooterStyled>
  );
}

const FooterStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  padding: 16px 24px;
  border-bottom: 1px solid #1c2c49;
  background-color: #1c2c49;
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.2);
  font-size: 0.9em;
  font-weight: 600;
  z-index: 1;

  .show-places {
    background-color: #fff;
    color: #1c2c49;
    padding: 10px 15px;
    border-radius: 8px;
    user-select: none;
    &.disabled {
      filter: grayscale(100%);
      cursor: default;
    }
  }
`;

export default Footer;
