import React from "react";
import { RangeDatePicker } from "../../../../commons/RangeDatePicker";

const EditDates = ({ onChange, dates }) => {
  return (
    <div>
      <RangeDatePicker
        changeDates={onChange}
        startDate={dates.startDate || new Date()}
        endDate={dates.endDate || new Date()}
        direction="vertical"
        className={`p-3 md:p-5 w-full flex flex-col items-center justify-start`}
      />
    </div>
  );
};

export default EditDates;
