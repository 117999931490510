import React from "react";
import styled from "styled-components";
import HorizontalScrolling from "../../../HorizontalScrolling";

const numbers = [...Array(9).keys()].map((i, _, arr) => {
  if (i === 0) return "Any";
  if (i === arr.length - 1) return `${arr.length - 1}+`;
  return i;
});

function Item({ name, value, setter }) {
  return (
    <ItemStyled className="item-wrapper">
      <p className="item-detail px-5">{name}</p>
      <HorizontalScrolling>
        {numbers.map((key, i) => (
          <div
            key={key}
            className={`item-content ${i === 0 ? "ml-5 mr-5" : "mr-5"} ${
              i === value && "selected"
            }`}
            onClick={() => setter(i)}
          >
            {key}
          </div>
        ))}
      </HorizontalScrolling>
    </ItemStyled>
  );
}

const ItemStyled = styled.div`
  .item-detail {
    margin: 50px 0 10px;
    font-size: 1em;
    text-transform: capitalize;
    &:first-of-type {
      margin-top: 20px;
    }
  }
  .item-content {
    padding: 8px 25px;
    margin-bottom: 20px;
    border-radius: 30px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background-color: transparent;
    font-weight: 500;
    user-select: none;
    transition: all 0.25s linear;
    &.selected {
      background-color: rgba(255, 255, 255, 1);
      color: #1c2c49;
    }
    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.8);
      cursor: pointer;
    }
  }
`;

export default Item;
