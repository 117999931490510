export const categories = [
  {
    id: 1,
    category_name: "Apartment",
    category_code: "APARTMENT",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/images/4d46430c-74e4-469b-945a-4e63db95d509_1659559038872_NaNAPRM_5.png",
    description:
      "You can choose any residential unit inside a building. The building can be a house, townhouse, large residential building, and even condominium.",
  },
  {
    id: 4,
    category_name: "Barn",
    category_code: "BARN",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/images/bff40dcb-dded-42f8-9732-052a5487d44b_1659558943387_NaNHOUSES_6.png",
    description:
      "If you are looking for a home away from home. With a full venue, you will have all the space to yourself. This usually includes a bedroom, a bathroom, a kitchen, and a separate, dedicated entrance.",
  },
  {
    id: 6,
    category_name: "Bed and Breakfast",
    category_code: "BED_AND_BR",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/images/27cc7581-9d5a-4fdc-9992-995b5259ddec_1659558997071_NaNB%26B_7.png",
    description:
      "Bed and Breakfast are great when you prefer a little privacy but still value a local connection. When you book a private room, you will have your own private room to sleep in and you will be able to share some spaces with other people.",
  },
  {
    id: 48,
    category_name: "Boat",
    category_code: "BOAT",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Boat.png",
    description:
      "Stay afloat with our selection of boats, from sailboats to luxurious yachts. Enjoy breathtaking views, gentle waves, and the freedom to explore coastal or inland waters. Experience a one-of-a-kind maritime adventure with a range of accommodations",
  },
  {
    id: 9,
    category_name: "Bungalow",
    category_code: "BUNGALOW",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Bungalow.png",
    description:
      "Cozy, single-story dwelling with a small yard, ideal for a quiet retreat.",
  },
  {
    id: 10,
    category_name: "Cabin",
    category_code: "CABIN",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Cabin.png",
    description:
      "Rustic and charming retreat surrounded by nature, offering a back-to-basics experience.",
  },
  {
    id: 11,
    category_name: "Campsite",
    category_code: "CAMPSITE",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Campsite.png",
    description:
      "Outdoor area equipped with facilities for camping enthusiasts to pitch tents or park RVs.",
  },
  {
    id: 12,
    category_name: "Castle",
    category_code: "CASTLE",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Castle.png",
    description:
      "Majestic, historically significant fortress available for exclusive stays, providing a regal experience.",
  },
  {
    id: 13,
    category_name: "Chacara",
    category_code: "CHACARA",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Chacara.png",
    description:
      "Brazilian country house with extensive land, perfect for nature lovers and outdoor activities.",
  },
  {
    id: 14,
    category_name: "Chalet",
    category_code: "CHALET",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Chalet.png",
    description:
      "Alpine-style wooden house, nestled in scenic locations, providing a cozy and picturesque escape.",
  },
  {
    id: 15,
    category_name: "Condo",
    category_code: "CONDO",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Condo.png",
    description:
      "Modern and stylish apartment unit within a shared complex, offering comfort and convenience",
  },
  {
    id: 16,
    category_name: "CondoTel",
    category_code: "CONDOTEL",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Condotel.png",
    description:
      "Combination of a condo and a hotel, providing both ownership and rental options with hotel-like amenities.",
  },
  {
    id: 17,
    category_name: "Cottage",
    category_code: "COTTAGE",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Cottage+resort.png",
    description:
      "Quaint and charming small house, often located in a peaceful, rural setting, providing a serene atmosphere.",
  },
  {
    id: 18,
    category_name: "Cottage Resort",
    category_code: "COTTAGE_RE",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Cottage.png",
    description:
      "Collection of cottages in a resort setting, offering a relaxing vacation with access to amenities",
  },
  {
    id: 19,
    category_name: "Country House / Chateau",
    category_code: "COUNTRY_HO",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Country+House+_+Chateau.png",
    description:
      "Spacious and elegant house or mansion in a countryside or estate setting, perfect for luxurious getaways.",
  },
  {
    id: 20,
    category_name: "Estate",
    category_code: "ESTATE",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Estate+land.png",
    description:
      "Sprawling property with a grand house and extensive grounds, offering privacy and luxury.",
  },
  {
    id: 21,
    category_name: "Farmhouse",
    category_code: "FARMHOUSE",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/farmhouse.png",
    description:
      "Traditional house located on a working farm, allowing guests to experience rural life and enjoy fresh produce.",
  },
  {
    id: 23,
    category_name: "Guest House",
    category_code: "GUEST_HOUS",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Guesthouse.png",
    description:
      "Similar to a guesthouse, providing lodging separate from the host's main residence, suitable for short-term stays.",
  },
  {
    id: 22,
    category_name: "Guesthouse",
    category_code: "GUESTHOUSE",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Guesthouse.png",
    description:
      "Welcoming accommodation usually attached to the main residence, offering a comfortable stay with personalized service.",
  },
  {
    id: 24,
    category_name: "Hotel",
    category_code: "HOTEL",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Hotel.png",
    description:
      "Professional establishment offering a range of rooms, amenities, and services for travelers.",
  },
  {
    id: 25,
    category_name: "House",
    category_code: "HOUSE",
    category_image: null,
    description:
      "Traditional residential dwelling, providing a comfortable and familiar environment for guests.",
  },
  {
    id: 26,
    category_name: "House Boat",
    category_code: "HOUSE_BOAT",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/House+boat.png",
    description:
      "Floating accommodation, offering a unique experience on the water, with amenities for a comfortable stay.",
  },
  {
    id: 27,
    category_name: "Inn",
    category_code: "INN",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Inn.png",
    description:
      "Cozy and quaint establishment offering lodging, meals, and a warm atmosphere for a relaxing stay.",
  },
  {
    id: 28,
    category_name: "Lodge",
    category_code: "LODGE",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Lodge.png",
    description:
      "Retreat-style accommodation in natural surroundings, often near forests or mountains, providing outdoor activities and relaxation.",
  },
  {
    id: 29,
    category_name: "Log Cabin",
    category_code: "LOG_CABIN",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Login+cabin.png",
    description:
      "Traditional wooden cabin, typically made from logs, providing a rustic and cozy atmosphere.",
  },
  {
    id: 30,
    category_name: "Mill",
    category_code: "MILL",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Mill.png",
    description:
      "Converted historical mill building, offering a unique and picturesque setting for a memorable stay.",
  },
  {
    id: 31,
    category_name: "Mobile Home",
    category_code: "MOBILE_HOM",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Mobile+home.png",
    description:
      "Moveable dwelling offering the comforts of home, suitable for those seeking a flexible and affordable accommodation option.",
  },
  {
    id: 32,
    category_name: "Motel",
    category_code: "MOTEL",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Motel.png",
    description:
      "Convenient and budget-friendly accommodation for road travelers, typically with parking facilities.",
  },
  {
    id: 8,
    category_name: "Other",
    category_code: "OTHER",
    category_image: null,
    description: '""',
  },
  {
    id: 33,
    category_name: "Parking Space",
    category_code: "PARKING_SP",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Parking+space.png",
    description:
      "Secure and convenient parking area available for rent, providing a safe place to park vehicles.",
  },
  {
    id: 34,
    category_name: "Private Island",
    category_code: "PRIVATE_IS",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Private+island.png",
    description:
      "Exclusive island retreat, offering seclusion and privacy for an unforgettable vacation.",
  },
  {
    id: 35,
    category_name: "Resort",
    category_code: "RESORT",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Resort.png",
    description:
      "Full-service vacation facility with various amenities and activities, ensuring a relaxing and entertaining stay.",
  },
  {
    id: 36,
    category_name: "Room",
    category_code: "ROOM",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Room.png",
    description:
      "Individual space within a larger property, ideal for solo travelers or those seeking a budget-friendly option.",
  },
  {
    id: 38,
    category_name: "Room type",
    category_code: "ROOM_TYPE",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Rooms+for+rent.png",
    description:
      "Categorization of different types of rooms available for booking within a property.",
  },
  {
    id: 37,
    category_name: "Rooms for Rent",
    category_code: "ROOMS_FOR_",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Rooms+for+rent.png",
    description:
      "Multiple rooms available for short-term rental within a shared property, offering affordability and flexibility.",
  },
  {
    id: 39,
    category_name: "RV",
    category_code: "RV",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/RV.png",
    description:
      "Recreational vehicle, providing a mobile accommodation option for travelers exploring different destinations.",
  },
  {
    id: 40,
    category_name: "Studio",
    category_code: "STUDIO",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Studio.png",
    description:
      "Compact and self-contained living space, often suitable for solo travelers or couples.",
  },
  {
    id: 41,
    category_name: "Tiny Home",
    category_code: "TINY_HOME",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Tiny+home.png",
    description:
      "Small and minimalist house, offering a cozy and eco-friendly stay with a focus on sustainable living.",
  },
  {
    id: 42,
    category_name: "Tower",
    category_code: "TOWER",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Tower.png",
    description:
      "Unique and often historic tower-like structure, providing panoramic views and an extraordinary stay.",
  },
  {
    id: 43,
    category_name: "Townhome",
    category_code: "TOWNHOME",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Townhome.png",
    description:
      "Multi-story house in a residential area, offering a comfortable and spacious stay with a homely feel.",
  },
  {
    id: 44,
    category_name: "Treehouse",
    category_code: "TREEHOUSE",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Treehouse.png",
    description:
      "Elevated dwelling built within or around trees, providing a unique and adventurous experience in nature.",
  },
  {
    id: 45,
    category_name: "Villa",
    category_code: "VILLA",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Villa.png",
    description:
      "Spacious and luxurious private residence, often featuring amenities like pools and gardens, perfect for lavish vacations.",
  },
  {
    id: 46,
    category_name: "Yacht",
    category_code: "YACHT",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Yatch.png",
    description:
      "Luxurious watercraft with sleeping accommodations, offering a high-end experience",
  },
  {
    id: 47,
    category_name: "Yurt",
    category_code: "YURT",
    category_image:
      "https://bbop-app-dev.s3.amazonaws.com/assets/landing-asset/Yurt.png",
    description:
      "Experience a nomadic lifestyle in a traditional circular tent with a cozy and eco-friendly atmosphere. Immerse yourself in nature while enjoying the comfort of a spacious interior, offering a unique and off-the-grid retreat.",
  },
];
