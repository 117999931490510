import React, { useEffect, useMemo } from "react";
import useWeb3 from "./useWeb3";
import useGlobalComp from "../../hooks/useGlobalComp";
import useConfetti from "../../hooks/confetti/useConfetti";
import { useCreateReservation } from "./web3Interactions";
import useAproval from "./useAproval";
import useBooking from "../../hooks/useBooking";
import useUserState from "./useUserState";
import useChainId from "../../hooks/useChainId";
import useHistory from "./useHistory";

const useReserve = (contractAddress, reserveProps) => {
  const { confetti } = useConfetti();
  const { loadContractInteraction } = useGlobalComp();
  const history = useHistory();

  const {
    requestAproval,
    loading: requestLoading,
    confirm: confirmRequest,
  } = useAproval();

  const { toWei } = useWeb3();

  const args = useMemo(() => {
    if (!reserveProps) return "";
    const { price, sd, startTime, endTime, cancelationId } = reserveProps;
    const start = Math.floor(new Date(startTime).getTime() / 1000);
    const end = Math.floor(new Date(endTime).getTime() / 1000);
    const totalPrice = toWei(price);
    const totalSD = toWei(sd);
    return [totalPrice, totalSD, start, end, cancelationId];
  }, [reserveProps]);

  const { create } = useBooking();
  const { userStore } = useUserState();
  const chain = useChainId();

  const { execute, confirm } = useCreateReservation(
    contractAddress,
    args,
    async (r) => {
      const createBook = {
        end: reserveProps.endTime,
        start: reserveProps.startTime,
        listing_id: reserveProps.listingId,
        amount_per_unit: reserveProps.amount_per_unit,
        duration: reserveProps.duration,
        guests: reserveProps.guests,
        amount: reserveProps.price,
        amount_total: reserveProps.price + reserveProps.sd,
        user_id: userStore?.id,
      };
      const addPayment = {
        network_response: r,
        network: chain,
      };
      await create(createBook, addPayment);
      confetti();
      setTimeout(() => {
        history("/bookings");
      }, "3000");
    }
  );

  useEffect(() => {
    if (confirmRequest && !requestLoading) {
      execute();
    }
  }, [confirmRequest, requestLoading]);

  const reserve = async () => {
    try {
      loadContractInteraction(true);
      let total = reserveProps.price + reserveProps.sd;
      requestAproval(total, contractAddress);
    } catch (err) {
      console.log("CONTRACT ERROR", err);
    }
  };

  return { reserve, confirm };
};

export default useReserve;
