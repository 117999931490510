import React from "react";

function OutdoorTableIcon(props) {
  return (
    <svg
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.st0{fill:none;stroke:${props.color};stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}`}
      </style>
      <path
        fill={props.color}
        className="st0"
        d="M30.2 11.4L16 1 1.8 11.4c-.5.4-.8 1-.8 1.6v1.8c0 1.6 1.2 3.1 2.8 3.2 1.8.1 3.2-1.3 3.2-3 0 1.7 1.3 3 3 3s3-1.3 3-3c0 1.7 1.3 3 3 3s3-1.3 3-3c0 1.7 1.3 3 3 3s3-1.3 3-3c0 1.7 1.4 3.1 3.2 3 1.6-.1 2.8-1.5 2.8-3.2V13c0-.6-.3-1.2-.8-1.6z"
      />
      <path fill={props.color} className="st0" d="M16 18L16 31" />
      <path fill={props.color} className="st0" d="M11 25L21 25" />
      <path fill={props.color} className="st0" d="M10 31L10 28 6 28" />
      <path
        fill={props.color}
        className="st0"
        d="M4 24l.4.8c1 2 .8 4.4-.4 6.2"
      />
      <path fill={props.color} className="st0" d="M22 31L22 28 26 28" />
      <path
        fill={props.color}
        className="st0"
        d="M28 24l-.4.8c-1 2-.8 4.4.4 6.2M16 1L7.8 11c-.5.6-.8 1.5-.8 2.3V15M16 1l8.2 10c.5.6.8 1.5.8 2.3V15M16 1l-2.8 10.2c-.1.5-.2 1.1-.2 1.7V15M16 1l2.8 10.2c.1.5.2 1.1.2 1.7V15"
      />
    </svg>
  );
}

export default OutdoorTableIcon;
