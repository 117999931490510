import React from "react";
import { DisputeComp, Main } from "../components";
import Loading from "../../commons/Loading";
import { useQuery } from "../hooks";
import useSimpleAxios from "../../hooks/useSimpleAxios";
import { mapConfig } from "../hooks/useAxios";

const Dispute = () => {
  const { query } = useQuery(["id"]);
  const response = useSimpleAxios(
    mapConfig(`/disputes/get?booking_id=${query?.id}&include=offer`, "get")
  );
  return (
    <Main>
      {!response?.isLoaded && <Loading size={160} />}
      {!!response.isLoaded && <DisputeComp {...response} />}
    </Main>
  );
};

export default Dispute;
