export const LISTING_STATUS = {
  ENABLE: "ENABLE",
  REJECTED: "REJECTED",
  REVIEW: "REVIEW",
  DRAFT: "DRAFT",
  DISABLE: "DISABLE",
  PRE_CONTRACT: "PRE_CONTRACT",
  DELETED: "DELETED",
};
export const LISTING_STATUS_SELECT = {
  ENABLE: "ENABLE",
  REJECTED: "REJECTED",
  REVIEW: "REVIEW",
  DRAFT: "DRAFT",
  DISABLE: "DISABLE",
};
