import React from "react";
import styled from "styled-components";
import ImageUploaderCrop from "../../../commons/ImageUploaderCrop";
import { useTranslate } from "../../../../hooks";

function Photos({ listing, setListing }) {
  const { t } = useTranslate();

  const addImage = (img) => {
    setListing({ ...listing, images: [...listing.images, img] });
  };

  const removeImage = ({ path }) => {
    setListing({
      ...listing,
      images: listing.images.filter((img) => img.path !== path),
    });
  };

  return (
    <PhotosStyled className="px-6">
      <div className="title">{t("Photos")}</div>
      <ImageUploaderCrop
        imagesUpload={listing.images}
        add={addImage}
        remove={removeImage}
      />
    </PhotosStyled>
  );
}

const PhotosStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`;

export default Photos;
