import { useMemo } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsCheckSquare, BsPencilSquare } from "react-icons/bs";
import { MdDisabledVisible, MdOutlineCancelPresentation } from "react-icons/md";
import {
  LISTING_STATUS,
  LISTING_STATUS_COLOR,
} from "../const/ListingCompletedSection";

const ListingStatusIcon = ({ status, haveText = true }) => {
  const renderIcon = useMemo(() => {
    switch (status) {
      case LISTING_STATUS.ENBALE:
        return <BsCheckSquare className="text-lg text-lighGreen" />;
      case LISTING_STATUS.REJECTED:
        return <MdOutlineCancelPresentation className="text-lg text-cancel" />;
      case LISTING_STATUS.DRAFT:
        return <BsPencilSquare className="text-lg text-blueBbop " />;
      case LISTING_STATUS.REVIEW:
        return <AiOutlineClockCircle className="text-lg text-yellowBbop" />;
      case LISTING_STATUS.PRE_CONTRACT:
        return <AiOutlineClockCircle className="text-lg text-[#507306]" />;
      case LISTING_STATUS.DISABLE:
        return <MdDisabledVisible className="text-lg text-cancel" />;
      default:
        return "";
    }
  }, [status]);

  return (
    <div className="flex opacity-90">
      {renderIcon}
      {haveText && (
        <p className={`ml-2 text-lg text-${LISTING_STATUS_COLOR[status]}`}>
          {status?.charAt(0).toUpperCase() + status?.slice(1).toLowerCase()}
        </p>
      )}
    </div>
  );
};

export default ListingStatusIcon;
