export const WEB3_TX_TYPE = {
  SIGNATURE: "signature",
  DONATION: "donation",
  PAYMENT: "payment",
  REFUND: "refund",
  PAYOUT: "payout",
  CANCELATION: "cancelation",
};
export const WEB3_TX_TYPE_TEXT = {
  signature: "1st signature",
  donation: "Donation",
  payment: "Checkout Payment",
  refund: "Refund",
  payout: "2nd signature",
  dispute: "1st signature",
};
