import {
  add,
  addDays,
  differenceInDays,
  endOfDay,
  format,
  formatISO,
  parseISO,
  setHours,
  setMinutes,
  startOfDay,
  sub,
} from "date-fns";
import { useMemo } from "react";

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export default function useDate() {
  const handleDateFormat = (date, key, formatKey, dateToChange = {}) => {
    if (!isValidDate(date)) {
      return "invalid date";
    }
    switch (key) {
      case "sub":
        return format(sub(date, dateToChange), formatKey);
      case "format":
        return format(date, formatKey);
      case "endOfDay-sub":
        return format(endOfDay(sub(date, dateToChange)), formatKey);
      case "startOfDay-sub":
        return format(startOfDay(sub(date, dateToChange)), formatKey);
      case "endOfDay-add":
        return format(endOfDay(add(date, dateToChange)), formatKey);
      case "startOfDay-add":
        return format(startOfDay(add(date, dateToChange)), formatKey);
      case "add":
        return format(add(date, dateToChange), formatKey);
      case "endOfDay":
        return format(endOfDay(date), formatKey);
      case "startOfDay":
        return format(startOfDay(date), formatKey);
    }
  };

  const handleSeparateDateRangeOnDays = (dateStart, dateEnd, isExternal) => {
    const newDateStartISO = new Date(parseISO(dateStart));
    const newDateEndISO = new Date(parseISO(dateEnd));
    const days = differenceInDays(newDateEndISO, newDateStartISO) + 1;
    let daysSelected = [];
    if (days === 0) {
      return [
        {
          start_date: dateStart,
          end_date: dateEnd,
          reason: "",
        },
      ];
    }
    const finalDays = isExternal ? days + 1 : days;
    for (let i = 0; i < finalDays; i++) {
      const start = format(addDays(newDateStartISO, i), "yyyy-MM-dd");
      const objToPsuh = { start_date: start, end_date: start };
      daysSelected.push(objToPsuh);
    }
    return daysSelected;
  };

  const handleOverlapDates = ({ selected, events }, type) => {
    switch (type) {
      case "overlap":
        return (
          (events.start >= selected.start && events.start <= selected.end) ||
          (selected.start >= events.start && selected.start <= events.end) ||
          (events.start === selected.start && events.end === selected.end)
        );
      case "inside":
        return selected.start >= events.start && selected.end <= events.end;
      default:
        console.log(
          "Overlap type not provided, please provide an overlap type, error in useOverlapDates"
        );
        break;
    }
  };

  const searchForOverlapDates = (events, { start, end }, type) => {
    let check = [];
    events.forEach((item) => {
      if (item.title !== "Selected") {
        if (
          handleOverlapDates(
            {
              selected: { start: start, end: end },
              events: { start: item.start, end: item.end },
            },
            type
          )
        ) {
          check.push(item);
        }
      }
    });
    return check;
  };

  const searchForEqualDatesRangeInDays = (events, dates, filter = false) => {
    let check = [];
    let checkOut = [];
    let prices = {};
    let eventsFilter = events;
    if (filter) {
      eventsFilter = events?.filter((ev) => ev.title !== "Selected");
    }
    eventsFilter?.forEach((event) => {
      const checkDates = dates?.find(
        (date) => date.start_date === event.start && date.end_date === event.end
      );
      if (checkDates) {
        check.push(event);
      }
    });
    dates?.forEach((date) => {
      const checkEvents = !eventsFilter?.find(
        (event) =>
          date.start_date === event.start && date.end_date === event.end
      );
      if (checkEvents) {
        checkOut.push(date);
      }
    });
    check.forEach((item) => {
      if (!prices[item.price]) {
        prices[item.price] = {
          total: item.price,
          days: check.filter((v) => v.price === item.price)?.length,
        };
      } else {
        prices[item.price] = {
          total: prices[item.price].total + item.price,
          days: check.filter((v) => v.price === item.price)?.length,
        };
      }
    });
    return { equals: check, rest: checkOut, prices };
  };
  const searchForEqualDatesRangeInDaysAsync = async (
    events,
    dates,
    filter = false,
    notCountLastDate = false
  ) => {
    let check = [];
    let checkOut = [];
    let eventsFilter = events;
    if (filter) {
      eventsFilter = events?.filter((ev) => ev.title !== "Selected");
    }
    eventsFilter?.forEach((event) => {
      if (
        dates?.find(
          (date) =>
            format(new Date(date.start_date), "yyyy-MM-dd") === event.start &&
            format(new Date(date.end_date), "yyyy-MM-dd") === event.end
        )
      ) {
        check.push(event);
      }
    });
    dates?.forEach((date) => {
      if (
        !eventsFilter?.find(
          (event) =>
            format(new Date(date.start_date), "yyyy-MM-dd") === event.start &&
            format(new Date(date.end_date), "yyyy-MM-dd") === event.end
        )
      ) {
        checkOut.push(date);
      }
    });
    return { equals: check, rest: checkOut };
  };

  const formatManual = (objectDate, joinType = "-", sum) => {
    const dateArray = objectDate.split("-");
    let day = dateArray[2].split("T")[0];
    if (sum?.days) {
      const dayInt = parseInt(day);
      const newDay = dayInt + sum.days;
      day = newDay.toString();
    }
    let month = dateArray[1];
    let year = dateArray[0];

    return year + joinType + month + joinType + day;
  };

  const today = new Date();

  const tomorrow = useMemo(() => {
    const res = new Date(today);
    res.setDate(today.getDate() + 1);
    return res;
  }, [today]);

  const formatChecksInOut = (checkTimes, start, end) => {
    if (!checkTimes?.check_in || !checkTimes?.check_out) return;
    const checkIn = Number(checkTimes?.check_in.split(":")[0]);
    const checkOut = Number(checkTimes?.check_out.split(":")[0]);

    return {
      startDate: formatISO(setHours(setMinutes(start, 0), checkIn)),
      endDate: formatISO(setHours(setMinutes(end, 0), checkOut)),
    };
  };

  const showCheckInOut = (time) => {
    const timeSplit = time.split(":");
    return `${timeSplit[0]}:${timeSplit[1]}`;
  };

  return {
    formatManual,
    handleDateFormat,
    handleSeparateDateRangeOnDays,
    searchForOverlapDates,
    handleOverlapDates,
    searchForEqualDatesRangeInDays,
    searchForEqualDatesRangeInDaysAsync,
    formatChecksInOut,
    showCheckInOut,
    today,
    tomorrow,
  };
}
