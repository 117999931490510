import React from "react";
import gravatar from "../utils/gravatarBuilder";

export default function ProfileImage({
  loading = false,
  className,
  source,
  size,
  type = "user",
  onClick = () => {},
}) {
  const user = JSON.parse(window.sessionStorage.getItem("userState"))?.user;
  return (
    <>
      {!loading ? (
        source ? (
          <img
            onClick={() => onClick && onClick()}
            className={`inline-block rounded-full object-cover ${className}`}
            style={size ? { width: size, height: size } : {}}
            src={source}
            alt="User profile img"
          />
        ) : (
          <div
            className="bg-gray rounded-full"
            style={size ? { width: size, height: size } : {}}
          ></div>
        )
      ) : (
        "Loading..."
      )}
    </>
  );
}
