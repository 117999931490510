import React, { useEffect, useMemo, useState } from "react";
import DummyImg from "../../../commons/DummyImg";

const styleArrows = {
  background: "rgba(255, 255, 255, 0.1)",
  backdropFilter: "blur(6px)",
  height: "20%",
  borderRadius: "30px",
};

function Arrows({ prevSlide, nextSlide }) {
  return (
    <div className="arrows w-[98%] left-[6px] z-[100]  h-full flex justify-between items-center absolute top-0">
      <span
        style={styleArrows}
        className="w-10 cursor-pointer justify-center h-full flex items-center text-2xl text-nebula drop-shadow-lg"
        onClick={(e) => {
          prevSlide();
          e.stopPropagation();
        }}
      >
        {/* <span className="text-nebula bg-[rgba(255, 255, 255, 0.1)]   rounded-full px-[10px] flex justify-center items-center "> */}
        &#10094;
        {/* </span> */}
      </span>
      <span
        style={styleArrows}
        className="w-10 cursor-pointer justify-center h-full flex items-center text-2xl text-nebula drop-shadow-lg"
        onClick={(e) => {
          nextSlide();
          e.stopPropagation();
        }}
      >
        {/* <span className="text-nebula bg-[rgba(255, 255, 255, 0.1)]   rounded-full px-[10px] flex justify-center items-center "> */}
        &#10095;
        {/* </span> */}
      </span>
    </div>
  );
}

function Dots({ activeIndex, onClick, sliderImage, dotStyles }) {
  return (
    <div className="flex justify-center z-[100] w-full">
      <div className={"flex absolute bottom-2 p-2 rounded " + dotStyles}>
        {sliderImage?.map((slide, index) => (
          <span
            key={index}
            className={`${
              activeIndex === index
                ? "bg-white border-2 border-white "
                : "border-2 border-nebula"
            } w-4 h-4 mx-1 rounded-full cursor-pointer`}
            onClick={() => onClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
}
function SliderContent({
  activeIndex,
  sliderImage,
  actions,
  classNameImage = "h-[236px] w-full md:w-[322px]",
  listingId,
  changeState,
  actionProp,
}) {
  return (
    <div className="relative w-full h-full">
      {sliderImage.length !== 0 ? (
        sliderImage.map((slide, index) => (
          <div
            key={`${slide.filename}_${index}`}
            className={`w-full ${
              index === activeIndex ? "relative h-full" : "hidden"
            } transition-all`}
          >
            <img
              className={`object-cover bg-gray-300 ${classNameImage}`}
              src={slide.external_path || slide.path}
              alt={slide.description}
            />
          </div>
        ))
      ) : (
        <DummyImg classNameImg={` ${classNameImage}`} />
      )}
      {actions &&
        actions.map((item, index) => (
          <div key={index} className={`absolute z-[101] ${item.className}`}>
            {item.children({ listingId, changeState, actionProp })}
          </div>
        ))}
    </div>
  );
}

export const Slider = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const {
    interval,
    data,
    arrows,
    actions,
    dotStyles,
    dots,
    classNameSlider = "",
    classNameImage,
    listingId,
    changeState,
    actionProp,
    onClick,
  } = props;
  const len = useMemo(() => data?.length - 1, [data]);

  useEffect(() => {
    if (interval) {
      const interval = setInterval(() => {
        setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [activeIndex, interval, len]);

  return (
    <div
      onClick={onClick}
      className={`items-center relative mb-2 scroll-smooth z-[10]  ${classNameSlider}`}
    >
      {data ? (
        <>
          <SliderContent
            activeIndex={activeIndex}
            sliderImage={data}
            actions={actions}
            actionProp={actionProp}
            classNameImage={classNameImage}
            listingId={listingId}
            changeState={changeState}
          />
          {arrows && (
            <Arrows
              prevSlide={() =>
                setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
              }
              nextSlide={() =>
                setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
              }
            />
          )}
          {dots && (
            <Dots
              activeIndex={activeIndex}
              sliderImage={data}
              onClick={(activeIndex) => setActiveIndex(activeIndex)}
              dotStyles={dotStyles}
            />
          )}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};
