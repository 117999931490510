import React, { useMemo, useState } from "react";
import styled from "styled-components";
import PriceRange from "./PriceRange";
import Categories from "./Categories";
import Details from "./Details";
import Services from "./Services";
import Footer from "./Footer";

function FilterListing({ filters, close }) {
  const { cleanResponse, filter } = filters;

  const [filterPrices, setFilterPrices] = useState(() => ({
    min: filter.price.min,
    max: filter.price.max,
  }));

  const placesCount = useMemo(() => {
    const { min: minStr, max: maxStr } = filterPrices;
    const min = Number(minStr);
    const max = Number(maxStr);
    const places = cleanResponse.prices.filter((p) => {
      const price = Number(p);
      if (max <= 999) {
        return price >= min && price <= max;
      } else {
        return price >= min;
      }
    });
    return places.length;
  }, [filterPrices, cleanResponse.prices]);

  return (
    <FilterListingStyled className="pt-5 pb-[10em]">
      <PriceRange
        setFilterPrices={setFilterPrices}
        prices={cleanResponse?.prices}
      />
      <Categories filters={filters} />
      <Details filters={filters} />
      <Services filters={filters} />
      <Footer
        filters={filters}
        filterPrices={filterPrices}
        places={placesCount}
        close={close}
      />
    </FilterListingStyled>
  );
}

const FilterListingStyled = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 18px;
  @media (max-width: 992px) {
    font-size: 16px;
  }
  @media (max-width: 600px) {
    font-size: 15px;
  }
  .headline {
    font-size: 1.3em;
    font-weight: 600;
  }
  p {
    font-size: 0.9em;
    font-weight: 300;
  }
  .separator {
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 20px;
  }
`;

export default FilterListing;
