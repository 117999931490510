import useTranslate from "../mobile/hooks/useTranslate";

const TextArea = ({
  value = "",
  setValue,
  title,
  cols = "40",
  rows = "8",
  className = "",
  disabled = false,
  textHelper,
  classNameTextHelper,
  classNameInput,
  titleInside,
  limit,
  resize = true,
  error,
}) => {
  const { t } = useTranslate();

  return (
    <>
      <div className={`${className}`}>
        {title && !titleInside && (
          <p className="text-nebula font-bold ml-2 text-lg">{title}</p>
        )}
        <div
          className={`flex w-full justify-between bg-blueContrast rounded ${
            titleInside && "flex-col p-2 !rounded-xl"
          }`}
        >
          {title && titleInside && <p className="text-nebula ">{title}</p>}
          <textarea
            maxLength={limit}
            disabled={disabled}
            className={`p-5 w-full text-nebula bg-blueContrast border-2 border-blueDark rounded-xl scrollbarNone ${classNameInput} ${
              !resize && "resize-none"
            }`}
            onChange={(e) => setValue(e.target.value)}
            cols={cols}
            rows={rows}
            value={value}
          ></textarea>
          {limit && (
            <p className="w-full flex justify-end text-sm text-nebula">{`${t(
              "max characters"
            )} ${limit}`}</p>
          )}
        </div>
        {textHelper && <p className={`${classNameTextHelper}`}>{textHelper}</p>}
        <InputError message={error} />
      </div>
    </>
  );
};

export default TextArea;

const InputError = ({ message }) => {
  return message ? (
    <div className="w-full justify-start">
      <p className="text-cancel m-0">{message}</p>
    </div>
  ) : (
    ""
  );
};
