import React from "react";
import styled from "styled-components";
import { trackHeight, thumbHeight } from "./constants";

function Rail({ getRailProps }) {
  return (
    <>
      <RailHotspotStyled {...getRailProps()} />
      <RailStyled />
    </>
  );
}

const RailStyled = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  height: ${trackHeight}px;
  position: absolute;
  pointer-events: none;
`;

const RailHotspotStyled = styled.div`
  width: 100%;
  height: ${thumbHeight * 2}px;
  top: ${-thumbHeight}px;
  position: absolute;
  cursor: pointer;
`;

export default Rail;
