import React from "react";

const TextMessage = ({ text, time, background }) => {
  return (
    <>
      <div className={`flex flex-col mb-2 w-fit`}>
        {text !== "" ? (
          <div
            className={`text-justify break-all text-ellipsis mt-2 mb-2 max-w-2/3 rounded-xl shadow-sm shadow-lighGreen flex flex-col items-end p-2 text-sm ${background}`}
          >
            {text}
            <div className="text-xs text-blueDark text-opacity-50 self-end">
              {time}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TextMessage;
