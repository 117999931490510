import React, { useState } from "react";
import styled from "styled-components";

function Logix() {
  const [url, setUrl] = useState("");
  const onChange = (e) => {
    setUrl(e.target.value);
  };
  return (
    <LogixStyled>
      <div className="group">
        <input
          className="bg-blueDark"
          id="url-logix"
          name="url-logix"
          value={url}
          autoComplete="off"
          placeholder="Logix URL"
          onChange={onChange}
        />
      </div>
      <button className="send" onClick={() => {}}>
        Create
      </button>
    </LogixStyled>
  );
}

const LogixStyled = styled.form`
  display: grid;
  place-items: center;
  padding: 20px 30px;
  .group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    input {
      border-radius: 8px;
      padding: 10px 20px;
    }
  }
  .send {
    padding: 10px 25px;
    margin-top: 20px;
    border-radius: 12px;
    color: #1c2c49;
    background-color: #fff;
    transition: opacity 0.3s linear;
    &:disabled {
      opacity: 0.3;
    }
  }
`;

export default Logix;
