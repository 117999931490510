import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useUserState } from "./hooks";

export default function PrivateRoute({ render, ...rest }) {
  const { jwt } = useUserState();
  const location = useLocation();
  if (!jwt)
    return (
      <Navigate
        to={"/"}
        state={{
          from: `${location?.pathname}${
            location.search ? location.search : ""
          }`,
          prevState: location?.state,
        }}
      />
    );
  return render;
}
