import React from "react";
import ManageLargeText from "../../../commons/ManageLargeText";
import { StarRating } from "../commons";
import { useHistory } from "../../hooks";

const UserListingCard = ({ value }) => {
  const history = useHistory();
  if (!value) return <></>;
  return (
    <div
      className="w-[20em] flex flex-col cursor-pointer"
      onClick={() => history(`/listing?id=${value.id}`)}
    >
      <div
        style={{ backgroundImage: `url(${value?.listing_images[0]?.path})` }}
        className="w-full h-[15em] bg-cover bg-center rounded-lg"
      ></div>
      <div className="w-full pt-3 px-2 flex justify-between items-center">
        <ManageLargeText
          maxLength={20}
          cutValue={20}
          value={value?.name}
          className="text-nebula text-lg"
        />
        <StarRating
          routeRating={value?.average_rating}
          showRating
          oneStar
          disabled={true}
          classNameStars="1em"
        />
      </div>
      <ManageLargeText
        maxLength={30}
        cutValue={30}
        value={value?.description}
        className="text-nebula text-lg px-2 opacity-50"
      />
    </div>
  );
};

export default UserListingCard;
