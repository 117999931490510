import React from "react";
import { ChatComp, Main } from "../components";

const Chat = () => {
  return (
    <Main className="!pb-[6em]">
      <ChatComp />
    </Main>
  );
};

export default Chat;
