import Loader from "react-loader-spinner";

const ManageLoading = ({
  loading,
  size = 40,
  type,
  children,
  height,
  className = "",
  classNameLoader = "ml-2",
  color,
}) => {
  return loading ? (
    type === "screen" ? (
      <div
        style={{ height: height ? height : "" }}
        className={`w-full h-[600px] lg:h-[800px] flex justify-center items-center ${className}`}
      >
        <Loader
          className={classNameLoader}
          type="TailSpin"
          color={color || "#00BFFF"}
          height={size}
          width={size}
        />
      </div>
    ) : (
      <div className={className}>
        <Loader
          className={classNameLoader}
          type="TailSpin"
          color={color || "#00BFFF"}
          height={size}
          width={size}
        />
      </div>
    )
  ) : (
    children
  );
};

export default ManageLoading;
