import React, { useEffect, useRef } from "react";
import styled from "styled-components";

function ClickableTooltip({ options = [], close }) {
  const ref = useRef();
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <ClickableTooltipStyled id="tooltip-wrapper" ref={ref}>
      <div className="arrow" />
      <div className="options">
        {options.map(({ name, callback }) => {
          return (
            <div className="option" onClick={callback}>
              {name}
            </div>
          );
        })}
      </div>
    </ClickableTooltipStyled>
  );
}

const ClickableTooltipStyled = styled.div`
  position: absolute;
  top: -3.7rem;
  left: -225%;
  border-radius: 0.2rem;
  font-size: 0.45em;
  background-color: #1c2c49;
  /* color: #1c2c49; */
  color: #fff;
  text-align: center;
  font-weight: 500;
  transform: translateY(50%);
  opacity: 1;
  box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.1);
  z-index: 100;
  .arrow {
    position: absolute;
    top: 50%;
    right: -1rem;
    border-width: 0.5rem;
    border-style: solid;
    border-color: transparent transparent transparent #1c2c49;
    transform: translateY(-50%);
  }
  .option {
    padding: 3px 25px;
    cursor: pointer;
    &:not(:first-of-type) {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`;

export default ClickableTooltip;
