import React from "react";
import { useGetData, useHistory, useUserState } from "../../hooks";
import ListingCardExplore from "./ListingCardExplore";
import { SearchListing } from "../commons";

const ExploreComp = () => {
  const { userStore } = useUserState();
  const history = useHistory();

  const listings = useGetData(
    `/listing/getNewListings/${
      userStore && userStore.id ? `?user_id=${userStore.id}` : ""
    }`
  );

  if (!listings) return <></>;

  return (
    <div className="pt-[7em]">
      <SearchListing fixed />
      <div className="text-nebula flex flex-col items-center px-5 gap-5">
        {listings?.map((listing) => (
          <ListingCardExplore
            className="md:max-w-full"
            key={listing.id}
            listing={listing}
            onClickButton={() => {
              history(`/listing?id=${listing.id}`);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ExploreComp;
