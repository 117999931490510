import React, { useEffect } from "react";
import styled from "styled-components";
import { trackHeight, thumbHeight } from "./constants";

function Track({ source, target, getTrackProps, activeHandleID, setHandleId }) {
  const left = `${source.percent}%`;
  const width = `${target.percent - source.percent}%`;
  useEffect(() => {
    setHandleId(activeHandleID);
    //eslint-disable-next-line
  }, [activeHandleID]);
  return (
    <>
      <TrackStyled style={{ left, width }} />
      <TrackHotspotStyled style={{ left, width }} {...getTrackProps()} />
    </>
  );
}

const TrackStyled = styled.div`
  background-color: #fff;
  height: ${trackHeight}px;
  position: absolute;
  z-index: 1;
  pointer-events: none;
`;

const TrackHotspotStyled = styled.div`
  height: ${thumbHeight}px;
  top: calc(${thumbHeight}px * -0.5);
  position: absolute;
  cursor: pointer;
`;

export default Track;
