import React, { useMemo } from "react";
import GenerateSvg from "./GenerateSvg";
import { useSelector } from "react-redux";

const NetworkConnected = ({ chainId, className, preText }) => {
  const wallets = useSelector((state) => state.wallets);

  const bookingWallet = useMemo(
    () => (!chainId ? "" : wallets[chainId]),
    [wallets, chainId]
  );

  return (
    <div
      className={`flex justify-center w-fit items-center gap-2 text-nebula ${className}`}
    >
      <p>
        {preText ? preText : ""}
        {`${bookingWallet?.name}`}
      </p>
      <GenerateSvg
        svg={bookingWallet?.icon}
        className="w-[30px] h-[30px] mr-2"
      />
    </div>
  );
};

export default NetworkConnected;
