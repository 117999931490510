import React, { useState } from "react";
import PlacesAutocompleteNew from "../../../../commons/PlacesAutocompleteNew";
import useGetCitites from "../../../../hooks/useGetCitites";
import { useDebounceAuto } from "../../../../hooks/useDebounce";
import { useTranslate } from "../../../hooks";

const EditLocation = ({ onChange, location }) => {
  const { t } = useTranslate();
  const [text, setText] = useState("");
  const searchDebounce = useDebounceAuto(text, 800);
  const { cities, loading } = useGetCitites(searchDebounce, "listing");

  return (
    <div>
      <PlacesAutocompleteNew
        onChange={(v, isSelect) => {
          setText(v);
        }}
        value={location}
        options={cities}
        onSelect={onChange}
        search={text}
        loading={loading}
        placeholder={t("Search by city")}
      />
    </div>
  );
};

export default EditLocation;
