import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import countryList from "react-select-country-list";
import {
  FormAction,
  InputDate,
  InputTextArea,
  InputTextEdit,
} from "../../../commons/Form";
import useAxios, { mapConfig } from "../../../hooks/useAxios";
import { prefixPhone } from "../../../utils/prefixPhone";
import { useDebounceAuto } from "../../../hooks/useDebounce";
import AutoCompleteEdit from "../components/AutoCompleteEdit";
import { useTranslate, useUserState } from "../../../mobile/hooks";
import useToast from "../../../mobile/hooks/useToast";
import useSimpleAxios from "../../../hooks/useSimpleAxios";

const prefix = prefixPhone.map((v) => ({
  id: v.phone,
  label: `${v.label} ${v.phone}`,
}));

export default function EditUserProfileLogic() {
  const { userStore, changeUserStateUser } = useUserState();
  const [selectedImage, setSelectedImage] = useState("");
  const fileRef = useRef(null);
  const [preview, setPreview] = useState(null);
  const [updateImage, setUpdateImage] = useState(false);
  const options = useMemo(
    () =>
      countryList()
        .getData()
        .map((v) => ({ id: v.value, label: v.label })),
    []
  );
  const [timezones, setTimezones] = useState([]);
  const [required, setRequired] = useState(false);
  const [requiredLast, setRequiredLast] = useState(false);
  const [requiredPhone, setRequiredPhone] = useState(false);
  const [values, setValues] = useState({
    last_name: "",
    first_name: "",
    username: "",
    phone_prefix: "",
    phone: "",
    birthday: "",
    nationality: "",
    country_of_residence: "",
    profile_summary: "",
    time_zone: "",
    wallet_address: "",
    wallet_network: [],
  });
  const { t } = useTranslate();

  const { handleAxios } = useAxios();

  //Distpach of Redux
  const distpatch = useDispatch();

  const { notify } = useToast([
    {
      position: "top-center",
      type: "success",
      key: "s",
    },
    {
      position: "top-center",
      type: "error",
      key: "e",
    },
  ]);

  // example useSimpleAxios
  const { recall, work, isLoaded } = useSimpleAxios(
    mapConfig(`/users/getUserById?id=${userStore?.id}`, "get", {
      postFetch: (res) => setValues(res),
    }),
    false
  );
  // example useSimpleAxios
  const { work: workTimeZone } = useSimpleAxios(
    mapConfig(`/tz`, "get", {
      postFetch: (res) => setTimezones(res),
    }),
    false
  );

  const handleUseEffect = useCallback(async () => {
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        setPreview(e.target.result);
        setUpdateImage(true);
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setPreview(null);
    }
  }, [selectedImage, handleAxios, notify, userStore]);

  useEffect(() => {
    handleUseEffect();
    //eslint-disable-next-line
  }, [selectedImage]);

  const saveImage = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("image_profile", selectedImage.name);
    formData.append("fileToUpload", selectedImage);
    const configAxiosSaveImage = {
      path: "/users/profile/saveImage",
      method: "put",
      body: { formData },
      toast: false,
      postFetch: (res) => {
        changeUserStateUser({ profile_image_url: res.pathUrl });
        notify.s(res.message);
      },
      postErrorFetch: (err) => notify.e(err),
    };
    await handleAxios(configAxiosSaveImage);
  };

  const validate = () => {
    let validityStatus = true;
    if (values.first_name === "") {
      validityStatus = false;
      setRequired(true);
    }
    if (values.last_name === "") {
      validityStatus = false;
      setRequiredLast(true);
    }
    if (values.phone === "") {
      validityStatus = false;
      setRequiredPhone(true);
    }
    return validityStatus;
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (!validate()) {
      notify.e("Please check!");
    } else {
      setRequired(false);
      const configAxiosSaveUserInfo = {
        path: "/users/updateUser",
        method: "put",
        body: values,
        postFetch: (res) => {
          // recall();
          changeUserStateUser({
            first_name: values?.first_name,
            last_name: values?.last_name,
          });
        },
        postErrorFetch: (err) => notify.e(err),
      };

      if (values?.rejections_id?.length > 0) {
        await handleAxios(
          mapConfig("/rejections/updateRejections", "put", {
            body: { rejections: values?.rejections_id },
            toast: false,
          })
        );
        await handleAxios(
          mapConfig("/users/updateStatus", "put", {
            body: { status: "enabled" },
            toast: false,
          })
        );
      }
      await handleAxios(configAxiosSaveUserInfo);
    }
  };

  const handleFormOnChange = useCallback(
    (e, keyProp) => {
      e.preventDefault();
      setValues({ ...values, [keyProp]: e.target.value });
    },
    [values]
  );

  // example useSimpleAxios
  // const { value: walletNetwork } = useSimpleAxios(
  //   mapConfig("/wallets/getWallets?wallet_type=payment&enabled=1", "get")
  // );

  const [searchCountry, setSearchCountry] = useState("");
  const searchCountryDebounce = useDebounceAuto(searchCountry, 800);

  const formInputs = useMemo(
    () => [
      {
        render: (
          <InputTextEdit
            onChange={(e) => handleFormOnChange(e, "first_name")}
            value={values?.first_name}
            placeholder="First Name"
            label="First Name:"
            classNameInput="bg-transparent"
            className={`w-full ${
              values?.rejections?.includes("first_name") &&
              "border-2 border-cancel"
            }`}
            required={required}
          />
        ),
      },
      {
        render: (
          <InputTextEdit
            onChange={(e) => handleFormOnChange(e, "last_name")}
            value={values?.last_name}
            placeholder="Last Name"
            label="Last Name:"
            classNameInput="bg-transparent"
            className={`w-full ${
              values?.rejections?.includes("last_name") &&
              "border-2 border-cancel"
            }`}
            required={requiredLast}
          />
        ),
      },
      {
        render: (
          <InputTextEdit
            onChange={(e) => handleFormOnChange(e, "username")}
            value={values?.username}
            placeholder="Username"
            label="Username:"
            classNameInput="bg-transparent"
            className={`w-full ${
              values?.rejections?.includes("username") &&
              "border-2 border-cancel"
            }`}
            required={requiredLast}
          />
        ),
      },
      {
        render: (
          <InputTextArea
            onChange={(e) => handleFormOnChange(e, "profile_summary")}
            value={values?.profile_summary}
            label="Profile Summary:"
            placeholder="Profile Summary"
            className={` w-full ${
              values?.rejections?.includes("profile_summary") &&
              "border-2 border-cancel"
            }`}
          />
        ),
      },
      {
        render: (
          <InputDate
            onChange={(e) => handleFormOnChange(e, "birthday")}
            value={values?.birthday}
            id="birthday"
            className="w-full relative"
            label="Birthday"
          />
        ),
      },
      {
        render: (
          <InputTextEdit
            onChange={(e) => handleFormOnChange(e, "email")}
            value={values?.email}
            placeholder="Email"
            label="Email:"
            disabled={true}
            classNameInput="bg-transparent"
            className="!w-full"
          />
        ),
      },
      {
        render: (
          <AutoCompleteEdit
            options={options}
            label="Country of residence:"
            value={values?.country_of_residence || "Select Country"}
            onSelect={(v) =>
              setValues((prev) => ({ ...prev, country_of_residence: v }))
            }
            placeholder="Search Country"
          />
        ),
      },
      {
        render: (
          <AutoCompleteEdit
            options={options}
            label="Nationality:"
            value={values?.nationality || "Select Nationality"}
            onSelect={(v) => setValues((prev) => ({ ...prev, nationality: v }))}
            placeholder="Search Country"
          />
        ),
      },
      // {
      //   render: (
      //     <ManageLoading loading={workTimeZone} size={40}>
      //       <Collapse isOpened={visibleInfo}>
      //         <div className="grid grid-flow-col-dense bg-nebula opacity-[0.7] rounded-lg">
      //           <label className="text-greenDark font-bold lg:pt-2.5 md:pt-2.5 text-sm lg:text-base pl-2 border-2 border-nebula rounded-md border-r-0 rounded-r-none shadow-lg">
      //             Time Zone:
      //           </label>
      //           <InputSelect
      //             onChange={(e) => handleFormOnChange(e, "time_zone")}
      //             id="time_zone"
      //             name="time_zone"
      //             value={values?.time_zone}
      //             options={timezones.map((item) => ({
      //               value: item.id,
      //               label: item.name,
      //             }))}
      //             defaultProp="Select your time zone"
      //             disabled={true}
      //             className="border-l-0 rounded-l-none w-full"
      //           />
      //         </div>
      //       </Collapse>
      //     </ManageLoading>
      //   ),
      // },
      {
        render: (
          <AutoCompleteEdit
            options={prefix}
            label="Country Code:"
            value={values?.phone_prefix || "Select Country Code"}
            onSelect={(v) =>
              setValues((prev) => ({ ...prev, phone_prefix: v }))
            }
            placeholder="Search Country Code"
          />
        ),
        // <Collapse isOpened={visibleInfo}>
        //   <div className="grid grid-flow-col">
        //     <label className="text-greenDark font-bold text-sm lg:text-base pt-1 lg:pt-2.5 md:pt-2.5 pl-2 border-2 border-nebula rounded-md border-r-0 rounded-r-none shadow-lg bg-white">
        //       Country Code:
        //     </label>
        //     <InputSelect
        //       onChange={(e) => handleFormOnChange(e, "phone_prefix")}
        //       id="phone_prefix"
        //       name="phone_prefix"
        //       value={values?.phone_prefix}
        //       options={prefix.map((item) => ({
        //         value: item.phone,
        //         label: item.phone,
        //         label2: item.label,
        //       }))}
        //       defaultProp="Select Country code"
        //       className="border-l-0 rounded-l-none w-full"
        //     />
        //   </div>
        // </Collapse>
      },
      {
        render: (
          <InputTextEdit
            onChange={(e) => handleFormOnChange(e, "phone")}
            value={values?.phone}
            placeholder="Phone Number"
            label="Phone:"
            type="tel"
            classNameInput="bg-transparent"
            className="w-full"
            required={requiredPhone}
          />
        ),
      },
      // {
      //   render: (
      //     <WalletAddress
      //       values={values}
      //       recall={recall}
      //       setValues={setValues}
      //     />
      //   ),
      // },
      // {
      //   render: (
      //     <Collapse isOpened={visibleSecurity}>
      //       <PasswordChange
      //         newPassword={newPassword}
      //         onChange={(e) => [
      //           setNewPassword(e.target.value),
      //           setValidation(false),
      //         ]}
      //         onChangeConfirm={(e) => [
      //           setConfirmNewPassword(e.target.value),
      //           setValidationPass(false),
      //         ]}
      //         confirmNewPassword={confirmNewPassword}
      //         validation={validation}
      //         validationPass={validationPass}
      //       />
      //     </Collapse>
      //   ),
      // },
    ],
    [values, timezones, options, handleFormOnChange, prefix]
  );

  const handleFormOnSubmit = async (e) => {
    e.preventDefault();
    if (!updateImage) {
      await handleSave(e);
    } else {
      saveImage(e);
      await handleSave(e);
    }
  };

  const validateAddress = useMemo(
    () =>
      values?.wallet_network
        ? Object.keys(values?.wallet_network)
            .map((item) => values?.wallet_network[item])
            .find((item) => !item.address)
        : false,
    [values]
  );

  const formaActions = [
    {
      render: (
        <FormAction
          onSubmit={(e) => handleFormOnSubmit(e)}
          label="Save"
          className="mr-2"
          // disabled={!!validateAddress}
          work={work}
        />
      ),
    },
  ];
  return {
    saveImage,
    handleSave,
    notify,
    timezones,
    prefix,
    options,
    updateImage,
    preview,
    fileRef,
    setSelectedImage,
    values,
    setValues,
    formInputs,
    formaActions,
    work,
    isLoaded,
    handleFormOnSubmit,
  };
}
