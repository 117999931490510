const GenerateSvg = ({ svg, className }) => {
  return (
    <>
      {svg && (
        <div
          className={`flex items-center ${className}`}
          dangerouslySetInnerHTML={{ __html: svg }}
        />
      )}
    </>
  );
};

export default GenerateSvg;
