import React from "react";
import { BookingComp, Main } from "../components";
import useSimpleAxios from "../../hooks/useSimpleAxios";
import { mapConfig } from "../hooks/useAxios";
import { useQuery } from "../hooks";
import Loading from "../../commons/Loading";

const Booking = () => {
  const { query } = useQuery(["id"]);
  const response = useSimpleAxios(
    mapConfig(`/booking/findById?bookingId=${query?.id}`, "get")
  );

  return (
    <Main>
      {!response?.isLoaded && <Loading size={160} />}
      {!!response.isLoaded && <BookingComp {...response} />}
    </Main>
  );
};

export default Booking;
