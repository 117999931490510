import React from "react";
import { IoArrowBack } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";

const BackButton = ({ to, onClick, setLoading, ...props }) => {
  const { state, pathname } = useLocation();
  return (
    <>
      {!!onClick && (
        <div
          onClick={onClick}
          className={`${
            props.className ||
            "absolute top-[0.5em] p-[0.4em] cursor-pointer text-2xl  left-[0.5em] z-[1000] rounded-full bg-nebula text-buttonDark"
          } ${props.border ? "border border-buttonDark" : ""}`}
        >
          <IoArrowBack />
        </div>
      )}
      {!onClick && (
        <Link
          to={to ? to : state?.from || "/"}
          state={{ from: pathname }}
          className={`${
            props.className ||
            "absolute top-[0.5em] p-[0.4em] text-2xl cursor-pointer left-[0.5em] z-[1000] rounded-full bg-nebula text-buttonDark"
          } ${props.border ? "border border-buttonDark" : ""}`}
        >
          <IoArrowBack />
        </Link>
      )}
    </>
  );
};

export default BackButton;
