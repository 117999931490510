import React, { useEffect, useState } from "react";
import styled from "styled-components";

function MultipleChoiceByItems({
  data = [],
  showOnlyFirst = 8,
  style,
  state,
  setState,
}) {
  const [showAll, setShowAll] = useState(false);
  const elements = showAll ? data.length : showOnlyFirst;

  return (
    <MultipleChoiceByItemsStyled style={style}>
      {data.slice(0, elements).map(({ id, category_name: category }) => {
        return (
          <label key={id} className="checkbox-label">
            <span className="category">{category}</span>
            <input
              type="checkbox"
              checked={state?.includes(id)}
              onChange={() => setState(id)}
            />
            <span className="check" />
          </label>
        );
      })}

      <p
        className="would-show"
        role="button"
        onClick={() => setShowAll((prev) => !prev)}
      >
        Show {showAll ? "less" : "more"}
      </p>
    </MultipleChoiceByItemsStyled>
  );
}

const MultipleChoiceByItemsStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 20px;
  @media (max-width: 745px) {
    grid-template-columns: 1fr;
    .checkbox-label {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 12px 0;
    cursor: pointer;
    &:hover .check {
      border-color: rgba(255, 255, 255, 1);
    }
    .category {
      order: 3;
    }
    .check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background-color: transparent;
      order: 2;
      transition: 0.3s;

      &:before {
        content: "";
        display: inline-block;
        width: 12px;
        height: 6px;
        border-bottom: 3px solid #1c2c49;
        border-left: 3px solid #1c2c49;
        transform: scale(0) rotate(-45deg);
        position: relative;
        bottom: 2px;
        transition: 0.6s;
      }
    }
    input {
      display: none;
      &:checked ~ .check {
        background-color: #fff;
      }
      &:checked ~ .check::before {
        transform: scale(1) rotate(-45deg);
      }
    }
  }
  .would-show {
    margin-top: 10px;
    text-decoration: underline;
    font-size: 1em;
    font-weight: 600;
  }
`;

export default MultipleChoiceByItems;
