import React from "react";
import { SettingsComp } from "../components";
import { ComponentTitle, Main } from "../components/commons";

const Settings = () => {
  return (
    <Main>
      <ComponentTitle title={"Profile"} />
      <SettingsComp />
    </Main>
  );
};

export default Settings;
