import React from "react";
import styled from "styled-components";
import { useTranslate } from "../../../../hooks";

function Denomination({ listing, setListing }) {
  const { name, description } = listing;
  const { t } = useTranslate();

  const onChange = (e) => {
    setListing({ ...listing, [e.target.name]: e.target.value });
  };
  return (
    <DenominationStyled className="relative px-[20px]">
      <h2 className="title">{t("Descriptive naming")}</h2>
      <div className="inputs-wrapper">
        <div className="input-content">
          <label htmlFor="name">{t("Name")}</label>
          <input
            id="name"
            name="name"
            value={name}
            autoComplete="off"
            onChange={onChange}
          />
        </div>
        <div className="input-content">
          <label htmlFor="description">{t("Description")}</label>
          <input
            id="description"
            name="description"
            value={description}
            autoComplete="off"
            onChange={onChange}
          />
        </div>
      </div>
    </DenominationStyled>
  );
}

const DenominationStyled = styled.div`
  margin-bottom: 40px;
  .inputs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    @media (max-width: 500px) {
      flex-direction: column;
    }
    .input-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      label {
        font-size: 0.7em;
        margin-bottom: 5px;
      }
      input {
        padding: 10px 15px;
        border-radius: 15px;
        font-size: 0.85em;
        font-weight: 500;
        background-color: #1c2641;
        /* color: #1c2c49; */
        color: #fff;
        outline: none;
        box-shadow: none;
      }
    }
  }
`;

export default Denomination;
