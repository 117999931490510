import React from "react";
import useAxios from "./useAxios";

const useMsgs = () => {
  const { handleAxios } = useAxios();

  const createChat = async (message, toId, listingId, callback) => {
    const object = {
      listing_id: Number(listingId),
      content: message,
      send_to_userid: toId,
    };

    try {
      const configAxios = {
        path: "/messages/create",
        method: "post",
        body: object,
        toast: false,
        postFetch: (res) => {
          if (callback) callback(res);
        },
      };

      await handleAxios(configAxios);
    } catch (err) {
      //   notify.e(err.message);
      console.log("----Error Creating Message----", err);
    }
  };
  return { createChat };
};

export default useMsgs;
