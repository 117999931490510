import React from "react";
//base styles
import "../src/styles/index.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import * as serviceWorker from "./serviceWorker";
// routing component
// Redux configuration
import { Provider } from "react-redux";
import { createStore } from "redux";
import { MainReducer } from "../src/app/reducers/MainReducer";
//import "mapbox-gl/dist/mapbox-gl.css";
import AppRouter from "./mobile/AppRouter";
import WalletConnectProvider from "./mobile/WalletConnectProvider";
import { createRoot } from "react-dom/client";
import reactDom from "react-dom";

//for Dev only
const reduxDevTool =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

// Redux store
const store = createStore(MainReducer, reduxDevTool);

// const root = document.getElementById("root");

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <WalletConnectProvider>
        <div className="flex flex-col justify-start items-center w-full bg-blueDark min-h-screen">
          <AppRouter />
        </div>
      </WalletConnectProvider>
    </Provider>
  </React.StrictMode>
);

// ReactDom.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <Web3ReactProvider getLibrary={getLibrary}>
//         {/* <MainRouter /> */}
//         <AppRouter />
//       </Web3ReactProvider>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// reactDom.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <WalletConnectProvider>
//         {/* <Web3ReactProvider getLibrary={getLibrary}> */}
//         {/* <MainRouter /> */}
//         <AppRouter />
//       </WalletConnectProvider>
//       {/* </Web3ReactProvider> */}
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
