import React from "react";
import FooterWhoWeAre from "../../../../features/Home/Footer/FooterWhoWeAre";
import { useTranslate } from "../../../hooks";

const ModalWWA = ({ Modal, open, ...modalPropsRest }) => {
  const { t } = useTranslate();
  return (
    <Modal
      id="who-we-are-modal"
      bodyScroll={false}
      defaultCloseButton
      transitionType="botToTop"
      type="largeBox"
      title={t("Who We Are ?")}
      {...modalPropsRest}
    >
      <FooterWhoWeAre />
    </Modal>
  );
};

export default ModalWWA;
