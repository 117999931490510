import { useState, useEffect } from "react";
import axios from "axios";
import useUserState from "./useUserState";

const API_URLS = {
  DEV: "https://bbop-api.herokuapp.com/api/v1",
  // DEV: "http://localhost:4001/api/v1",
  // DEV: "http://192.168.50.224:4001/api/v1",
  // PROD: "https://prod.api.bbop.co/api/v1",
  PROD: "https://prod-bbop-api-04c4e8416b07.herokuapp.com/api/v1",
};

const url = API_URLS[process.env.REACT_APP_WEB3_ARTIFACT_ENVIRONMENT];

const promiseWrapper = (promise) => {
  let status = "pending";
  let result;

  const s = promise.then(
    (value) => {
      status = "success";
      result = value;
    },
    (error) => {
      status = "error";
      result = error;
    }
  );

  return () => {
    switch (status) {
      case "pending":
        throw s;
      case "success":
        return result;
      case "error":
        throw result;
      default:
        throw new Error("Unknown status");
    }
  };
};

function useGetData(path, auth = true, conditional = true) {
  const [resource, setResource] = useState(null);
  const { jwt } = useUserState();

  if (auth) {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${jwt}`,
      client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
    };
  }

  useEffect(() => {
    if (!conditional) return;
    const getData = async () => {
      const promise = axios
        .get(`${url}${path}`)
        .then((response) => response.data);
      setResource(promiseWrapper(promise));
    };

    getData();
  }, [url, conditional]);

  return resource;
}

export default useGetData;
