import React, { forwardRef, useMemo } from "react";
import { IoMdClose } from "react-icons/io";
import styled from "styled-components";
import { useTranslate } from "../../../../hooks";

const SimpleModalTemplate = forwardRef(
  ({ title, theme, defaultCloseButton, close, modalHeight, children }, ref) => {
    const { t } = useTranslate();

    const colors = useMemo(() => {
      let text = "";
      if ((theme = "blueContrast")) {
        text = "nebulaOpac";
      }
      return {
        bg: theme,
        text,
      };
    }, [theme]);
    return (
      <ModalExampleStyled
        ref={ref}
        modalHeight={modalHeight}
        className={`bg-${colors.bg} text-${colors.text}`}
      >
        <div className="header">
          {title && (
            <div className="text-3xl font-medium w-full flex flex-col justify-center items-center">
              <p>{t(title)}</p>
            </div>
          )}
          {defaultCloseButton && (
            <IoMdClose
              className="default-button text-3xl"
              role="button"
              fontSize="1.2em"
              onClick={close}
            />
          )}
        </div>
        <div className="px-5 pt-5 pb-[5em] overflow-y-scroll h-full">
          {children}
        </div>
      </ModalExampleStyled>
    );
  }
);

const ModalExampleStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${(props) => `${props.modalHeight}vh`};
  border-radius: 15px 15px 0 0;
  transform: translateY(100%);
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    .default-button {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .title {
      font-weight: 700;
    }
  }
`;

export default SimpleModalTemplate;
