import React, { useState } from "react";
import { useWriteContract } from "wagmi";
import useGlobalComp from "../../hooks/useGlobalComp";
import useConfirmTx from "./useConfirmTx";
import useWeb3 from "./useWeb3";
import useCurrentNetwork from "./useCurrentNetwork";

const useAproval = () => {
  const [error, setError] = useState("");
  const { data, writeContract } = useWriteContract();
  const { loadContractInteraction } = useGlobalComp();
  const { toWei } = useWeb3();
  const currentNetwork = useCurrentNetwork();

  const { confirm, loading } = useConfirmTx(data);

  const requestAproval = (amount, contractAddress) => {
    const amountToWei = toWei(amount);
    writeContract(
      {
        abi: currentNetwork?.currency_abi?.abi,
        address: currentNetwork?.currency_abi?.address,
        functionName: "approve",
        args: [contractAddress, amountToWei.toString()],
      },
      {
        onError: (err) => {
          setError(err?.message);
          loadContractInteraction(false);
        },
      }
    );
  };
  return {
    requestAproval,
    error,
    setError,
    loading,
    confirm,
  };
};

export default useAproval;
