import React from "react";
import { Main, UserComp } from "../components";

const User = () => {
  return (
    <Main>
      <UserComp />
    </Main>
  );
};

export default User;
