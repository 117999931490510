export const sortData = (data) => {
  const groupedServices = data.reduce((acc, service) => {
    const key = service.group;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(service);
    return acc;
  }, {});

  const orderedGroups = Object.keys(groupedServices)
    .filter((group) => group !== "OTHER")
    .map((key) => ({
      group: key,
      services: groupedServices[key],
    }));

  orderedGroups.sort((a, b) => {
    const indexA = data.findIndex((service) => service.group === a.group);
    const indexB = data.findIndex((service) => service.group === b.group);
    return indexA - indexB;
  });

  return [
    ...orderedGroups,
    {
      group: "OTHER",
      services: groupedServices["OTHER"],
    },
  ];
};
