import React from "react";
import { AiOutlineQuestion } from "react-icons/ai";
import { BiDonateHeart } from "react-icons/bi";
import { FiUser } from "react-icons/fi";
import {
  IoChevronForward,
  IoHeartOutline,
  IoLanguageOutline,
} from "react-icons/io5";
import { MdOutlineVerified } from "react-icons/md";
import useLogOut from "../../../hooks/useLogOut";
import { useHistory, useUserState } from "../../hooks";
import useTranslate from "../../hooks/useTranslate";
import { Divider, ShowUser } from "../commons";
import {
  ModalContactSupport,
  ModalEditProfile,
  ModalFAQ,
  ModalHBW,
  ModalLanguage,
  ModalWWA,
} from "../commons/Modals";
import { useModalTemplate } from "../commons/ModalTemplate";
import ChangeMode from "./ChangeMode";
import { ROLES } from "../../const";

const SettingsComp = () => {
  const { userStore } = useUserState();
  const history = useHistory();
  const { t } = useTranslate();
  const { logout } = useLogOut();
  const modalLang = useModalTemplate();
  const modalContactSupport = useModalTemplate();
  const modalWWA = useModalTemplate();
  const modalHBW = useModalTemplate();
  const modalFAQ = useModalTemplate();
  const modalEditProfile = useModalTemplate();

  return (
    <div className="w-full pb-[2em]">
      <div className="w-full px-5 pt-10">
        <ShowUser user={userStore} type="settings" />
      </div>
      <Divider px="5" />
      <ChangeMode />
      <Item
        icon={<FiUser className="text-3xl" />}
        label="Personal Information"
        onClick={() => modalEditProfile.open()}
      />
      <Divider px="5" />
      {userStore?.role === ROLES.GUEST && (
        <>
          <Item
            icon={<IoHeartOutline className="text-3xl" />}
            label="Favorites"
            onClick={() => history("/favorites")}
          />
          <Divider px="5" />
        </>
      )}
      <Item
        icon={<IoLanguageOutline className="text-3xl" />}
        label="Language"
        onClick={() => modalLang.open()}
      />
      <Divider px="5" />
      <Item
        color={
          userStore.kyc_status === "pending" ||
          userStore.kyc_status === "unverified"
            ? "yellowBbop"
            : ""
        }
        icon={<MdOutlineVerified className="text-3xl" />}
        label="KYC"
        onClick={() => history("/kyc")}
      />
      <Divider px="5" />
      <Item
        icon={<BiDonateHeart className="text-3xl" />}
        label="Donate"
        onClick={() => history("/donate")}
      />
      <Divider px="5" />
      <p className="text-3xl text-nebula px-5 m-0 pt-8 pb-5">{t("Support")}</p>
      <Item
        icon={<AiOutlineQuestion className="text-3xl" />}
        label="Frequent Questions"
        onClick={() => modalFAQ.open()}
      />
      <Divider px="5" />
      <Item
        icon={<AiOutlineQuestion className="text-3xl" />}
        label="Contact Support"
        onClick={() => modalContactSupport.open()}
      />
      <Divider px="5" />
      <Item
        icon={<AiOutlineQuestion className="text-3xl" />}
        label="Who we are"
        onClick={() => modalWWA.open()}
      />
      <Divider px="5" />
      <Item
        icon={<AiOutlineQuestion className="text-3xl" />}
        label="How Bbop works"
        onClick={() => modalHBW.open()}
      />
      <Divider px="5" />
      <div className="w-full px-5 pt-[5em]">
        <div
          onClick={logout}
          className="border-2 border-nebula rounded-lg w-full text-2xl cursor-pointer py-3 text-nebula flex justify-center"
        >
          <p className="m-0">{t("Logout")}</p>
        </div>
      </div>
      <ModalEditProfile {...modalEditProfile} />
      <ModalLanguage {...modalLang} />
      <ModalContactSupport {...modalContactSupport} />
      <ModalWWA {...modalWWA} />
      <ModalHBW {...modalHBW} />
      <ModalFAQ {...modalFAQ} />
    </div>
  );
};

export default SettingsComp;

const Item = ({ icon, label, onClick, color }) => {
  const { t } = useTranslate();
  return (
    <div
      className={`w-full flex items-center justify-between text-nebula px-5 py-4 cursor-pointer ${
        color ? `text-${color}` : ""
      }`}
      onClick={onClick}
    >
      <div className="flex items-center gap-5">
        {icon}
        <p className="text-xl">{t(label)}</p>
      </div>
      <IoChevronForward className="text-3xl" />
    </div>
  );
};
