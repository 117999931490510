import { differenceInDays, format, parseISO, sub } from "date-fns";
import { useCallback, useEffect, useState } from "react";

const useCalculatePrice = ({
  dates,
  config,
  listing,
  specialPriceDays,
  conditional = true,
}) => {
  const [days, setDays] = useState();
  const [subTotal, setSubTotal] = useState();
  const [total, setTotal] = useState();
  const [subTotalDescount, setSubTotalDescount] = useState();
  const [securityPercentage, setSecurityPercentage] = useState();
  const [pricePerDays, setPricePerDays] = useState();
  const [subPricePerDays, setSubPricePerDays] = useState();
  const [pricePerDaysResult, setPricePerDaysResult] = useState();

  const filterSpecialPricesAndCalculateFinalPrice = (innerDays) => {
    // useCallback(
    if (specialPriceDays?.equals?.length === 0) {
      return {
        totalPricePerDays: parseInt(listing?.price) * innerDays,
      };
    }
    const totalPriceEquals = specialPriceDays?.equals.reduce(
      (accumulator, object) => {
        return accumulator + object.price;
      },
      0
    );
    const totalPriceRest = specialPriceDays?.rest.reduce(
      (accumulator, object) => {
        return accumulator + parseInt(listing?.price);
      },
      0
    );
    return {
      totalPricePerDays: totalPriceEquals + totalPriceRest,
    };
  };
  // , [listing, specialPriceDays, days]);

  const handleCalculatePrice = useCallback(async () => {
    let innerDays = 0;
    const startFormat = format(dates?.start, "yyyy-MM-dd");
    const endFormat = format(dates?.end, "yyyy-MM-dd");
    if (endFormat === startFormat) {
      innerDays = 1;
    } else {
      const newDateStartISO = new Date(parseISO(startFormat));
      const newDateEndISO = new Date(parseISO(endFormat));
      const daysISO = differenceInDays(newDateEndISO, newDateStartISO) + 1;
      // ? differenceInDays(sub(dates?.end, { days: 1 }), dates?.start) + 1 || 1
      innerDays = conditional ? daysISO : 0;
    }
    setDays(innerDays);
    const { totalPricePerDays } =
      filterSpecialPricesAndCalculateFinalPrice(innerDays);
    setPricePerDays(totalPricePerDays);
    setPricePerDaysResult(totalPricePerDays);
    setSubPricePerDays(
      days === 1 && specialPriceDays?.equals?.length === 1
        ? 0
        : listing?.price * specialPriceDays?.rest?.length
    );
    // setSecurityPercentage(
    //   pricePerDays * (listing?.security_deposit_percentage * 0.01)
    // );
    setSecurityPercentage(listing?.security_deposit_percentage);
    const preSubTotal =
      totalPricePerDays + listing?.security_deposit_percentage;
    setSubTotal(preSubTotal);
    // setSubTotal(
    //   pricePerDays +
    //   pricePerDays * (listing?.security_deposit_percentage * 0.01)
    // );
    setSubTotalDescount(preSubTotal - preSubTotal * 0.2);
    setTotal(
      config?.value === "visible" ? subTotalDescount.toFixed(2) : preSubTotal
    );
  }, [dates, conditional, listing]);

  useEffect(() => {
    if (dates?.start && dates?.end) {
      handleCalculatePrice();
    }
  }, [dates, specialPriceDays, listing]);

  return {
    total,
    subTotal,
    subTotalDescount,
    pricePerDays,
    subPricePerDays,
    securityPercentage,
    days,
    pricePerDaysResult,
    recallPrices: handleCalculatePrice,
  };
};

export default useCalculatePrice;
