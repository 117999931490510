import useAxios from "./useAxios";
import useToast from "./useToast";
import useTranslate from "./useTranslate";
import useUserState from "./useUserState";

const useFavorites = ({ setFavorite, toastDismiss, setFavListing }) => {
  const { handleAxios } = useAxios();
  const { t } = useTranslate();

  const { notify } = useToast([
    {
      position: "top-center",
      type: "success",
      key: "s",
    },
    {
      position: "top-center",
      type: "error",
      key: "e",
    },
  ]);

  const getFavoriteByListingId = (listing_id) => {
    const configGetFavorites = {
      path: `/listingFavorite/getFavoriteById/${listing_id}`,
      method: "get",
      postFetch: (res) => {
        setFavListing(res);
        if (res?.status === "ENABLED") {
          setFavorite(true);
        } else {
          setFavorite(false);
        }
      },
    };

    handleAxios(configGetFavorites);
  };

  const { isLogged } = useUserState();

  const handleFavListing = async (e, listing_id) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isLogged) {
      notify.e(t("You need to login first"));
      return;
    }
    const configFav = {
      path: `/listingFavorite/addFavorite`,
      body: {
        listing_id,
      },
      toast: false,
      method: "post",
      postFetch: (res) => {
        setFavorite(true);
        if (toastDismiss) toastDismiss();
      },
      postErrorFetch: (err) => {
        setFavorite(false);
        notify.e(err?.response?.data?.message || "Internal Server Error");
      },
    };

    await handleAxios(configFav);
  };

  const handleDeleteFav = async (e, listing_id) => {
    e.preventDefault();
    e.stopPropagation();
    const configDeleteFav = {
      path: `/listingFavorite/deleteFavorite/${listing_id}`,
      method: "put",
      toast: false,
      postFetch: (res) => {
        // notify.s(res?.message);
        setFavorite(false);
      },
      postErrorFetch: (err) => {
        setFavorite(true);
        notify.e(err?.response?.data?.message || "Internal Server Error");
      },
    };
    await handleAxios(configDeleteFav);
  };

  return {
    getFavoriteByListingId,
    handleFavListing,
    handleDeleteFav,
  };
};

export default useFavorites;
