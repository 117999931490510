import React from "react";
import FooterFaq from "../../../../features/Home/Footer/FooterFaq";

const ModalFAQ = ({ Modal, open, ...modalPropsRest }) => {
  return (
    <Modal
      id="faq-modal"
      bodyScroll={false}
      defaultCloseButton
      transitionType="botToTop"
      type="largeBox"
      overFlowYScroll
      title={"Frequent Questions"}
      {...modalPropsRest}
    >
      <FooterFaq />
    </Modal>
  );
};

export default ModalFAQ;
