import React from "react";
import TimePicker from "react-time-picker";
import styled from "styled-components";

const InputTimer = ({ time, onChange, disabled }) => {
  return (
    <InputTimeStyled
      className={`flex items-center w-full text-nebula bg-[#1c2641] p-4 rounded-lg ${
        disabled ? "opacity-30" : ""
      }`}
    >
      <TimePicker
        onChange={(v) => onChange(v)}
        value={time}
        disabled={disabled}
      />
    </InputTimeStyled>
  );
};

export default InputTimer;

const InputTimeStyled = styled.div`
  .react-time-picker {
    width: 100%;
    .react-time-picker__wrapper {
      width: 100%;
      display: flex;
      gap: 0.5em;
      .react-time-picker__button {
        width: 100%;
        color: #fff;
        display: none;
      }
      .react-time-picker__inputGroup {
        width: 100%;
        display: flex;
        justify-content: start;
        .react-time-picker__inputGroup__hour {
          text-align: center;
          width: 2em !important;
        }
        .react-time-picker__inputGroup__minute {
          width: 2em !important;
        }
        .react-time-picker__inputGroup__divider {
          font-size: 1.3em;
          padding: 0 1em 0 0;
        }
        .react-time-picker__inputGroup__amPm {
          width: 3em !important;
        }
        .react-time-picker__inputGroup__leadingZero {
          font-size: 1.3em;
        }
        .react-time-picker__inputGroup__input {
          background: transparent;
          font-size: 1.3em;
        }
      }
    }
  }
`;
