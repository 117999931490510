import { useEffect, useState } from "react";

export const useDebounce = () => {
  const [active, setActive] = useState(0);
  const [debounceValue, setDebounceValue] = useState();

  const handleDebounce = (value, delay, callback) => {
    if (active) {
      setActive(active + 1);
    }
    if (active === 1) {
      setTimeout(() => {
        setDebounceValue(value);
        callback(debounceValue);
      }, delay);
    }
  };

  return { handleDebounce, debounceValue };
};

export const useDebounceAuto = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};
