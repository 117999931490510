import { useEffect, useMemo, useState } from "react";
import useAxios from "./useAxios";

//this is used to make simple axio calls returns a value that contains the response
// a recall function to make the fetch again whenever you want and a work to manage the loading
// search for examples with '//example useSimpleAxiosPaginated'
const useSimpleAxiosPaginated = (config, simple = true, dependencie = true) => {
  const [value, setValue] = useState();
  const [work, setWork] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [offset, setOffset] = useState(0);

  const { handleAxios } = useAxios();

  const recall = async () => {
    if (simple) {
      config.postFetch = (res) => setValue(res);
    }
    setWork(true);
    await handleAxios(config);
    setWork(false);
    setIsLoaded(true)
  };

  const callMore = async () => {
    setOffset(offset + config.body.limit);
    config.body.offset = offset + config.body.limit;
    config.postFetch = (res) => {
      setValue({ hasMore: res.hasMore, data: [...value.data, ...res.data] });
    };
    setWork(true);
    await handleAxios(config);
    setWork(false);
  };

  useEffect(() => {
    recall();
  }, [dependencie]);

  const isShowMore = useMemo(() => {
    if (!offset) {
      return value?.length === config?.body?.limit;
    }
    return value?.length === config?.body?.limit + offset;
  }, [offset, config, value]);

  return {
    notFound: value?.notFound,
    value: value?.data,
    hasMore: value?.hasMore,
    recall,
    work,
    isLoaded,
    callMore,
    isShowMore,
  };
};

export default useSimpleAxiosPaginated;
