import React, { useState } from "react";
import styled from "styled-components";
import { useTranslate } from "../../../hooks";

function convertText(text) {
  const words = text.split("_");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  return capitalizedWords.join(" ");
}

function MultipleChoiceByGroup({
  data = [],
  showOnlyFirst = 2,
  alwaysShowAll = false,
  state,
  setState,
  style,
}) {
  const [showAll, setShowAll] = useState(alwaysShowAll);
  const { t } = useTranslate();

  const elements = showAll ? data.length : showOnlyFirst;

  return (
    <MultipleChoiceByGroupStyled style={style}>
      {data.slice(0, elements).map(({ group: groupName, services }) => {
        return (
          <div key={groupName} className="group-wrapper">
            <p className="group-name">{t(convertText(groupName))}</p>
            <div className="services">
              {services.map(({ id, name }) => {
                return (
                  <label key={id} className="checkbox-label">
                    <span className="service-name">{t(convertText(name))}</span>
                    <input
                      type="checkbox"
                      checked={state?.includes(id)}
                      onChange={() => setState(id)}
                    />
                    <span className="check" />
                  </label>
                );
              })}
            </div>
          </div>
        );
      })}

      {!alwaysShowAll && (
        <p
          className="would-show"
          role="button"
          onClick={() => setShowAll((prev) => !prev)}
        >
          {t("Show")} {showAll ? t("less") : t("more")}
        </p>
      )}
    </MultipleChoiceByGroupStyled>
  );
}

const MultipleChoiceByGroupStyled = styled.div`
  .group-name {
    font-weight: 700;
    margin: 30px 0 10px;
  }
  .services {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 20px;
    @media (max-width: 745px) {
      grid-template-columns: 1fr;
      .checkbox-label {
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }
    .checkbox-label {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 12px 0;
      cursor: pointer;
      &:hover .check {
        border-color: rgba(255, 255, 255, 1);
      }
      .service-name {
        font-size: 0.95em;
        font-weight: 200;
        order: 3;
      }
      .check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background-color: transparent;
        order: 2;
        transition: 0.3s;

        &:before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 6px;
          border-bottom: 3px solid #1c2c49;
          border-left: 3px solid #1c2c49;
          transform: scale(0) rotate(-45deg);
          position: relative;
          bottom: 2px;
          transition: 0.6s;
        }
      }
      input {
        display: none;
        &:checked ~ .check {
          background-color: #fff;
        }
        &:checked ~ .check::before {
          transform: scale(1) rotate(-45deg);
        }
      }
    }
  }

  .would-show {
    margin-top: 10px;
    text-decoration: underline;
    font-size: 1em;
    font-weight: 600;
  }
`;

export default MultipleChoiceByGroup;
