import React from "react";
import { useTranslate } from "../../../../mobile/hooks";
import { HOW_IT_WORKS } from "../../../../utils/footerDescriptions";

const HowItWorks = () => {
  const { t } = useTranslate();
  const commonP = "m-0 text-nebula font-light text-lg";
  const commonList = "font-extrabold pr-2";

  return (
    <div classNameInner="">
      <p className={`${commonP} `}>
        {t(
          HOW_IT_WORKS[0].description
        )}
      </p>
      <p className={`${commonP} `}>
        {t(
          HOW_IT_WORKS[1].description
        )}
      </p>
      <p className={`${commonP} py-4`}>
        {t(
          HOW_IT_WORKS[2].description
        )}
      </p>
      <div className="md:pl-4 pl-2">
        <p className={`${commonP} `}>
          <strong className={commonList}>{"-"}</strong>
          {t(
            HOW_IT_WORKS[3].description
          )}
        </p>
        <br></br>
        <p className={`${commonP} `}>
          <strong className={commonList}>{"-"}</strong>
          {t(
            HOW_IT_WORKS[4].description
          )}
        </p>
        <br></br>
        <p className={`${commonP} `}>
          <strong className={commonList}>{"-"}</strong>
          {t(
            HOW_IT_WORKS[5].description
          )}
        </p>
        <br></br>
        <p className={`${commonP} `}>
          <strong className={commonList}>{"-"}</strong>
          {t(
            HOW_IT_WORKS[6].description
          )}
        </p>
        <br></br>
      </div>
      <p className={`${commonP} py-4`}>
        {t(
          HOW_IT_WORKS[7].description
        )}
      </p>
      <p className={`${commonP} py-4`}>
        {t(
          HOW_IT_WORKS[8].description
        )}
      </p>
    </div>
  );
};

export default HowItWorks;
