import { format } from "date-fns";
import React from "react";
import useTranslate from "../../../mobile/hooks/useTranslate";
import NetworkConnected from "../../../commons/NetworkConnected";

const PaymentHostGuest = ({ dispute }) => {
  const { t } = useTranslate();
  return (
    <div className="flex w-full flex-col  pt-5">
      <div className="flex flex-col w-full justify-center items-start">
        <p className="font-semibold text-white">{t("Payment to host")}</p>
        <p className="text-white">{dispute?.payment_to_host} USDT</p>
      </div>
      <div className="flex flex-col w-full justify-center items-start">
        <p className="font-semibold text-white">
          {t("Payment to guest (security deposit)")}
        </p>
        <p className="text-white">
          {dispute?.payment_to_guest?.toFixed(2)} USDT
        </p>
      </div>
    </div>
  );
};

export const DisputeInfo = ({ dispute, users }) => {
  const { t } = useTranslate();

  return !dispute ? (
    <div className="px-5 md:px-[10rem]">
      <p className="text-center text-white ">
        {t(
          "We hope it is not a major issue and you and your fellow BBOPer can come to a resolution.  As per the BBOP policy, we allow one month for you and your fellow BBOPer to discuss any issues and try to agree to a negotiated proposal. If after 1 month has passed, you still have not reached an agreement, our BBOP support team will review the case.  After a full review, we will provide our own proposal for the distribution of the funds along with our signature.  This proposal will only require one additional signature from the host or the guest in order for the crypto funds to be released."
        )}
      </p>
      <PaymentHostGuest dispute={users} />
    </div>
  ) : (
    <div className="w-full flex justify-center items-center">
      <div className="rounded-xl flex flex-col items-center py-2 px-5 w-full md:w-fit justify-center">
        <p className="text-white text-2xl w-full flex justify-center items-center font-semibold">
          {t("Booking information")}
        </p>
        <div className="w-full h-full flex flex-col justify-center items-center px-5 py-2 md:px-[10rem] gap-2">
          <div className="md:w-[22em] w-full">
            <div className="w-fit flex items-center text-white gap-2">
              <p className="font-semibold">{`${t("Location:")}`}</p>
              <p className="text-center">{` ${dispute?.listing?.listing_location?.city}, ${dispute?.listing?.listing_location?.country}`}</p>
            </div>
            <div className="w-fit flex items-center gap-2  text-white">
              <p className="font-semibold">{`${t("From:")}`}</p>
              <p>{format(new Date(dispute?.booking.start), "dd-MM-yyyy")}</p>
            </div>
            <div className="w-fit flex items-center gap-2  text-white">
              <p className="font-semibold">{`${t("To:")}`}</p>
              <p>{format(new Date(dispute?.booking.end), "dd-MM-yyyy")}</p>
            </div>
            <NetworkConnected
              chainId={dispute.network}
              className="!justify-start"
              preText={<span className="font-semibold">{"Network: "}</span>}
            />
          </div>
          <PaymentHostGuest
            dispute={{
              payment_to_guest: dispute?.listing.security_deposit_percentage,
              payment_to_host: dispute?.booking.amount,
            }}
          />
        </div>
      </div>
    </div>
  );
};
