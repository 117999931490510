import { BsCamera, BsCreditCard2Front } from "react-icons/bs";
import { FaRegAddressCard } from "react-icons/fa";
import selfies from "../assets/selfies64.png";

export const APP_FILES_TYPES_TITLE_UNVERIFIED = {
  front_id: "Upload your Front ID",
  back_id: "Upload your Back ID",
  selfie: "Take a selfie holding your ID + date",
};
export const APP_FILES_TYPES_TITLE = {
  front_id: "Front ID",
  back_id: "Back ID",
  selfie: "Selfie",
};

export const APP_FILES_TYPES = {
  FRONT_ID: "front_id",
  BACK_ID: "back_id",
  SELFIE: "selfie",
};

export const APP_FILES_ICONS = {
  front_id: <FaRegAddressCard size={80} color="#45BFF8" />,
  back_id: <BsCreditCard2Front size={80} color="#45BFF8" />,
  selfie: <BsCamera size={80} color="#45BFF8" />,
  // selfie: <img src={selfies} alt="Selfie"></img>,
};
