import React from "react";
import { useTranslate } from "../../hooks";
import { useModalTemplate } from "./ModalTemplate";

const InfoToModal = ({
  info,
  title,
  showLength,
  maxLength,
  className = "",
}) => {
  const { t } = useTranslate();
  const { Modal, open, ...modalPropsRest } = useModalTemplate();
  const textClassName = `m-0 text-nebulaOpac text-lg ${className}`;
  return (
    <div className="flex flex-col justify-start items-start p-5 gap-5">
      {title && (
        <span className="text-nebula font-medium text-3xl ">{t(title)}</span>
      )}
      {info?.length > maxLength ? (
        <>
          <p className={textClassName}>{`${info.slice(0, showLength)}...`}</p>
          <span
            onClick={open}
            className={`underline text-nebulaOpac text-lg ${className}`}
          >
            {t("See more")}
          </span>
        </>
      ) : (
        <p className={textClassName}>{info}</p>
      )}
      <Modal
        id="info-to-modal"
        bodyScroll={false}
        defaultCloseButton
        transitionType="botToTop"
        type="largeBox"
        title={title}
        {...modalPropsRest}
      >
        <p className="">{info}</p>
      </Modal>
    </div>
  );
};

export default InfoToModal;
