import React from "react";

const ManageLargeText = ({
  maxLength,
  cutValue,
  value,
  className,
  onClick = () => {},
}) => {
  var regex = new RegExp(".{1," + cutValue + "}", "g");

  return (
    <p className={className} onClick={onClick}>
      {value?.length > maxLength ? `${value?.match(regex)[0]}...` : value}
    </p>
  );
};

export default ManageLargeText;
