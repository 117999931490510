import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { Transition } from "react-transition-group";
import { useWindowSize } from "react-use";
import { transitionTypes, getModalType } from "./utils";
import Portal from "./Portal";
import { useTranslate } from "../../../hooks";

const Modal = ({
  id,
  isOpen,
  defaultCloseButton,
  bodyScroll,
  title,
  headerTitle,
  type,
  transitionType = "botToTop",
  close,
  modalHeight = "90%",
  zIndex = "1001",
  overFlowYScroll,
  theme,
  notChildrenWrapper = false,
  style,
  children,
}) => {
  const childRef = useRef();

  const { width, height } = useWindowSize();
  const { t } = useTranslate();

  const colors = useMemo(() => {
    const themeValue = theme || "blueContrast";

    return {
      bg: themeValue,
      text: themeValue === "blueContrast" ? "nebulaOpac" : "",
    };
  }, [theme]);

  const tl = useMemo(() => gsap.timeline(), []);

  const modalType = useMemo(() => {
    if (!type) return false;
    return getModalType(type);
  }, [type]);

  const isDesktop = width >= 800;

  const transition = modalType
    ? modalType.transition
    : transitionTypes[transitionType];

  const onTransition = (node, done) => {
    tl.to(node, {
      ...transitionTypes.overlay[isOpen ? "onEnter" : "onExit"],
    }).to(
      childRef.current,
      {
        ...transition(isDesktop)[isOpen ? "onEnter" : "onExit"],
        onComplete: done,
      },
      "<"
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape" && isOpen) close();
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (typeof bodyScroll !== "undefined" && !bodyScroll)
      document.body.style.overflow = isOpen ? "hidden" : "unset";
    return () => {
      if (typeof bodyScroll !== "undefined" && !bodyScroll)
        document.body.style.overflow = "unset";
    };
  }, [isOpen, bodyScroll]);

  useEffect(() => {
    if (childRef.current)
      gsap.to(childRef.current, {
        ...transition(isDesktop)[isOpen ? "onEnter" : "onExit"],
        overwrite: true,
      });
    // eslint-disable-next-line
  }, [isOpen, width, height]);

  const childModalProps = {
    isOpen,
    isDesktop,
    title,
    headerTitle,
    defaultCloseButton,
    close,
    modalHeight,
    zIndex,
    overFlowYScroll,
    colors,
    notChildrenWrapper,
    style,
    ref: childRef,
    ...(modalType ? { children } : {}),
  };

  return (
    <Transition
      in={isOpen}
      addEndListener={onTransition}
      unmountOnExit
      mountOnEnter
    >
      <Portal id={id}>
        <ModalStyled tabIndex="0" onClick={close} zIndex={zIndex}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {React.cloneElement(
              modalType ? <modalType.Component /> : children,
              childModalProps
            )}
          </div>
        </ModalStyled>
      </Portal>
    </Transition>
  );
};

const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: ${(p) => p.zIndex};
`;

export default Modal;
