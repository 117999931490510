import React from "react";

export default function DummyImg({ className, classNameImg }) {
  return (
    <div className={"relative h-full transition-all " + className || ""}>
      <img
        className={
          "w-full h-full object-cover bg-gray-300 rounded-t-xl " +
            classNameImg || ""
        }
        src="https://picsum.photos/seed/picsum/600/400"
        alt="dummy"
      />
    </div>
  );
}
