import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../../commons/Button";
import useAxios, { mapConfig } from "../../../hooks/useAxios";
import useToast from "../../../hooks/useToast";
import useTranslate from "../../../mobile/hooks/useTranslate";

function ContactUsModal({ close }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslate();
  const { handleAxios } = useAxios();
  const [error, setError] = useState(false);
  const recaptchaRef = useRef();

  const { notify } = useToast([
    {
      position: "top-center",
      type: "success",
      key: "s",
    },
    {
      position: "top-center",
      type: "error",
      key: "e",
    },
  ]);

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      notify.e(t("Please verify you are not a robot"));
      return;
    }

    if (!name || !email || !message || !recaptchaValue) {
      notify.e(t("All fields are required"));
      return;
    }

    if (!isEmailValid(email)) {
      notify.e(t("Email is invalid"));
      return;
    }

    const data = {
      name,
      email,
      message,
      recaptchaValue,
    };

    handleAxios(
      mapConfig("/contact/send", "post", {
        body: data,
        postFetch: () => {
          close();
          notify.s(t("Message sent successfully"));
          // loadRecaptcha();
        },
        postErrorFetch: () => {
          setError(true);
          // loadRecaptcha();
        },
        toast: false,
      })
    );
  };

  return (
    <div className="w-full flex flex-col gap-4 ">
      <input
        className="rounded-lg p-2 bg-blueDark text-nebula text-xl "
        type="text"
        placeholder="Name"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
      <input
        className="rounded-lg p-2 bg-blueDark text-nebula text-xl "
        type="email"
        placeholder="Email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <textarea
        className="rounded-lg p-2 bg-blueDark text-nebula text-xl "
        placeholder="Message"
        rows="10"
        value={message}
        onChange={(event) => setMessage(event.target.value)}
      />
      <div className="w-full flex flex-col md:flex-row justify-center gap-5 items-center md:justify-between md:items-end ">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        />
        <div className="w-full md:w-fit h-fit flex justify-center">
          {error && (
            <p className="text-red-500 text-sm">
              {t("Something went wrong, please try again later")}
            </p>
          )}
          <Button
            className="rounded-full px-[5em] py-2 w-fit"
            type="dark"
            onClick={onSubmit}
          >
            {t("Send")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ContactUsModal;
