import React from "react";
import { useHistory, useTranslate, useUserState } from "../../hooks";

// const MessageUser = ({ id, listingId }) => {
const MessageUser = ({ user, listing, fullWidth }) => {
  const { t } = useTranslate();
  const history = useHistory();
  const { userStore } = useUserState();

  return (
    <div
      className={`bg-blueContrast rounded-lg text-nebulaOpac py-4 px-10 ${
        fullWidth ? "w-full flex justify-center items-center" : "w-fit"
      } cursor-pointer`}
      onClick={() =>
        history("/chat", {
          listingRes: listing,
          userRes: listing?.user,
          role: userStore?.role,
        })
      }
    >
      <span className="text-xl">{t("Message Host")}</span>
    </div>
  );
};

export default MessageUser;
