import useAxios from "./useAxios";

const useAppFiles = (toast = false) => {
  const { handleAxios } = useAxios();

  const create = async (data, listingId, callback) => {
    let formData = new FormData();

    for (let item of data) {
      if (item.value) {
        formData.append("fileToUpload", item.value);
        formData.append("type_of_file", item.typeFile);
        formData.append("listing_id", listingId ? listingId : null);
      }
    }
    const configAxiosSaveFrontID = {
      path: "/users/profile/fileMassiveUpload",
      method: "post",
      body: formData,
      toast,
      postFetch: (res) => {
        if (callback) callback(res);
      },
    };
    await handleAxios(configAxiosSaveFrontID);
  };

  const update = async (data, callback) => {
    const body = { files: data };
    const configAxiosUpdateFiles = {
      path: "/app_file/updateFiles",
      method: "put",
      body,
      toast,
      postFetch: (res) => {
        if (callback) callback(res);
      },
    };
    await handleAxios(configAxiosUpdateFiles);
  };
  return { create, update };
};

export default useAppFiles;
