import React, { useMemo } from "react";
import { FaExchangeAlt } from "react-icons/fa";
import useAxios, { mapConfig } from "../../../hooks/useAxios";
import { ROLES, ROLES_SHOW } from "../../const";
import { useTranslate, useUserState } from "../../hooks";
import { useStore } from "../../hooks/useStore";

const ChangeMode = () => {
  const { t } = useTranslate();
  const { handleAxios } = useAxios();
  const { changeUserStateUser, userStore } = useUserState();
  const { lang } = useStore();

  const otherMode = useMemo(
    () => (userStore.role === ROLES.GUEST ? ROLES.HOST : ROLES.GUEST),
    [userStore.role]
  );

  const changeMode = () =>
    handleAxios(
      mapConfig("/users/mode", "put", {
        postFetch: (res) => {
          changeUserStateUser({ role: res.data });
        },
      })
    );

  const buttonText = lang === 2
    ? `${t("Change to")} ${ROLES_SHOW[otherMode]} ${t("mode")}`
    : `${t("Change to")} ${t("mode")} ${ROLES_SHOW[otherMode]}`;

  return (
    <div className="fixed bottom-[6em] max-w-[700px] w-full flex justify-center cursor-pointer">
      <div
        onClick={changeMode}
        className="bg-blueContrast text-nebula flex justify-center items-center py-3 px-5 rounded-full gap-2 shadow-2xl shadow-buttonDark"
      >
        <FaExchangeAlt className="text-2xl" />
        <span className="text-lg">{buttonText}</span>
      </div>
    </div>
  );
};

export default ChangeMode;