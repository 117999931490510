import React from "react";
import { useHistory, useTranslate } from "../../hooks";
import { Card, FavButtonDetails, StarRating } from "../commons";

const ListingCardFavorites = ({ listing }) => {
  const { t } = useTranslate();
  const history = useHistory();
  if (!listing) return <></>;
  return (
    <Card
      className="max-w-[400px] md:max-w-full"
      classNameInnerCard="m-1"
      classNameImage="w-full h-[15em] md:h-[20em] rounded-tr-xl rounded-tl-xl"
      key={listing.id}
      data={listing?.listing_images}
      interval={false}
      onClick={() => {
        history(`/listing?id=${listing.id}`);
      }}
    >
      <div className="p-4 pb-8 rounded-xl w-full">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-xl text-nebula">{listing.name}</p>
            <p className="text-nebulaOpac opacity-50 text-lg px-1">{`${listing?.listing_location?.city}, ${listing?.listing_location?.country}`}</p>
          </div>
          <FavButtonDetails listing={listing} />
        </div>
        <div className="flex items-center justify-between pt-8">
          <p className="text-2xl font-medium text-nebulaOpac">
            {"$" + listing?.price + " "}
            <span className="text-lg opacity-50">{t("night")}</span>
          </p>
          <StarRating
            routeRating={listing.average_rating}
            disabled={true}
            showRating={true}
            oneStar={true}
            classNameRating="text-[1em] !pl-1"
            classNameStars="text-[1.2em]"
          />
        </div>
      </div>
    </Card>
  );
};

export default ListingCardFavorites;
