export const BOOKING_STATUS = {
  PRE_CANCELLED: "pre_cancelled",
  PRE_BOOKED: "pre_booked",
  EXPIRED: "expired",
  PAID: "paid",
  CANCELLED: "cancelled",
  COMPLETE: "complete",
  ACCEPTED: "accepted",
  REFUSED: "refused",
  PENDING: "pending",
};

export const BOOKING_STATUS_COLOR = {
  accepted: "lighGreen",
  refused: "cancel",
  paid: "lighGreen",
  pending: "yellowBbop",
  expired: "cancel",
  complete: "lighGreen",
  cancelled: "cancel",
  pre_cancelled: "nebula",
  pre_booked: "yellowBbop",
};
