import { useMemo } from "react";
import useDate from "../../hooks/useDate";

const useCalculateSpecialPrice = ({
  start,
  end,
  listing,
  conditional = true,
}) => {
  const {
    handleSeparateDateRangeOnDays,
    searchForEqualDatesRangeInDays,
    handleDateFormat,
  } = useDate();

  const specialPriceDates = useMemo(() => {
    if (listing?.source) {
      const newEvents = [];
      for (let event of listing?.listingSpecialPrices) {
        const eventOnDays = handleSeparateDateRangeOnDays(
          // `${event.date_from} 00:00:00`,
          // `${event.date_to} 00:00:00`,
          `${event.date_from}`,
          `${event.date_to}`,
          listing?.source
        );
        newEvents.push(
          ...eventOnDays.map((item) => ({
            start: item.start_date,
            end: item.end_date,
            price: event.price,
          }))
        );
      }
      return newEvents;
    }
    return listing?.listingSpecialPrices?.map((item) => ({
      // start: `${item.date_from} 00:00:00`,
      // end: `${item.date_to} 23:59:59`,
      start: `${item.date_from}`,
      end: `${item.date_to}`,
      price: item.price,
    }));
  }, [listing]);

  const datesSelectedInDays = useMemo(
    () =>
      conditional
        ? handleSeparateDateRangeOnDays(
            // handleDateFormat(new Date(start), "format", "yyyy-MM-dd HH:mm:ss"),
            // handleDateFormat(new Date(end), "format", "yyyy-MM-dd HH:mm:ss")
            handleDateFormat(new Date(start), "format", "yyyy-MM-dd"),
            handleDateFormat(new Date(end), "format", "yyyy-MM-dd")
          )
        : [],
    [start, end]
  );

  const specialPriceDays = useMemo(
    () =>
      conditional
        ? searchForEqualDatesRangeInDays(specialPriceDates, datesSelectedInDays)
        : [],
    [specialPriceDates, datesSelectedInDays]
  );

  const daysWithEqualSpecialPrices = useMemo(() => {
    if (specialPriceDays?.equals?.length === 0) return;
    return specialPriceDays?.equals.reduce(
      (a, v) => ({ ...a, [v.price]: a[v.price] ? a[v.price] + 1 : 1 }),
      {}
    );
  }, [specialPriceDays]);

  return {
    datesSelectedInDays,
    specialPriceDays,
    specialPriceDates,
    daysWithEqualSpecialPrices,
  };
};

export default useCalculateSpecialPrice;
