import React, { useMemo } from "react";
import Button from "../../../commons/Button";
import { useDisputes } from "../../../hooks/useDisputes";
import useTranslate from "../../../mobile/hooks/useTranslate";
import useUserState from "../../../mobile/hooks/useUserState";
import useHistory from "../../../mobile/hooks/useHistory";
import ManageLoading from "../../../commons/ManageLoading";
import { useAcceptDisputeOffer } from "../../../mobile/hooks/web3Interactions";
import { utils } from "web3";
import { useCheckContractInteraction, useWeb3 } from "../../../mobile/hooks";
import { WrongAddress } from "../../../mobile/components/commons";
import useBooking from "../../../hooks/useBooking";
import useWeb3TxDB from "../../../hooks/web3/useWeb3TxDB";
import { useModalTemplate } from "../../../mobile/components/commons/ModalTemplate";

export const DisputeCardAction = ({
  className,
  users,
  bookingId,
  recall,
  offerAmounts,
  disable,
  showPropose,
  dispute: currentDispute,
  showAcceptSign = true,
  title,
  contractAddress,
  dispute,
  setIsEdit,
  ...props
}) => {
  const { t } = useTranslate();
  const {
    createDispute,
    createOffer,
    // cancelDispute,
    changeOfferStatus,
    loading: loadingDispute,
  } = useDisputes();
  // const history = useHistory();
  // const { userStore } = useUserState();
  const web3txDB = useWeb3TxDB();
  const { sign } = useBooking();
  const { toWei } = useWeb3();

  const finalOfferId = currentDispute?.final_offer_id;
  const findFinalOffer = currentDispute?.offers?.find(
    (offer) => offer.id === finalOfferId
  );

  const { canInteract } = useCheckContractInteraction({
    booking: currentDispute?.booking,
    listing: currentDispute?.listing,
  });

  const { execute } = useAcceptDisputeOffer(
    contractAddress || users?.contract_address,
    currentDispute?.reservation_id
      ? [
          utils.toBN(Number(currentDispute?.reservation_id)),
          findFinalOffer
            ? toWei(Number(findFinalOffer.amount_to_guest))
            : toWei(Number(props.amount_to_guest)),
          findFinalOffer
            ? toWei(Number(findFinalOffer.amount_to_host))
            : toWei(Number(props.amount_to_host)),
        ]
      : 0,
    async (r) => {
      const signed_by = props.isHost ? "host" : "guest";
      const hostSigned = currentDispute?.booking?.signed_by_host;
      const guestSigned = currentDispute?.booking?.signed_by_guest;
      let txType = !hostSigned && !guestSigned ? "dispute" : "payout";

      const changeOfferBody = {
        offers_id: props?.id,
        firstSign: !hostSigned && !guestSigned,
        booking_id: bookingId,
      };
      const saveReceipt = JSON.stringify(
        r,
        (key, value) => (typeof value === "bigint" ? value.toString() : value) // return everything else unchanged
      );
      await sign(currentDispute?.booking_id, signed_by);
      await web3txDB.add(saveReceipt, txType, currentDispute?.booking_id);
      await changeOfferStatus(changeOfferBody);
      recall();
    }
  );

  const handleProposeClick = async () => {
    if (!currentDispute) {
      const body = {
        guest_id: users?.guest_id,
        host_id: users?.host_id,
        booking_id: bookingId,
        dispute_status_id: 1,
        offer: {
          amount_to_host: parseFloat(offerAmounts?.host),
          amount_to_guest: parseFloat(offerAmounts?.guest),
          offer_status_id: 1,
        },
      };
      await createDispute(body, recall);
    } else {
      const body = {
        amount_to_host: parseFloat(offerAmounts?.host),
        amount_to_guest: parseFloat(offerAmounts?.guest),
        offer_status_id: 1,
        dispute_id: currentDispute?.id,
        booking_id: bookingId,
        guest_id: users?.guest_id,
        host_id: users?.host_id,
      };
      await createOffer(body, recall);
    }
    setIsEdit(false);
  };

  const isMyOfferProposeNew = useMemo(
    () => props.yours && showPropose,
    [props.yours, showPropose]
  );

  const isMyOfferSignAccept = useMemo(
    () =>
      props.id &&
      currentDispute.final_offer_id &&
      currentDispute.final_offer_id === props.id
        ? showAcceptSign
        : !props.yours,
    [props.yours, showAcceptSign]
  );

  const { Modal, open, ...modalPropsRest } = useModalTemplate();

  return (
    <>
      <div className="w-full flex justify-center">
        {isMyOfferProposeNew && (
          <Button
            disabled={disable}
            className={`px-8 py-3 rounded-lg mt-5`}
            onClick={() => {
              handleProposeClick();
            }}
          >
            <ManageLoading size={35} loading={loadingDispute}>
              {t("Propose new offer")}
            </ManageLoading>
          </Button>
        )}
        {/* {currentDispute?.created_by === userStore?.id &&
          props.yours &&
          !isMyOfferProposeNew &&
          !isMyOfferSignAccept && (
            <Button
              disabled={disable}
              className={className}
              type="cancel"
              onClick={() => open()}
            >
              {t("Cancel dispute")}
            </Button>
          )} */}

        {isMyOfferSignAccept && (
          <div className="w-full flex flex-col items-center">
            <Button
              onClick={() => {
                if (canInteract?.error) {
                  if (!!canInteract?.onClick) canInteract?.onClick();
                  return;
                }
                execute();
              }}
              className={`px-8 py-3 rounded-lg mt-5`}
            >
              {!canInteract?.error
                ? t("Accept and sign offer")
                : canInteract?.text}
            </Button>
            {canInteract?.type === "address" && (
              <WrongAddress
                {...canInteract?.info}
                className="pt-2 flex flex-col items-center justify-center"
              />
            )}
          </div>
        )}
      </div>
      <Modal
        id="cancel-dispute-modal"
        bodyScroll={false}
        defaultCloseButton
        transitionType="botToTop"
        type="largeBox"
        // title={"Amenities"}
        {...modalPropsRest}
      >
        <div className="flex flex-col w-full h-full p-2 items-center">
          <h3 className="font-bold mb-4 text-nebula text-2xl text-center">
            {t("Are you sure you want to cancel the dispute?")}
          </h3>
          {/* <div className="flex justify-center w-full">
            <Button
              type="cancel"
              onClick={() =>
                cancelDispute({ disputeId: currentDispute?.id }, () =>
                  history(`/booking?id=${currentDispute?.booking_id}`)
                )
              }
              className={className}
            >
              {t("Cancel dispute")}
            </Button>
          </div> */}
        </div>
      </Modal>
    </>
  );
};
