import React, { useRef, useMemo, useState } from "react";
import { AiOutlineCloudSync } from "react-icons/ai";
import { FaFileContract } from "react-icons/fa";
import { useUserState } from "../../hooks";
import { USER_KYC_STATUS } from "../../../const/KYCStatus";
import { LISTING_STATUS } from "../../../const/Listing";
import { useModalTemplate } from "../commons/ModalTemplate";
import { FiPlus } from "react-icons/fi";
import ContractModal from "../../../features/userdashboard/Host/components/Listings/components/Modals/ContractModal";
import Integrations from "../../../features/EditUserProfile/components/Integrations/Integrations";
import Wizard from "../wizard";
import ClickableTooltip from "../commons/ClickableTooltip";
import { Transition } from "react-transition-group";
import gsap from "gsap";
import Logix from "../logix";

const ListingsActions = ({ listings, recall }) => {
  const { userStore } = useUserState();
  const {
    Modal: ModalLogix,
    open: openLogixModal,
    ...logixModalPropsRest
  } = useModalTemplate();
  const {
    Modal: ModalCreate,
    open: openCreateModal,
    ...createModalPropsRest
  } = useModalTemplate();
  const {
    Modal: ModalIntegrations,
    open: opanIntegrations,
    ...modalIntegrationsPropsRest
  } = useModalTemplate();
  const {
    Modal: ModalContracts,
    open: opanContracts,
    ...modalContractsPropsRest
  } = useModalTemplate();

  const itemsRef = useRef();

  const [showTooltip, setShowTooltip] = useState(false);

  const havePreContractListing = useMemo(() => {
    if (!listings) return;
    let show = false;
    for (let list of listings) {
      if (
        list.status === LISTING_STATUS.PRE_CONTRACT &&
        !userStore?.contract_address
      ) {
        show = true;
      }
    }
    return show;
  }, [listings, userStore?.contract_address]);

  const haveContract = useMemo(
    () => userStore?.contract_address,
    [userStore?.contract_address]
  );

  const actions = [
    {
      icon: <FiPlus color="#fff" role="button" />,
      tooltip: [
        {
          name: "Logix",
          callback: () => {
            setShowTooltip(false);
            openLogixModal();
          },
        },
        {
          name: "Wizard",
          callback: () => {
            setShowTooltip(false);
            openCreateModal();
          },
        },
      ],
      onClick: () => {
        setShowTooltip(true);
        // openCreateModal();
      },
    },
    {
      icon: <AiOutlineCloudSync color="#fff" />,
      onClick: () => {
        opanIntegrations();
      },
    },
    {
      icon: <FaFileContract color="#fff" />,
      onClick: () => {
        opanContracts();
      },
      hidden: !haveContract && !havePreContractListing,
    },
  ];

  if (userStore?.kyc_status !== USER_KYC_STATUS.VERIFIED) return <></>;

  return (
    <div ref={itemsRef} className="flex gap-3 w-full justify-end">
      {actions &&
        actions?.map(
          (item, ix) =>
            !item?.hidden && (
              <div
                key={ix}
                className="bg-blueContrast rounded-full p-2 text-3xl cursor-pointer"
                onClick={item.onClick}
              >
                <div onClick={item.onClick}>
                  <div className="opacity-80">{item.icon}</div>
                </div>
                {/* <Transition
                  in={showTooltip}
                  addEndListener={onTransitionTooltip}
                > */}
                {item.tooltip && showTooltip && (
                  <ClickableTooltip
                    options={item.tooltip}
                    close={() => setShowTooltip(false)}
                  />
                )}
                {/* </Transition> */}
              </div>
            )
        )}
      <ModalLogix
        id="logix-modal"
        bodyScroll={false}
        title="Create Listing by Logix"
        defaultCloseButton
        type="smallBox"
        notChildrenWrapper
        style={{ paddingTop: 10 }}
        {...logixModalPropsRest}
      >
        <Logix />
      </ModalLogix>
      <ModalCreate
        id="create-modal"
        bodyScroll={false}
        defaultCloseButton
        type="largeBox"
        notChildrenWrapper
        style={{ paddingTop: 10 }}
        {...createModalPropsRest}
      >
        <Wizard />
      </ModalCreate>
      <ModalIntegrations
        id="integrations-modal"
        bodyScroll={false}
        defaultCloseButton
        transitionType="botToTop"
        type="largeBox"
        title={"Integrations"}
        {...modalIntegrationsPropsRest}
      >
        <Integrations callback={recall} />
      </ModalIntegrations>
      <ModalContracts
        id="contract-modal"
        bodyScroll={false}
        defaultCloseButton
        transitionType="botToTop"
        type="largeBox"
        title={"Contracts"}
        zIndex="995"
        {...modalContractsPropsRest}
      >
        <ContractModal onClose={modalContractsPropsRest.close} />
      </ModalContracts>
    </div>
  );
};

export default ListingsActions;
