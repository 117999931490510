import React from "react";
import SelectNumber from "../SelectNumber";
import { useTranslate } from "../../../hooks";

const textHelper = {
  adult: "13 years and above",
  children: "From 2 to 12 years",
  infant: "Under 2 years",
};

const EditGuests = ({ guests, onChange, max }) => {
  const { t } = useTranslate();

  if (!guests) return <></>;
  return (
    <div className="flex flex-col w-full gap-5 ">
      {guests &&
        Object.keys(guests)?.map((k) => {
          const value = guests[k];
          const maxValue = !max ? "" : max[k];
          return (
            <div key={k} className="w-full flex justify-between items-center">
              <div>
                <p className="capitalize text-nebula text-xl">{t(k)}</p>
                {textHelper[k] && (
                  <p className="capitalize text-nebula opacity-50 text-sm">
                    {t(textHelper[k])}
                  </p>
                )}
              </div>
              <SelectNumber
                disablePlus={!!max && value === max[k]}
                num={value}
                onChange={(n) => {
                  const newGuests = { ...guests, [k]: n };
                  if (!!max && n > max[k]) return;

                  onChange(newGuests);
                }}
                min={"0"}
                max={max ? max : 0}
              />
            </div>
          );
        })}
    </div>
  );
};

export default EditGuests;
