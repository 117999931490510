import { FiThumbsUp } from "react-icons/fi";
import useTranslate from "../../mobile/hooks/useTranslate";
import { BiDownload, BiUpload } from "react-icons/bi";

const KycVerified = ({ appfile }) => {
  const { t } = useTranslate();

  return (
    <div className="flex flex-col w-full items-center justify-center gap-[1em]">
      <div className="flex items-center">
        <FiThumbsUp className="text-lighGreen text-3xl mr-2" />
        <p className="text-lg text-nebula ">{t("approved")}</p>
      </div>
      <a
        className="flex items-center justify-center cursor-pointer no-underline"
        target="_blank"
        href={appfile?.path}
      >
        <BiDownload className="text-lighGreen text-3xl mr-2" />
        <span className="text-lg text-nebula ">{t("download")}</span>
      </a>
    </div>
  );
};

export default KycVerified;
