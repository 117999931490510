import React, { useMemo } from "react";
import { useHistory, useTranslate } from "../../hooks";
import {
  BackButton,
  Divider,
  InfoToModal,
  MessageUser,
  ShowUser,
  Slider,
} from "../commons";
import { compareAsc, compareDesc, format } from "date-fns";
import useDate from "../../../hooks/useDate";
import { IoChevronForward, IoHomeOutline } from "react-icons/io5";
import ShowCancelationPolicy from "../listing/ShowCancelationPolicy";
import BookingContractInteraction from "./BookingContractInteraction";
import { BOOKING_STATUS } from "../../../const/BookingStatus";
import BookingCancelInteraction from "./BookingCancelInteraction";
import BookingComplete from "./BookingComplete";
import { Navigate } from "react-router-dom";
import { BiDonateHeart } from "react-icons/bi";

const SHOW_DATA_USER = {
  HOST: "host",
  GUEST: "guest",
};

const BookingComp = ({ value: booking, recall }) => {
  const { t } = useTranslate();
  const history = useHistory();
  const { showCheckInOut } = useDate();

  const info = useMemo(
    () =>
      !booking
        ? ""
        : [
            {
              label: "Who is coming",
              value: `${booking.guests} ${t("guests")}`,
            },
            {
              label: "Booking code",
              value: booking.booking_code,
            },
          ],
    [booking]
  );

  const paidStatus = useMemo(() => {
    if (!!booking && booking?.status === BOOKING_STATUS.PAID.toLowerCase()) {
      const isFalse = -1;
      const isTrue = 1;
      const isToStay =
        compareAsc(new Date(), new Date(booking.start)) === isFalse &&
        compareDesc(new Date(), new Date(booking.end)) === isTrue;
      const isFinish =
        compareAsc(new Date(), new Date(booking.start)) === isTrue &&
        compareDesc(new Date(), new Date(booking.end)) === isFalse;

      if (isToStay) return "t";
      if (isFinish) return "f";
    }
  }, [booking]);

  if (!booking) return <Navigate to="/bookings" />;

  return (
    <div className="pt-[5em] px-5">
      <BackButton />
      <p className="text-nebula text-3xl pb-5">
        {booking?.status === BOOKING_STATUS.CANCELLED
          ? t("Reservation Cancelled")
          : booking?.show_data_user === SHOW_DATA_USER.GUEST
          ? `${t("Your hosting to")} ${booking.user.first_name}`
          : `${t("Your trip to")} ${booking?.listing?.listing_location?.city}`}
      </p>
      <Slider
        data={booking?.listing?.listing_images || []}
        arrows={true}
        classNameImage="max-w-[900px] w-full md:h-[32em] h-[18em] rounded-xl"
      />
      <div className="flex w-full justify-between items-center text-xl pt-5 pb-5 px-3">
        <div className="flex flex-col text-nebula">
          <p className="font-semibold m-0">{t("Check-in")}</p>
          <p className="opacity-50 m-0 text-lg">
            {format(new Date(booking.start), "EEE, MMM dd")}
          </p>
          <p className="opacity-50 m-0 text-lg">
            {showCheckInOut(booking.listing.check_in)}
          </p>
        </div>
        <div className="w-[2px] bg-blueContrast h-[5em] "></div>
        <div className="flex flex-col text-nebula text-right">
          <p className="font-semibold m-0">{t("Check-out")}</p>
          <p className="opacity-50 m-0 text-lg">
            {format(new Date(booking.end), "EEE, MMM dd")}
          </p>
          <p className="opacity-50 m-0 text-lg">
            {showCheckInOut(booking.listing.check_out)}
          </p>
        </div>
      </div>
      {booking.status === BOOKING_STATUS.PAID &&
        paidStatus === "f" &&
        !booking?.dispute && (
          <BookingContractInteraction
            booking={booking}
            recall={recall}
            paidStatus={paidStatus}
          />
        )}
      {!!booking?.dispute && booking?.status === BOOKING_STATUS.PAID && (
        <p className="text-nebulaOpac text-xl px-1 m-0 w-full text-center pt-2">
          {t("You have an ongoing dispute")}
          <br></br>
          {"  "}
          <strong
            className="underline cursor-pointer"
            onClick={() => history(`/dispute?id=${booking.id}`)}
          >
            {t("See more")}
          </strong>
        </p>
      )}
      {booking.status === BOOKING_STATUS.COMPLETE && (
        <>
          <Divider />
          <BookingComplete booking={booking} recall={recall} />
        </>
      )}
      <Divider />
      <div className="px-3 py-5">
        {info &&
          info?.map((item, ix) => {
            const isLast = ix === info.length - 1;
            return (
              <div
                key={ix}
                className="flex flex-col w-full justify-start gap-2"
              >
                <p className="text-nebula font-semibold text-xl">
                  {t(item.label)}
                </p>
                <p className="text-nebula text-md opacity-60">{item.value}</p>
                {!isLast && <Divider px="0" />}
              </div>
            );
          })}
      </div>
      <Divider />
      <div className="w-full flex justify-center">
        <MessageUser listing={booking.listing} fullWidth />
      </div>
      <Divider />
      <div
        className="flex justify-between items-center text-3xl text-nebula p-3 cursor-pointer"
        onClick={() => history(`/listing?id=${booking?.listing?.id}`)}
      >
        <div className="flex justify-start items-center gap-5">
          <IoHomeOutline />
          <p className="text-2xl">{t("Your place")}</p>
        </div>
        <IoChevronForward />
      </div>
      <Divider />
      <div
        className="flex justify-between items-center text-3xl text-nebula p-3 cursor-pointer"
        onClick={() => history(`/donate`)}
      >
        <div className="flex justify-start items-center gap-5">
          <BiDonateHeart />
          <p className="text-2xl">{t("Donate")}</p>
        </div>
        <IoChevronForward />
      </div>
      <Divider />
      <div className="py-5">
        <ShowUser
          user={
            booking?.show_data_user === SHOW_DATA_USER.HOST
              ? booking.listing?.user
              : booking.user
          }
          contactHost={true}
        />
      </div>
      <Divider />
      <ShowCancelationPolicy
        policy={booking.listing?.listing_cancellation_policies}
      />
      {booking.listing?.rules_and_conditions && (
        <>
          <Divider />
          <InfoToModal
            info={booking.listing?.rules_and_conditions}
            title={"House Rules"}
            className="opacity-50"
            showLength={250}
            maxLength={255}
          />
        </>
      )}
      {booking.status === BOOKING_STATUS.PAID &&
        paidStatus === "t" &&
        booking?.show_data_user === SHOW_DATA_USER.HOST && (
          <>
            <Divider />
            <BookingCancelInteraction booking={booking} recall={recall} />
          </>
        )}
    </div>
  );
};

export default BookingComp;
