import React, { useMemo, useState } from "react";
import PlacesAutocompleteNew from "../../../commons/PlacesAutocompleteNew";
import { useTranslate } from "../../../mobile/hooks";
import { useDebounceAuto } from "../../../hooks/useDebounce";
import { FiEdit } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";

const AutoCompleteEdit = ({ options, value, placeholder, onSelect, label }) => {
  const { t } = useTranslate();
  const [search, setSearch] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const searchDebounce = useDebounceAuto(search, 800);

  const findLabelValue = useMemo(() => {
    const findLabel = options.find((v) => v.id === value)?.label;
    if (findLabel) {
      setSearch(findLabel);
      return findLabel;
    }
    return value;
  }, [value, options]);

  return (
    <div className="flex flex-col items-start gap-2 justify-center border-2 border-blueDark bg-blueMid p-4 rounded-lg relative">
      <p className="text-nebula font-bold m-0">{label}</p>
      {!isEdit && (
        <div className="w-full flex gap-2 text-nebula items-start py-2 justify-start">
          <p className="m-0">{findLabelValue}</p>
        </div>
      )}
      {isEdit && (
        <PlacesAutocompleteNew
          onChange={(v, isSelect) => {
            setSearch(v);
          }}
          onSelect={(v) => {
            onSelect(v.id);
            setIsEdit(false);
          }}
          value={value || "Select Country"}
          options={options.filter((v) =>
            v.label.toLowerCase().includes(searchDebounce.toLowerCase())
          )}
          search={search}
          placeholder={t(placeholder)}
        />
      )}
      <div
        className="text-nebula text-xl absolute top-[0.5em] right-[0.5em]"
        onClick={() => setIsEdit((prev) => !prev)}
      >
        {isEdit && <IoMdClose />}
        {!isEdit && <FiEdit />}
      </div>
    </div>
  );
};

export default AutoCompleteEdit;
