import React from "react";
import { useSelector } from "react-redux";
import ModalComp from "./features/Home/Navbar/ModalComp";
import LoginModal from "./features/Home/LoginModal";
import useGlobalComp from "./hooks/useGlobalComp";
import useUserState from "./mobile/hooks/useUserState";
import SignUpModal from "./features/Home/SignUpModal";
import LoadingContractInteraction from "./globalComponents/LoadingContractInteraction";
import { ModalSignWallet } from "./features/Home/Wallet/ModalSignWallet";
import ContractModal from "./features/userdashboard/Host/components/Listings/components/Modals/ContractModal";
import { useGetWallets, useSetTranslate } from "./mobile/hooks";

const Options = () => {
  const modalLogin = useSelector((state) => state.modalLogin);
  const modalSignUp = useSelector((state) => state.modalSignUp);
  const modalSignWallet = useSelector((state) => state.modalSignWallet);
  const modalContract = useSelector((state) => state.modalContract);
  const { loginModal, signUpModal, signWalletModal, handleContractModal } =
    useGlobalComp();
  const { userState } = useUserState();

  useSetTranslate();
  useGetWallets();

  return (
    <>
      {modalLogin?.open && (
        <ModalComp
          open={modalLogin?.open}
          onClose={() => loginModal({ ...modalLogin, open: false })}
          paragraph={"Log In"}
          text={"START BBOP'N!"}
        >
          <LoginModal
            userState={userState}
            {...modalLogin}
            setOpenSignUp={signUpModal}
            setOpenLogin={(v) => loginModal({ ...modalLogin, open: v })}
            onClose={() => loginModal({ ...modalLogin, open: false })}
          />
        </ModalComp>
      )}
      {modalSignWallet?.open && (
        <ModalComp
          open={modalSignWallet?.open}
          onClose={() => signWalletModal({ ...modalSignWallet, open: false })}
          text={"START BBOP'N!"}
        >
          <ModalSignWallet
            onClose={() => signWalletModal({ ...modalSignWallet, open: false })}
            {...modalSignWallet}
          />
        </ModalComp>
      )}
      {modalSignUp && (
        <ModalComp
          open={modalSignUp}
          onClose={() => signUpModal(false)}
          paragraph="Sign Up"
          text="START BBOP'N!"
        >
          <SignUpModal setOpenSignUp={(v) => signUpModal(v)} />
        </ModalComp>
      )}
      <LoadingContractInteraction />
      {modalContract && (
        <ModalComp
          open={modalContract}
          onClose={() => handleContractModal(false)}
          paragraph="Contracts"
        >
          <ContractModal onClose={() => handleContractModal(false)} />
        </ModalComp>
      )}
    </>
  );
};

export default Options;