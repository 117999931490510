import React, { useState } from "react";
import DynamicIcon from "./DynamicIcon";
import GenerateSvg from "./GenerateSvg";
import { FaChevronDown } from "react-icons/fa";

export default function Select({
  icon,
  reactIcon,
  multiple = true,
  label,
  onChange = () => {},
  options,
  className,
  classNameRoot = "",
  classNameOptions,
  classNameIcon = "w-[40px] h-[40px] mr-2",
  classNameLabel = "text-greenDark",
  placeHolder,
  disabled = false,
  name = "select",
  id = "select",
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {options && (
        <>
          {label && <p className={classNameLabel}>{label}</p>}
          <div
            className={`flex w-full styled-select py-2 px-5 ${classNameRoot}`}
          >
            {icon && <GenerateSvg svg={icon} className={classNameIcon} />}
            {reactIcon && (
              <div className="mr-2">
                <DynamicIcon name={reactIcon} />
              </div>
            )}
            <select
              onChange={(e) => onChange(e.target.value)}
              multiple={multiple}
              id={id}
              name={name}
              className={`${className} ${
                disabled && "text-softgray"
              } appearance-none`}
              disabled={disabled}
              onFocus={() => setIsOpen(true)}
              onBlur={() => setIsOpen(false)}
            >
              {placeHolder && <option value="">{placeHolder}</option>}
              {options.map((item, idx) => (
                <option
                  key={idx}
                  className={` ${classNameOptions}`}
                  value={item.value}
                >
                  {item.label}
                </option>
              ))}
            </select>
            <FaChevronDown
              className={`text-lg transition-all ${isOpen ? "rotate-180" : ""}`}
            />
          </div>
        </>
      )}
    </>
  );
}
