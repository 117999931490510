import React from "react";
import useUserState from "./useUserState";
import { ROLES } from "../const";
import useHistory from "./useHistory";

const CHECK_ROLE = {
  guest: ROLES.GUEST,
  host: ROLES.HOST,
};

const useCheckRole = (check, to) => {
  const { userStore } = useUserState();
  const history = useHistory();
  if (userStore.role !== CHECK_ROLE[check]) {
    history(to);
  }
  return {};
};

export default useCheckRole;
