import React from "react";
import gravatar from "../../utils/gravatarBuilder";
import { useTranslate } from "../../mobile/hooks";

const NotificationPreMade = ({
  info,
  goTo,
  title,
  img,
  content,
  type,
  icon,
}) => {
  const { t } = useTranslate();
  if (type === "simple")
    return (
      <div className="flex h-full items-center justify-start pb-5">
        {icon ? icon : <></>}
        <div className="flex flex-col h-full items-start justify-center pr-5">
          <span className="text-nebula font-semibold text-lg">{t(title)}</span>
          <span className="text-nebula text-md leading-[1em]">{content}</span>
        </div>
      </div>
    );

  if (type === "user")
    return (
      <div className="flex justify-start items-center h-[6em]">
        <div
          className="rounded-full w-[4em] h-[4em] bg-cover bg-center cursor-pointer "
          style={{
            backgroundImage: `url(${img || gravatar(info?.send_by_name)})`,
          }}
          onClick={() => goTo(`/profile/${info?.send_by_id}`)}
        ></div>
        <div className="pl-2">
          <p className="text-nebula font-semibold text-lg m-0">{t(title)}</p>
          {content && (
            <p className="text-nebula text-sm m-0 leading-[1em]">
              {t(content)}
            </p>
          )}
        </div>
      </div>
    );

  return <></>;
};

export default NotificationPreMade;
