import React, { useState, useEffect } from "react";
import { useQuery, useTranslate } from "../../hooks";
import ListingCardExplore from "./../explore/ListingCardExplore";
import { SearchListing } from "../commons";
import { useHistory } from "../../hooks";
import useAxiosPaginated from "../../../hooks/useAxiosPaginated";
import { RADIUS_BY_ZOOM } from "../../../const/Gmap";
import { IoArrowUp, IoList } from "react-icons/io5";
import MapSearchListing from "../../../features/search-listings/MapSearchListing";
import { useModalTemplate } from "../commons/ModalTemplate";
import { useGeolocated } from "react-geolocated";
import ManageLoading from "../../../commons/ManageLoading";
import PreviousNext from "./PreviousNext";

const initialFilter = {
  rooms: [
    {
      room_type_id: 1,
      amount: 0,
    },
    {
      room_type_id: 2,
      amount: 0,
    },
    {
      room_type_id: 3,
      amount: 0,
    },
    {
      room_type_id: 4,
      amount: 0,
    },
    {
      room_type_id: 5,
      amount: 0,
    },
  ],
  amenities: [],
  categories: [],
  price: {
    max: 9999,
    min: 0,
  },
};

const ISOToDate = (ISOdate) => {
  const date = new Date(ISOdate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};

const SearchComp = () => {
  const history = useHistory();
  const { query, changeQuery } = useQuery([
    "dates",
    "guests",
    "location",
    "filter",
  ]);
  const { queryToPath } = useQuery(["dates", "guests"]);
  const [filter, setFilter] = useState(initialFilter);
  const { t } = useTranslate();
  const [modalRef, setModalRef] = useState();
  const [modalDeskRef, setModalDeskRef] = useState();
  const setInitialFilter = () => setFilter(initialFilter);

  const setRooms = (index) => (value) => {
    setFilter((prev) => {
      const arr = [...prev.rooms];
      arr[index] = { ...prev.rooms[index], amount: value };
      return { ...prev, rooms: arr };
    });
  };

  const setMultipleChoice = (key) => (id) => {
    setFilter((prev) => ({
      ...prev,
      [key]: prev[key].includes(id)
        ? prev[key].filter((item) => item !== id)
        : [...prev[key], id],
    }));
  };

  const {
    call,
    value,
    isLoaded,
    loading,
    next,
    previous,
    hasMore,
    pageIndex,
    loadingMore,
  } = useAxiosPaginated();
  const { call: callPrices, cleanResponse } = useAxiosPaginated();

  useEffect(() => {
    if (!query?.location) return;
    call({
      path: "/searchModule/searchListing",
      method: "post",
      page_size: 10,
      toast: false,
      dontAddNextValue: true,
      body: {
        end: ISOToDate(query?.dates?.endDate),
        start: ISOToDate(query?.dates?.startDate),
        type: "radius",
        radius: query?.location?.radius,
        filter: {
          ...query?.filter,
          rooms: query?.filter?.rooms.filter(({ amount }) => amount > 0),
        },
        guests: [],
        coordinates: query?.location,
      },
    });
  }, [JSON.stringify(query)]);

  useEffect(() => {
    if (!query?.location) return;
    callPrices({
      path: "/searchModule/searchListing?only_prices=1&only_count=1",
      method: "post",
      page_size: 10,
      toast: false,
      body: {
        end: ISOToDate(query?.dates?.endDate),
        start: ISOToDate(query?.dates?.startDate),
        type: "radius",
        radius: query?.location?.radius,
        filter: {
          ...filter,
          rooms: filter?.rooms.filter(({ amount }) => amount > 0),
        },
        guests: [],
        coordinates: query?.location,
      },
    });
  }, [JSON.stringify({ filter })]);

  const mapSearchListingProps = {
    coordinates: query?.location,
    setRootCoordinates: (v) => {
      changeQuery({
        location: {
          ...v,
          radius: query?.location?.radius,
          zoom: query?.location?.zoom,
        },
      });
    },
    setRootRadius: (r, z) => {
      changeQuery({
        location: {
          lat: query?.location?.lat,
          lng: query?.location?.lng,
          radius: r,
          zoom: z,
        },
      });
    },
    isSearchMap: true,
    listings: value,
  };

  const { Modal, open, ...modalPropsRest } = useModalTemplate();

  const { coords, isGeolocationEnabled, getPosition } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  useEffect(() => {
    if (!query?.location && !isGeolocationEnabled) {
      getPosition();
    }
  }, []);

  useEffect(() => {
    if (coords && !query?.location) {
      const today = new Date();
      const startDate = ISOToDate(today.setDate(today.getDate() + 1));
      const endDate = ISOToDate(today.setDate(today.getDate() + 2));
      changeQuery({
        guests: {
          adult: 1,
          children: 0,
          infant: 0,
          pets: 0,
        },
        location: {
          lat: coords?.latitude,
          lng: coords?.longitude,
          zoom: 13,
          radius: RADIUS_BY_ZOOM[13],
        },
        dates: {
          startDate,
          endDate,
        },
        filter: initialFilter,
      });
    }
  }, [coords, isGeolocationEnabled, JSON.stringify(query)]);

  const loadMoreAndScroll = async (ref, loadMore) => {
    await loadMore();
    if (ref) ref.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [loadingDeskMore, setLoadingDeskMore] = useState("");

  return (
    <div className="text-nebula h-full w-full">
      {!query?.location &&
        !query?.dates &&
        !query?.guets &&
        !value &&
        isLoaded && (
          <div className="p-5 w-full flex justify-center pt-[8em]">
            <div className="bg-blueContrast rounded-lg p-8 flex flex-col items-center w-full gap-5">
              <IoArrowUp className="text-5xl" />
              <p className="text-lg text-center">
                {t("Select Place, Time and Guests to continue searching")}
              </p>
            </div>
          </div>
        )}
      <div className="absolute md:hidden bottom-[0.2em] right-[0.2em] z-50">
        <PreviousNext
          pageIndex={pageIndex}
          hasMore={hasMore}
          next={async () => {
            next();
          }}
          previous={async () => {
            previous();
          }}
        />
      </div>
      <div className="absolute hidden md:flex bottom-[1em] right-0 px-2 z-50 w-1/2">
        <PreviousNext
          desktop
          loading={loadingDeskMore}
          pageIndex={pageIndex}
          hasMore={hasMore}
          next={async () => {
            setLoadingDeskMore("next");
            await loadMoreAndScroll(modalDeskRef, next);
            setLoadingDeskMore("");
          }}
          previous={async () => {
            setLoadingDeskMore("prev");
            await loadMoreAndScroll(modalDeskRef, previous);
            setLoadingDeskMore("");
          }}
        />
      </div>
      {!value && loading && (
        <div className="absolute md:hidden z-50 text-3xl bottom-[0.2em] left-[0.2em] bg-blueContrast text-nebula p-3 rounded-full">
          <ManageLoading
            loading={loading}
            size={30}
            classNameLoader=""
          ></ManageLoading>
        </div>
      )}
      {value?.length > 0 && (
        <div
          className="absolute md:hidden z-50 text-3xl bottom-[0.2em] left-[0.2em] bg-blueContrast text-nebula p-3 rounded-full"
          onClick={() => {
            if (!loading) open();
          }}
        >
          <ManageLoading loading={loading} size={30} classNameLoader="">
            <IoList />
          </ManageLoading>
        </div>
      )}
      <div
        className={`h-full w-full flex justify-center flex-col items-center`}
      >
        <div
          className={`w-full bg-blueDark flex justify-center absolute top-0 z-[45] h-[6em]`}
        >
          <div className={`max-w-[700px] w-full `}>
            <SearchListing
              fixed
              backButton="/"
              isSearch
              filters={{
                filter,
                cleanResponse,
                setFilter,
                setInitialFilter,
                setMultipleChoice,
                setRooms,
              }}
            />
          </div>
        </div>
        {!!query?.location && !!query?.dates && !!query?.guests && (
          <div
            className={`text-nebula flex flex-col items-center px-5 gap-5 relative w-full h-screen overflow-hidden max-w-[1700px]`}
          >
            <div className="w-full h-screen absolute top-0 pt-[6em] z-40 left-0 md:w-1/2 max-w-[1000px]">
              <MapSearchListing {...mapSearchListingProps} />
            </div>
            <div
              ref={(node) => {
                if (node !== null) {
                  setModalDeskRef(node);
                }
              }}
              className="hidden md:flex h-screen pb-10 scrollbarNone overflow-y-scroll md:absolute top-0 pt-[6em] z-40 right-0 w-1/2 flex-col items-center justify-start max-w-[1000px]"
            >
              {value?.map((listing) => (
                <ListingCardExplore
                  key={listing.id}
                  listing={listing}
                  onClick={() => {
                    history(`/listing${queryToPath}&id=${listing.id}`);
                  }}
                />
              ))}
            </div>
          </div>
        )}
        <Modal
          id="amenities-modal"
          bodyScroll={false}
          defaultCloseButton
          transitionType="botToTop"
          type="draggerBox"
          modalHeight="90%"
          {...modalPropsRest}
        >
          <div
            ref={(node) => {
              if (node !== null) {
                setModalRef(node);
              }
            }}
            className="w-full flex flex-col items-center justify-start h-full overflow-y-scroll pb-[5em]"
          >
            {value?.map((listing) => (
              <ListingCardExplore
                key={listing.id}
                listing={listing}
                onClick={() => {
                  history(`/listing${queryToPath}&id=${listing.id}`);
                }}
              />
            ))}
            <div className="w-full flex justify-center pt-5">
              <PreviousNext
                loading={loadingMore}
                text
                pageIndex={pageIndex}
                hasMore={hasMore}
                next={async () => {
                  await loadMoreAndScroll(modalRef, next);
                }}
                previous={async () => {
                  await loadMoreAndScroll(modalRef, previous);
                }}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default SearchComp;
