import React, { useMemo } from "react";
import {
  FaFire,
  FaFireExtinguisher,
  FaLaptop,
  FaSwimmingPool,
  FaWifi,
} from "react-icons/fa";
import AirIcon from "../../../SVGComponents/AirIcon";
import TvIcon from "../../../SVGComponents/TvIcon";
import KitchenIcon from "../../../SVGComponents/KitchenIcon";
import JacuzziIcon from "../../../SVGComponents/JacuzziIcon";
import OutdoorTableIcon from "../../../SVGComponents/OutdoorTableIcon";
import {
  GiFireplace,
  GiForkKnifeSpoon,
  GiHomeGarage,
  GiWashingMachine,
} from "react-icons/gi";
import { MdOutdoorGrill, MdOutlineErrorOutline } from "react-icons/md";
import { CgGym } from "react-icons/cg";
import { ImAidKit } from "react-icons/im";
import { useTranslate } from "../../hooks";
import { Divider } from "../commons";
import { useModalTemplate } from "../commons/ModalTemplate";

const size = "w-10 h-10 text-nebulaOpac";

const color = "rgb(255, 255, 255, .85)";

const ShowAmenities = ({ amenities }) => {
  const { t } = useTranslate();
  const { Modal, open, ...modalPropsRest } = useModalTemplate();

  const lessAcom = useMemo(
    () => (!amenities ? "" : amenities.slice(0, 4)),
    [amenities]
  );

  if (!amenities) return <></>;
  return (
    <div className="flex flex-col w-full px-5 gap-2">
      <span className="text-nebula text-3xl font-medium pb-5 text-left">
        {t("You will have")}
      </span>
      <div className="flex flex-col w-full items-center gap-2">
        {lessAcom &&
          lessAcom?.map((am, ix) => (
            <div
              className="flex justify-start items-center w-full gap-10"
              key={ix}
            >
              {am.attribute.name !== "other" && (
                <>
                  {renderIcon(am.attribute.name)}
                  <span className="text-nebulaOpac text-xl text-center">
                    {am.attribute.name}
                  </span>
                </>
              )}
              {am.attribute.name === "other" && (
                <>
                  <p className="text-nebulaOpac text-xl text-center">
                    {am.value.split("-/-")[0]}
                  </p>
                  <p className="text-nebulaOpac text-xl text-center">
                    {am.value.split("-/-")[1]}
                  </p>
                </>
              )}
            </div>
          ))}
      </div>
      <span
        onClick={open}
        className="underline underline-offset-2 text-nebulaOpac pt-5 w-full text-right"
      >
        {t("See All Amenities")}
      </span>
      <Modal
        id="amenities-modal"
        bodyScroll={false}
        defaultCloseButton
        transitionType="botToTop"
        type="largeBox"
        title={t("Amenities")}
        {...modalPropsRest}
      >
        <div className="flex flex-col w-full items-center">
          {amenities &&
            amenities?.map((am, ix) => (
              <div key={ix} className="w-full">
                <div className="flex justify-start items-center w-full gap-10">
                  {am.attribute.name !== "other" && (
                    <>
                      {renderIcon(am.attribute.name)}
                      <span className="text-nebulaOpac text-xl text-center">
                        {am.attribute.name}
                      </span>
                    </>
                  )}
                  {am.attribute.name === "other" && (
                    <>
                      <p className="text-nebulaOpac text-xl text-center">
                        {am.value.split("-/-")[0]}
                      </p>
                      <p className="text-nebulaOpac text-xl text-center">
                        {am.value.split("-/-")[1]}
                      </p>
                    </>
                  )}
                </div>
                <Divider px="0" color="nebula" opacity />
              </div>
            ))}
        </div>
      </Modal>
    </div>
  );
};

export default ShowAmenities;

export const renderIcon = (name, innerColor, innerSize) => {
  if (name === "WIFI") {
    return <FaWifi className={innerSize || size} color={innerColor || color} />;
  } else if (name === "AC") {
    return (
      <AirIcon className={innerSize || size} color={innerColor || color} />
    );
  } else if (name === "TV") {
    return <TvIcon className={innerSize || size} color={innerColor || color} />;
  } else if (name === "LAUNDRY") {
    return (
      <GiWashingMachine
        className={innerSize || size}
        color={innerColor || color}
      />
    );
  } else if (name === "KITCHEN") {
    return (
      <KitchenIcon className={innerSize || size} color={innerColor || color} />
    );
  } else if (name === "PARKING") {
    return (
      <GiHomeGarage className={innerSize || size} color={innerColor || color} />
    );
  } else if (name === "CUTLERY") {
    return (
      <GiForkKnifeSpoon
        className={innerSize || size}
        color={innerColor || color}
      />
    );
  } else if (name === "POOL") {
    return (
      <FaSwimmingPool
        className={innerSize || size}
        color={innerColor || color}
      />
    );
  } else if (name === "JACUZZI") {
    return (
      <JacuzziIcon className={innerSize || size} color={innerColor || color} />
    );
  } else if (name === "PATIO") {
    return (
      <OutdoorTableIcon
        className={innerSize || size}
        color={innerColor || color}
      />
    );
  } else if (name === "GRILL") {
    return (
      <MdOutdoorGrill
        className={innerSize || size}
        color={innerColor || color}
      />
    );
  } else if (name === "FIREPLACE") {
    return (
      <GiFireplace className={innerSize || size} color={innerColor || color} />
    );
  } else if (name === "GYM") {
    return <CgGym className={innerSize || size} color={innerColor || color} />;
  } else if (name === "OFFICE") {
    return (
      <FaLaptop className={innerSize || size} color={innerColor || color} />
    );
  } else if (name === "EXTINGUISHER") {
    return (
      <FaFireExtinguisher
        className={innerSize || size}
        color={innerColor || color}
      />
    );
  } else if (name === "FIRE_ALARM") {
    return <FaFire className={innerSize || size} color={innerColor || color} />;
  } else if (name === "FIRST_AID") {
    return (
      <ImAidKit className={innerSize || size} color={innerColor || color} />
    );
  } else {
    return (
      <MdOutlineErrorOutline className={size} color={innerColor || color} />
    );
  }
};
