import React, { useMemo } from "react";
import { format } from "date-fns";
import {
  useHistoryQuery,
  useQuery,
  useTranslate,
  useUserState,
} from "../../hooks";
import useDate from "../../../hooks/useDate";

const ReserveButton = ({
  price,
  dates,
  disableDates,
  listing,
  onClickNoDates,
}) => {
  const { t } = useTranslate();
  const { query } = useQuery(["dates", "id", "guests"]);
  const history = useHistoryQuery();
  const { userStore, isLogged } = useUserState();
  const { handleOverlapDates, formatChecksInOut } = useDate();

  const isSelectedDatesAvailable = useMemo(() => {
    if (!dates) return;
    let checkDates = true;
    const rangesStartDate = format(dates?.start, "yyyy-MM-dd HH:mm");
    const rangesEndDate = format(dates?.end, "yyyy-MM-dd HH:mm");
    disableDates.forEach((item) => {
      if (
        handleOverlapDates(
          {
            selected: { start: rangesStartDate, end: rangesEndDate },
            events: { start: item.start, end: item.end },
          },
          "overlap"
        )
      ) {
        checkDates = false;
      }
    });
    return checkDates;
  }, [dates, disableDates]);

  const isYourListing = useMemo(
    () => listing?.user_id === userStore?.id,
    [listing?.user_id, userStore?.id]
  );

  const invalidGuests = useMemo(() => {
    if (!query.guests) return;
    let res = 0;
    for (let k of Object.keys(query.guests)) {
      const value = query.guests[k];
      res = res + value;
    }
    return res === 0;
  }, [query.guests]);

  const validateReserve = useMemo(() => {
    let res = {
      text: t("Reserve"),
    };
    if (!isLogged)
      return {
        text: t("Log in"),
        execute: () => history("/"),
        error: true,
      };
    if (isYourListing) return { text: t("Your Listing"), error: true };
    if (!isSelectedDatesAvailable)
      return {
        text: t("Change Dates"),
        error: true,
        execute: () => {
          if (onClickNoDates) onClickNoDates();
        },
      };
    if (invalidGuests) return { text: t("Select Guests"), error: true };
    return res;
  }, [isLogged, isYourListing, isSelectedDatesAvailable, invalidGuests]);

  return (
    <div className="fixed max-w-[700px] rounded-tr-2xl rounded-tl-2xl bottom-[5em] w-full flex justify-between items-center px-5 py-6 bg-blueContrast shadow-2xl shadow-blueContrast">
      <div className="text-nebulaOpac flex flex-col items-start justify-center">
        {!isSelectedDatesAvailable && !isYourListing && isLogged && (
          <span>{t("Select new dates")}</span>
        )}
        {isSelectedDatesAvailable && (
          <>
            <span>
              {"Total: "}
              <strong className="text-xl">{price + "$"}</strong>
            </span>
            <span>
              {format(dates?.start, "MMM dd")}
              {" - "}
              {format(dates?.end, "MMM dd")}
            </span>
          </>
        )}
      </div>

      <div
        className={`rounded-lg text-buttonDark text-xl bg-nebula py-3 px-8 cursor-pointer ${
          validateReserve?.error ? "opacity-50" : ""
        }`}
        onClick={() => {
          if (validateReserve?.error) {
            if (validateReserve?.execute) validateReserve.execute();
            return;
          }
          let pushQuery = query;
          const startDay = format(
            new Date(query.dates.startDate),
            "yyyy-MM-dd"
          );
          const endDay = format(new Date(query.dates.endDate), "yyyy-MM-dd");
          if (startDay === endDay) {
            const checkTimes = {
              check_in: listing?.check_in,
              check_out: listing?.check_out,
            };
            const datesFormated = formatChecksInOut(
              checkTimes,
              new Date(query.dates.startDate),
              new Date(query.dates.endDate)
            );
            pushQuery.dates = datesFormated;
          }
          if (!pushQuery.guests) {
            pushQuery.guests = {
              adult: 0,
              children: 0,
              infant: 0,
              pets: 0,
            };
          }
          history(`/reserve`, pushQuery);
        }}
      >
        {validateReserve?.text}
      </div>
    </div>
  );
};

export default ReserveButton;
