import React, { useState } from "react";
import useAxios, { mapConfig } from "./useAxios";

const useListing = () => {
  const [loading, setLoading] = useState(false);
  const { handleAxios } = useAxios();
  const save = async (list, callback) => {
    setLoading(true);
    let body = {
      id: list?.id,
      name: list?.name,
      description: list?.description,
      category: list?.listing_categories,
      amenities: list?.listing_amenities,
      images: list?.listingImages,
      price: list?.price,
      rules_and_conditions: list?.rules_and_conditions,
      security_deposit: list?.security_deposit_percentage,
      check_in: list?.check_in,
      check_out: list?.check_out,
      cancellation_policy: list?.listing_cancellation_policies[0],
      rooms: list?.listing_rooms,
      guests: list?.listing_guests,
      location: list?.listing_location,
      rejections: list?.changeRejections,
    };
    await handleAxios(
      mapConfig(`/listing/update`, "put", {
        body,
        postFetch: async (res) => {
          if (callback) await callback(res);
          setLoading(false);
        },
      })
    );
  };

  return { save, loading };
};

export default useListing;
