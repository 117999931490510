import React, { forwardRef } from "react";
import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { useTranslate } from "../../../../hooks";

export const SmallBox = forwardRef(
  (
    {
      defaultCloseButton,
      close,
      headerTitle,
      title,
      colors,
      style,
      notChildrenWrapper,
      children,
    },
    ref
  ) => {
    const { t } = useTranslate();

    return (
      <SmallBoxStyled
        className={`bg-${colors.bg} text-${colors.text}`}
        ref={ref}
        style={style}
      >
        <div
          className="header"
          style={{
            borderBottom: headerTitle
              ? "1px solid rgba(221, 221, 221, .2)"
              : "",
          }}
        >
          {defaultCloseButton ? (
            <IoMdClose
              className="default-button text-3xl"
              role="button"
              fontSize="1.2em"
              onClick={close}
            />
          ) : (
            <IoMdClose
              className="default-button --left"
              role="button"
              fontSize="1.2em"
              onClick={close}
            />
          )}

          {headerTitle && <div className="header-title">{headerTitle}</div>}
          {title && (
            <div className="text-3xl font-medium w-full flex flex-col justify-center items-center">
              <p>{t(title)}</p>
            </div>
          )}
        </div>
        {notChildrenWrapper ? (
          children
        ) : (
          <div className="body px-5 pt-5 pb-[5em] h-full">{children}</div>
        )}
      </SmallBoxStyled>
    );
  }
);

const SmallBoxStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  max-width: 780px;
  border-radius: 15px;
  transform: translate(-50%, -50%) scale(0.7);
  opacity: 0;
  overflow: hidden;
  @media (max-width: 650px) {
    width: 90%;
  }

  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    .default-button {
      position: absolute;
      right: 15px;
      &.--left {
        left: 15px;
        right: unset;
      }
    }
    .header-title {
      font-weight: 700;
    }
  }
  .body {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export default SmallBox;
