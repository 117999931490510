import Axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import useTranslate, { useSetTranslate } from "../../mobile/hooks/useTranslate";
import LogoNav from "../Home/Navbar/Logo";
import { setUserAction } from "../../app/actions";
import useToast from "./../../hooks/useToast";
import useApiUrl from "../../hooks/useApiUrl";
import useHistory from "../../mobile/hooks/useHistory";
import { useStore } from "../../mobile/hooks/useStore";

export default function LoginForm(props) {
  const { setUser } = useStore();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const distpach = useDispatch();
  const { t } = useTranslate();
  const locationRoute = useLocation();

  const { notify } = useToast([
    {
      position: "top-center",
      type: "success",
      key: "s",
    },
    {
      position: "top-center",
      type: "error",
      key: "e",
    },
  ]);

  const handleForgotPass = () => {
    history("/secure/user/recovery");
  };

  function handleRedirect() {
    let redirect = locationRoute?.state?.from
      ? locationRoute?.state?.from
      : "/explore";
    if (
      redirect === "/signup" ||
      redirect === "/donate" ||
      redirect === "/support"
    ) {
      redirect = "/explore";
    }
    history(redirect);
  }

  const initialValues = {
    email: "",
    password: "",
  };

  //Messages
  const validation = Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      //validation for password input to login
      .matches(/^\S{3,25}$/),
  });
  const url = useApiUrl();

  const { setTranslate } = useSetTranslate();

  const submit = async ({ email, password }) => {
    setLoading(true);
    const data = { email, password };
    try {
      const res = await Axios.post(`${url}/secure/auth/user`, data);
      setUser(res?.data?.user);
      distpach(setUserAction(res?.data));
      notify.s("Log in success");
      setTranslate(res?.data?.user?.id);
      setLoading(false);
      handleRedirect();
    } catch (error) {
      notify.e("Log in failed");
      setLoading(false);
    }
    // setLoading(false)
  };

  return (
    <div className="flex-col items-center justify-center w-full h-full">
      <div className="absolute top-0 flex justify-center w-full">
        <LogoNav h={"h-[5em]"} />
      </div>
      <div className="w-full flex h-screen justify-center align-center">
        {/* <div className="justify-end hidden w-full md:w-1/2 h-full lg:flex signUp">
          <div
            style={{
              backgroundImage:
                'url("https://bbop-app-prod.s3.amazonaws.com/images/login-image.png")',
            }}
            className="w-full h-full bg-cover "
          ></div>
        </div> */}

        <div className="justify-center w-full max-w-[35em] h-full flex flex-col px-2">
          <div className="w-full flex flex-col justify-center gap-5 pb-5">
            {/* <LogoNav show={"flex md:hidden p-3"} h="h-[8em]" /> */}
            <span className="text-lighGreen text-center text-5xl font-semibold">
              {t("Vacation Rental Reinvented")}
            </span>
            <span className="text-nebula opacity-[0.4] text-center text-lg">
              {t(
                "Zero-fee, cryptocurrency-driven platform that redefines the way you travel and share your home."
              )}
            </span>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={submit}
          >
            {({ isSubmitting, errors }) => (
              <Form>
                <div className="flex flex-col items-center justify-center w-full h-full space-y-4">
                  <div className="flex flex-col items-center w-full">
                    <div className="flex flex-col items-center w-full h-30 ">
                      <Field
                        className={`w-full h-12 mb-4 pl-4 placeholder-buttonDark rounded-lg drop-shadow-lg focus:outline-none focus:bg-white focus:shadow-lg ${
                          errors.email
                            ? "placeholder-[#ff0000] placeholder:opacity-80 text-[#ff0000] shadow-sm shadow-[#ff0000] focus:shadow-sm focus:shadow-[#ff0000]"
                            : "border-nebula drop-shadow-lg text-buttonDark shadow-sm shadow-nebula focus:shadow-sm focus:shadow-nebula"
                        }`}
                        placeholder={`${errors.email ? errors.email : "Email"}`}
                        type="email"
                        name="email"
                        id="email"
                      />

                      <Field
                        // onKeyPress={(e) => e.key === 'Enter' && submit()}
                        className={`w-full h-12 mb-4 pl-4 placeholder-buttonDark rounded-lg drop-shadow-lg focus:outline-none focus:bg-white focus:shadow-lg ${
                          errors.password
                            ? "placeholder-[#ff0000] placeholder:opacity-80 text-[#ff0000] shadow-sm shadow-[#ff0000] focus:shadow-sm focus:shadow-[#ff0000]"
                            : "border-nebula drop-shadow-lg text-buttonDark shadow-sm shadow-nebula focus:shadow-sm focus:shadow-nebula"
                        }`}
                        placeholder={`${
                          errors.password ? errors.password : t("Password")
                        }`}
                        type="password"
                        name="password"
                        id="password"
                      />
                    </div>

                    <div className="flex items-center justify-center w-full mt-2 h-14 ">
                      <button
                        type="submit"
                        disabled={loading}
                        onClick={isSubmitting}
                        className="buttons w-full h-12 text-nebula font-bold text-lg bg-lighGreen rounded-lg shadow-md"
                      >
                        {loading ? t("Authenticating") : t("Log in")}
                      </button>
                    </div>
                    <div className="flex flex-col justify-center pt-3 w-full items-center ">
                      {/* <p
                        className="underline font-bold text-nebula m-0 cursor-pointer"
                        onClick={handleForgotPass}
                      >
                        {t("Forgot Password?")}
                      </p> */}
                      <p
                        className="underline font-bold text-nebula m-0 cursor-pointer"
                        onClick={() => history("/signup")}
                      >
                        {t("Sign Up")}
                      </p>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
