import React from "react";
import useAxios, { mapConfig } from "./useAxios";
import useUserState from "../mobile/hooks/useUserState";
import useAxiosPaginated from "./useAxiosPaginated";
import { useStore } from "../mobile/hooks/useStore";

const useNotifications = () => {
  const { handleAxios } = useAxios();
  const { user, isLogged } = useUserState();
  const { setNotifications } = useStore();

  const {
    value: notifications,
    call,
    loading,
    isLoaded,
    setValue,
    next,
    previous,
    hasMore,
    count,
    pageIndex,
    setLoading,
    loadingMore,
    cleanResponse,
  } = useAxiosPaginated();

  const get = async (userId) => {
    if (isLogged && !notifications) {
      call(
        mapConfig(`/notifications/get`, "get", {
          param: `&user=${userId || user?.id}`,
          page_size: 10,
          postFetch: (res) => {
            // dispatch(setNotifications(res?.data));
            setNotifications(res?.data);
          },
        })
      );
    }
  };
  const getAfterLogin = async (userId) => {
    call(
      mapConfig(`/notifications/get`, "get", {
        param: `&user=${userId}`,
        page_size: 10,
        postFetch: (res) => {
          // dispatch(setNotifications(res?.data));
          setNotifications(res?.data);
        },
      })
    );
  };

  return {
    get,
    loading,
    isLoaded,
    setValue,
    next,
    previous,
    hasMore,
    count,
    pageIndex,
    setLoading,
    loadingMore,
    cleanResponse,
    notifications,
    getAfterLogin,
  };
};

export default useNotifications;
