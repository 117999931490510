import useAxios, { mapConfig } from "./useAxios";
import useTranslate from "../mobile/hooks/useTranslate";
import { useState } from "react";

export const useDisputes = () => {
  const { t } = useTranslate();
  const { handleAxios } = useAxios();
  const [loading, setLoading] = useState();

  const createDispute = async (body, recall) => {
    setLoading(true);
    await handleAxios(
      mapConfig("/disputes/create", "post", {
        body,
        postFetch: () => recall(),
      })
    );
    setLoading(false);
  };

  const createOffer = async (body, recall) => {
    setLoading(true);
    await handleAxios(
      mapConfig("/offers/create", "post", {
        body,
        postFetch: () => recall(),
      })
    );
    setLoading(false);
  };

  const changeOfferStatus = async (body) => {
    setLoading(true);
    await handleAxios(
      mapConfig("/disputes/change-offer-status", "put", {
        body,
        toast: false,
      })
    );
    setLoading(false);
  };

  const cancelDispute = async (body, callback) => {
    setLoading(true);
    await handleAxios(
      mapConfig("/disputes/cancelDispute", "put", {
        body,
      })
    );
    if (callback) callback();
    setLoading(false);
  };

  const disputeMessage = {
    0: t("There is no Dispute in this Booking"),
    1: t("There is an ongoing dispute 😔"),
    2: t("The dispute is being solved by customer service"),
    3: t("The dispute is Closed"),
    4: t("The dispute is Canceled"),
  };

  return {
    disputeMessage,
    loading,
    createDispute,
    createOffer,
    changeOfferStatus,
    cancelDispute,
  };
};
