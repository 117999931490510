import React, { useMemo } from "react";
import GMap from "../admin/common/GMap";
import CustomMarker from "../admin/common/CustomMarker";
import { useHistory, useQuery } from "../../mobile/hooks";
import { useJsApiLoader } from "@react-google-maps/api";

const MapSearchListing = ({ listings, cleanResponse, isDesktop, ...props }) => {
  const { queryToPath } = useQuery(["dates", "guests"]);
  let history = useHistory();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLEMAPS_KEY}`,
  });

  const formatOnCords = useMemo(() => {
    if (!listings) return;
    const listingsCoords = listings
      ?.map((item) => ({
        key: item.location.lat + item.location.lng,
        value: item,
      }))
      .reduce(
        (a, v) => ({
          ...a,
          [v.key]: a[v.key] ? [...a[v.key], v.value] : [v.value],
        }),
        {}
      );

    return listingsCoords;
  }, [listings]);

  if (!isLoaded) return <></>;

  return (
    <div className={`w-full h-[100%]`}>
      <GMap {...props} isLoaded={isLoaded}>
        {listings?.length > 0 &&
          listings?.map((item, index) => {
            const sumCoords = item.location.lat + item.location.lng;
            return (
              <CustomMarker
                onClick={() => history(`/listing${queryToPath}&id=${item.id}`)}
                markerCoordinates={{
                  lat: Number(item?.location?.lat),
                  lng: Number(item?.location?.lng),
                }}
                key={index}
                item={item}
                items={
                  formatOnCords[sumCoords].length > 1
                    ? formatOnCords[sumCoords]
                    : false
                }
              />
            );
          })}
        {/* {!listings &&
          cleanResponse?.similar?.length > 0 &&
          cleanResponse?.similar?.map((item, index) => (
            <CustomMarker
              markerCoordinates={{
                lat: Number(item?.location?.lat),
                lng: Number(item?.location?.lng),
              }}
              key={index}
              item={item}
            />
          ))} */}
      </GMap>
    </div>
  );
};

export default MapSearchListing;
