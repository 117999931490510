import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNewUserState } from "../../app/actions";
import { useStore } from "./useStore";

export default function useUserState(dependency = "") {
  //Getting the data from session storage
  const store = JSON.parse(sessionStorage.getItem("userState"));
  const user = useSelector((state) => {
    if (state?.user !== null && state?.jwt) {
      return state;
    } else if (store) {
      return store;
    }
  });
  const [userState, setUserState] = useState(user);
  const { setUser } = useStore();

  //Extract the userState to Redux Glabal State

  useEffect(() => {
    setUserState(user);
  }, []);

  const distpach = useDispatch();

  const changeUserStateUser = (props) => {
    setUser(props);
    const newStore = JSON.parse(sessionStorage.getItem("userState"));
    window.sessionStorage.setItem(
      "userState",
      JSON.stringify({ ...newStore, user: { ...newStore.user, ...props } })
    );
    distpach(setNewUserState({ ...newStore.user, ...props }));
    setUserState({ ...newStore, user: { ...newStore.user, ...props } });
  };

  const userStore = useMemo(() => store?.user, [store]);
  const isTest = useMemo(() => store?.user?.white_list, [store]);

  return {
    userState,
    user: userState?.user,
    isTest: userState?.user?.white_list,
    isLogged: !!store?.jwt,
    userStore,
    jwt: store?.jwt,
    isTest,
    changeUserStateUser,
    store,
  };
}
