const ButtonComp = ({
  title,
  onPress,
  enabled = true,
  loading = false,
}) => {
  return (
    <div className="p-6 pt-3">
      <button
        disabled={!enabled || loading}
        onClick={onPress}
        className=
        {
          `block w-full
        select-none rounded-lg 
        bg-lighGreen py-2
        px-2 text-center
        align-middle font-sans 
        text-sm  font-bold
        uppercase text-white 
        shadow-md shadow-gray-900/10 
        transition-all hover:shadow-lg 
        hover:shadow-gray-900/20 
        focus:opacity-[0.85] 
        focus:shadow-none active:opacity-[0.85] 
        active:shadow-none disabled:pointer-events-none 
        disabled:opacity-50 disabled:shadow-none
        ${loading ? 'cursor-wait' : 'cursor-pointer'}
      `
        }
        type="button">
        {title}
      </button>
    </div>
  );
}

export default ButtonComp;