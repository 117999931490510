import React from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Options from "../Options";
import LoginForm from "../features/login-form/LoginForm";
import PrivateRoute from "./PrivateRoute";
import { FooterNavbar } from "./components";
import { ConnectButton } from "./components/commons";
import {
  Bookings,
  Dispute,
  Donate,
  EditListing,
  Explore,
  Favorites,
  Kyc,
  Listing,
  Listings,
  Notifications,
  Reserve,
  Search,
  Settings,
  Support,
  Today,
  User,
} from "./paths";
import Booking from "./paths/Booking";
import Msgcomp from "./components/messages/MsgComp";
import SignUpModal from "../features/Home/SignUpModal";
import Chat from "./paths/Chat";

export default function AppRouter() {
  // const { jwt, user } = useUserState();

  // const { connect } = useSocket();

  // useEffect(() => {
  //   if (jwt) {
  //     connect("noti", user?.id, jwt);
  //   }
  // }, []);

  return (
    <BrowserRouter>
      <Options />
      <Toaster style={{ zIndex: "10000000" }} />
      <ConnectButton />
      <Routes>
        <Route exact path="/listing" element={<Listing />} />
        <Route exact path="/reserve" element={<Reserve />} />
        <Route exact path="/user" element={<User />} />
        <Route exact path="/explore" element={<Explore />} />
        <Route exact path="/" element={<LoginForm />} />
        <Route exact path="/signup" element={<SignUpModal />} />
        <Route exact path="/search" element={<Search />} />
        <Route exact path="/support" element={<Support />} />
        <Route exact path="/donate" element={<Donate />} />
        <Route
          exact
          path="/booking"
          element={<PrivateRoute render={<Booking />} />}
        />
        <Route exact path="/kyc" element={<PrivateRoute render={<Kyc />} />} />
        <Route
          exact
          path="/edit/listing"
          element={<PrivateRoute render={<EditListing />} />}
        />
        <Route
          exact
          path="/dispute"
          element={<PrivateRoute render={<Dispute />} />}
        />
        <Route
          exact
          path="/favorites"
          element={<PrivateRoute render={<Favorites />} />}
        />
        <Route
          exact
          path="/notifications"
          element={<PrivateRoute render={<Notifications />} />}
        />
        <Route
          exact
          path="/settings"
          element={<PrivateRoute render={<Settings />} />}
        />
        <Route
          exact
          path="/listings"
          element={<PrivateRoute render={<Listings />} />}
        />
        <Route
          exact
          path="/bookings"
          element={<PrivateRoute render={<Today />} />}
        />
        <Route
          exact
          path="/today"
          element={<PrivateRoute render={<Today />} />}
        />
        <Route
          exact
          path="/msg"
          element={<PrivateRoute render={<Msgcomp />} />}
        />
        <Route
          exact
          path="/chat"
          element={<PrivateRoute render={<Chat />} />}
        />
      </Routes>
      {/* <Footer /> */}
      <FooterNavbar />
    </BrowserRouter>
  );
}
