import { useState } from "react";
import useAxios, { mapConfig } from "./useAxios";

const useReview = () => {
  const { handleAxios } = useAxios();
  const [work, setWork] = useState(false);

  const edit = async ({ id, comment, rating }, callback) =>
    await handleAxios(
      mapConfig(`/review/edit?id=${id}`, "put", {
        body: {
          comment,
          rating,
        },
        postFetch: (res) => {
          if (callback) {
            callback(res);
          }
        },
        setWork,
      })
    );

  const create = async ({ booking, postFetch, isHost, body }) => {
    body.booking_id = booking?.id;
    if (!isHost) {
      body.review_by = booking?.user_id;
      body.review_to = booking?.listing?.user_id;
    }

    if (isHost) {
      body.review_to = booking?.user_id;
      body.review_by = booking?.listing?.user_id;
    }
    await handleAxios(
      mapConfig("/review/createReview", "post", {
        body,
        postFetch: (res) => {
          if (postFetch) {
            postFetch(res);
          }
        },
        setWork,
      })
    );
  };

  return {
    create,
    work,
    edit,
  };
};

export default useReview;
