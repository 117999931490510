import React from "react";

export default function Checkbox({
  label,
  setValue,
  value,
  className,
  labelPosition = "right",
  textHelper,
  classNameTextHelper = "",
  classNameInner = "",
}) {
  const labelComponent = <p className="text-[#214003] font-bold">{label}</p>;
  const textHelperComponent = textHelper ? (
    <p className={`font-light text-sm ${classNameTextHelper}`}>{textHelper}</p>
  ) : (
    ""
  );

  return (
    <>
      {labelPosition === "right" ? (
        <div className={`flex flex-col ${className || ""}`}>
          <div className={`flex gap-5 items-center ${classNameInner}`} onClick={() => setValue(!value)}>
            <div
              className={`rounded-full lg:h-1/2 p-2 mx-1 border border-[#097F9A] ${
                value ? "bg-[#097F9A]" : ""
              }`}
            ></div>
            {labelComponent}
          </div>
          {textHelperComponent}
        </div>
      ) : labelPosition === "top" ? (
        <div className={`flex flex-col ${className || ""}`}>
          <div
            className={`flex gap-2 items-center flex-col text-center ${classNameInner}`}
            onClick={() => setValue(!value)}
          >
            {labelComponent}
            <div className="w-full flex justify-center">
              <div
                className={`rounded-full lg:h-1/2 p-2 mx-1 border border-[#097F9A] ${
                  value ? "bg-[#097F9A]" : ""
                }`}
              ></div>
            </div>
            {textHelperComponent}
          </div>
        </div>
      ) : labelPosition === "left" ? (
        <div className={`flex flex-col ${className || ""}`}>
          <div className={`flex gap-5 items-center ${classNameInner}`} onClick={() => setValue(!value)}>
            {labelComponent}
            <div
              className={`rounded-full lg:h-1/2 p-2 mx-1 border border-[#097F9A] ${
                value ? "bg-[#097F9A]" : ""
              }`}
            ></div>
          </div>
          {textHelperComponent}
        </div>
      ) : (
        <div className={`flex flex-col ${className || ""}`}>
          <div
            className={`flex gap-2 items-center flex-col ${classNameInner}`}
            onClick={() => setValue(!value)}
          >
            <div className="w-full flex justify-center">
              <div
                className={`rounded-full lg:h-1/2 p-2 mx-1 border border-[#097F9A] ${
                  value ? "bg-[#097F9A]" : ""
                }`}
              ></div>
            </div>
            {labelComponent}
          </div>
          {textHelperComponent}
        </div>
      )}
    </>
  );
}
