import React, { useEffect } from "react";
import {
  useGetData,
  useHistory,
  useQuery,
  useTranslate,
  useUserState,
} from "../../hooks";
import {
  BackButton,
  Divider,
  InfoToModal,
  ShowReviews,
  ShowUser,
} from "../commons";
import { ROLES } from "../../const";
import { CardSlider } from "../../../features/Home/CardSlider";
import UserListingCard from "./UserListingCard";

const UserComp = () => {
  const { query } = useQuery(["id"]);
  const { t } = useTranslate();
  const { userStore } = useUserState();
  const user = useGetData(
    `/users/getUserById?id=${query?.id}&review=${
      userStore?.role === ROLES.GUEST ? "listing" : "user"
    }${userStore?.role === ROLES.GUEST ? "&listings=true" : ""}`,
    false
  );

  const history = useHistory();

  useEffect(() => {
    if (!query?.id) {
      history("/explore");
    }
  }, [query?.id]);

  if (!user) return <></>;
  return (
    <div className="pt-[3em] px-5">
      <BackButton />
      <ShowUser user={user} type="big" title />
      <Divider />
      {user?.reviews && (
        <>
          <ShowReviews
            reviews={user?.reviews}
            id={user?.id}
            averageRating={user?.average_rating}
            to={userStore?.role === ROLES.GUEST ? "listing" : "user"}
          />
          <Divider />
        </>
      )}
      <InfoToModal
        info={user?.profile_summary || t("no summary")}
        title="Summary"
        className="opacity-50"
        showLength={250}
        maxLength={255}
      />
      {user?.listings?.length > 0 && (
        <>
          <Divider />
          <p className="text-nebula text-3xl font-semibold px-5 pt-[1em]">
            {t("Listings")}
          </p>
          <CardSlider showLeft={user?.listings} showRight={user?.listings}>
            {user?.listings &&
              user?.listings?.map((value, index) => (
                <UserListingCard value={value} key={value?.id} />
              ))}
          </CardSlider>
        </>
      )}
    </div>
  );
};

export default UserComp;
