import React from "react";
import useCurrentNetwork from "./useCurrentNetwork";

const useWeb3 = () => {
  const currentNetwork = useCurrentNetwork();

  const toWei = (num, d) => {
    if (!currentNetwork) return;
    const addChainDecimals =
      currentNetwork?.params?.add_chain?.params[0].nativeCurrency.decimals;
    let decimals = d;
    if (!d && addChainDecimals) {
      decimals = addChainDecimals;
    }
    if (!d && !!currentNetwork?.params?.to_wei) {
      decimals = currentNetwork?.params?.to_wei;
    }
    let multipleBy = "1";
    for (let i = 0; i < decimals; i++) {
      multipleBy = multipleBy + "0";
    }
    const res = num * Number(multipleBy);
    return String(res);
  };

  const toWeiDonate = (num, network) => {
    if (!network) return;
    const addChainDecimals =
      network?.params?.add_chain?.params[0].nativeCurrency.decimals;
    let decimals = 0;
    if (addChainDecimals) {
      decimals = addChainDecimals;
    }
    if (!!network?.params?.to_wei) {
      decimals = network?.params?.to_wei;
    }
    let multipleBy = "1";
    for (let i = 0; i < decimals; i++) {
      multipleBy = multipleBy + "0";
    }
    const res = num * Number(multipleBy);
    return String(res);
  };

  return {
    toWei,
    toWeiDonate,
  };
};

export default useWeb3;
