import { useCallback, useEffect, useState } from "react";
import { io } from "socket.io-client";
import useUserState from "./useUserState";


const socket = io(`${process.env.REACT_APP_BACKEND_URL_SOCKET}`, {
  transports: ["websocket"],
});

const useSocket = () => {
  const [active, setActive] = useState(false);
  const { jwt } = useUserState();
  const connect = useCallback(
    (name, send, innerJwt, callback) => {
      if (!socket?.connected && socket?.disconnected) {
        socket.connect();
        socket.emit(`${name}-connect`, { data: send, jwt: innerJwt || jwt });
        return;
      }
      socket.emit(`${name}-connect`, { data: send, jwt: innerJwt || jwt });
      setActive(true);
    },
    [socket]
  );
  const newConnection = useCallback(
    (name, send, callback) => {
      socket.connect();
      socket.emit(`${name}-connect`, { data: send, jwt: jwt });
      setActive(true);
    },
    [socket]
  );
  const receive = (name, callback) => {
    socket.on(`${name}-connect`, (data) => {
      if (callback) callback(data);
    });
  };
  const disconnect = (name) => {
    socket.emit(`${name}-disconnect`);
    setActive(false);
  };
  return { connect, receive, disconnect, newConnection, socket, active };
};

export default useSocket;
