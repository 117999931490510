import Axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useLogin } from "../../hooks/useLogin";
import useToast from "../../hooks/useToast";
import { useSetTranslate } from "../../mobile/hooks/useTranslate";
import ModalComp from "../Home/Navbar/ModalComp";
import ForgotPasswordModal from "../../commons/ForgotPasswordModal";
import useApiUrl from "../../hooks/useApiUrl";
import useChainId from "../../hooks/useChainId";

export default function LoginModal({
  isNewAddress,
  userState,
  setIsNewAddress,
  setOpenSignUp,
  setOpenLogin,
  onClose,
}) {
  const [loading, setLoading] = useState(false);
  const [isSaveNewWallet, setIsSaveNewWallet] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const chain = useChainId();

  const { notify } = useToast([
    {
      position: "top-center",
      type: "success",
      key: "s",
    },
    {
      position: "top-center",
      type: "error",
      key: "e",
    },
  ]);

  const openSignUp = () => {
    setOpenLogin(false);
    setOpenSignUp(true);
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const validation = Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      //validation for password input to login
      .matches(/^\S{3,25}$/),
  });

  const { setTranslate } = useSetTranslate();

  const {
    continueLogin,
    loading: loadingWallet,
    account,
    error,
    user,
    isTest,
  } = useLogin({ setIsSaveNewWallet, isSaveNewWallet, onClose });

  const modalAction = () => {
    setOpenForgotPassword(!openForgotPassword);
  };

  const url = useApiUrl();

  return openForgotPassword ? (
    <ModalComp
      open={openForgotPassword}
      onClose={modalAction}
      paragraph="Reset Password"
      centerBold={true}
    >
      <ForgotPasswordModal setOpenForgotPassword={setOpenForgotPassword} />
    </ModalComp>
  ) : (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={async (values, { resetForm }) => {
          setLoading(true);
          const data = {
            email: values.email,
            password: values.password,
          };

          try {
            const res = await Axios.post(`${url}/secure/auth/user`, data);

            if (res.status === 200) {
              setLoading(false);
              continueLogin(res, true);
            } else {
              setLoading(false);
              setIsNewAddress(true);
              continueLogin(res, true);
              onClose();
            }
            // getAfterLogin(res?.data?.user?.id);
          } catch (error) {
            setLoading(false);
            notify.e("Log in failed");
          }
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <div className="h-full w-full flex flex-col justify-center items-center space-y-4">
              <div className="w-full h-30 flex flex-col items-center">
                <div className="w-full h-30 flex flex-col items-center ">
                  <Field
                    className={`w-full h-12 mb-4 pl-4 placeholder-buttonDark rounded-lg drop-shadow-lg focus:outline-none focus:bg-white focus:shadow-lg ${
                      errors.email
                        ? "placeholder-[#ff0000] placeholder:opacity-80 text-[#ff0000] shadow-sm shadow-[#ff0000] focus:shadow-sm focus:shadow-[#ff0000]"
                        : "border-greenDark drop-shadow-lg text-buttonDark shadow-sm shadow-greenDark focus:shadow-sm focus:shadow-greenDark"
                    }`}
                    placeholder={`${errors.email ? errors.email : "Email"}`}
                    type="email"
                    name="email"
                    id="email"
                  />

                  <Field
                    className={`w-full h-12 mb-4 pl-4 placeholder-buttonDark rounded-lg drop-shadow-lg focus:outline-none focus:bg-white focus:shadow-lg ${
                      errors.password
                        ? "placeholder-[#ff0000] placeholder:opacity-80 text-[#ff0000] shadow-sm shadow-[#ff0000] focus:shadow-sm focus:shadow-[#ff0000]"
                        : "border-greenDark drop-shadow-lg text-buttonDark shadow-sm shadow-greenDark focus:shadow-sm focus:shadow-greenDark"
                    }`}
                    placeholder={`${
                      errors.password ? errors.password : "Password"
                    }`}
                    type="password"
                    name="password"
                    id="password"
                  />
                </div>
              </div>
            </div>

            <div className="w-full flex justify-center items-center">
              <Link
                to="#"
                className="underline font-bold text-greenDark mt-3 ml-1"
                onClick={() => {
                  setOpenForgotPassword(true);
                  // setOpenLogin(false)
                }}
              >
                Forgot Password?
              </Link>
            </div>

            <div className="w-full h-14 flex justify-center items-center mt-2">
              <button
                type="submit"
                disabled={loading}
                onClick={() => {}}
                className="w-fit px-[5em] py-2 h-12 text-nebula font-bold text-lg bg-lighGreen rounded-full shadow-md"
              >
                {loading ? "Authenticating" : "Log in"}
              </button>
            </div>

            <div className="text-buttonDark my-4">
              Don't Have and Account?{" "}
              <button
                className="underline font-extrabold text-greenDark"
                onClick={() => openSignUp()}
              >
                Sign Up Here
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
