import React from "react";
import { Main, SearchComp } from "../components";

const Search = () => {
  return (
    <div className="w-full">
      <SearchComp />
    </div>
  );
};

export default Search;
