import useConfettiRealistic from "./hooks/useConfettiRealistic";
import useFirework from "./hooks/useFirework";
import useStars from "./hooks/useStars";


const useConfetti = () => {
  const { fireworks } = useFirework()
  const { showConfetti } = useConfettiRealistic()
  const { stars } = useStars()

  return { fireworks, confetti: showConfetti, stars }
}

export default useConfetti