import { id } from "date-fns/locale"

export const FOOTER_DESCRIPTIONS = [
  {
    description: "BBOP is the world's first fee-free home sharing platform. BBOP leverages the power of crypto currencies and smart contracts to take out the middleman. You now have the full power of travelling the world and sharing your home completely in your hands.",
    id: "who",
  },
  {
    description: "BBOP uses a modified 2 of 3 signature smart contract to allow guests and hosts to directly interact with each other. This removes the need for a middle man and the associated costs. When the guest and host are both happy with a completed trip, they will both sign the BBOP smart contract. The host will be paid. The guest's security deposit will be returned. All of this happens seamlessly without any intervention from the BBOP team.",
    id: "contract",
  },
  {
    description: "BBOP reserves the 3rd signature in the smart contract only for the times when there is a dispute between the guest and the host. If the guest and host cannot agree on a payout distribution of the funds in the smart contract (payment and security deposit), then BBOP will help both parties try to resolve the problem.",
    id: "signature",
  },
  {
    description: "BBOP's security deposit feature is a novel solution to assuring your guests treat your home with the utmost respect and care. The security deposit is handled seamlessly by the smart contract and is returned to the guest once the stay is complete and the host is happy with the stay.",

    id: "security",
  },
  {
    description: "Pay with crypto. Earn crypto. Travel the world. Skip the middleman fees. All of this on our fee-free donation supported platform.",
    id: "fees",
  }
]

export const HOW_IT_WORKS = [
  {
    id: "text1",
    description: "At its heart, BBOP is a place to connect travelers and hosts.  BBOP prouds itself as being part of the sharing economy.  Guests and hosts are connected through the application on BBOP.co."
  },
  {
    id: "text2",
    description: "Once a traveler has found a suitable place they will contact the host through the platform to discuss details of the stay.  Hosts and guests are encouraged to share helpful details like phone numbers and email addresses to ensure they both feel comfortable with the transaction."
  },
  {
    id: "text3",
    description: "Now the BBOP platform leverages the smart contracts of the web3 world.  The user flow is as follows:"
  },
  {
    id: "text4",
    description: "Following the agreed upon price and dates, the traveler deposits the payment amount plus the security deposit into the BBOP smart contract.  This officially books the stay."
  },
  {
    id: "text5",
    description: "Upon completion of the stay, a satisfied user and host will both provide their \"signatures\" "
  },
  {
    id: "text6",
    description: "With 2 signatures collected, the wallet contract sends the payment to the host and the security deposit is returned to the guest."
  },
  {
    id: "text7",
    description: "If a dispute arises or a user forgets to sign their payments, BBOP can use our \"back-up\" signature along with either the host or the guest to provide the 2 of 3 signatures to have the funds sent out accordingly."
  },
  {
    id: "text8",
    description: "BBOP never has control of the crypto in the smart contract and always needs a second signature to send the funds."
  },
  {
    id: "text9",
    description: "As for the business model, users are simply encouraged to donate to support BBOP.  There are no obligatory fees to be paid to use the platform."
  }
]
