import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import useTranslate from "../../mobile/hooks/useTranslate";
import Button from "../Button";
import useNetworkUrl from "../../hooks/web3/useNetworkUrl";

const TransactionCompleted = ({
  transactionHash,
  isDonation,
  isSign,
  chainId,
}) => {
  const NETWORK_URLS = useNetworkUrl(chainId);
  const { t } = useTranslate();

  return (
    transactionHash !== null && (
      <div
        className={`text-center text-nebula flex flex-col justify-center items-center w-full ${
          isDonation && "py-5"
        }`}
      >
        {!isSign && (
          <>
            <AiOutlineCheckCircle className="text-7xl text-lighGreen" />
            <p
              className={`py-5 px-10 w-full ${
                isDonation ? "text-greenDark" : "text-nebula"
              }`}
            >
              {t("Transaction completed successfully")}{" "}
              {isDonation && `, ${t("thanks for your donation")}`}
            </p>
          </>
        )}
        <a
          href={`${NETWORK_URLS}tx/${transactionHash}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button className="px-8 py-3 rounded-lg">
            {t("View transaction")}
          </Button>
        </a>
      </div>
    )
  );
};

export default TransactionCompleted;
