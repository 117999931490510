import React from "react";
import MsgCard from "../../../../../../../mobile/components/messages/MsgCard";
import MsgChatTop from "../../../../../../../mobile/components/messages/MsgChatTop";
import { useUserState } from "../../../../../../../mobile/hooks";
const Messages = ({
  onClick,
  disabled,
  children,
  loading,
  listing,
  otherUser,
  chatSelectedInfo,
  setLoading,
  date,
  ...props
}) => {
  const { userStore } = useUserState();
  const lastTextSenderName =
    props.last_text?.send_by_userid === otherUser?.id
      ? otherUser?.username
      : props.last_text?.send_by_userid === listing?.user_id
      ? listing?.user?.username
      : userStore?.username;
  return (
    <div
      className={`flex items-center rounded-lg p-2 ${
        chatSelectedInfo ? "bg-blueContrast" : ""
      }`}
    >
      <div className="w-full" disabled={disabled} onClick={onClick}>
        <MsgCard
          image={listing?.listingImages[0].path}
          guestImg={otherUser?.profile_image_url}
          guestName={otherUser?.username}
          hostImg={listing?.user?.profile_image_url}
          hostName={listing?.user?.username}
          timestamp={date}
          lastText={{ ...props.last_text, senderName: lastTextSenderName }}
          badge={props.badge}
        />
      </div>
      {loading ? (
        <div className="flex items-center justify-end w-full">
          <div className="flex justify-end">{children}</div>
        </div>
      ) : null}
    </div>
  );
};

export default Messages;
