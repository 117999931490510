import React from "react";
import { useStore } from "./useStore";
import useAxios, { mapConfig } from "./useAxios";

const useGetNotReadNotis = () => {
  const { setNotRead, setMsgNotRead } = useStore();
  const { handleAxios } = useAxios();

  const getNotReadNotis = () => {
    handleAxios(
      mapConfig(`/notifications/get/not_read`, "get", {
        postFetch: (res) => {
          setNotRead(res);
        },
      })
    );

    handleAxios(
      mapConfig(`/notifications/get/not_read?msg=true`, "get", {
        postFetch: (res) => {
          setMsgNotRead(res);
        },
      })
    );
  };

  const getNotReadMsgs = () =>
    handleAxios(
      mapConfig(`/notifications/get/not_read?msg=true`, "get", {
        postFetch: (res) => {
          setMsgNotRead(res);
        },
      })
    );

  return { getNotReadNotis, getNotReadMsgs };
};

export default useGetNotReadNotis;
