import React from "react";
// import Modal from "react-responsive-modal";
import { useTranslate } from "../../../mobile/hooks";

const ModalComp = ({
  onClose,
  open,
  text,
  paragraph,
  children,
  centerBold = false,
  paragraphClassName = "",
}) => {

  const { t } = useTranslate();

  return (
    <>
      {/* <Modal
        open={open}
        onClose={onClose}
        center
        classNames={{
          modal: "customModal",
        }}
      >
        <div
          className={`flex flex-col w-full h-full p-2 ${
            centerBold ? "items-center" : ""
          }`}
        >
          {paragraph && (
            <h2
              className={`font-bold mb-4 text-greenDark ${
                centerBold ? "text-3xl" : ""
              } ${paragraphClassName}`}
            >
              {t(paragraph)}
            </h2>
          )}
          {text && (
            <div className="w-full h-[3rem] bg-nebula text-greenDark flex mb-8 text-2xl p-1.5 font-extrabold rounded-lg drop-shadow-lg justify-center">
              {t(text)}
            </div>
          )}

          {children}
        </div>
      </Modal> */}
    </>
  );
};

export default ModalComp;
