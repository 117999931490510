const API_URLS = {
  DEV: "https://bbop-api.herokuapp.com/api/v1",
  // DEV: "http://localhost:4001/api/v1",
  // PROD: "https://prod.api.bbop.co/api/v1",
  PROD: "https://prod-bbop-api-04c4e8416b07.herokuapp.com/api/v1",
};

const useApiUrl = () =>
  API_URLS[process.env.REACT_APP_WEB3_ARTIFACT_ENVIRONMENT];

export default useApiUrl;
