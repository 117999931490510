import { useEffect, useMemo, useRef, useState } from "react";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { IoCloudDownload, IoCloudUpload } from "react-icons/io5";
import ToolTip from "../features/userdashboard/Host/components/Listings/components/CryptoConditions/ToolTip";
import useToast from "../hooks/useToast";
import Button from "./Button";
import FileInput from "./FileInput";
import useTranslate from "../mobile/hooks/useTranslate";

const UploadAppFile = ({
  iconSize = 65,
  className = "",
  classNameInputRoot,
  label,
  selecteFileText = true,
  appFile,
  onCancel,
  rejectionNoAppFile,
  setChangeRejections,
}) => {
  const [input, setInput] = useState("");
  const fileRef = useRef(null);
  const { t } = useTranslate();
  const [upload, setUpload] = useState(true);

  useEffect(() => {
    if (appFile?.id) {
      setUpload(false);
    } else {
      setUpload(true);
    }
  }, [appFile]);

  const { notify } = useToast([
    {
      position: "top-center",
      type: "success",
      key: "s",
    },
    {
      position: "top-center",
      type: "error",
      key: "e",
    },
  ]);

  const reset = () => {
    setInput("");
  };

  const rejection = useMemo(() => {
    if (rejectionNoAppFile) return { rejection_reason: "No billing uploaded" };
    if (appFile?.rejection_reason)
      return { rejection_reason: appFile.rejection_reason };
  }, [appFile, rejectionNoAppFile]);

  return {
    reset,
    upload,
    setUpload,
    value: input,
    input: (
      <div
        className={`${
          rejection ? "border-cancel" : "border-nebula"
        } border-2 rounded-lg p-2 ${className}`}
      >
        <div
          className={`${
            rejection && "text-cancel"
          } pl-2 text-nebula w-full text-start text-lg font-bold flex`}
        >
          {label}
          {
            <ToolTip
              placement={"top"}
              className=" "
              text="Add a billing document of the listing with the same address as your listing"
            >
              <button className="ml-4 rounded-full w-6 h-6 text-center text-nebula shadow-[#c1c1c1] shadow-md">
                ?
              </button>
            </ToolTip>
          }
        </div>
        {!upload ? (
          <div
            className={` flex flex-col w-full justify-center h-fit items-center mt-5 text-nebula`}
          >
            <IoCloudDownload
              className="cursor-pointer"
              size={iconSize}
              aria-label="Descargar archivo"
              onClick={() => {
                if (appFile?.path) {
                  window.open(appFile.path, "_blank");
                } else {
                  notify("e", "No file to download");
                }
              }}
            />
            <p className="break-all text-nebula">{appFile?.original_name}</p>
            {rejection?.rejection_reason && (
              <p className="text-cancel break-all">
                {rejection?.rejection_reason}
              </p>
            )}
            <Button
              className="px-5 py-1 rounded-full mt-2"
              onClick={() => {
                setUpload(true);
                setInput("");
              }}
            >
              New Billing
            </Button>
          </div>
        ) : (
          <>
            <div className={`${classNameInputRoot}`}>
              <FileInput
                upload={upload}
                setSelectedImage={(v) => {
                  if (rejectionNoAppFile)
                    setChangeRejections(rejectionNoAppFile);
                  if (appFile?.rejections)
                    setChangeRejections(appFile.rejections);
                  setInput(v);
                }}
                fileRef={fileRef}
                notify={notify}
                classNameRoot="w-fit"
                icon={<IoCloudUpload color="#707070" size={iconSize} />}
              />
              {input && (
                <div className="flex flex-row w-full justify-center h-fit items-center mt-5">
                  <label className="break-all text-nebula">{input?.name}</label>
                  <div className="flex items-center h-full">
                    <AiOutlineCloseSquare
                      color="red"
                      className="cursor-pointer text-2xl ml-2"
                      onClick={() => {
                        setInput("");
                        onCancel();
                      }}
                    />
                  </div>
                </div>
              )}
              {selecteFileText && !input && (
                <p className="text-nebula w-full text-center">
                  {t("Select a File")}
                </p>
              )}
              {rejection?.rejection_reason && (
                <p className="text-cancel break-all">
                  {rejection?.rejection_reason}
                </p>
              )}
            </div>
            {appFile?.id && (
              <div className="w-full flex justify-center mt-2">
                <Button
                  className="px-5 rounded-full w-fit py-1"
                  type="light"
                  onClick={() => {
                    setUpload(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    ),
  };
};

export default UploadAppFile;
