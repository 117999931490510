import React from "react";
import { Slider } from "./Slider";

export default function Card({
  data,
  className,
  classNameSlider,
  classNameInnerCard,
  interval = false,
  arrows = true,
  actions = [],
  dotStyles,
  dots = false,
  children,
  classNameImage,
  listingId,
  changeState,
  actionProp,
  onClick = () => {},
}) {
  return (
    <div className={`flex justify-center w-full  ${className || ""}`}>
      <div
        className={`rounded-xl h-fit w-full transition ease-in-out delay-150 bg-blueContrast duration-300 drop-shadow-xl ${
          classNameInnerCard || ""
        }`}
      >
        <Slider
          interval={interval}
          data={data}
          arrows={data?.length > 1 ? arrows : false}
          actionProp={actionProp}
          actions={actions}
          dotStyles={dotStyles}
          dots={dots}
          classNameSlider={classNameSlider}
          classNameImage={classNameImage}
          listingId={listingId}
          changeState={changeState}
          onClick={onClick}
        />
        <div onClick={onClick}>{children}</div>
      </div>
    </div>
  );
}
