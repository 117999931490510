import React, { Component, Suspense } from "react";
import Loading from "../../../commons/Loading";
import { Link, Navigate } from "react-router-dom";
import { BiError } from "react-icons/bi";

const Main = ({ children, className }) => {
  return (
    <div
      className={`relative pb-[8em] max-w-[700px] w-full bg-blueDark min-h-screen ${
        className || ""
      }`}
    >
      <Suspense fallback={<Loading size={160} />}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Suspense>
    </div>
  );
};

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.status = 0;
    this.message = "";
  }
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      status: error?.response?.status,
      message: error?.message,
    };
  }

  render() {
    if (this.state.status === 401) {
      return (
        <Navigate
          to="/"
          replace={true}
          state={{
            from: `${window.location.pathname}${
              window.location.search ? window.location.search : ""
            }`,
          }}
        />
      );
    }
    if (this.state.hasError) {
      return (
        <div className="w-full flex h-screen justify-center items-center flex-col">
          <BiError className="text-nebula text-[10em]" />
          <p className="text-nebula text-3xl pb-10">
            {"Something went wrong!"}
          </p>
          <Link to="/">
            <p className="text-nebula text-lg border border-nebula rounded-lg py-3 px-8">
              {"Go back to home"}
            </p>
          </Link>
        </div>
      );
    }

    return this.props.children;
  }
}

export default Main;
