import React from "react";
import styled from "styled-components";
import MultipleChoiceByItems from "../../../MultipleChoice/ChoiceByItems";
import { categories } from "./data";

function Categories({ filters }) {
  const { filter, setMultipleChoice } = filters;
  return (
    <CategoriesStyled className="px-5">
      <h2 className="headline">Categories</h2>
      <p>Search for rooms, entire accommodations or any type of space</p>
      <MultipleChoiceByItems
        data={categories}
        state={filter.categories}
        setState={setMultipleChoice("categories")}
        style={{ margin: "25px 0" }}
      />
      <div className="separator" />
    </CategoriesStyled>
  );
}

const CategoriesStyled = styled.div``;

export default Categories;
