import React, { useMemo } from "react";
import { useCancelReservation } from "../../hooks/web3Interactions";
import useBooking from "../../../hooks/useBooking";
import useWeb3TxDB from "../../../hooks/web3/useWeb3TxDB";
import { WEB3_TX_TYPE } from "../../../const/Web3Tx";
import { BOOKING_STATUS } from "../../../const/BookingStatus";
import { useHistory, useTranslate } from "../../hooks";
import { utils } from "web3";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";

const BookingCancelInteraction = ({ booking }) => {
  const { t } = useTranslate();
  const { status } = useBooking();
  const web3txDB = useWeb3TxDB();
  const history = useHistory();
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();

  const validateAddress = useMemo(() => {
    if (!booking) return;
    const findTxPayment = booking.transactions?.find(
      (t) => t.type === "payment"
    );
    if (!findTxPayment) return;
    return findTxPayment.network_response.from === address.toLocaleLowerCase();
  }, [booking?.transactions, address]);

  const { execute } = useCancelReservation(
    booking?.listing?.user?.contract_address,
    booking?.reservationId ? [utils.toBN(Number(booking?.reservationId))] : 0,
    async (r) => {
      const saveReceipt = JSON.stringify(
        r,
        (key, value) => (typeof value === "bigint" ? value.toString() : value) // return everything else unchanged
      );
      await web3txDB.add(saveReceipt, WEB3_TX_TYPE.CANCELATION, booking?.id);
      await status(booking?.id, BOOKING_STATUS.CANCELLED);
      history("/bookings");
    }
  );
  if (!booking || booking?.transactions?.length === 0) return <></>;
  return (
    <div className="w-full flex justify-center items-center py-5 px-3 flex-col">
      <div
        className="bg-blueContrast cursor-pointer rounded-lg py-3 text-lg text-nebulaOpac w-full text-center"
        onClick={() => {
          if (!isConnected) {
            open();
            return;
          }
          if (!validateAddress) return;
          execute();
        }}
      >
        {!isConnected
          ? t("Connect Wallet")
          : !validateAddress
          ? t("Wrong Address to Cancel")
          : t("Cancel Reservation")}
      </div>
    </div>
  );
};

export default BookingCancelInteraction;
