import React, { useMemo, useState } from "react";
import IconsIntegrations from "./IconsIntegrations";
import { InputText } from "../../../../commons/Form";
import { AiOutlineEdit } from "react-icons/ai";
import Button from "../../../../commons/Button";
import useTranslate from "../../../../mobile/hooks/useTranslate";
import useIntegrations from "../../../../hooks/useIntegrations";
import ManageLoading from "../../../../commons/ManageLoading";
import Switch from "../../../../commons/Switch";
import { BiCopy } from "react-icons/bi";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import { useValidation } from "../../../../hooks/useValidation";
import useSyncUser from "../../../../hooks/useSyncUser";

const truncateString = (text) => {
  return !text ? "" : `${text?.substr(0, 6)}...${text?.substr(-4)}`;
};

const IntegrationSelected = ({ api, onCancel, recall }) => {
  const [apiKey, setApiKey] = useState(api?.api_key || "");
  const [status, setStatus] = useState(api?.status);
  const [mode, setMode] = useState(api?.api_key ? "selected" : "edit");
  const { copyToClipboard } = useCopyToClipboard();
  const { updateStatus, updateManually, updateApiKey, working } =
    useIntegrations();

  const syncUser = useSyncUser();

  const { t } = useTranslate();

  const apiTruncaded = useMemo(
    () => truncateString(api?.api_key),
    [api?.api_key]
  );
  const validate = [
    {
      keyValue: "api_key",
      valid: [
        { key: "text-max", value: 65 },
        { key: "text-min", value: 64 },
        { key: "string-not-only-number" },
        { key: "string-not-only-letters" },
        { key: "string-not-spaces" },
      ],
    },
  ];
  const { errors, hasErrors } = useValidation({
    values: { api_key: apiKey },
    validate,
  });

  return (
    <div className="flex flex-col justify-center items-end gap-3 w-full">
      <div className="flex flex-col items-center w-full gap-4">
        <InputText
          classNameInput={`bg-transparent ${
            mode === "edit"
              ? "text-buttonGreen"
              : status === "enabled"
              ? "text-buttonGreen"
              : "opacity-50"
          }`}
          className={`!w-full `}
          placeholder={t("Paste your api key")}
          classNameRoot="w-full"
          disabled={mode === "selected"}
          value={mode === "edit" ? apiKey : apiTruncaded}
          onChange={(e) => setApiKey(e.target.value)}
          error={hasErrors ? errors?.api_key : false}
          InputPrefix={
            <div className="relative ">
              <IconsIntegrations
                id={api?.source_id}
                width="3em"
                height="auto"
                className={`mr-2 asolute z-[10] ${
                  status === "disabled" ? "opacity-50" : ""
                }`}
              />
            </div>
          }
          inputPrompt={
            <ManageLoading loading={working}>
              {mode === "selected" && (
                <>
                  <BiCopy
                    className={`text-[2em] cursor-pointer mr-2 text-nebula`}
                    onClick={(e) =>
                      copyToClipboard(
                        e,
                        api?.api_key,
                        "Api Key copied successfully"
                      )
                    }
                  />
                  <AiOutlineEdit
                    onClick={(e) => {
                      e.preventDefault();
                      if (api?.api_key) {
                        setApiKey(api?.api_key);
                      }
                      setMode("edit");
                    }}
                    className={`text-[2em] cursor-pointer mr-2 text-nebula `}
                  />
                  <Switch
                    value={status === "enabled"}
                    onChange={async (value) => {
                      const res = await updateStatus(api?.source_id);
                      setStatus(res.status);
                    }}
                  />
                </>
              )}
            </ManageLoading>
          }
        />
      </div>
      <div
        className={`flex ${
          mode === "edit" ? "justify-end" : "justify-start"
        } w-full gap-3`}
      >
        {mode === "selected" && (
          <>
            <Button
              type="dark"
              className="px-5 rounded-full py-2"
              onClick={() => updateManually()}
            >
              {t("Sync listings")}
            </Button>
          </>
        )}
        {mode === "edit" && (
          <div className="flex gap-2">
            <Button
              type="dark"
              className="px-10 rounded-full py-2"
              disable={!apiKey || syncUser?.working}
              onClick={async () => {
                // if (api?.api_key) {
                //   updateApiKey(apiKey, api?.source_id, (res) => {
                //     setApiKey(res.api_key);
                //     setMode("selected");
                //     recall();
                //   });
                //   return;
                // }
                syncUser?.create(
                  {
                    api_key: apiKey,
                    source_id: api.source_id,
                  },
                  recall
                );
              }}
            >
              {t("Save")}
            </Button>
            <Button
              type="cancel"
              disable={syncUser?.working}
              className="px-3 rounded-full py-2"
              onClick={() => {
                if (api?.api_key) {
                  setMode("selected");
                  return;
                }
                onCancel();
              }}
            >
              {t("Cancel")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default IntegrationSelected;
