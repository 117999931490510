import React from "react";
import FileInput from "./FileInput";
import ProfileImage from "./ProfileImage";
import { StarRating } from "../mobile/components/commons";
import { ROLES } from "../mobile/const";
import { useUserState } from "../mobile/hooks";

export const EditProfileImage = ({
  info,
  widthHeigth,
  setSelectedImage,
  fileRef,
  preview,
  notify,
  width = "30px",
  external = false,
  className = "",
  listings,
}) => {
  const { userStore } = useUserState();
  return (
    <div className={`grid grid-cols-1 justify-items-center w-full${className}`}>
      <div className="flex w-full justify-end mb-3 ">
        {fileRef && (
          <div className={`flex items-center ml-5`} style={{ width }}>
            <FileInput
              setSelectedImage={setSelectedImage}
              fileRef={fileRef}
              notify={notify}
            />
          </div>
        )}
      </div>
      <div className="grid justify-items-center">
        <ProfileImage
          size={`${widthHeigth}px`}
          type={external ? "host" : "user"}
          source={preview || userStore?.profile_image_url}
        />
      </div>
      <div className="grid justify-items-center">
        <div className="grid grid-cols-1 gap-2 mt-2">
          {info?.roles === ROLES.GUEST && (
            <StarRating
              bgStar={{ empty: "blueDark", fill: "#fff" }}
              disabled={true}
              className="bg-blueDark rounded-lg flex items-center py-1 px-2 "
              routeRating={info?.average_rating_as_asker || 0}
            />
          )}
          {info?.roles === ROLES.HOST && (
            <StarRating
              bgStar={{ empty: "blueDark" }}
              disabled={true}
              routeRating={info?.average_rating_as_offerer || 0}
            />
          )}
        </div>
      </div>
    </div>
  );
};
