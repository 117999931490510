import React, { useMemo } from "react";
import { useAccount, useSwitchChain } from "wagmi";
import useChainId from "../../hooks/useChainId";
import useUserState from "./useUserState";
import { useSelector } from "react-redux";
import useTranslate from "./useTranslate";
import { useWeb3Modal } from "@web3modal/wagmi/react";

const useCheckContractInteraction = ({ booking, listing }) => {
  const chain = useChainId();
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const { userStore } = useUserState();
  const wallets = useSelector((state) => state.wallets);
  const { t } = useTranslate();
  const { switchChain } = useSwitchChain();

  const isHost = useMemo(() => {
    if (booking && userStore) {
      const hostId = listing?.user_id;
      const guestId = booking?.user_id;

      if (hostId === userStore?.id) return true;
      if (guestId === userStore?.id) return false;
    }
  }, [booking, userStore, listing]);

  const correctNetwork = useMemo(
    () =>
      !booking?.transactions ? "" : Number(booking?.transactions[0]?.network),
    [booking?.transactions]
  );

  const correctWallet = useMemo(
    () => wallets[correctNetwork],
    [wallets, correctNetwork]
  );

  const addressCanSign = useMemo(() => {
    if (!isConnected) return;
    if (!booking?.transactions) return;
    if (!userStore) return;
    if (isHost) {
      const contractAddress = listing?.user?.contract_address;
      const addressFromContract = userStore?.address[contractAddress];
      return {
        error: address?.toLowerCase() !== addressFromContract?.toLowerCase(),
        address: addressFromContract,
      };
    }
    const transactionMadeWith = booking?.transactions[0]?.network_response.from;
    return {
      error: address?.toLowerCase() !== transactionMadeWith?.toLowerCase(),
      address: transactionMadeWith,
    };
  }, [booking, isConnected, userStore, isHost, listing]);

  const canInteract = useMemo(() => {
    if (!isConnected)
      return {
        error: true,
        type: "connect",
        onClick: open,
        text: t("Connect Wallet"),
      };
    if (correctNetwork !== chain)
      return {
        error: true,
        type: "network",
        onClick: () =>
          switchChain({ chainId: Number(correctWallet?.chain_id) }),
        text: `${t("Switch to")} ${correctWallet?.name}`,
      };
    if (addressCanSign?.error)
      return {
        error: true,
        type: "address",
        text: t("Wrong Address"),
        info: {
          correct: addressCanSign.address,
          wrong: address,
        },
      };
    return { error: false };
  }, [isConnected, correctNetwork, addressCanSign, chain, correctWallet]);

  return {
    addressCanSign,
    isCorrectNetwork: correctNetwork !== chain,
    correctWallet,
    canInteract,
    isConnected,
    address,
    correctNetwork,
    isHost,
  };
};

export default useCheckContractInteraction;
