import useAxios, { mapConfig } from "../useAxios";
import useChainId from "../useChainId";

const useWeb3TxDB = () => {
  const { handleAxios } = useAxios();
  const chain = useChainId();

  const add = async (network_response, type, booking_id = {}) => {
    const body = {
      network_response,
      type,
      booking_id,
      network: chain,
    };
    await handleAxios(
      mapConfig(`/tx/storeTransaction`, "post", { body, toast: false })
    );
  };

  return { add };
};

export default useWeb3TxDB;
