import React, { useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

const useQuery = (keys) => {
  const { search, state } = useLocation();
  const [currentQueryParameters, setSearchParams] = useSearchParams();

  const getQuery = () => {
    let res = {};
    for (let key of keys) {
      let value = currentQueryParameters?.get(key);
      try {
        value = JSON.parse(value || "");
        // value = JSON.parse(value || "");
      } catch (e) {}
      res[key] = value;
    }
    return res;
  };

  const query = React.useMemo(() => {
    if (keys?.length > 0 && currentQueryParameters) {
      return getQuery();
    }
  }, [currentQueryParameters, keys, search]);

  const changeQuery = (newQuery) => {
    const newQueryParameters = new URLSearchParams();
    const currentQuery = getQuery();
    const checkQuery = { ...currentQuery, ...newQuery };
    for (let key of Object.keys(checkQuery)) {
      const value =
        typeof checkQuery[key] === "object"
          ? JSON.stringify(checkQuery[key])
          : checkQuery[key];

      newQueryParameters.set(key, value);
      setSearchParams(newQueryParameters, { state });
    }
  };

  const queryToPath = useMemo(() => {
    let newPath = `?`;
    let index = 1;
    const queryArrayKeys = Object.keys(query);
    for (let k of queryArrayKeys) {
      const value = query[k];
      let addQuery = "";
      if (index > 1) {
        addQuery = "&";
      }
      addQuery = addQuery + k + "=";
      if (typeof value === "object") {
        newPath = newPath + addQuery + JSON.stringify(value);
      } else {
        newPath = newPath + addQuery + value;
      }
      index = index + 1;
    }
    return newPath;
  }, [query]);

  const haveQuery = React.useMemo(() => {
    if (query) {
      let res = false;
      for (let key of Object.keys(query)) {
        if (query[key]) res = true;
      }
      return res;
    }
  }, [query]);

  return { query, haveQuery, changeQuery, queryToPath };
};

export default useQuery;
