import React, { useMemo } from "react";
import styled from "styled-components";
import useLoadAllData from "../../../../../hooks/useLoadAllData";
import LoadingWrapper from "../../../commons/LoadingWrapper";
import MultipleChoiceByGroup from "./../../../commons/MultipleChoice/ChoiceByGroup";
import { sortData } from "./utils";
import { useTranslate } from "../../../../hooks";

function Attributes({ listing, setListing }) {
  const { data, ...props } = useLoadAllData("amenities/get");
  const { t } = useTranslate();

  const attributes = useMemo(() => {
    if (!!data) return sortData(data);
    return [];
  }, [data]);

  const setAmenities = (id) => {
    setListing({
      ...listing,
      amenities: listing.amenities.includes(id)
        ? listing.amenities.filter((item) => item !== id)
        : [...listing.amenities, id],
    });
  };

  return (
    <AttributesStyled className="h-full px-6">
      <div className="title">{t("Attributes")}</div>
      <LoadingWrapper
        {...props}
        style={{
          flexGrow: 1,
          overflowY: "auto",
        }}
      >
        <div className="attributes-container">
          <MultipleChoiceByGroup
            data={attributes}
            state={listing.amenities}
            setState={setAmenities}
            alwaysShowAll
            style={{ paddingBottom: 20 }}
          />
        </div>
      </LoadingWrapper>
    </AttributesStyled>
  );
}

const AttributesStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  .attributes-container {
    .group-name {
      margin: 10px 0;
    }
    .checkbox-label {
      padding: 6px 0;
    }
  }
`;

export default Attributes;
