import React from "react";
import { useTranslate } from "../../hooks";
import Button from "../../../commons/Button";
import ManageLoading from "../../../commons/ManageLoading";

const ShowMoreButton = ({ hasMore, callMore, loading }) => {
  const { t } = useTranslate();
  if (!hasMore) return <></>;
  return (
    <div className="w-full flex justify-center">
      <Button
        className="py-4 my-5 w-full flex justify-center rounded-lg"
        onClick={() => callMore()}
      >
        <ManageLoading loading={loading} size={25}>
          {t("SHOW MORE")}
        </ManageLoading>
      </Button>
    </div>
  );
};

export default ShowMoreButton;
