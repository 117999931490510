import { InfoWindow, Marker } from "@react-google-maps/api";
import React, { useMemo, useState } from "react";
import BbopMarker from "../../../assets/BbopMarker.png";
import BbopDoubleMarker from "../../../assets/BbopDoubleMarker.png";
import Button from "../../../commons/Button";
import StarRating from "../../../commons/StarRating";
import useTranslate from "../../../mobile/hooks/useTranslate";
import styled from "styled-components";
import ManageLargeText from "../../../commons/ManageLargeText";

export default function CustomMarker({
  markerCoordinates,
  item,
  items,
  extraInfo = true,
  map,
  onClick,
}) {
  const [showInfo, setShowInfo] = useState(!!items ? 0 : false);
  const { t } = useTranslate();

  const info = useMemo(
    () => (!!items && showInfo > 0 ? items[showInfo - 1] : item),
    [items, showInfo]
  );

  return (
    <MarkerStyled>
      <Marker
        title={"Hello World!"}
        position={markerCoordinates}
        icon={!items ? BbopMarker : BbopDoubleMarker}
        map={map}
        onClick={() => {
          if (!!items) {
            const newIndex = showInfo + 1;
            if (newIndex > items?.length) {
              setShowInfo(0);
            }
            if (newIndex <= items?.length) {
              setShowInfo(newIndex);
            }
            return;
          }
          setShowInfo(!showInfo);
        }}
      >
        {showInfo && extraInfo && (
          <InfoWindow
            onCloseClick={() => {
              if (!!items) setShowInfo(0);
              if (!items) setShowInfo(false);
            }}
            position={markerCoordinates}
          >
            <div className="flex flex-col items-center w-full justify-center">
              {info?.listingImages && (
                <div
                  className={`h-[140px] object-cover`}
                  style={{ width: "inherit" }}
                >
                  <img
                    src={info?.listingImages[0].path}
                    className="w-[18em] h-full object-cover rounded mt-2"
                  />
                </div>
              )}
              <div className="flex w-full justify-between text-lg text-buttonDark mt-2">
                <ManageLargeText
                  maxLength={15}
                  cutValue={15}
                  value={info.name}
                />
                <p>${info.price}</p>
              </div>
              <div className="flex w-full items-center justify-between mt-2">
                <Button
                  className="py-2 flex justify-center rounded-lg w-full px-5 font-bold"
                  onClick={onClick}
                >
                  {t("View Listing")}
                </Button>
                {info?.average_rating && (
                  <StarRating
                    classNameStars=" text-xl lg:text-xl"
                    routeRating={info?.average_rating}
                  />
                )}
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>
    </MarkerStyled>
  );
}

const MarkerStyled = styled.div`
  color: #000;
  .gm-style-iw-c {
    background-color: #000;
  }
`;
