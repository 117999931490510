import React from "react";
import StarRating from "../StarRating";
import { format } from "date-fns";
import { useTranslate } from "../../../hooks";
import ReviewCard from "./ReviewCard";
import ReviewsModal from "./ReviewsModal";
import { useModalTemplate } from "../ModalTemplate";
import { CardSlider } from "../../../../features/Home/CardSlider";

const ShowReviews = ({ reviews, to = "listing", id, averageRating }) => {
  const { t } = useTranslate();
  const { Modal, open, ...modalPropsRest } = useModalTemplate();

  if (!reviews) return <></>;
  return (
    <>
      <div
        className={`pt-5 pl-5 w-full flex flex-col ${
          reviews?.hasMore ? "" : "pb-5"
        }`}
      >
        {" "}
        <StarRating
          routeRating={averageRating}
          disabled={true}
          showRating={true}
          oneStar={true}
          totalReviews={reviews?.total}
          classNameRating="text-[1.7em]"
          classNameReviews="text-[1.7em]"
          circlePadding="4"
        />
      </div>
      <CardSlider gap="5">
        {reviews?.data &&
          reviews?.data?.map((item) => (
            <ReviewCard
              key={item.id}
              date={format(new Date(item.created_at), "MMMM yyyy")}
              text={item.comment}
              seeMore={open}
              rating={item.rating}
              user={{
                full_name: item?.full_name,
                profile_image_url: item?.profile_image_url,
                created_at: item?.user_created_at,
              }}
            />
          ))}
      </CardSlider>
      {reviews?.hasMore && (
        <div className="w-full p-2">
          <div
            onClick={open}
            className="bg-blueContrast rounded-lg w-full text-nebulaOpac text-xl flex justify-center items-center py-4"
          >
            {t("See All Reviews")}
          </div>
        </div>
      )}
      <Modal
        id="reiews-modal"
        bodyScroll={false}
        defaultCloseButton
        transitionType="botToTop"
        type="largeBox"
        // title={"Amenities"}
        {...modalPropsRest}
      >
        <ReviewsModal id={id} to={to} rating={averageRating} />
      </Modal>
    </>
  );
};

export default ShowReviews;
