import React, { forwardRef } from "react";
import styled from "styled-components";
import { IoMdClose } from "react-icons/io";

export const MiddleBox = forwardRef(
  ({ defaultCloseButton, close, title, children }, ref) => {
    return (
      <MiddleBoxStyled ref={ref}>
        <div className="header">
          {defaultCloseButton && (
            <IoMdClose
              className="default-button"
              role="button"
              fontSize="1.2em"
              onClick={close}
            />
          )}
          <div className="title">{title}</div>
        </div>
        {children}
      </MiddleBoxStyled>
    );
  }
);

const MiddleBoxStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 55%;
  border-radius: 15px 15px 0 0;
  background-color: #fff;
  color: #000;
  transform: translate(0, 100%);
  @media (min-width: 800px) {
    bottom: 50%;
    left: 50%;
    height: 90%;
    width: 80%;
    max-width: 780px;
    border-radius: 15px;
    transition: border-radius 0.25s linear;
    transform: translate(-50%, 100%);
  }

  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #dddddd;
    .default-button {
      position: absolute;
      left: 15px;
    }
    .title {
      font-weight: 700;
    }
  }
`;

export default MiddleBox;
