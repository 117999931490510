import React from "react";
import { ProfileImage } from "../commons";

const MsgCard = ({
  image,
  timestamp,
  guestImg,
  guestName,
  lastText,
  badge,
}) => {
  return (
    <div className="rounded-lg relative w-full flex justify-between border h-[8em] border-blueContrast bg-blueContrast shadow-sm gap-4 transition-transform duration-200 ease-in-out hover:scale-[99%] hover:bg-blueDark hover:cursor-pointer">
      {badge?.length > 0 && (
        <div className="h-[1.5em] w-fit pb-[0.1em] pt-[0.2em] px-[0.5em] rounded-full flex items-center justify-center text-blueDark bg-lighGreen text-[0.7em] absolute -top-[10px] -right-[5px]">
          <span className="">{badge.length}</span>
        </div>
      )}
      <div className="flex gap-2 items-center w-[300px]">
        {image ? (
          <img
            src={image}
            alt="Profile Picture"
            className="rounded-tl-lg rounded-bl-lg object-cover w-full h-full"
          />
        ) : (
          <div className="rounded-tl-lg rounded-bl-lg object-cover w-full h-full bg-gray"></div>
        )}
      </div>
      <div className="flex items-end gap-2 p-2 h-full w-full">
        <div className="flex flex-col items-end justify-between h-full gap-2 w-full">
          <div className="flex items-start md:items-center gap-2 w-full justify-between">
            <div className="font-medium text-white text-sm md:text-lg !leading-[18px]">
              {guestName}
            </div>
            <div className="relative flex shrink-0 overflow-hidden rounded-full w-[3em] h-[3em]">
              <ProfileImage url={guestImg} size="3" />
            </div>
          </div>
          <div className="flex flex-col items-end">
            <div className="text-xs text-nebula opacity-50">{timestamp}</div>
            {lastText?.senderName && lastText.content && (
              <div className="text-xs text-nebula opacity-50">
                {`${lastText.senderName}: ${
                  lastText.content?.length > 50
                    ? lastText.content?.match(
                        new RegExp(".{1," + 50 + "}", "g")
                      )[0] + "..."
                    : lastText.content
                }`}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MsgCard;
