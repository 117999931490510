import * as React from "react";

function AirIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 503.607 503.607"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M477.426 74.541H24.18C9.911 74.541-1 85.452-1 99.721V267.59c0 14.269 10.911 25.18 25.18 25.18h453.246c14.269 0 25.18-10.911 25.18-25.18V99.721c.001-14.269-10.911-25.18-25.18-25.18zm8.394 193.049c0 5.036-3.357 8.393-8.393 8.393H24.18c-5.036 0-8.393-3.357-8.393-8.393V99.721c0-5.036 4.197-8.393 8.393-8.393h453.246c5.036 0 8.393 3.357 8.393 8.393V267.59z"
        transform="translate(1 1)"
        fill={props.color}
      />
      <path
        d="M217.229 150.082h67.148c5.036 0 8.393-3.357 8.393-8.393s-3.357-8.393-8.393-8.393h-67.148c-5.036 0-8.393 3.357-8.393 8.393s3.357 8.393 8.393 8.393zM460.639 242.41H40.967c-5.036 0-8.393 3.357-8.393 8.393s3.357 8.393 8.393 8.393h419.672c5.036 0 8.393-3.357 8.393-8.393s-3.357-8.393-8.393-8.393zM460.639 208.836H40.967c-5.036 0-8.393 3.357-8.393 8.393s3.357 8.393 8.393 8.393h419.672c5.036 0 8.393-3.357 8.393-8.393s-3.357-8.393-8.393-8.393zM255 352.364c-8.393-4.197-12.59-9.233-12.59-13.43s5.036-9.233 11.751-14.269c4.197-1.679 5.036-6.715 3.357-10.911-1.679-4.197-6.715-5.875-10.911-3.357-13.43 7.554-20.984 17.626-20.984 28.538 0 10.911 7.554 20.984 20.984 29.377 8.393 4.197 12.59 9.233 12.59 13.43s-5.036 9.233-12.59 13.429c-4.197 1.679-5.875 6.715-3.357 10.911 1.679 2.518 4.197 4.197 7.554 4.197 1.679 0 2.518 0 4.197-.839 13.43-7.554 20.984-17.626 20.984-28.538S268.43 359.079 255 352.364zM162.672 369.151c-8.393-4.197-12.59-9.233-12.59-13.43s5.036-9.233 11.751-14.269c4.197-1.679 5.875-6.715 3.357-10.911-1.679-4.197-6.715-5.875-10.911-3.357-13.43 7.554-20.984 17.626-20.984 28.538 0 10.911 7.554 20.984 20.984 29.377 8.393 4.197 12.59 9.233 12.59 13.43s-5.036 9.233-12.59 13.43c-4.197 1.679-5.875 6.715-3.357 10.911 1.679 2.518 4.197 4.197 7.554 4.197 1.679 0 2.518 0 4.197-.839 13.43-7.554 20.984-17.626 20.984-28.538s-7.555-21.824-20.985-28.539zM347.328 369.151c-8.393-4.197-12.59-9.233-12.59-13.43s5.036-9.233 11.751-14.269c4.197-1.679 5.036-6.715 3.357-10.911-1.679-4.197-6.715-5.875-10.911-3.357-13.43 7.554-20.984 17.626-20.984 28.538 0 10.911 7.554 20.984 20.984 29.377 8.393 4.197 12.59 9.233 12.59 13.43s-5.036 9.233-12.59 13.43c-4.197 1.679-5.875 6.715-3.357 10.911 1.679 2.518 4.197 4.197 7.554 4.197 1.679 0 2.518 0 4.197-.839 13.43-7.554 20.984-17.626 20.984-28.538s-7.556-21.824-20.985-28.539z"
        transform="translate(1 1)"
        fill={props.color}
      />
    </svg>
  );
}

export default AirIcon;
