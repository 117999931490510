export const USER_KYC_STATUS = {
  UNVERIFIED: "unverified",
  REJECTED: "rejected",
  PENDING: "pending",
  VERIFIED: "verified",
};

export const USER_KYC_STATUS_WITH_FILE_INPUT = [
  USER_KYC_STATUS.UNVERIFIED,
  USER_KYC_STATUS.REJECTED,
];

export const USER_KYC_STATUS_WITH_UPLOAD = [
  USER_KYC_STATUS.VERIFIED,
  USER_KYC_STATUS.PENDING,
];

export const USER_KYC_STATUS_TEXT = {
  unverified:
    "Please fill this form and save the changes to verify your identity",
  rejected:
    "Please review all your rejected files and make the changes required",
  pending: "KYC under verification, please wait for approval",
  verified: "Your KYC files are verified",
};

export const USER_KYC_STATUS_COLOR = {
  unverified: "nebula",
  rejected: "cancel",
  pending: "yellowBbop",
  verified: "lighGreen",
};
