import React, { useState } from "react";
import useAxios, { mapConfig } from "./useAxios";

const useIntegrations = () => {
  const { handleAxios } = useAxios();
  const [working, setWorking] = useState(false);

  // const save = async (body, callback) => {
  //   setWorking("save");
  //   const res = await handleAxios(
  //     mapConfig("/sources/my_apis", "post", {
  //       postFetch: (res) => {
  //         if (callback) callback(res);
  //       },
  //       body,
  //     })
  //   );
  //   setWorking(false);
  //   return res;
  // };

  const updateStatus = async (source_id, callback) => {
    setWorking("status");
    let res = "";
    await handleAxios(
      mapConfig("/sources/my_api/status", "put", {
        body: {
          source_id,
        },
        postFetch: (data) => {
          res = data;
          if (callback) callback();
        },
      })
    );
    setWorking(false);
    return res;
  };

  const updateManually = async (callback) => {
    setWorking("manual");
    const res = await handleAxios(
      mapConfig("/sources/update_manually", "put", {
        postFetch: () => {
          if (callback) callback();
        },
      })
    );
    setWorking(false);
    return res;
  };

  const updateApiKey = async (newApiKey, source_id, callback) => {
    setWorking("updateApi");
    const res = await handleAxios(
      mapConfig("/sources/update_api_key", "put", {
        body: {
          api_key: newApiKey,
          source_id,
        },
        postFetch: (res) => {
          if (callback) callback(res);
        },
      })
    );
    setWorking(false);
    return res;
  };

  return {
    // save,
    updateStatus,
    updateManually,
    updateApiKey,
    workingSave: working === "save",
    workingUpdateManually: working === "manual",
    workingUpdateStatus: working === "status",
    workingUpdateApiKey: working === "updateApi",
    working: !!working,
  };
};

export default useIntegrations;
