import React from "react";
import useAxios, { mapConfig } from "./useAxios";

const useCheckSession = () => {
  const { handleAxios } = useAxios();
  const checkSession = async () => {
    let v = "";
    await handleAxios(
      mapConfig("/secure/auth/check", "get", {
        postFetch: (res) => (v = res?.message),
      })
    );
    return v === "ok";
  };
  return {
    checkSession,
  };
};

export default useCheckSession;
