import React, { useMemo } from "react";
import useSimpleAxios from "../../../../hooks/useSimpleAxios";
import { mapConfig } from "../../../hooks/useAxios";
import ManageLoading from "../../../../commons/ManageLoading";
import { renderIcon } from "../../listing/ShowAmenities";
import { useTranslate } from "../../../hooks";

const Amenities = ({ listing, onChange }) => {
  const { t } = useTranslate();
  const { value: amenities, isLoaded } = useSimpleAxios(
    mapConfig("/amenities/get", "get")
  );
  const groups = useMemo(
    () =>
      !amenities
        ? false
        : amenities.reduce(
            (a, v) =>
              v.group !== "OTHER"
                ? {
                    ...a,
                    [v.group]: a[v.group] ? [...a[v.group], v] : [v],
                  }
                : false,
            {}
          ),
    [amenities]
  );

  return (
    <ManageLoading loading={!isLoaded} type="screen">
      <div className="flex flex-col w-full p-2 gap-10 items-start justify-center pb-[10em]">
        {groups &&
          Object.keys(groups).map((group) => {
            if (!group) return <></>;
            const amenitiesGroup = groups[group];
            return (
              <div className="w-full flex justify-center items-center gap-2 flex-col">
                <p className="pb-1 text-2xl font-semibold w-full text-left">
                  {t(group)}
                </p>
                <div className="flex w-full gap-2 items-start justify-center flex-wrap">
                  {amenitiesGroup &&
                    amenitiesGroup?.map((am) => {
                      const isSelected = listing.listing_amenities.find(
                        (a) => a.attribute_id === am.id && a.value === "1"
                      );
                      const color = isSelected ? "#84C7F4" : "#fff";
                      return (
                        <div
                          style={{ border: `2px solid ${color}` }}
                          className={`h-auto flex cursor-pointer flex-col gap-1 justify-center w-[7em] py-4 items-center rounded-lg transition-all`}
                          onClick={() => {
                            if (isSelected) {
                              const filterListAmenities =
                                listing?.listing_amenities?.filter(
                                  (item) =>
                                    item.attribute_id !==
                                    isSelected.attribute_id
                                );
                              if (!isSelected?.id) {
                                onChange(
                                  "listing_amenities",
                                  filterListAmenities
                                );
                                return;
                              }
                              filterListAmenities.push({
                                ...isSelected,
                                value: "0",
                              });
                              onChange(
                                "listing_amenities",
                                filterListAmenities
                              );
                              return;
                            }
                            const isCreated = listing.listing_amenities.find(
                              (a) => a.attribute_id === am.id
                            );
                            if (isCreated) {
                              const filterListAmenities =
                                listing?.listing_amenities?.filter(
                                  (item) =>
                                    item.attribute_id !== isCreated.attribute_id
                                );
                              filterListAmenities.push({
                                ...isCreated,
                                value: "1",
                              });
                              onChange(
                                "listing_amenities",
                                filterListAmenities
                              );
                            }
                            if (!isCreated) {
                              onChange("listing_amenities", [
                                ...listing?.listing_amenities,
                                { attribute_id: am.id, value: "1" },
                              ]);
                            }
                          }}
                        >
                          {renderIcon(am.name, color, "w-[3.8em] h-[3.8em]")}
                          <p style={{ color }} className={`m-0 text-sm`}>
                            {am.name}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </div>
    </ManageLoading>
  );
};

export default Amenities;
