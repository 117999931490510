import { useMemo } from "react";
import useTranslate from "../../mobile/hooks/useTranslate";

export const DisputesLogic = ({ dispute }) => {
  const { t } = useTranslate();

  const cards = useMemo(() => {
    if (!dispute) {
      return [
        {
          dispute: false,
          yours: true,
          title: t("You want to start a dispute?"),
        },
      ];
    }

    const offers = dispute.offers;

    if (offers.length === 1) {
      const isYours = offers.find((item) => item.yours);

      if (isYours) {
        return [
          {
            ...isYours,
            dispute: true,
            dispute_id: dispute.id,
            title: t("Your offer"),
          },
        ];
      } else {
        const title =
          offers[0].from === "guest" ? t(`Guest's offer`) : t(`Host's offer`);

        return [
          {
            ...offers[0],
            dispute: true,
            dispute_id: dispute.id,
            title,
          },
          {
            dispute: true,
            yours: true,
            title: t("Send a new Offer"),
          },
        ];
      }
    }

    return offers.map((item) => ({
      ...item,
      dispute: true,
      dispute_id: dispute.id,
      title: item.yours
        ? t("Your offer")
        : item.from === "guest"
        ? t(`Guest's offer`)
        : t(`Host's offer`),
    }));
  }, [dispute, dispute?.offers, t]);

  return { cards };
};
