import { useState } from "react";
import useAxios from "./useAxios";

//this is used to make simple axio calls returns a value that contains the response
// a recall function to make the fetch again whenever you want and a work to manage the loading
// search for examples with '//example useAxiosPaginated'
const useAxiosPaginated = (callback) => {
  const [value, setValue] = useState();
  const [nextUrl, setNextUrl] = useState();
  const [previousUrl, setPreviousUrl] = useState();
  const [cleanResponse, setCleanResponse] = useState();
  const [hasMore, setHasMore] = useState();
  const [count, setCount] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [config, setConfig] = useState();

  const { handleAxios } = useAxios();

  const call = async (configCall) => {
    setConfig(configCall);
    configCall.isPaginated = true;
    configCall.path = `${configCall.path}?page_size=${
      configCall.page_size
    }&page_index=0${configCall?.param || ""}`;
    setLoading(true);
    const resp = await handleAxios(configCall);
    if (callback) callback(configCall?.body);
    setNextUrl(resp?.next);
    setHasMore(resp?.hasMore);
    setPreviousUrl(resp?.previous);
    setCount(resp?.count);
    setValue(resp?.data);
    setCleanResponse(resp);
    setPageIndex(0);
    setLoading(false);
    setIsLoaded(true);
  };

  const next = async (callback) => {
    let newConfig = config;
    newConfig.path = nextUrl;
    setLoading(true);
    setLoadingMore("next");
    const resp = await handleAxios(newConfig);
    setNextUrl(resp?.next);
    setCount(resp?.count);
    setPreviousUrl(resp?.previous);
    setHasMore(resp?.hasMore);
    setCleanResponse(resp);
    if (!!newConfig.dontAddNextValue) {
      setValue(resp?.data);
    }
    if (!newConfig.dontAddNextValue) {
      setValue((prev) => [...prev, ...resp?.data]);
    }
    if (callback) callback(resp);
    setPageIndex((prev) => prev + 1);
    setLoading(false);
    setLoadingMore("");
  };

  const previous = async () => {
    let newConfig = config;
    setLoading(true);
    setLoadingMore("prev");
    newConfig.path = previousUrl;
    const resp = await handleAxios(newConfig);
    setNextUrl(resp?.next);
    setCount(resp?.count);
    setPreviousUrl(resp?.previous);
    setHasMore(resp?.hasMore);
    setCleanResponse(resp);
    if (!!newConfig.dontAddNextValue) {
      setValue(resp?.data);
    }
    if (!newConfig.dontAddNextValue) {
      setValue((prev) => [...prev?.data, ...resp?.data]);
    }
    setPageIndex((prev) => prev - 1);
    setLoading(false);
    setLoadingMore("");
  };

  return {
    value,
    call,
    loading,
    isLoaded,
    setValue,
    next,
    previous,
    hasMore,
    count,
    pageIndex,
    setLoading,
    loadingMore,
    cleanResponse,
  };
};

export default useAxiosPaginated;
