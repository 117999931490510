import { useEffect } from "react";
import useAxios, { mapConfig } from "./useAxios";
import useSimpleAxios from "./useSimpleAxios";
import { useStore } from "./useStore";
import useUserState from "./useUserState";

const useTranslate = () => {
  const { lang, translate } = useStore();

  const t = (word) => {
    if (lang !== 2) {
      if (translate) {
        return translate[word] || word;
      } else {
        return word;
      }
    } else {
      return word;
    }
  };

  return { t };
};

export const useSetTranslate = () => {
  const { lang, setLang, setTranslate, newLang, setNewLang } = useStore();
  const { jwt, userStore } = useUserState();
  const { handleAxios } = useAxios();
  useSimpleAxios(
    mapConfig(
      `/users/getUserLang${userStore?.id ? `?userId=${userStore?.id}` : ""}`,
      "get",
      {
        postFetch: (res) => {
          setLang(res?.lang);
          setNewLang(true);
        },
      }
    )
  );

  const handleUseEffect = async (id) => {
    await handleAxios(
      mapConfig(
        `/users/getUserLang${
          userStore?.id || id ? `?userId=${userStore?.id || id}` : ""
        }`,
        "get",
        {
          postFetch: (res) => {
            setLang(res?.lang);
            setNewLang(true);
          },
        }
      )
    );
  };

  const handleUseEffectLang = async () => {
    await handleAxios(
      mapConfig(`/lang/getLang?id=${lang}`, "get", {
        postFetch: (res) => {
          setTranslate(res?.translate);
        },
      })
    );
  };

  useEffect(() => {
    if (newLang) {
      handleUseEffectLang();
    }
    if (!jwt) {
      handleUseEffectLang();
    }
  }, [lang]);

  useEffect(() => {
    if (!newLang && jwt) {
      handleUseEffect();
    }
  }, []);

  return { setTranslate: handleUseEffect };
};

export default useTranslate;
