import React from "react";
import ContactUsModal from "../../../../features/Home/Footer/ContactUsModal";

const ModalContactSupport = ({ Modal, open, ...modalPropsRest }) => {
  return (
    <Modal
      id="contact-suport-modal"
      bodyScroll={false}
      defaultCloseButton
      transitionType="botToTop"
      type="largeBox"
      title={"Contact Support"}
      {...modalPropsRest}
    >
      <ContactUsModal close={modalPropsRest.close} />
    </Modal>
  );
};

export default ModalContactSupport;
