import React, { useRef, forwardRef } from "react";
import styled from "styled-components";
import { IoMdClose } from "react-icons/io";

export const DraggerBox = forwardRef(
  (
    { defaultCloseButton, isDesktop, close, title, children, modalHeight },
    ref
  ) => {
    const initialY = useRef(0);
    const offsetY = useRef(0);

    const handleDragStart = (event) => {
      initialY.current = event.touches[0].clientY;
      ref.current.style.transition = "none";
    };

    const handleDrag = (event) => {
      if (initialY.current === 0) return;
      offsetY.current = event.touches[0].clientY - initialY.current;
      if (offsetY.current > 0) {
        ref.current.style.transform = `translateY(${offsetY.current}px)`;
      }
    };

    const handleDragEnd = () => {
      ref.current.style.transition = "transform 0.3s ease-out";
      if (offsetY.current > 100) {
        ref.current.style.transition = "none";
        close();
      } else {
        ref.current.style.transform = "translateY(0)";
      }
      initialY.current = 0;
      offsetY.current = 0;
    };

    return (
      <DraggerBoxStyled
        ref={ref}
        modalHeight={modalHeight}
        isDesktop={isDesktop}
        className="bg-blueDark"
      >
        {!isDesktop && (
          <div
            className="handler"
            onTouchStart={handleDragStart}
            onTouchMove={handleDrag}
            onTouchEnd={handleDragEnd}
          >
            <div className="dragger" />
          </div>
        )}
        <div className="header">
          {isDesktop && defaultCloseButton && (
            <IoMdClose
              className="default-button"
              role="button"
              fontSize="1.2em"
              onClick={close}
            />
          )}
          <div className="title">{title}</div>
        </div>
        {children}
      </DraggerBoxStyled>
    );
  }
);

const DraggerBoxStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${(p) => (p?.modalHeight ? p?.modalHeight : "98%")};
  /* height: 75%; */
  border-radius: 15px 15px 0 0;
  background-color: #1c2c49;
  color: #fff;
  transform: translate(0, 100%);
  @media (min-width: 800px) {
    bottom: 50%;
    left: 50%;
    height: 90%;
    width: 80%;
    max-width: 780px;
    border-radius: 15px;
    transition: border-radius 0.25s linear;
    transform: translate(-50%, 100%);
  }
  .handler {
    position: relative;
    top: 30px;
    padding: 20px 0;
    margin: -30px 0 15px 0;

    cursor: grab;
    .dragger {
      position: absolute;
      left: 50%;
      width: 30%;
      height: 5px;
      border-radius: 12px;
      transform: translateX(-50%);
      background-color: #e0e0e0;
    }
  }
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${({ isDesktop }) => (isDesktop ? "15px" : "15px 15px 5px")};
    transition: all 0.25s linear;

    .default-button {
      position: absolute;
      left: 15px;
    }
    .title {
      font-weight: 700;
    }
  }
`;

export default DraggerBox;
