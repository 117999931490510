import React from "react";
import { useLocation } from "react-router-dom";
import useUserState from "../../hooks/useUserState";

const showPaths = ["/reserve", "/booking", "/dispute"];

export default function ConnectButton() {
  const { pathname } = useLocation();
  const { isLogged } = useUserState();

  if (!showPaths.includes(pathname)) {
    return <></>;
  }

  if (!isLogged) {
    return <></>;
  }

  return (
    <div className="relative w-full max-w-[700px]">
      <div className="absolute top-[1em] right-[0.5em] z-[1000]">
        <w3m-button />
      </div>
    </div>
  );
}
