import { REJECTION_TEXT } from "../const/ListingCompletedSection";
import useAxios, { mapConfig } from "./useAxios";
import useListing from "./useListing";

const useRejection = (listingState) => {
  const { handleAxios } = useAxios();

  const handleRejection = (rejectionTextKey) => {
    let response = false;
    if (listingState?.rejections) {
      if (
        rejectionTextKey === "Billing" &&
        listingState?.rejections?.find((item) => item.rejection_type_id === 4)
      ) {
        response = true;
      }
      if (!REJECTION_TEXT[rejectionTextKey]) return false;
      for (let rejec of listingState?.rejections) {
        if (REJECTION_TEXT[rejectionTextKey].includes(rejec.value)) {
          response = true;
        }
      }
    }
    return response;
  };

  const handleRejectionText = (rejectionValue) => {
    let rejectionText = "";
    Object.keys(REJECTION_TEXT).forEach((item) => {
      if (REJECTION_TEXT[item].includes(rejectionValue)) {
        rejectionText = item;
      }
    });
    return rejectionText;
  };

  const handleFindRejection = (rejectionKey, type) => {
    const findRejection = !listingState?.rejections?.find(
      (item) => item.value === rejectionKey
    );
    if (type) {
      switch (type) {
        case "text":
          return findRejection ? "" : "!text-cancel";
        case "div":
          return findRejection ? "" : "border border-cancel rounded-lg";
        default:
          return findRejection ? "" : "!text-cancel";
      }
    }
    return !listingState?.rejections?.find(
      (item) => item.value === rejectionKey
    );
  };

  const handleListingStatusFromRejection = async (id, status) => {
    if (status !== "REJECTED") {
      return status;
    }
    let response = "";
    await handleAxios(
      mapConfig(
        `/rejections/getRejections?relation_id=${id}&rejection_type_id=1&status=sent`,
        "get",
        {
          postFetch: (res) => {
            if (res.length > 0) {
              response = "REJECTED";
            } else {
              response = "REVIEW";
            }
          },
        }
      )
    );
    return response;
  };

  const handleFilterRejectionsToUpdate = (check) => {
    return (
      listingState?.rejections?.filter((item) => check.includes(item.value)) ||
      []
    );
  };

  const handleFindRejectionToUpdate = (check) => {
    return listingState?.rejections?.find((item) => item.value === check);
  };

  return {
    handleRejection,
    handleRejectionText,
    handleFindRejection,
    handleListingStatusFromRejection,
    handleFilterRejectionsToUpdate,
    handleFindRejectionToUpdate,
  };
};

export default useRejection;
