import React from "react";

const Switch = ({ value, onChange, label }) => {
  return (
    <label class="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        value=""
        class="sr-only peer"
        checked={value}
        onChange={() => onChange(value)}
      />
      <div
        class={`
        w-11 h-6 
        bg-nebula
        rounded-full
        relative 
        peer 
        peer-focus:ring-4 peer-focus:ring-blue-300 
        dark:peer-focus:ring-blue-800 dark:bg-gray-700 
        peer-checked:after:translate-x-full peer-checked:after:border-white 
        after:content-[''] after:absolute after:top-0.5 after:left-[2px] 
        after:bg-white after:border-blueDark after:border after:rounded-full 
        after:h-5 after:w-5 after:transition-all 
        dark:border-gray-600 peer-checked:bg-blueDark
      `}
      ></div>
      {label && (
        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          {label}
        </span>
      )}
    </label>
  );
};

export default Switch;
