const data = [
  {
    id: 2,
    name: "other",
    type: "TEXT",
    group: "OTHER",
  },
  {
    id: 3,
    name: "WIFI",
    type: "BOOLEAN",
    group: "ACCOMMODATION",
  },
  {
    id: 4,
    name: "AC",
    type: "BOOLEAN",
    group: "ACCOMMODATION",
  },
  {
    id: 5,
    name: "TV",
    type: "BOOLEAN",
    group: "ACCOMMODATION",
  },
  {
    id: 6,
    name: "LAUNDRY",
    type: "BOOLEAN",
    group: "ACCOMMODATION",
  },
  {
    id: 7,
    name: "KITCHEN",
    type: "BOOLEAN",
    group: "ACCOMMODATION",
  },
  {
    id: 8,
    name: "PARKING",
    type: "BOOLEAN",
    group: "ACCOMMODATION",
  },
  {
    id: 9,
    name: "CUTLERY",
    type: "BOOLEAN",
    group: "ACCOMMODATION",
  },
  {
    id: 10,
    name: "POOL",
    type: "BOOLEAN",
    group: "AMENITY",
  },
  {
    id: 11,
    name: "JACUZZI",
    type: "BOOLEAN",
    group: "AMENITY",
  },
  {
    id: 12,
    name: "PATIO",
    type: "BOOLEAN",
    group: "AMENITY",
  },
  {
    id: 13,
    name: "GRILL",
    type: "BOOLEAN",
    group: "AMENITY",
  },
  {
    id: 14,
    name: "FIREPLACE",
    type: "BOOLEAN",
    group: "AMENITY",
  },
  {
    id: 15,
    name: "GYM",
    type: "BOOLEAN",
    group: "AMENITY",
  },
  {
    id: 16,
    name: "OFFICE",
    type: "BOOLEAN",
    group: "AMENITY",
  },
  {
    id: 17,
    name: "EXTINGUISHER",
    type: "BOOLEAN",
    group: "SECURITY",
  },
  {
    id: 18,
    name: "FIRE_ALARM",
    type: "BOOLEAN",
    group: "SECURITY",
  },
  {
    id: 19,
    name: "FIRST_AID",
    type: "BOOLEAN",
    group: "SECURITY",
  },
];

const groupedServices = data.reduce((acc, service) => {
  const key = service.group;
  if (!acc[key]) {
    acc[key] = [];
  }
  acc[key].push(service);
  return acc;
}, {});

const orderedGroups = Object.keys(groupedServices)
  .filter((group) => group !== "OTHER")
  .map((key) => ({
    group: key,
    services: groupedServices[key],
  }));

orderedGroups.sort((a, b) => {
  const indexA = data.findIndex((service) => service.group === a.group);
  const indexB = data.findIndex((service) => service.group === b.group);
  return indexA - indexB;
});

export const services = [
  ...orderedGroups,
  {
    group: "OTHER",
    services: groupedServices["OTHER"],
  },
];
