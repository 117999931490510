import React, { useEffect, useMemo } from "react";
import { InputText } from "../../../../commons/Form";
import { EditCancellationPolicy, InputTimer } from "../../commons";
import { useTranslate } from "../../../hooks";
import { useStore } from "../../../hooks/useStore";
import { useValidation } from "../../../../hooks/useValidation";
import useRejection from "../../../../hooks/useRejection";

const validate = [
  {
    keyValue: "security_deposit",
    valid: [{ key: "required" }],
  },
  {
    keyValue: "check_in",
    valid: [{ key: "required" }],
  },
  {
    keyValue: "check_out",
    valid: [{ key: "required" }],
  },
];

const Conditions = ({ listing, onChange, setChangeRejections }) => {
  const { t } = useTranslate();
  const { setErrorEditListing } = useStore();

  const cancellation_policy = useMemo(() => {
    if (listing?.listing_cancellation_policies?.length === 1) {
      return listing?.listing_cancellation_policies[0];
    }
  }, [listing?.listing_cancellation_policies]);

  const check = useMemo(() => {
    const checkInSplit = listing.check_in.split(":");
    const checkOutSplit = listing.check_out.split(":");
    return {
      in: `${checkInSplit[0]}:${checkInSplit[1]}`,
      out: `${checkOutSplit[0]}:${checkOutSplit[1]}`,
    };
  }, [listing?.check_in, listing?.check_out]);

  const { errors, hasErrors } = useValidation({
    values: {
      security_deposit: listing?.security_deposit_percentage || "",
      check_in: listing?.check_in || "",
      check_out: listing?.check_out || "",
    },
    validate,
  });
  const { handleFindRejectionToUpdate } = useRejection(listing);

  useEffect(() => {
    setErrorEditListing(hasErrors);
  }, [hasErrors]);

  return (
    <div className="flex flex-col gap-10 pb-[10em]">
      <div className="flex flex-col gap-3">
        <p
          className={`text-2xl font-semibold ${
            listing?.source ? "opacity-50" : ""
          }`}
        >
          {t("Cancellation Policies")}
        </p>
        <EditCancellationPolicy
          listPolicy={cancellation_policy}
          onChange={onChange}
          disabled={listing?.source}
        />
      </div>
      <InputText
        classNameLabel="text-2xl font-semibold pb-3"
        className="flex-col !items-start"
        classNameInput={`!bg-[#1c2641] rounded-lg p-4 text-lg ${
          handleFindRejectionToUpdate("security_deposit_percentage")
            ? "border-cancel border"
            : ""
        }`}
        type="number"
        style="dark"
        value={listing?.security_deposit_percentage}
        error={errors?.security_deposit_percentage}
        minValue={0}
        label={t("Security Deposit ($)")}
        onChange={(v) => {
          const r = handleFindRejectionToUpdate("security_deposit_percentage");
          if (r) setChangeRejections(r);
          onChange("security_deposit_percentage", v.target.value);
        }}
      />
      <div className="flex flex-col md:flex-row gap-2 md:justify-between">
        <div className="flex flex-col gap-3 w-full md:w-[45%]">
          <p
            className={`text-2xl font-semibold ${
              listing?.source ? "opacity-50" : ""
            }`}
          >
            {t("Check In")}
          </p>
          <InputTimer
            onChange={(v) => onChange("check_in", `${v}:00`)}
            time={check?.in}
            disabled={listing?.source}
          />
        </div>
        <div className="flex flex-col gap-3 w-full md:w-[45%]">
          <p
            className={`text-2xl font-semibold ${
              listing?.source ? "opacity-50" : ""
            }`}
          >
            {t("Check Out")}
          </p>
          <InputTimer
            onChange={(v) => onChange("check_out", `${v}:00`)}
            time={check?.out}
            disabled={listing?.source}
          />
        </div>
      </div>
    </div>
  );
};

export default Conditions;
