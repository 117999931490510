import React from "react";
import HowItWorks from "../../../../features/Home/Footer/HowItWorks/HowItWorks";

const ModalHBW = ({ Modal, open, ...modalPropsRest }) => {
  return (
    <Modal
      id="how-bbop-works-modal"
      bodyScroll={false}
      defaultCloseButton
      transitionType="botToTop"
      type="largeBox"
      title={"How Bbop Works ?"}
      {...modalPropsRest}
    >
      <HowItWorks />
    </Modal>
  );
};

export default ModalHBW;
