import React from "react";
import { useHistory, useTranslate } from "../../hooks";
import { BiDonateHeart } from "react-icons/bi";
import { AiOutlineQuestion } from "react-icons/ai";
import { useModalTemplate } from "../commons/ModalTemplate";
import {
  ModalContactSupport,
  ModalFAQ,
  ModalHBW,
  ModalLanguage,
  ModalWWA,
} from "../commons/Modals";
import { Divider } from "../commons";
import { IoChevronForward } from "react-icons/io5";

const SupportComp = () => {
  const { t } = useTranslate();
  const modalContactSupport = useModalTemplate();
  const modalWWA = useModalTemplate();
  const modalHBW = useModalTemplate();
  const modalFAQ = useModalTemplate();
  const modalLang = useModalTemplate();
  const history = useHistory();
  return (
    <div>
      <p className="m-0 text-4xl text-center w-full text-nebulaOpac tracking-wider p-5">
        {t("Support")}
      </p>
      <Divider px="5" />
      <Item
        // icon={<AiOutlineQuestion className="text-3xl" />}
        label="Frequent Questions"
        onClick={() => modalFAQ.open()}
      />
      <Divider px="5" />
      <Item
        // icon={<AiOutlineQuestion className="text-3xl" />}
        label="Contact Support"
        onClick={() => modalContactSupport.open()}
      />
      <Divider px="5" />
      <Item
        // icon={<AiOutlineQuestion className="text-3xl" />}
        label="Who we are"
        onClick={() => modalWWA.open()}
      />
      <Divider px="5" />
      <Item
        // icon={<AiOutlineQuestion className="text-3xl" />}
        label="How Bbop works"
        onClick={() => modalHBW.open()}
      />
      <Divider px="5" />
      <Item
        // icon={<BiDonateHeart className="text-3xl" />}
        label="Donate"
        onClick={() => history("/donate")}
      />
      <Divider px="5" />
      <Item
        // icon={<BiDonateHeart className="text-3xl" />}
        label="Language"
        onClick={() => modalLang.open()}
      />
      <Divider px="5" />
      <ModalContactSupport {...modalContactSupport} />
      <ModalWWA {...modalWWA} />
      <ModalHBW {...modalHBW} />
      <ModalFAQ {...modalFAQ} />
      <ModalLanguage {...modalLang} />
    </div>
  );
};

export default SupportComp;

const Item = ({ icon, label, onClick }) => {
  const { t } = useTranslate();
  return (
    <div
      className="w-full flex items-center justify-between text-nebula px-8 py-4"
      onClick={onClick}
    >
      <div className="flex items-center gap-5">
        <p className="text-xl">{t(label)}</p>
      </div>
      <IoChevronForward className="text-3xl" />
    </div>
  );
};
