import React, { useMemo } from "react";
import { useWriteContract, useAccount } from "wagmi";
import useGlobalComp from "../../hooks/useGlobalComp";
import useCurrentNetwork from "./useCurrentNetwork";
import useConfirmTx from "./useConfirmTx";
import useCheckSession from "../../hooks/useCheckSession";

const useContract = (address, method, args, callback, decodedReceipt) => {
  const currentNetwork = useCurrentNetwork();
  const { data, writeContract } = useWriteContract();
  const { loadContractInteraction } = useGlobalComp();
  const { checkSession } = useCheckSession();

  const { confirm, loading } = useConfirmTx(
    data,
    async (r) => {
      if (callback) await callback(r);
      loadContractInteraction(false);
    },
    decodedReceipt
  );

  const writeContractConfig = useMemo(
    () => ({
      abi: currentNetwork?.abi,
      address: address,
      functionName: method,
      args,
    }),
    [currentNetwork, args, method]
  );

  const onError = (err) => {
    // setError(err?.message);
    loadContractInteraction(false);
    throw err;
  };

  const execute = async () => {
    if (!args) return;
    const v = await checkSession();
    if (!v) return;
    loadContractInteraction(true);
    writeContract(writeContractConfig, { onError });
  };

  return { confirm, loading, execute };
};

export default useContract;

export const useFactory = (address, args, callback, decodedReceipt) => {
  const currentNetwork = useCurrentNetwork();
  const { data, writeContract } = useWriteContract();
  const { loadContractInteraction } = useGlobalComp();
  const { checkSession } = useCheckSession();
  const { isConnected } = useAccount();

  const { confirm, loading } = useConfirmTx(
    data,
    async (r) => {
      try {
        if (callback) await callback(r);
        loadContractInteraction(false);
      } catch (err) {
        loadContractInteraction(false);
      }
    },
    decodedReceipt
  );

  const writeContractConfig = useMemo(() => {
    if (!currentNetwork) return;
    if (!isConnected) return;
    const res = {
      abi: currentNetwork?.factory?.abi,
      address: currentNetwork?.factory?.address,
      functionName: "deployNewHomeSharingForNewHost",
      args,
      // maxPriorityFeePerGas: null,
      // maxFeePerGas: null,
    };
    if (currentNetwork.currency_abi.gasLimit) {
      res.gasLimit = currentNetwork.currency_abi.gasLimit;
    }
    return res;
  }, [currentNetwork, args, address, isConnected]);

  const onError = (err) => {
    // setError(err?.message);
    loadContractInteraction(false);
    throw err;
  };

  const execute = async () => {
    if (!args) return;
    const v = await checkSession();
    if (!v) return;
    loadContractInteraction(true);
    writeContract(writeContractConfig, { onError });
  };

  return { confirm, loading, execute };
};

export const useSendCrypto = (callback, decodedReceipt) => {
  const { data, writeContract } = useWriteContract();
  const { loadContractInteraction } = useGlobalComp();
  const { isConnected } = useAccount();

  const { confirm, loading, setConfirm } = useConfirmTx(
    data,
    async (r) => {
      try {
        if (callback) await callback(r);
        loadContractInteraction(false);
      } catch (err) {
        loadContractInteraction(false);
      }
    },
    decodedReceipt
  );

  const onError = (err) => {
    // console.log("ERROR", err);
    loadContractInteraction(false);
    throw err;
  };

  const execute = async (to, value, abi) => {
    const writeContractConfig = {
      abi: abi?.abi,
      address: abi?.address,
      functionName: "transfer",
      args: [to, value],
    };
    if (abi?.gasLimit) {
      writeContractConfig.gasLimit = abi?.gasLimit;
    }
    if (!abi) return;
    if (!writeContractConfig) return;
    if (!isConnected) return;
    loadContractInteraction(true);
    writeContract(writeContractConfig, { onError });
  };

  return { confirm, loading, execute, setConfirm };
};
