import useAxios, { mapConfig } from "./useAxios";

const useMessages = () => {
  const { handleAxios } = useAxios();

  const send = async ({
    from,
    to,
    content,
    postFetch,
    listingId,
    guest_receiver_status,
    host_receiver_status,
    guest_id,
  }) => {
    const body = {
      listing_id: Number(listingId),
      content,
      send_by_userid: from,
      send_to_userid: to,
      host_receiver_status,
      guest_receiver_status,
      guest_id,
    };
    handleAxios(
      mapConfig("/messages/replyMessage", "post", {
        body,
        postFetch: (res) => {
          if (postFetch) {
            postFetch(res);
          }
        },
        toast: false,
      })
    );
  };

  return { send };
};

export default useMessages;
