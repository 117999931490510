import React, { useEffect, useMemo, useRef } from "react";
import {
  AvailableNetworks,
  BackButton,
  Divider,
  EditGuests,
  FavButtonDetails,
  InfoToModal,
  ShowReviews,
  ShowUser,
  Slider,
  StarRating,
} from "../commons";
import {
  useCalculatePrice,
  useCalculateSpecialPrice,
  useGetData,
  useHistory,
  useQuery,
  useTranslate,
  useUserState,
} from "../../hooks";
import ReserveButton from "./ReserveButton";
import ShowAmenities from "./ShowAmenities";
import ShowCancelationPolicy from "./ShowCancelationPolicy";
import CustomMarker from "../../../features/admin/common/CustomMarker";
import ShowCalendar from "./ShowCalendar";
import { parseISO } from "date-fns";
import ShowRooms from "./ShowRooms";
import GMapListing from "../../../features/admin/common/GMapListing";

const ListingComp = () => {
  const { userStore } = useUserState();
  const { query, changeQuery } = useQuery(["dates", "id", "guests"]);
  const { t } = useTranslate();

  const listing = useGetData(
    `/listing/getListingById?id=${query?.id}&review=true${
      userStore?.id ? `&userId=${userStore?.id}` : ""
    }`,
    false
  );

  const dates = useMemo(() => {
    if (!query?.dates) return;
    return {
      start: new Date(query?.dates.startDate),
      end: new Date(query?.dates.endDate),
    };
  }, [query?.dates]);

  const { specialPriceDays, specialPriceDates } = useCalculateSpecialPrice({
    start: dates?.start,
    end: dates?.end,
    listing,
  });

  const { subTotal, days } = useCalculatePrice({
    dates,
    config: false,
    listing: listing,
    specialPriceDays,
  });

  const disableDatesAviabilities = useMemo(
    () =>
      listing?.listingAviability?.map((item) => ({
        start: parseISO(item.not_available_from).toISOString().slice(0, -1),
        end: parseISO(item.not_available_to).toISOString().slice(0, -1),
      })),
    [listing]
  );

  const disableDatesBookings = useMemo(
    () =>
      listing?.listingBookings?.map((item) => ({
        start: parseISO(item.start).toISOString().slice(0, -1),
        end: parseISO(item.end).toISOString().slice(0, -1),
      })),
    [listing]
  );

  const isYourListing = useMemo(
    () => listing?.user_id === userStore?.id,
    [listing?.user_id, userStore?.id]
  );

  const guestsInitialValue = useMemo(() => {
    if (!listing?.guests) return;
    return Object.keys(listing?.guests).reduce(
      (a, v) => ({ ...a, [v]: 0 }),
      {}
    );
  }, [listing?.guests]);

  const history = useHistory();

  useEffect(() => {
    if (!query?.id) {
      history("/explore");
    }
  }, [query?.id]);

  const refPickDate = useRef();

  if (!listing) return <></>;

  return (
    <div className="w-full">
      <div className="fixed z-[1000] flex justify-start md:max-w-[700px] md:w-full items-center top-[0.5em] left-[0.5em] md:left-[45%] md:-translate-x-[50%]">
        <BackButton className="text-2xl cursor-pointer border p-[0.4em] border-buttonDark rounded-full bg-nebula text-buttonDark" />
      </div>
      <div className="fixed z-[1000] top-[0.5em] flex justify-end items-center right-[0.5em] md:right-[45%] md:translate-x-[50%] md:max-w-[700px] md:w-full">
        <div className="p-[0.4em] flex justify-center w-fit items-center rounded-full bg-nebula">
          <FavButtonDetails listing={listing} />
        </div>
      </div>
      <Slider
        data={listing?.listingImages || []}
        arrows={listing?.listingImages?.length > 1}
        classNameImage="max-w-[900px] w-full md:h-[32em] h-[18em] md:rounded-br-lg md:rounded-bl-lg"
      />
      <div className="flex flex-col items-start justify-start p-2">
        <span className="text-nebulaOpac text-4xl pl-3">{listing?.name}</span>
        <div className="px-2 pt-8">
          <span className="text-nebulaOpac text-lg px-1">{`${t("Listing in")} ${
            listing?.listing_location?.city
          }, ${listing?.listing_location?.country}`}</span>
          {!listing?.average_rating && (
            <p className="text-nebulaOpac text-lg px-1 opacity-40 m-0">{`${t(
              "This place doesn't have reviews"
            )} `}</p>
          )}
          {isYourListing && (
            <p className="text-nebulaOpac text-lg px-1 opacity-40 m-0">{`${t(
              "This listing is yours"
            )} `}</p>
          )}
          <StarRating
            routeRating={listing?.average_rating}
            disabled={true}
            showRating={true}
            totalReviews={listing?.reviews?.total || 0}
          />
        </div>
        <Divider />
        <ShowAmenities amenities={listing?.amenities} />
        <Divider />
        <ShowUser
          user={listing?.user}
          type={"big"}
          contactHost={true}
          title="Your Host"
          listing={listing}
        />
        <Divider />
        <InfoToModal
          info={listing?.description}
          title={"Description"}
          showLength={250}
          maxLength={255}
        />
        <Divider />
        <ShowRooms rooms={listing?.rooms} />
        <div className="p-5 w-full">
          <p className="text-nebula text-3xl font-medium pb-5 text-left">
            {t("Guests")}
          </p>
          <EditGuests
            guests={query?.guests || guestsInitialValue}
            onChange={(v) => changeQuery({ guests: v })}
            max={listing?.guests}
          />
        </div>
        <Divider />
        <div className="w-full" ref={refPickDate}>
          <ShowCalendar
            listing={listing}
            specialPriceDates={specialPriceDates}
            title={`${t("Stay")} ${
              !days
                ? t("1 night")
                : days > 1
                ? `${days} ${t("nights")}`
                : `${days} ${t("nights")}`
            }`}
            disableDates={
              disableDatesBookings && disableDatesAviabilities
                ? [...disableDatesBookings, ...disableDatesAviabilities]
                : []
            }
          />
        </div>
        <Divider />
        <AvailableNetworks contracts={listing?.user?.contract_address} />
        <Divider />
        <span className="text-nebula text-3xl font-medium pb-5 text-left pl-5">
          {t("This Place Location")}
        </span>
        <div className="w-full h-[30em] md:h-[35em] relative">
          <div className="flex justify-center w-full h-full">
            <GMapListing
              id="map-details"
              coordinates={{
                lat: Number(listing?.listing_location?.lat),
                lng: Number(listing?.listing_location?.lng),
              }}
              zoom={14}
            >
              <CustomMarker
                markerCoordinates={{
                  lat: Number(listing?.listing_location?.lat),
                  lng: Number(listing?.listing_location?.lng),
                }}
                item={listing}
                extraInfo={false}
              />
            </GMapListing>
          </div>
        </div>
        {listing?.reviews && <Divider />}
        <ShowReviews
          reviews={listing?.reviews}
          id={listing?.user?.id}
          averageRating={listing?.average_rating}
        />
        <Divider />
        <ShowCancelationPolicy
          policy={listing?.listing_cancellation_policies}
        />
        {listing?.rules_and_conditions && (
          <>
            <Divider />
            <InfoToModal
              info={listing?.rules_and_conditions}
              title={"House Rules"}
              className="opacity-50"
              showLength={250}
              maxLength={255}
            />
          </>
        )}
      </div>
      <ReserveButton
        price={subTotal}
        dates={dates}
        listing={listing}
        onClickNoDates={() => {
          refPickDate.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }}
        disableDates={
          disableDatesBookings && disableDatesAviabilities
            ? [...disableDatesBookings, ...disableDatesAviabilities]
            : []
        }
      />
    </div>
  );
};

export default ListingComp;
