import React, { useMemo } from "react";
import { NOTIFICATIONS, STATUS, TYPES } from "./NotificationsGuide";
import useTranslate from "../../mobile/hooks/useTranslate";
import { format, set } from "date-fns";
import { IoMdDoneAll, IoMdExit } from "react-icons/io";
import { HiOutlineIdentification } from "react-icons/hi";
import NotificationPreMade from "./NotificationPreMade";
import useUserState from "../../mobile/hooks/useUserState";
import { GiPartyPopper } from "react-icons/gi";
import { AiOutlineClockCircle } from "react-icons/ai";
import { RiUserSearchFill } from "react-icons/ri";
import { MdOutlineCancel } from "react-icons/md";
import { IoHome } from "react-icons/io5";
import { FaBomb, FaExchangeAlt } from "react-icons/fa";
import useHistory from "../../mobile/hooks/useHistory";

const NotificationItem = ({
  notification_type,
  status,
  info,
  created_at,
  onHide,
}) => {
  const { t } = useTranslate();
  const setup = useMemo(
    () => NOTIFICATIONS[notification_type.name],
    [notification_type]
  );

  const { user } = useUserState();
  const history = useHistory();

  const infoObj = useMemo(() => (info ? JSON.parse(info) : {}), [info]);

  const goTo = (path) => {
    onHide();
    history(path);
  };

  const imSendBy = useMemo(
    () => infoObj?.send_by_id === user?.id,
    [infoObj?.send_by_id, user?.id]
  );

  return (
    <>
      {setup?.type === TYPES.GENERAL && (
        <NotificationWrapper status={status} time={created_at}>
          <NotificationPreMade
            type={"simple"}
            title={t(infoObj?.title)}
            content={t(infoObj?.content)}
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.BOOKING_CREATED && imSendBy && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/booking?id=${infoObj?.id}`)}
        >
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title2)}
            icon={
              <IoMdDoneAll className="w-[3em] h-[3em] text-lighGreen mr-2" />
            }
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.BOOKING_CREATED && !imSendBy && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/booking?id=${infoObj?.id}`)}
        >
          <NotificationPreMade
            type={"user"}
            goTo={goTo}
            info={infoObj}
            setup={setup}
            img={infoObj?.img}
            title={t(setup?.title)}
            content={
              !imSendBy ? `${t(setup?.content)} ${infoObj?.send_by_name}` : ""
            }
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.BOOKING_SIGN && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/booking?id=${infoObj?.id}`)}
        >
          <NotificationPreMade
            type={"user"}
            goTo={goTo}
            img={infoObj?.igm}
            info={infoObj}
            title={t(setup?.title)}
            content={`${t(setup?.content)} ${infoObj?.send_by_name}`}
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.BOOKING_PAYOUT && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/booking?id=${infoObj?.id}`)}
        >
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={
              <GiPartyPopper className="w-[3em] h-[3em] text-lighGreen mr-2" />
            }
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.BOOKING_CANCEL && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/booking?id=${infoObj?.id}`)}
        >
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={
              <MdOutlineCancel className="w-[3em] h-[3em] text-cancel mr-2" />
            }
          />
        </NotificationWrapper>
      )}

      {setup?.type === TYPES.KYC && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/kyc`)}
        >
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={
              <HiOutlineIdentification className="w-[3em] h-[3em] text-lighGreen mr-2" />
            }
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.LISTING_PRE_CONTRACT && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/listings`)}
        >
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={
              <AiOutlineClockCircle className="w-[3em] h-[3em] text-[#507306] mr-2" />
            }
          />
        </NotificationWrapper>
      )}

      {/* NEW */}
      {setup?.type === TYPES.MARKETING && (
        <NotificationWrapper status={status} time={created_at}>
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.MESSAGE && (
        <NotificationWrapper status={status} time={created_at}>
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.USER_KYC_FILES_REJECTED && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/kyc`)}
        >
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={
              <HiOutlineIdentification className="w-[3em] h-[3em] text-cancel mr-2" />
            }
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.USER_REJECTED && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/settings`)}
        >
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={
              <HiOutlineIdentification className="w-[3em] h-[3em] text-cancel mr-2" />
            }
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.USER_VALIDATED && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/settings`)}
        >
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={
              <RiUserSearchFill className="w-[3em] h-[3em] text-check mr-2" />
            }
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.LISTING_REJECTED && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/listings`)}
        >
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={<IoHome className="w-[3em] h-[3em] text-cancel mr-2" />}
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.LISTING_BILLING_REJECTED && (
        <NotificationWrapper status={status} time={created_at}>
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={<IoHome className="w-[3em] h-[3em] text-cancel mr-2" />}
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.LISTING_VALIDATED && (
        <NotificationWrapper status={status} time={created_at}>
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={<IoHome className="w-[3em] h-[3em] text-check mr-2" />}
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.DONATION_GRATITUDE && (
        <NotificationWrapper status={status} time={created_at}>
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={
              <GiPartyPopper className="w-[3em] h-[3em] text-lighGreen mr-2" />
            }
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.DISPUTE_STARTED && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/dispute?id=${infoObj?.id}`)}
        >
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={<FaBomb className="w-[3em] h-[3em] text-lighGreen mr-2" />}
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.DISPUTE_SIGNED && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/dispute?id=${infoObj?.id}`)}
        >
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={<FaBomb className="w-[3em] h-[3em] text-check mr-2" />}
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.DISPUTE_COMPLETED && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/dispute?id=${infoObj?.id}`)}
        >
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={
              <GiPartyPopper className="w-[3em] h-[3em] text-lighGreen mr-2" />
            }
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.OFFER_CREATED && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/dispute?id=${infoObj?.id}`)}
        >
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={
              <FaExchangeAlt className="w-[3em] h-[3em] text-lighGreen mr-2" />
            }
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.DISPUTE_SOLVING_BY_COSTUMER_SERVICE && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/dispute?id=${infoObj?.id}`)}
        >
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={<FaBomb className="w-[3em] h-[3em] text-yellowBbop mr-2" />}
          />
        </NotificationWrapper>
      )}
      {setup?.type === TYPES.DISPUTE_SIGNED_BY_ADMIN && (
        <NotificationWrapper
          status={status}
          time={created_at}
          action={() => goTo(`/dispute?id=${infoObj?.id}`)}
        >
          <NotificationPreMade
            type={"simple"}
            title={t(setup?.title)}
            content={t(setup?.content)}
            icon={
              <GiPartyPopper className="w-[3em] h-[3em] text-lighGreen mr-2" />
            }
          />
        </NotificationWrapper>
      )}
    </>
  );
};

export default NotificationItem;

const TimesStamp = ({ time }) => {
  if (!time) return <></>;
  return (
    <span className="text-nebula text-sm opacity-40 absolute bottom-1 right-1">
      {format(new Date(time), "yyyy/MM/dd - H:mm")}
    </span>
  );
};

const NotificationWrapper = ({ status, time, action, children }) => {
  return (
    <div className="border border-nebula flex flex-col rounded-xl py-2 px-4 relative h-[6em]">
      {children}
      {status === STATUS.UNREAD && (
        <div className="w-[1em] h-[1em] bg-blueLight rounded-full absolute top-1 right-1"></div>
      )}
      {time && <TimesStamp time={time} />}
      {action && (
        <IoMdExit
          className="cursor-pointer absolute right-1 bottom-[2em] w-[2em] h-[2em] text-nebula"
          onClick={action}
        />
      )}
    </div>
  );
};
