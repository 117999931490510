import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setUserAction } from "../app/actions";
// import { useConnector } from "../web3";
import useToast from "./useToast";
import useTranslate, { useSetTranslate } from "../mobile/hooks/useTranslate";
import useUserState from "../mobile/hooks/useUserState";
// import useWalletAuth from "../mobile/hooks/useWalletAuth";
import useSocket from "./useSocket";
import useChainId from "./useChainId";

const NoEthereumProviderError = "NoEthereumProviderError";

export const useLogin = ({
  setIsSaveNewWallet,
  isSaveNewWallet,
  onClose,
  isFromWallet,
  isFromEditProfile,
}) => {
  const [loading, setLoading] = useState(false);
  // const { saveWallet } = useWalletAuth();
  const [user, setUser] = useState();
  const { active, activate, deactivate, account, error, library } =
    useWeb3React();
  const { userState, changeUserStateUser, userStore } = useUserState();

  const { setTranslate } = useSetTranslate();

  const { notify } = useToast([
    {
      position: "top-center",
      type: "success",
      key: "s",
    },
    {
      position: "top-center",
      type: "error",
      key: "e",
    },
  ]);

  const dispatch = useDispatch();

  const isUnsupportedChain = error instanceof UnsupportedChainIdError;

  // const connector = useConnector();

  // const connect = useCallback(
  //   async (saveNewWallet, callback) => {
  //     try {
  //       if (!connector) {
  //         return;
  //       }
  //       if (saveNewWallet) {
  //         if (setIsSaveNewWallet) setIsSaveNewWallet(true);
  //         await activate(connector);
  //         if (callback) callback();
  //       } else {
  //         await activate(connector);
  //         if (callback) callback();
  //         localStorage.setItem("previouslyConnected", "true");
  //       }
  //       return true;
  //     } catch (err) {
  //       throw err;
  //     }
  //   },
  //   [activate, connector]
  // );

  const { t } = useTranslate();

  const noProvider = useMemo(
    () => error?.name === NoEthereumProviderError,
    [error?.name, NoEthereumProviderError]
  );

  const chain = useChainId();

  // const saveNewWallet = async () => {
  //   try {
  //     const token =
  //       isFromWallet || isFromEditProfile ? userState?.jwt : user?.token;
  //     const address = { address: account, chainId: chain };
  //     // await saveWallet(address, token, (res) => {
  //     //   onClose();
  //     //   if (!userStore?.address) {
  //     //     changeUserStateUser({
  //     //       address: { ...res?.data },
  //     //     });
  //     //     notify.s(res.message);
  //     //     return;
  //     //   }
  //     //   changeUserStateUser({
  //     //     address: { ...userStore?.address, ...res?.data },
  //     //   });
  //     //   notify.s(res.message);
  //     // });
  //     localStorage.setItem("previouslyConnected", "true");
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  // useEffect(() => {
  //   if (account && isSaveNewWallet) {
  //     saveNewWallet();
  //     setIsSaveNewWallet(false);
  //   }
  // }, [account, isSaveNewWallet]);

  const { connect: connectSocket } = useSocket();

  const continueLogin = (res, toast) => {
    setLoading(false);
    dispatch(setUserAction(res.data));
    connectSocket("noti", res?.data?.user?.id, res?.data?.token);
    if (toast) notify.s("Log in success");
    setTranslate(res?.data?.user?.id);
    setUser(res.data);
  };

  return {
    // connect,
    continueLogin,
    loading,
    active,
    user,
    account,
    error,
    library,
    deactivate,
    isUnsupportedChain,
    noProvider,
    isTest: user?.white_list,
  };
};
