import { useMemo } from "react";
import { useSelector } from "react-redux";

// const NETWORK_URLS = {
//   DEV: "https://testnet.bscscan.com",
//   PROD: "https://bscscan.com",
// };

const useNetworkUrl = (chainId) => {
  const wallets = useSelector((state) => state.wallets);

  const url = useMemo(
    () =>
      !chainId
        ? ""
        : wallets[Number(chainId)]?.params.add_chain.params[0]
            .blockExplorerUrls[0],
    [wallets, chainId]
  );
  return url;
};

export default useNetworkUrl;
