import React from "react";

const Divider = ({
  color = "blueContrast",
  opacity = false,
  px = "3",
  py = "5",
  direction = "x",
}) => {
  return (
    <div
      className={`py-${py} ${
        direction === "x" ? "w-full" : "w-fit h-full"
      } px-${px}`}
    >
      <div
        className={`bg-${color} ${
          direction === "x" ? "h-[2px]" : "w-[2px] h-full"
        } ${opacity ? "opacity-10" : ""}`}
      ></div>
    </div>
  );
};

export default Divider;
