import React from "react";
import styled from "styled-components";
import { thumbHeight } from "./constants";

function Handle({
  domain: [min, max],
  handle: { id, value, percent },
  isDragging,
  handleId,
  getHandleProps,
}) {
  return (
    <HandleStyled
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        left: `${percent}%`,
        width:
          handleId === id && isDragging
            ? `${thumbHeight * 1.2}px`
            : `${thumbHeight}px`,
        height:
          handleId === id && isDragging
            ? `${thumbHeight * 1.2}px`
            : `${thumbHeight}px`,
      }}
      {...getHandleProps(id)}
    />
  );
}

const HandleStyled = styled.div`
  background-color: #1c2c49;
  border: solid 0.1px rgba(255, 255, 255, 0.3);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  white-space: nowrap;
  position: absolute;
  z-index: 2;
  transform: translate(-50%, -50%);
  transition: width 0.1s ease-out, height 0.2s ease-out;
  cursor: pointer;
`;

export default Handle;
