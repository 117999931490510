import React from "react";
import styled from "styled-components";
import useLoadAllData from "./../../../../../hooks/useLoadAllData";
import LoadingWrapper from "./../../../commons/LoadingWrapper";
import { useTranslate } from "../../../../hooks";
import SelectAccordion from "./SelectAccordion";

function Information({ listing, setListing }) {
  const { data: policies, ...props } = useLoadAllData("policy/get");

  const handleSecurityDeposit = (e) => {
    setListing({ ...listing, security_deposit: e.target.value });
  };

  const handleCancelationPolicy = (id) => {
    setListing({ ...listing, cancellation_policy: id });
  };

  return (
    <InformationStyled className="h-full px-6">
      <div className="deposit-wrapper">
        <label htmlFor="security_deposit" className="title">
          Security Deposit ($)
        </label>
        <input
          type="number"
          placeholder="0"
          autoComplete="off"
          name="security_deposit"
          id="security_deposit"
          min={0}
          value={listing.security_deposit}
          onChange={handleSecurityDeposit}
        />
      </div>
      <div className="policy-wrapper">
        <div className="title">Cancellation Policies</div>
        <LoadingWrapper
          {...props}
          style={{
            flexGrow: 1,
            overflowY: "auto",
          }}
        >
          <SelectAccordion
            items={policies}
            selected={listing.cancellation_policy}
            select={handleCancelationPolicy}
          />
        </LoadingWrapper>
      </div>
    </InformationStyled>
  );
}

const InformationStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
  overflow: auto;

  label {
    display: block;
  }
  input,
  textarea {
    width: 100%;
    padding: 12px 18px;
    border-radius: 14px;
    border: none;
    background-color: #1c2641;
    outline: none;
    overflow: overlay;
  }
  textarea {
    resize: none;
    min-height: 100px;
  }
`;

export default Information;
