import React from "react";
import { useTranslate } from "../../hooks";
import { useTruncatedAddressFunction } from "../../../hooks/useTruncatedAddress";

const WrongAddress = ({ correct, wrong, className = "" }) => {
  const { t } = useTranslate();
  const handleTruncated = useTruncatedAddressFunction();
  return (
    <div className={`w-full ${className}`}>
      <p className="text-nebulaOpac w-full text-sm text-center px-1 opacity-70 m-0">
        {`${t("You are connected with")} `}{" "}
        <strong>{`${handleTruncated(wrong)}`}</strong>
      </p>
      <p className="text-nebulaOpac w-full text-sm px-1 text-center opacity-70 m-0">
        {`${t("You need to sign with")} `}{" "}
        <strong>{`${handleTruncated(correct)}`}</strong>
      </p>
    </div>
  );
};

export default WrongAddress;
