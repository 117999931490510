import React, { memo } from "react";
import styled from "styled-components";
import RangeSlider from "./RangeSlider";

function PriceRange({ prices, setFilterPrices }) {
  return (
    <PriceRangeStyled className="px-5">
      <h2 className="headline">Price range</h2>
      <p>Prices per night excluding taxes and fees</p>
      <RangeSlider
        setFilterPrices={setFilterPrices}
        data={prices.map(Number)}
      />
      <div className="separator" />
    </PriceRangeStyled>
  );
}

const PriceRangeStyled = styled.div`
  p {
    margin-bottom: 25px;
  }
`;

export default memo(PriceRange);
