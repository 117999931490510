import React, { useState, useEffect } from "react";
import useTranslate from "../../hooks/useTranslate";

export default function StarRating({
  className = "",
  routeRating = 0,
  disabled = true,
  classNameStars = "text-3xl",
  editFromParent,
  ratingType,
  showRating = false,
  totalReviews,
  oneStar = false,
  classNameReviews = "",
  classNameRating = "",
  circlePadding = "2",
  bgStar,
  date,
  starSize,
}) {
  const [rating, setRating] = useState(routeRating);
  const [hover, setHover] = useState(0);
  const { t } = useTranslate();

  useEffect(() => {
    if (rating !== routeRating && routeRating) {
      setRating(routeRating);
    }
    if (editFromParent?.rating && editFromParent?.setRating) {
      setRating(editFromParent?.rating);
    }
  }, [routeRating, editFromParent]);

  if (!routeRating && !editFromParent) return <></>;

  return (
    <div className={`star-rating ${className} flex items-center`}>
      {ratingType && <span className="rating-type">{ratingType}</span>}
      {[...Array(oneStar ? 1 : 5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            disabled={disabled}
            className={`transition-all duration-300
          ${
            oneStar
              ? `text-[${bgStar?.fill ? bgStar?.fill : "#097F9A"}]`
              : !disabled
              ? index <= hover
                ? `text-[${bgStar?.fill ? bgStar?.fill : "#097F9A"}]`
                : index <= rating
                ? `text-[${bgStar?.fill ? bgStar?.fill : "#097F9A"}]`
                : `text-${bgStar?.empty ? bgStar?.empty : "nebulaOpac"}`
              : index <= rating
              ? `text-[${bgStar?.fill ? bgStar?.fill : "#097F9A"}]`
              : `text-${bgStar?.empty ? bgStar?.empty : "nebulaOpac"}`
          } textBorderBlue
          `}
            onClick={() =>
              editFromParent
                ? editFromParent.setRating(index)
                : setRating(index)
            }
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(0)}
          >
            <span className={`${classNameStars}`}>&#9733;</span>
          </button>
        );
      })}
      {showRating && (
        <span className={`text-nebulaOpac pl-2 ${classNameRating}`}>
          {routeRating?.toFixed(1)}
        </span>
      )}

      {totalReviews && (
        <span
          className={`text-nebulaOpac pl-${circlePadding} flex items-center ${classNameReviews}`}
        >
          <div
            className={`h-[0.4em] w-[0.4em] bg-nebula rounded-full mr-${circlePadding}`}
          ></div>
          {`${totalReviews} ${t("reviews")}`}
        </span>
      )}
      {date && (
        <span
          className={`text-nebulaOpac pl-${circlePadding} flex items-center ${classNameReviews}`}
        >
          <div
            className={`h-[0.4em] w-[0.4em] bg-nebula rounded-full mr-${circlePadding}`}
          ></div>
          {date}
        </span>
      )}
    </div>
  );
}
