import React from "react";
import useSimpleAxios from "../../../../hooks/useSimpleAxios";
import useAxios, { mapConfig } from "../../../hooks/useAxios";
import { useTranslate, useUserState } from "../../../hooks";
import { useStore } from "../../../hooks/useStore";
import LanguageModal from "../../../../commons/Language/LanguageModal";

const ModalLanguage = ({ Modal, open, ...modalPropsRest }) => {
  const { handleAxios } = useAxios();
  const { value } = useSimpleAxios(mapConfig("/lang/getLang", "get"));
  const { store, jwt } = useUserState();
  const { lang, setLang } = useStore();
  const { t } = useTranslate();

  const handleOnClick = (langSelected) => {
    modalPropsRest.close();
    if (jwt) {
      handleAxios(
        mapConfig(`/users/updateUserLang?lang=${langSelected}`, "put", {
          postFetch: (res) => {
            setLang(langSelected);
          },
        })
      );
    } else {
      setLang(langSelected);
    }
  };
  return (
    <Modal
      id="language-modal"
      bodyScroll={false}
      defaultCloseButton
      transitionType="botToTop"
      type="largeBox"
      title={t("Languages")}
      modalHeight={"50%"}
      {...modalPropsRest}
    >
      <LanguageModal
        languages={value}
        onClose={() => modalPropsRest.close()}
        handleClick={handleOnClick}
        selected={lang}
      />
    </Modal>
  );
};

export default ModalLanguage;
