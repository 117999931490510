import React, { useEffect, useMemo } from "react";
import { AiOutlineQuestion } from "react-icons/ai";
import { IoMdSearch } from "react-icons/io";
import {
  IoBusinessOutline,
  IoHomeOutline,
  IoMenu,
  IoNotificationsOutline,
  IoPersonOutline,
} from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { ROLES } from "../const/roles";
import {
  useHistory,
  useTranslate,
  useUserState,
  useWalletAuth,
} from "../hooks";
import { useStore } from "../hooks/useStore";
import { FiMessageSquare } from "react-icons/fi";
import useGetNotReadNotis from "../hooks/useGetNotReadNotis";

// const disablePaths = ["/listing", "/reserve", "/search", "/edit/listing"];
const disablePaths = ["/search", "/chat"];

const FooterNavbar = () => {
  const { isLogged, userStore } = useUserState();
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslate();
  const { notRead, msgNotRead } = useStore();
  const { getNotReadNotis } = useGetNotReadNotis();

  const { getWallets } = useWalletAuth();

  useEffect(() => {
    getWallets();
  }, []);

  useEffect(() => {
    if (pathname === "/msg") return;
    if (pathname === "/chat") return;
    if (pathname === "/notifications") return;
    getNotReadNotis();
  }, [window.location.href]);

  const icons = useMemo(() => {
    if (isLogged && userStore?.role === ROLES.GUEST) {
      return [
        // {
        //   label: "Favs",
        //   icon: <IoHeartOutline />,
        //   redirect: "/favorites",
        // },
        {
          // label: "Alerts",
          icon: <IoNotificationsOutline />,
          redirect: "/notifications",
          badge: notRead?.length,
        },
        {
          // label: "Chat",
          icon: <FiMessageSquare />,
          redirect: "/msg",
          badge: msgNotRead?.length,
        },
        {
          // label: "Books",
          icon: <IoHomeOutline />,
          redirect: "/bookings",
        },
        {
          // label: "Find",
          icon: <IoMdSearch />,
          redirect: "/explore",
        },
        {
          // label: "Profile",
          icon: <IoPersonOutline />,
          redirect: "/settings",
        },
      ];
    }
    if (isLogged && userStore?.role === ROLES.HOST) {
      return [
        {
          // label: "Alerts",
          icon: <IoNotificationsOutline />,
          redirect: "/notifications",
          badge: notRead?.length,
        },
        {
          // label: "Chat",
          icon: <FiMessageSquare />,
          redirect: "/msg",
          badge: msgNotRead?.length,
        },
        {
          // label: "Today",
          icon: <IoHomeOutline />,
          redirect: "/today",
        },
        {
          // label: "Lists",
          icon: <IoBusinessOutline />,
          redirect: "/listings",
        },
        // {
        //   label: "Favs",
        //   icon: <IoHeartOutline />,
        //   redirect: "/favorites",
        // },
        {
          // label: "Menu",
          icon: <IoMenu />,
          redirect: "/settings",
        },
      ];
    }
    return [
      {
        // label: "Support",
        icon: <AiOutlineQuestion />,
        redirect: "/support",
      },
      {
        // label: "Search",
        icon: <IoMdSearch />,
        redirect: "/explore",
      },
      {
        // label: "Login",
        icon: <IoPersonOutline />,
        redirect: "/",
      },
    ];
  }, [isLogged, userStore, notRead, msgNotRead]);

  if (disablePaths.includes(pathname)) {
    return <></>;
  }

  return (
    <div
      style={{ boxShadow: "0px -7px 30px rgb(15, 24, 41)", bottom: "-1px" }}
      className={`fixed z-[950] p-5 max-w-[700px] h-[5.5em] w-full rounded-tr-2xl rounded-tl-2xl bg-blueContrast flex ${
        isLogged ? "justify-between" : "justify-around"
      } items-center `}
    >
      {icons &&
        icons?.map((item) => {
          const isSelected = pathname === item.redirect;
          return (
            <div
              key={item.redirect}
              className={`w-fit text-nebula flex flex-col justify-center items-center hover:cursor-pointer ${
                item.badge ? "relative" : ""
              }`}
              onClick={() => {
                if (item.redirect) {
                  history(item.redirect);
                }
                if (item.action) {
                  item.action();
                }
              }}
            >
              <div
                style={{ transition: "font-size .5s" }}
                className={`${
                  isSelected ? "text-4xl opacity-100" : "text-3xl opacity-50"
                }`}
              >
                {item.icon}
              </div>
              {!!item.badge && (
                <div className="h-[1.5em] w-fit pb-[0.1em] pt-[0.2em] px-[0.5em] rounded-full flex items-center justify-center text-blueDark bg-lighGreen text-[0.7em] absolute -top-[10px] -right-[10px]">
                  <span className="">{item.badge}</span>
                </div>
              )}
              <span
                style={{ transition: "font-size .5s" }}
                className={`${
                  isSelected ? "text-sm opacity-100" : "text-xs opacity-50"
                } pt-1 text-nebulaOpac`}
              >
                {t(item.label)}
              </span>
            </div>
          );
        })}
    </div>
  );
};

export default FooterNavbar;
