import React from "react";
import { Main } from "../components";
import { EditListingComp } from "../components/editListing";

const EditListing = () => {
  return (
    <Main className="!pb-0">
      <EditListingComp />
    </Main>
  );
};

export default EditListing;
