import React, { useEffect, useMemo, useState } from "react";
import { useTranslate, useUserState } from "../../hooks";
import { Badges, ComponentTitle, ShowMoreButton } from "../commons";
import Loading from "../../../commons/Loading";
import BookingCard from "../bookings/BookingCard";
import { ROLES } from "../../const";
import useAxiosPaginated from "../../../hooks/useAxiosPaginated";
import NoBookingCard from "../commons/NoBookingCard";

const STATUS_BOOKINGS = {
  ARRAVING_SOON: "as",
  TO_SING: "ts",
  CURRENTLY_STAYING: "cs",
  STAYS_ON_DISPUTE: "di",
  STAYS_FINISHED: "sf",
  CANCELLED: "ca",
};

const TodayComp = () => {
  const { userStore } = useUserState();
  const options = useMemo(
    () => [
      {
        id: "as",
        label:
          userStore?.role === ROLES.HOST ? "Arraving soon" : "Upcoming stays",
      },
      {
        id: "ts",
        label: "Sign to release",
      },
      {
        id: "cs",
        label: "Currently staying",
      },
      {
        id: "di",
        label: "Stays on dispute",
      },
      {
        id: "sf",
        label:
          userStore?.role === ROLES.HOST
            ? "Stays finished"
            : "Where you have been",
      },
      {
        id: "ca",
        label: "Cancelled",
      },
    ],
    [userStore?.role]
  );
  const [status, setStatus] = useState(options[0]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslate();

  const { call, value, next, hasMore, loadingMore } = useAxiosPaginated(() =>
    setLoading(false)
  );

  useEffect(() => {
    setLoading(true);
    call({
      path: `/booking/today`,
      method: "get",
      page_size: 5,
      toast: false,
      param: `&status=${status?.id}`,
    });
  }, [status]);

  return (
    <div className="text-nebula w-full">
      <ComponentTitle
        title={userStore?.role === ROLES.HOST ? "Today" : "Bookings"}
      />
      <Badges options={options} value={status} onSelect={(v) => setStatus(v)} />
      {!!loading && <Loading size={160} className="h-1/2" />}
      {value?.length === 0 && !loading && (
        <div className="px-5">
          <NoBookingCard
            title="No trips... yet!"
            message="Time to dust off your travel shoes and start exploring!"
            buttonText="Start Searching"
          />
        </div>
      )}
      {!loading && value && (
        <div className=" w-full flex flex-col gap-4 items-center justify-items-center px-5">
          {value.map(
            ({
              listing,
              start,
              end,
              status,
              id,
              user: guestUser,
              status_text,
            }) => {
              const { name, user: hostUser, listing_images } = listing ?? {};
              const { first_name } =
                userStore?.role === ROLES.GUEST ? guestUser : hostUser;
              const userText =
                userStore?.role === ROLES.HOST
                  ? `${t("The guest is")} ${first_name}`
                  : `${t("Hosted by")} ${first_name}`;
              const [firstImage] = listing_images ?? [];

              return (
                <BookingCard
                  key={id}
                  id={id}
                  title={name ?? ""}
                  startDate={start ?? ""}
                  endDate={end ?? ""}
                  user={userText ?? ""}
                  imageSrc={firstImage?.path ?? ""}
                  status={status ?? ""}
                  statusText={status_text}
                />
              );
            }
          )}
          <ShowMoreButton
            hasMore={
              status?.id === STATUS_BOOKINGS.TO_SING ||
              status?.id === STATUS_BOOKINGS.STAYS_ON_DISPUTE
                ? false
                : hasMore
            }
            callMore={next}
            loading={!!loadingMore}
          />
        </div>
      )}
    </div>
  );
};

export default TodayComp;
