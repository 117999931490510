import { add, format } from "date-fns";
import React, { useState } from "react";
import { DateRange } from "react-date-range";
import useDate from "../hooks/useDate";
import useToast from "../hooks/useToast";
import { useTranslate } from "../mobile/hooks";
const today = new Date();

export const RangeDatePicker = ({
  // setStartDate,
  // setEndDate,
  startDate,
  endDate,
  title,
  resetDates,
  maxDate = add(new Date(), { years: 2 }),
  // specialDates = [],
  direction = "horizontal",
  disableDates = [],
  scroll = { enabled: false },
  // enableMinDate = true,
  overlapMessages = "This date is unavailable",
  className = "",
  toaster = true,
  changeDates,
  checkTimes,
}) => {
  const { t } = useTranslate();
  const { notify } = useToast([
    {
      position: "top-center",
      type: "error",
      key: "e",
    },
  ]);
  const [isSecondPick, setIsSecondPick] = useState(0);

  const { handleOverlapDates, formatChecksInOut, tomorrow } = useDate();

  const onChangeRangeDatePicker = (ranges) => {
    const newIsSecondPick = isSecondPick === 2 ? 1 : isSecondPick + 1;
    setIsSecondPick(newIsSecondPick);
    let checkDates = true;
    let datesFormated = {
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    };
    if (checkTimes) {
      datesFormated = formatChecksInOut(
        checkTimes,
        ranges.selection.startDate,
        ranges.selection.endDate
      );
    }
    const rangesStartDate = format(
      new Date(datesFormated.startDate),
      "yyyy-MM-dd HH:mm"
    );
    const rangesEndDate = format(
      new Date(datesFormated.endDate),
      "yyyy-MM-dd HH:mm"
    );
    disableDates.forEach((item) => {
      if (
        handleOverlapDates(
          {
            selected: { start: rangesStartDate, end: rangesEndDate },
            events: { start: item.start, end: item.end },
          },
          "overlap"
        )
      ) {
        checkDates = false;

        return;
      }
    });
    if (checkDates) {
      changeDates(
        {
          startDate: datesFormated.startDate,
          endDate: datesFormated.endDate,
        },
        newIsSecondPick === 2
      );
    } else if (!checkDates && toaster) {
      notify.e(overlapMessages);
    }
  };

  const selectionRange = [
    {
      startDate,
      endDate,
      key: "selection",
    },
    ...disableDates?.map((item) => ({
      startDate: new Date(item?.start),
      endDate: new Date(item?.end),
      key: "selection2",
      disabled: true,
      autoFocus: false,
      color: "#a0a0a0",
    })),
  ];

  return (
    <>
      <div className={`${className}`}>
        {title && (
          <div className="flex justify-between items-end w-full text-nebulaOpac pb-5">
            <p className="justify-start px-2 text-3xl font-medium">{title}</p>
          </div>
        )}
        <DateRange
          minDate={tomorrow}
          moveRangeOnFirstSelection={false}
          editableDateInputs={true}
          direction={direction}
          scroll={scroll}
          ranges={selectionRange}
          onChange={onChangeRangeDatePicker}
          maxDate={maxDate}
        />
        {resetDates && (
          <p
            className="cursor-pointer text-nebulaOpac pt-5 underline underline-offset-4 w-full md:text-center text-left"
            onClick={() => resetDates()}
          >
            {t("Select Today")}
          </p>
        )}
      </div>
    </>
  );
};
