import React from "react";
import { ComponentTitle, Main } from "../components/commons";
import { ListingsComp } from "../components";

const Listings = () => {
  return (
    <Main className="px-2">
      <ComponentTitle title={"Your Listings"} />
      <ListingsComp />
    </Main>
  );
};

export default Listings;
