import { useMemo, useState } from "react";
import Button from "./Button";
import { BsChevronDown } from "react-icons/bs";
import { useWindowSize } from "react-use";
import { FaCircle, FaRegCircle } from "react-icons/fa";

const Accordion = ({
  text,
  icon,
  height = "20",
  children,
  className = "",
  classNameChildren = "",
}) => {
  const [isOpen, setIsOpen] = useState();
  return (
    <div
      className={`${className} flex flex-col items-end justify-center w-full`}
    >
      <Button
        className="flex w-fit py-2 px-5 rounded-full cursor-pointer"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {text && <p className="text-lg text-left flex font-medium">{text}</p>}
        {icon}
      </Button>
      <div
        className={`
            ${classNameChildren}
            w-full
            flex flex-col justify-center items-center
            transition-all duration-300
            ${!isOpen && `hidden`}
            `}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;

export const SimpleAccordion = ({
  children,
  title,
  height,
  openRoot,
  setOpenRoot,
  id,
  openIsFromRoot,
  className = "",
  titleClassName = "",
  select,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const { width } = useWindowSize();
  const finalOpen = useMemo(() => {
    if (!!openIsFromRoot && !!setOpenRoot) return openRoot;
    return open;
  }, [openRoot, openIsFromRoot, setOpenRoot, open]);

  const finalHeigth = useMemo(() => {
    if (height?.default && width < 900) return height?.default;
    if (height?.md && width > 900) return height?.md;
    return height;
  }, [height, width]);

  return (
    <div
      className={`w-full ${className} ${
        disabled ? "opacity-20" : ""
      } text-nebula`}
      id={id}
    >
      <div
        className={
          `w-full cursor-pointer ${titleClassName} flex items-center` +
          "hover:bg-blue-500 " +
          "transition-colors duration-1000 ease-in-out"
        }
        onClick={
          !!select
            ? null
            : () => {
                if (!!openIsFromRoot && !!setOpenRoot) {
                  setOpenRoot();
                  return;
                }
                setOpen(!open);
              }
        }
      >
        {select ? (
          <div className="w-full flex justify-between">
            <div
              className="text-lg font-normal"
              onClick={(e) => {
                e.stopPropagation();
                if (select) {
                  if (!!openIsFromRoot && !!setOpenRoot) {
                    setOpenRoot();
                    return;
                  }
                  setOpen(!open);
                }
              }}
            >
              {title}
            </div>
            <div className="flex items-center gap-5">
              <BsChevronDown
                onClick={(e) => {
                  e.stopPropagation();
                  if (select) {
                    if (!!openIsFromRoot && !!setOpenRoot) {
                      setOpenRoot();
                      return;
                    }
                    setOpen(!open);
                  }
                }}
                height={20}
                width={20}
                className={`ml-2 transition-all ${
                  finalOpen ? "rotate-180" : ""
                }`}
              />
              <div className="" onClick={select.onClick}>
                {select.isSelected ? <FaCircle /> : <FaRegCircle />}
              </div>
            </div>
          </div>
        ) : (
          <>
            {title}
            <BsChevronDown
              height={20}
              width={20}
              className={`ml-2 transition-all ${finalOpen ? "rotate-180" : ""}`}
            />
          </>
        )}
      </div>
      <div
        style={{
          height: finalOpen ? finalHeigth : "0",
          overflowY: finalOpen ? "scroll" : "hidden",
        }}
        className={
          "transition-all duration-1000 ease-in-out flex items-center scrollbarNone"
        }
      >
        {children}
      </div>
    </div>
  );
};
