import React, { forwardRef } from "react";
import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { useTranslate } from "../../../../hooks";

export const LargeBox = forwardRef(
  (
    {
      defaultCloseButton,
      close,
      headerTitle,
      title,
      colors,
      style,
      notChildrenWrapper,
      children,
      modalHeight,
      overFlowYScroll,
    },
    ref
  ) => {
    const { t } = useTranslate();

    return (
      <LargeBoxStyled
        className={`bg-${colors.bg} text-${colors.text} scrollbarNone`}
        ref={ref}
        style={style}
        modalHeight={modalHeight}
        overFlowYScroll={overFlowYScroll}
      >
        <div
          className="header"
          style={{
            borderBottom: headerTitle
              ? "1px solid rgba(221, 221, 221, .2)"
              : "",
          }}
        >
          {defaultCloseButton ? (
            <IoMdClose
              className="default-button text-3xl"
              role="button"
              fontSize="1.2em"
              onClick={close}
            />
          ) : (
            <IoMdClose
              className="default-button --left"
              role="button"
              fontSize="1.2em"
              onClick={close}
            />
          )}

          {headerTitle && <div className="header-title">{headerTitle}</div>}
          {title && (
            <div className="text-3xl font-medium w-full flex flex-col justify-center items-center">
              <p>{t(title)}</p>
            </div>
          )}
        </div>
        {notChildrenWrapper ? (
          children
        ) : (
          <div className="body px-5 pt-5 pb-[5em] h-full scrollbarNone">
            {children}
          </div>
        )}
      </LargeBoxStyled>
    );
  }
);

const LargeBoxStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${(p) => (p?.modalHeight ? p?.modalHeight : "98%")};
  border-radius: 15px 15px 0 0;
  transform: translate(0, 100%);
  ${(p) => (p?.overFlowYScroll ? "overflow-y: scroll;" : "overflow: hidden;")};
  @media (min-width: 800px) {
    bottom: 50%;
    left: 50%;
    height: 92%;
    width: 80%;
    max-width: 780px;
    border-radius: 15px;
    transition: border-radius 0.25s linear;
    transform: translate(-50%, 100%);
  }
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    .default-button {
      position: absolute;
      right: 15px;
      &.--left {
        left: 15px;
        right: unset;
      }
    }
    .header-title {
      font-weight: 700;
    }
  }
  .body {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export default LargeBox;
