import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale,
} from "chart.js";
import { binAmount } from "./utils/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale
);

function createBins(data, binCount, min, max) {
  const binSize = (max - min + 1) / binCount;
  let bins = new Array(binCount + 1).fill(0);

  data.forEach((value) => {
    if (value >= min && value <= max) {
      const binIndex = Math.floor((value - min) / binSize);
      bins[binIndex]++;
    } else if (value > max) {
      bins[binCount]++;
    }
  });

  return bins;
}

const BarChart = ({ data, highlight, domain }) => {
  const bins = createBins(data, binAmount, domain[0], 1000);

  const barData = {
    labels: bins.map((_, i) => (i === bins.length - 1 ? "999+" : i)),
    datasets: [
      {
        backgroundColor: bins.map((_, i) => {
          const scale = (1000 - domain[0]) / binAmount;
          const y = Math.round(domain[0] + i * scale);
          return y >= highlight[0] && y <= highlight[1]
            ? "rgba(255, 255, 255, 1)"
            : "rgba(255, 255, 255, 0.1)";
        }),

        // hoverBackgroundColor: "rgba(255,255,255,0.5)",
        data: bins,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        display: false,
        beginAtZero: true,
      },
      y: {
        max: 10,
        display: false,
        beginAtZero: true,
      },
    },
  };

  if (!!data.lenght) return;

  return <Bar data={barData} options={options} height={45} />;
};

export default BarChart;
