import React, { useEffect } from "react";
import useAxios, { mapConfig } from "./useAxios";
import { useDispatch } from "react-redux";
import { setWallets } from "../../app/actions";

const useGetWallets = () => {
  const dispatch = useDispatch();
  const { handleAxios } = useAxios();

  const getWallets = async () => {
    await handleAxios(
      mapConfig(
        "/wallets/getWallets?wallet_type=payment&status=1&enabled=1&from=web",
        "get",
        {
          postFetch: (res) => {
            dispatch(setWallets(res));
          },
        }
      )
    );
  };

  // useSetTranslate();

  useEffect(() => {
    getWallets();
  }, []);
};

export default useGetWallets;
