import React, { useState, useEffect } from "react";

export default function StarRating({
  className = "",
  routeRating = 0,
  disabled = true,
  classNameStars = "text-3xl",
  editFromParent,
  ratingType,
}) {
  const [rating, setRating] = useState(routeRating);
  const [hover, setHover] = useState(0);

  useEffect(() => {
    if (rating !== routeRating && routeRating) {
      setRating(routeRating);
    }
    if (editFromParent?.rating && editFromParent?.setRating) {
      setRating(editFromParent?.rating);
    }
  }, [routeRating, editFromParent]);

  return (
    <div className={`star-rating ${className}`}>
      {ratingType && <span className="rating-type">{ratingType}</span>}
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            disabled={disabled}
            className={`transition-all duration-300
          ${
            !disabled
              ? index <= hover
                ? "text-[#097F9A]"
                : index <= rating
                ? "text-[#097F9A]"
                : "text-white"
              : index <= rating
              ? "text-[#097F9A]"
              : "text-white "
          }
          textBorderBlue
          `}
            onClick={() =>
              editFromParent
                ? editFromParent.setRating(index)
                : setRating(index)
            }
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(0)}
          >
            <span className={`${classNameStars}`}>&#9733;</span>
          </button>
        );
      })}
    </div>
  );
}
