import React, { useLayoutEffect } from "react";
import Notifications from "../../../features/notifications/Notifications";
import { useStore } from "../../hooks/useStore";
import useAxios, { mapConfig } from "../../hooks/useAxios";
import { useUserState } from "../../hooks";

const NotificationsComp = () => {
  const { notifications, setNotifications, notRead } = useStore();
  const { handleAxios } = useAxios();
  const { isLogged } = useUserState();

  const read = async () => {
    if (isLogged) {
      await handleAxios(
        mapConfig(`/notifications/read`, "put", {
          toast: false,
          postFetch: (res) => {
            const newNotifications = notifications?.map((item) =>
              res.read?.includes(item.id) ? { ...item, status: 1 } : item
            );
            setNotifications(newNotifications);
          },
          body: { ids: notRead },
        })
      );
    }
  };

  useLayoutEffect(
    () => () => {
      if (notRead?.length > 0) read();
    },
    []
  );

  return <Notifications />;
};

export default NotificationsComp;
