import { useState, useCallback } from "react";
import Modal from "./Modal";

const useModalTemplate = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return {
    isOpen,
    open,
    close,
    Modal,
    ModalTemplate: ({ children }) => (
      <Modal isOpen={isOpen} close={close} {...props}>
        {children}
      </Modal>
    ),
  };
};

export default useModalTemplate;
