import React, { useMemo } from "react";
import useTranslate from "../../../mobile/hooks/useTranslate";

const OfferCard = ({
  offer,
  owner,
  admin,
  isHost,
  guestSigned,
  hostSigned,
}) => {
  const { t } = useTranslate();

  const title = useMemo(() => {
    if (!admin && guestSigned && !hostSigned) return `Offer signed by guest`;
    if (!admin && !guestSigned && hostSigned) return `Offer signed by host`;
    if (!admin && guestSigned && hostSigned)
      return `${owner.charAt(0).toUpperCase() + owner.slice(1)} offer accepted`;
    if (admin) return `Signed by admin accepted ${owner} offer`;
  }, [admin, owner, guestSigned, hostSigned]);

  const textBack = useMemo(() => {
    if (guestSigned && hostSigned)
      return "The guest and host signed this offer, the fund are released";
    if (isHost && guestSigned && owner === "host")
      return "The guest already signed your offer, please sign to release the funds";
    if (isHost && hostSigned)
      return "Thank you for signing you are now waiting for the guest signature";
    if (!isHost && hostSigned && owner === "guest")
      return "The host already signed your offer, please sign to release the funds";
    if (!isHost && guestSigned)
      return "Thank you for signing you are now waiting for the host signature";
  }, [isHost, owner, guestSigned, hostSigned]);

  return (
    <div className="rounded-lg bg-blueContrast p-5 text-white w-full flex-col items-center justify-center my-4">
      <div className="flex justify-center flex-col items-center">
        <h4 className="text-2xl font-semibold text-center">{t(title)}</h4>
        <p className="text-center">{t(textBack)}</p>
      </div>
      <div className="mt-4">
        <div className="flex flex-col items-center justify-center">
          <p>
            <span className="font-semibold text-lg">
              {t("Amount to Host:")}
            </span>
          </p>
          <p>{offer?.amount_to_host}</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p>
            <span className="font-semibold text-lg">
              {t("Amount to Guest:")}
            </span>
          </p>
          <p>{offer?.amount_to_guest}</p>
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
