import React from "react";
import { BackButton } from "../components/commons";
import Donation from "../../features/donation/Donation";

const Donate = () => {
  return (
    <div className="w-full overflow-y-scroll scrollbarNone pt-[2em] pb-[6em]">
      <div className="w-full flex justify-center items-center h-[5em] top-0 bg-blueDark fixed z-[995] px-2">
        <div className="w-full max-w-[40em] flex justify-between items-center">
          <BackButton className="text-2xl cursor-pointer p-3 left-[0.5em] z-[995] rounded-full bg-nebula text-buttonDark" />
          <div className="">
            <w3m-button />
          </div>
        </div>
      </div>
      <Donation />
    </div>
  );
};

export default Donate;
