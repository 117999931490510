import React, { useMemo } from "react";
import { useQuery, useTranslate } from "../../hooks";
import { format } from "date-fns";
import { EditDatesModal, EditGuestsModal } from "./modals";
import { useModalTemplate } from "../commons/ModalTemplate";

const ReserveEditData = (props) => {
  const { t } = useTranslate();
  const { query } = useQuery(["dates", "guests", "id"]);
  const {
    Modal: DatesModal,
    open: openDatesModal,
    ...datesModalPropsRest
  } = useModalTemplate();
  const {
    Modal: GuestsModal,
    open: openGuestsModal,
    ...guestsModalPropsRest
  } = useModalTemplate();

  const totalGuests = useMemo(() => {
    if (!query.guests) return;
    let res = 0;
    for (let k of Object.keys(query.guests)) {
      const value = query.guests[k];
      res = res + value;
    }
    return res;
  }, [query.guests]);

  const info = useMemo(
    () =>
      !query.dates
        ? ""
        : [
            {
              title: "Dates",
              value: `${format(
                new Date(query.dates.startDate),
                "MMM dd, yyyy"
              )} - ${format(new Date(query.dates.endDate), "MMM dd, yyyy")}`,
              edit: () => openDatesModal(),
            },
            {
              title: "Guests",
              value: totalGuests,
              edit: () => openGuestsModal(),
            },
          ],
    [query.dates, totalGuests]
  );

  if (!query.dates) return <></>;
  return (
    <div className="w-full flex flex-col px-5 gap-5">
      <p className="text-nebula text-3xl">{t("Your Stay")}</p>
      <div className="w-full flex flex-col gap-5 px-2">
        {info &&
          info.map((item) => (
            <div
              key={item.title}
              className="w-full flex justify-between items-center"
            >
              <div className="flex flex-col">
                <p className="text-nebula text-xl font-semibold">
                  {t(item.title)}
                </p>
                <p className="text-nebula text-md opacity-50">{item.value}</p>
              </div>
              <p
                onClick={item.edit}
                className="text-nebula text-lg font-semibold underline underline-offset-2"
              >
                {t("Edit")}
              </p>
            </div>
          ))}
      </div>
      <DatesModal
        id="dates-modal"
        bodyScroll={false}
        defaultCloseButton
        transitionType="botToTop"
        type="largeBox"
        // title={"Amenities"}
        {...datesModalPropsRest}
      >
        <EditDatesModal {...props} close={datesModalPropsRest.close} />
      </DatesModal>
      <GuestsModal
        id="guests-modal"
        bodyScroll={false}
        defaultCloseButton
        transitionType="botToTop"
        type="largeBox"
        // title={"Edit Guests"}
        modalHeight="70"
        {...guestsModalPropsRest}
      >
        <EditGuestsModal {...props} close={guestsModalPropsRest.close} />
      </GuestsModal>
    </div>
  );
};

export default ReserveEditData;
