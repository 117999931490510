import React from "react";
import useTranslate from "../../../mobile/hooks/useTranslate";
import { FOOTER_DESCRIPTIONS } from "../../../utils/footerDescriptions";

const FooterWhoWeAre = () => {
  const { t } = useTranslate();

  return (
    <div className="flex mx-auto justify-center items-center ">
      <div className="w-full h-full flex flex-col gap-10 items-center"> 
        {FOOTER_DESCRIPTIONS.map((description) => (
          <div key={description.id} className="p-2 rounded-lg">
            <p className={`text-nebula text-start text-md md:text-xl ${description.id === "fees" ? "font-bold" : ""}`}>
              {t(description.description)}
            </p>
          </div>
        ))}
        {/* <p className="text-nebula text-justify text-lg">
          {t(
            "BBOP is the world's first fee-free home sharing platform. BBOP leverages the power of crypto currencies and smart contracts to take out the middleman. You now have the full power of travelling the world and sharing your home completely in your hands."
          )}
        </p>

        <p className="text-nebula text-justify text-xl">
          {t(
            "BBOP uses a modified 2 of 3 signature smart contract to allow guests and hosts to directly interact with each other. This removes the need for a middle man and the associated costs. When the guest and host are both happy with a completed trip, they will both sign the BBOP smart contract. The host will be paid. The guest's security deposit will be returned. All of this happens seamlessly without any intervention from the BBOP team."
          )}
          {t(
            "BBOP uses a modified 2 of 3 signature smart contract to allow guests and hosts to directly interact with each other. This removes the need for a middle man and the associated costs. When the guest and host are both happy with a completed trip, they will both sign the BBOP smart contract. The host will be paid. The guest's security deposit will be returned. All of this happens seamlessly without any intervention from the BBOP team."
          )}
        </p>

        <p className="text-nebula text-justify text-xl">
          {t(
            "BBOP reserves the 3rd signature in the smart contract only for the times when there is a dispute between the guest and the host. If the guest and host cannot agree on a payout distribution of the funds in the smart contract (payment and security deposit), then BBOP will help both parties try to resolve the problem."
          )}
          {t(
            "BBOP reserves the 3rd signature in the smart contract only for the times when there is a dispute between the guest and the host. If the guest and host cannot agree on a payout distribution of the funds in the smart contract (payment and security deposit), then BBOP will help both parties try to resolve the problem."
          )}
        </p>

        <p className="text-nebula text-justify text-xl">
          {t(
            "BBOP's security deposit feature is a novel solution to assuring your guests treat your home with the utmost respect and care. The security deposit is handled seamlessly by the smart contract and is returned to the guest once the stay is complete and the host is happy with the stay."
          )}
        </p>

        <p className="text-nebula text-justify text-xl font-bold">
          {t(
            "Pay with crypto. Earn crypto. Travel the world. Skip the middleman fees. All of this on our fee-free donation supported platform."
          )}
        </p> */}
      </div>
    </div>
  );
};

export default FooterWhoWeAre;
