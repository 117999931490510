import React from "react";
import { format } from "date-fns";
import ManageLoading from "../../../../../commons/ManageLoading";
import useTranslate from "../../../../../mobile/hooks/useTranslate";
import Messages from "./components/Messages/Messages";
import MainMessagesLogic from "./logic/MainMessagesLogic";
import { useHistory, useUserState } from "../../../../../mobile/hooks";
import { useStore } from "../../../../../mobile/hooks/useStore";
import NoBookingCard from "../../../../../mobile/components/commons/NoBookingCard";
import { MdRefresh } from "react-icons/md";
import useGetNotReadNotis from "../../../../../mobile/hooks/useGetNotReadNotis";

function MainMessages() {
  const history = useHistory();
  const { userStore } = useUserState();
  const { msgNotRead } = useStore();
  const { getNotReadMsgs } = useGetNotReadNotis();

  const { messageWork, renderMessages, recall } = MainMessagesLogic();
  const { t } = useTranslate();

  return (
    <div className="w-full pt-4 md:px-5">
      <ManageLoading
        loading={messageWork}
        className="w-full flex justify-center pt-10"
        size={60}
      >
        <div
          onClick={() => {
            getNotReadMsgs();
            recall();
          }}
          className="absolute top-[1.5em] cursor-pointer right-[1em] md:right-[2em] py-2 text-center rounded-full drop-shadow-lg border border-greenDark text-greenDark bg-white "
        >
          <MdRefresh className="w-10 h-6" />
        </div>
        {renderMessages?.length === 0 && !messageWork && (
          <NoBookingCard
            title="No messages... yet!"
            message="Time to dust off your travel shoes and start exploring!"
            buttonText="Start Searching"
          />
        )}
        {renderMessages &&
          renderMessages.map((item, index) => (
            <Messages
              {...item}
              otherUser={item?.other_user}
              listing={item?.listing}
              badge={
                msgNotRead?.length > 0
                  ? msgNotRead?.filter((m) => m.info.docId === item.id)
                  : false
              }
              cursorPointer={"hover: cursor-pointer"}
              // number={item?.receriverStatus === "UNREAD" ? "1" : "0"}
              disabled={false}
              key={index}
              listingName={item?.listing?.name}
              userName={<p>{item?.other_user?.username}</p>}
              source={item?.listing?.listingImages[0]?.path}
              // className={"w-24 h-24"}
              date={format(new Date(item?.createdAt), "dd/MM/yyyy")}
              message={item?.thread === undefined}
              onClick={() => {
                history(`/chat`, {
                  userRes: item?.other_user,
                  listingRes: item?.listing,
                  value: item,
                  chatId: item?.id,
                  role: userStore?.role,
                  createdAt: item?.createdAt,
                });
              }}
            />
          ))}
      </ManageLoading>
    </div>
  );
}

export default MainMessages;
