import Axios from "axios";
import React, { useState } from "react";
import { InputText } from "../../commons/Form";
import useToast from "../../hooks/useToast";
import useTranslate, { useSetTranslate } from "../../mobile/hooks/useTranslate";
import { useValidation } from "../../hooks/useValidation";
import useApiUrl from "../../hooks/useApiUrl";
import useHistory from "../../mobile/hooks/useHistory";
import { useDispatch } from "react-redux";
import { setUserAction } from "../../app/actions";
import ManageLoading from "../../commons/ManageLoading";
import LogoNav from "./Navbar/Logo";

function SignUpModal({ setOpenSignUp }) {
  const [showErrors, setShowErrors] = useState(false);
  const { setTranslate } = useSetTranslate();
  const dispatch = useDispatch();

  const continueLogin = (res, toast) => {
    dispatch(setUserAction(res.data));
    // connectSocket("noti", res?.data?.user?.id, res?.data?.token);
    if (toast) notify.s("Log in success");
    setTranslate(res?.data?.user?.id);
  };

  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
    lastName: "",
    firstName: "",
    rgdp: false,
    phonePrefix: "",
    phone: "",
    section: "g",
  };
  const [values, setValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onChangeValues = (key, value) => {
    setValues((prev) => ({ ...prev, [key]: value }));
  };

  const { t } = useTranslate();

  function handleRedirect() {
    history("/explore");
  }

  const { notify } = useToast([
    {
      position: "top-center",
      type: "success",
      key: "s",
    },
    {
      position: "top-center",
      type: "error",
      key: "e",
    },
  ]);
  const validate = [
    {
      keyValue: "password",
      valid: [
        { key: "text-min", value: 8 },
        { key: "text-max", value: 15 },
        { key: "special-character" },
        { key: "blacklist", value: ["admin", "password"] },
        { key: "required" },
      ],
    },
    {
      keyValue: "email",
      valid: [{ key: "email" }, { key: "required" }],
    },
    {
      keyValue: "confirmPassword",
      valid: [
        {
          key: "equals",
          value: { value: values.password, message: "password" },
        },
        { key: "blacklist", value: ["admin", "password"] },
        { key: "required" },
      ],
    },
  ];

  const { errors, hasErrors } = useValidation({ values, validate });

  const resetValues = () => {
    setValues(initialValues);
  };

  const url = useApiUrl();

  const signUp = async () => {
    const data = {
      email: values.email,
      password: values.password,
      last_name: values.email,
      first_name: values.email,
      rgdp: values.rgdp,
      section: "g",
      phone_prefix: values.phonePrefix,
      phone: "",
      country: "CR",
      summary: "",
      profession: "",
      wallet_address: "",
    };

    const axios = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setLoading(true);
    try {
      const res = await Axios.post(`${url}/users/create`, data, axios);
      notify.s("Sign Up success");
      continueLogin(res);
      resetValues();
      handleRedirect();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      // console.log("ERROR", e)
      notify.e(
        e?.response?.data?.message ||
          "Something went wrong, please try again later"
      );
    }
  };

  return (
    <>
      <div className="w-full flex flex-col justify-center md:pt-0  md:justify-center items-center gap-2 h-screen px-2 max-w-[35em]">
        <div className="absolute top-0 flex justify-center w-full">
          <LogoNav h={"h-[5em]"} />
        </div>
        <div className="w-full flex flex-col justify-center gap-5 pb-5">
          <span className="text-lighGreen text-center text-5xl font-semibold">
            {t("Rental Vacation Reinvented")}
          </span>
          <span className="text-nebula opacity-[0.4] text-center text-lg">
            {t(
              "Zero-fee, cryptocurrency-driven platform that redefines the way you travel and share your home."
            )}
          </span>
        </div>
        <InputText
          className="w-full !bg-nebula py-1"
          classNameRoot="w-full rounded-full"
          classNameInput="!bg-nebula !text-greenDark"
          value={values.email}
          placeholder={t("Email")}
          id="email"
          onChange={(e) => onChangeValues("email", e.target.value)}
          error={showErrors && errors?.email}
        />
        <InputText
          className="w-full !bg-nebula py-1"
          classNameRoot="w-full rounded-full"
          classNameInput="!bg-nebula !text-greenDark"
          value={values.password}
          placeholder={t("Password")}
          type="password"
          id="password"
          onChange={(e) => onChangeValues("password", e.target.value)}
          error={showErrors && errors?.password}
        />
        <InputText
          className="w-full !bg-nebula py-1"
          classNameRoot="w-full rounded-full"
          classNameInput="!bg-nebula !text-greenDark"
          value={values.confirmPassword}
          placeholder={t("Confirm password")}
          type="password"
          id="confirmPassword"
          onChange={(e) => onChangeValues("confirmPassword", e.target.value)}
          error={showErrors && errors?.confirmPassword}
        />
        <div className="flex items-center justify-center w-full mt-2 h-14 ">
          <button
            type="submit"
            disabled={loading}
            onClick={() => (hasErrors ? setShowErrors(true) : signUp())}
            className="buttons flex justify-center items-center w-full h-12 text-nebula font-bold text-lg bg-lighGreen rounded-lg shadow-md"
          >
            <ManageLoading color="#FFFFFF" loading={loading}>
              {t("Sign Up")}
            </ManageLoading>
          </button>
        </div>
        <div className="flex flex-col justify-center pt-3 w-full items-center ">
          <p
            className="underline font-bold text-nebula m-0 cursor-pointer"
            onClick={() => history("/")}
          >
            {t("Log In")}
          </p>
        </div>
      </div>
    </>
  );
}

export default SignUpModal;
