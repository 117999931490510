import { useMemo } from "react";
import {
  APP_FILES_TYPES_TITLE,
  APP_FILES_TYPES_TITLE_UNVERIFIED,
} from "../../const/AppFiles";
import { USER_KYC_STATUS, USER_KYC_STATUS_COLOR } from "../../const/KYCStatus";
import KycPending from "./KycPending";
import KycUnverified from "./KycUnverified";
import KycVerified from "./KycVerified";
import { useTranslate } from "../../mobile/hooks";

const KycInput = ({ status, icon, propsUnverified, appfile }) => {
  const { t } = useTranslate();
  const render = useMemo(() => {
    switch (status) {
      case USER_KYC_STATUS.UNVERIFIED:
        return (
          <KycUnverified propsUnverified={propsUnverified} status={status} />
        );
      case USER_KYC_STATUS.REJECTED:
        return (
          <KycUnverified
            rejection={appfile?.rejection}
            propsUnverified={propsUnverified}
            status={status}
          />
        );
      case USER_KYC_STATUS.PENDING:
        return <KycPending />;
      case USER_KYC_STATUS.VERIFIED:
        return <KycVerified appfile={appfile} />;
      default:
        return <KycUnverified />;
    }
  }, [status, icon]);

  return (
    <div
      className={`flex flex-col border-2 py-4 px-2 border-${
        appfile?.rejection
          ? USER_KYC_STATUS_COLOR[status]
          : status === USER_KYC_STATUS.PENDING
          ? "yellowBbop"
          : "nebula"
      } rounded-xl w-full items-center justify-center h-full `}
    >
      <div className="flex flex-col items-center w-full  h-full  rounded-xl">
        <div className="flex flex-col justify-center items-center">
          <p className="text-nebula text-lg text-center ">
            {t(
              status === USER_KYC_STATUS.UNVERIFIED
                ? APP_FILES_TYPES_TITLE_UNVERIFIED[appfile?.type_of_file]
                : APP_FILES_TYPES_TITLE[appfile?.type_of_file]
            )}
          </p>
        </div>
        <div className="flex justify-center pt-4">{icon}</div>
      </div>
      <div className="flex w-full flex-row justify-center items-center">
        {render}
      </div>
    </div>
  );
};

export default KycInput;
