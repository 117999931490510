import React, { useMemo } from "react";
import { useTranslate } from "../../hooks";

const ReserveInfo = ({ listing, prices }) => {
  const { t } = useTranslate();

  const info = useMemo(() => {
    const res = [
      {
        label: `${prices.days} ${prices.days <= 1 ? "Night" : "Nights"}`,
        value: `${`$${prices.pricePerDays}`}`,
      },
      {
        label: "BBOP Fee",
        value: `$0🎉`,
      },
      {
        label: `Security deposit`,
        value: `$${listing?.security_deposit_percentage || 0}`,
      },
      {
        label: `Total`,
        value: `$${prices?.total?.toString()}`,
      },
    ];

    return res;
  }, [prices, listing, t]);

  if (!listing || !prices) return <></>;
  return (
    <div className="w-full">
      <p className="pb-5 text-nebulaOpac text-3xl m-0 px-5">
        {t("Price Details")}
      </p>
      {info &&
        info.map((item, ix) => {
          const isLast = ix === info.length - 1;
          return (
            <div key={ix} className="px-2">
              <div className="flex justify-between w-full py-2 px-5">
                <span
                  className={`text-nebulaOpac ${
                    isLast ? "text-3xl font-semibold" : "text-lg"
                  }`}
                >
                  {item.label}
                </span>
                <span
                  className={`text-nebula ${
                    isLast ? "text-3xl font-semibold" : "text-lg"
                  }`}
                >
                  {item.value}
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ReserveInfo;
