import { useMemo } from "react";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { FiThumbsUp } from "react-icons/fi";
import { IoCloudUpload } from "react-icons/io5";
import { MdOutlineCancel } from "react-icons/md";
import FileInput from "../../commons/FileInput";
import ManageLargeText from "../../commons/ManageLargeText";
import { USER_KYC_STATUS } from "../../const/KYCStatus";
import { useTranslate } from "../../mobile/hooks";

const KycUnverified = ({ propsUnverified, rejection, status }) => {
  const { t } = useTranslate();
  const fileInputMemo = useMemo(
    () =>
      !propsUnverified?.upload || propsUnverified?.value === "" ? (
        <>
          <FileInput
            upload={propsUnverified?.upload}
            setSelectedImage={propsUnverified?.setInput}
            fileRef={propsUnverified?.fileRef}
            notify={propsUnverified?.notify}
            icon={<IoCloudUpload color="#707070" size={65} />}
          />
          <p className="text-nebula ">{t("Select a File")}</p>
        </>
      ) : (
        <div className="flex flex-row w-fit h-fit items-center mt-5">
          <label>
            <ManageLargeText
              maxLength={25}
              cutValue={24}
              className="text-nebula"
              value={propsUnverified?.value?.name}
            />
          </label>
          <div className="flex items-center h-full">
            <AiOutlineCloseSquare
              color="red"
              className="cursor-pointer text-2xl ml-2"
              onClick={() => propsUnverified?.setInput("")}
            />
          </div>
        </div>
      ),
    [propsUnverified?.upload, propsUnverified?.value, rejection, status]
  );

  return (
    <div
      className={`flex flex-col h-fit  w-full justify-center items-center py-4`}
      onDragStart={(e) =>
        propsUnverified?.onDragStart(e, propsUnverified?.value)
      }
      onDragEnter={(e) =>
        propsUnverified?.onDragEnter(e, propsUnverified?.value)
      }
      draggable
    >
      {status === USER_KYC_STATUS.REJECTED ? (
        <>
          {rejection ? (
            <>
              {fileInputMemo}
              <div className="flex items-center">
                <MdOutlineCancel className={`text-cancel text-3xl mr-2`} />
                <p className="text-lg text-[#F42D2D]">{rejection?.reason}</p>
              </div>
            </>
          ) : (
            <div className="flex items-center">
              <FiThumbsUp className={`text-lighGreen text-3xl mr-2`} />
                <p className="text-lg text-lighGreen">{t("this File is verified")}</p>
            </div>
          )}
        </>
      ) : (
        fileInputMemo
      )}
    </div>
  );
};

export default KycUnverified;
