import React, { useCallback } from "react";
import { useTranslate } from "../mobile/hooks";
import ManageLoading from "./ManageLoading";

export default function Form({ inputs, actions, title, className = "mt-10" }) {
  return (
    <div className={`w-full flex flex-col items-center min-h-min ${className}`}>
      {title && (
        <h1 className="text-xl text-center font-extrabold text-nebula">
          {title}
        </h1>
      )}
      <form className="drop-shadow-xl w-full flex flex-col flex-wrap space-y-3 md:p-4">
        {inputs &&
          inputs.map((input, ix) => (
            <React.Fragment key={ix}>{input.render}</React.Fragment>
          ))}
        {actions && (
          <div className="w-full h-10 flex mt-10 justify-center">
            {actions &&
              actions.map((action, ix) => (
                <React.Fragment key={ix}>{action.render}</React.Fragment>
              ))}
          </div>
        )}
      </form>
    </div>
  );
}

export const FormAction = ({
  onSubmit,
  label,
  className,
  disabled = false,
  work,
}) => {
  return (
    <button
      key={label}
      className={`w-1/4 h-10 rounded-lg transition-all ${
        disabled ? "bg-[#FF5959] text-white" : "bg-nebula text-blueDark"
      } ${className}`}
      type="submit"
      onClick={(e) => onSubmit(e)}
      disabled={disabled}
    >
      <div className="w-full flex justify-center">
        <ManageLoading loading={work} size={25}>
          {label}
        </ManageLoading>
      </div>
    </button>
  );
};

export const useForm = (values, setValues) => {
  const handleFormOnChange = useCallback(
    (e, keyProp) => {
      e.preventDefault();
      setValues({ ...values, [keyProp]: e.target.value });
    },
    [values]
  );
  return { handleFormOnChange };
};

const InputError = ({ message }) => {
  return message ? (
    <div className="w-full justify-start">
      <p className="text-cancel m-0">{message}</p>
    </div>
  ) : (
    ""
  );
};

export const InputText = ({
  onChange,
  value,
  placeholder,
  label,
  disabled = false,
  type = "text",
  className = "",
  classNameInput = "",
  classNameRoot = "",
  inputPrompt,
  id = "",
  InputPrefix,
  minValue,
  maxValue,
  error,
  noStyles,
  showDisable,
  classNameLabel,
}) => {
  return (
    <div className={`${classNameRoot}`}>
      <div
        key={placeholder}
        className={
          noStyles
            ? className
            : `w-full rounded-lg ${
                disabled && showDisable ? "opacity-50" : ""
              } flex justify-center items-center rounded-lg bg-blueContrast  text-nebula focus:outline-none pr-2 ${className}`
        }
      >
        {InputPrefix && InputPrefix}
        {label && (
          <div>
            <p
              className={`text-nebula font-bold ml-2 text-lg w-max ${classNameLabel}`}
            >
              {label}
            </p>
          </div>
        )}
        <input
          id={id}
          disabled={disabled}
          className={`${classNameInput} bg-blueContrast p-2 text-nebula w-full focus:outline-none rounded-lg ${
            inputPrompt && "pr-5"
          }`}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={
            type === "number"
              ? (e) => {
                  if (Number(e.target.value) < minValue) return;
                  if (Number(e.target.value) > maxValue) return;
                  onChange(e);
                }
              : (e) => onChange(e)
          }
        />
        {inputPrompt && inputPrompt}
      </div>
      <InputError message={error} />
    </div>
  );
};

export const InputTextEdit = ({
  required,
  onChange,
  value,
  placeholder,
  label,
  disabled = false,
  type = "text",
  className = "",
  classNameInput = "",
  inputPrompt,
  id = "",
  InputPrefix,
  minValue,
}) => {
  const { t } = useTranslate();
  return (
    <div>
      <div
        key={placeholder}
        className={`${
          required
            ? "placeholder-[#ff0000] placeholder:opacity-80 w-1/2 border-2 rounded-lg flex justify-center items-center p-2 text-[#ff0000] shadow-sm shadow-[#ff0000] focus:shadow-sm focus:shadow-[#ff0000]"
            : `rounded-lg flex justify-center items-center bg-blueMid border-2 border-blueDark text-greenDark ${className}`
        }`}
      >
        {InputPrefix && InputPrefix}
        {label && (
          <div className=" border-r-0 rounded-l-lg py-4 pr-2 pl-4">
            <p className="text-nebula font-bold mr-2 w-max">{t(label)}</p>
          </div>
        )}
        <input
          id={id}
          disabled={disabled}
          required={required}
          className={`${classNameInput} w-full bg-transparent text-nebula border-l-0 p-4 rounded-r-lg  `}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={
            type === "number"
              ? (e) => {
                  if (e.target.value >= minValue || !e.target.value) {
                    onChange(e);
                  }
                }
              : (e) => onChange(e)
          }
        />
        {inputPrompt && inputPrompt}
      </div>
      {required && (
        <div className="w-full flex justify-start">
          <small className="text-[#ff0000] p-2 mb-2">Required</small>
        </div>
      )}
    </div>
  );
};

export const InputTextArea = ({
  onChange,
  value,
  placeholder,
  label,
  disabled = false,
  className = "",
}) => {
  return (
    <>
      <div key={placeholder} className="w-full">
        <div className="w-full flex flex-col justify-start items-start bg-blueMid border-2 border-blueDark p-2 rounded-lg">
          {label && (
            <label htmlFor="" className="text-nebula font-bold pb-2">
              {label}
            </label>
          )}
          <textarea
            disabled={disabled}
            className={`rounded-lg w-full h-32 p-2 bg-transparent text-nebula ${className}`}
            placeholder={placeholder}
            id="summary"
            row="20"
            // style={{ resize: "none" }}
            value={value}
            onChange={(e) => onChange(e)}
          ></textarea>
        </div>
      </div>
    </>
  );
};
export const InputDate = ({
  onChange,
  value,
  label,
  id,
  disabled = false,
  className = "",
  classNameInputParent = "h-10",
  classNameInput = "w-full lg:w-1/2 h-10",
  format = "dd-MM-YYYY",
  placeholder = "MM / dd / YYYY",
  type = "date",
}) => {
  return (
    <>
      <div
        key={id}
        className={`bg-blueMid border-2 border-blueDark p-2 rounded-lg ${className}`}
      >
        {label && (
          <label
            htmlFor=""
            className="text-nebula font-bold absolute top-[1em]"
          >
            {label}
          </label>
        )}

        <div className={`w-full ${classNameInputParent}`}>
          <input
            disabled={disabled}
            className={`date-input-hidde-icon text-transform: uppercase bg-transparent text-center text-nebula ${classNameInput}`}
            type={type}
            format={format}
            id={id}
            placeholder={placeholder}
            maxLength="7"
            value={value}
            onChange={(e) => onChange(e)}
          />
        </div>
      </div>
    </>
  );
};
export const InputSelect = ({
  onChange,
  value,
  label,
  id,
  options,
  name,
  className = "",
  classNameRoot = "",
  defaultProp,
  disabled = false,
}) => {
  return (
    <>
      <div key={id} className={`${classNameRoot}`}>
        {label && (
          <label htmlFor="" className="text-greenDark font-bold">
            {label}
          </label>
        )}
        <select
          disabled={disabled}
          name={name}
          id={id}
          className={`border-2 border-nebula rounded-lg w-full h-12 text-greenDark ${className}`}
          value={value || "x"}
          onChange={(e) => onChange(e)}
        >
          {" "}
          {defaultProp && <option value="x">{defaultProp}</option>}
          {options.map((o, e) => (
            <option key={e} value={o.value}>
              {o.label} {o.label2}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};
