import React, { useEffect } from "react";
import UploadAppFile from "../../../../commons/UploadAppfile";

const Billing = ({ listing, onChange, setChangeRejections }) => {
  const {
    input,
    // upload,
    value: inputValue,
    // setUpload,
  } = UploadAppFile({
    icon: 20,
    className: "w-full flex flex-col justify-center",
    label: "Upload a utility bill for this property",
    classNameInputRoot: "flex justify-center flex-col items-center",
    appFile: listing?.app_files,
    onCancel: () => {
      onChange("app_files", "");
    },
    rejectionNoAppFile: false,
    rejectionNoAppFile: listing?.rejections?.find(
      (i) => i.value === "no billing uploaded"
    ),
    setChangeRejections,
  });

  useEffect(() => {
    if (inputValue) {
      onChange("app_files", { new: inputValue });
    }
  }, [inputValue]);

  return <div>{input}</div>;
};

export default Billing;
