import { useEffect, useState } from "react";
import { mapConfig } from "../../hooks/useAxios";
import useSimpleAxios from "../../hooks/useSimpleAxios";

const DonationLogic = () => {
  const [walletSelected, setWalletSelected] = useState({});
  const [donationAmount, setDonationAmount] = useState(0);
  const marks = {
    0: "0",
    5: "$5",
    10: "$10",
    20: "$20",
    50: "$50",
    70: "$70",
    80: "$80",
    100: "$100",
  };

  const { value: wallets } = useSimpleAxios(
    mapConfig("/wallets/getWallets?wallet_type=donation&enabled=1", "get", {
      formatValue: (res) =>
        res.map((item) => ({
          ...item,
          currency_abi: JSON.parse(item.currency_abi),
          params: JSON.parse(item.params),
        })),
    })
  );

  useEffect(() => {
    if (wallets?.length > 0) {
      setWalletSelected(wallets[0]);
    }
  }, [wallets]);

  const handleChangeSelectCurrency = (value) => {
    setWalletSelected(wallets.find((item) => item.token === value));
  };

  return {
    wallets,
    handleChangeSelectCurrency,
    walletSelected,
    marks,
    setDonationAmount,
    donationAmount,
  };
};

export default DonationLogic;
