import * as React from "react";

function TvIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 503.588 503.588"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill={props.color}
        d="M492.128 76.714H11.676C5.168 76.714 0 80.238 0 86.75v295.012c0 6.512 5.168 13.632 11.676 13.632h196.84v15.74H59.132a7.867 7.867 0 00-7.868 7.868c0 4.344 3.52 7.872 7.868 7.872h387.404a7.87 7.87 0 000-15.74H295.072v-15.74h197.056c6.508 0 11.46-7.12 11.46-13.632V86.754c0-6.516-4.952-10.04-11.46-10.04zM257.448 379.27c-1.452 1.456-3.5 2.288-5.54 2.288a7.879 7.879 0 01-5.552-2.288 7.883 7.883 0 01-2.32-5.58 7.88 7.88 0 012.28-5.556c2.952-2.952 8.224-2.952 11.132 0 1.46 1.46 2.328 3.46 2.328 5.556 0 2.084-.868 4.084-2.328 5.58zM15.732 356.05V116.058h472.112V356.05H15.732z"
      />
    </svg>
  );
}

export default TvIcon;
