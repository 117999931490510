import React from "react";
import useTranslate from "../../../../mobile/hooks/useTranslate";
import { SYNC_USER_STATUS } from "../../../../hooks/useSyncUser";

const SyncUser = ({ syncUser, onClickTryAgain }) => {
  const { t } = useTranslate();
  if (syncUser?.sync_user_status_id === SYNC_USER_STATUS.DONE) {
    return (
      <div className="flex flex-col h-[5em] w-full items-center justify-center">
        <p className="m-0">
          {`${t("The status of your integration with")} `}
          <span className="text-blueBbop ">{`${syncUser?.source}`}</span>
          {` ${t(" appears to be ")} `}
          <span className="text-lighGreen">{` ${t(syncUser?.status)} `}</span>
          {` ${t(" but it's not fully finish ")} `}
        </p>
        <p className="m-0">{t("Please contact costumer service ")}</p>
        <p className="m-0">{t("or")}</p>
        <p
          onClick={onClickTryAgain}
          className="text-lighGreen underline cursor-pointer m-0"
        >
          {t("Try again")}
        </p>
      </div>
    );
  }
  if (syncUser?.sync_user_status_id === SYNC_USER_STATUS.ERROR) {
    return (
      <div className="flex flex-col h-[5em] w-full items-center justify-center">
        <p className="m-0">
          {t(`Your integration had an`)}
          <span className="text-cancel">{` ${t(syncUser?.status)} `}</span>
          {` ${t("with")} `}
          <span className="text-blueBbop ">{`${syncUser?.source}`}</span>
        </p>
        <p>{syncUser?.message}</p>
        <p
          onClick={onClickTryAgain}
          className="text-lighGreen underline cursor-pointer"
        >
          {t("Try again")}
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-[5em] w-full items-center justify-center">
      <p className="m-0">
        {`${t("Your user is currently")}`}
        <span className="text-yellowBbop">{` ${t(syncUser?.status)} `}</span>
        {`${t("to integrate with")} `}
        <span className="text-blueBbop ">{`${syncUser?.source}`}</span>
      </p>
      <p>{t("This procces may take a few minutes, we aprechiate your wait")}</p>
    </div>
  );
};

export default SyncUser;
