const hasName = (listing) => !!listing.name.trim();

const hasDescription = (listing) => !!listing.description.trim();

const hasCategory = (listing) => !!listing.category;

const hasAttributes = (listing) => !!listing.amenities.length;
const hasPhotos = (listing) => !!listing.images.length;

const hasPrice = (listing) => !!listing.price && listing.price > 0;

const hasRules = (listing) => !!listing.rules_and_conditions;

const hasCancellationPolicy = (listing) => listing.cancellation_policy >= 0;

const hasSecurityDeposit = (listing) =>
  !!listing.security_deposit && listing.security_deposit > 0;

const hasConditions = (listing) => {
  return false;
};

const hasAddress = (listing) => {
  return false;
};

const send = () => {
  return false;
};

export const allSteps = (state) => ({
  1: {
    1: hasName(state) && hasDescription(state) && hasCategory(state),
    2: hasAttributes(state),
    3: hasPhotos(state),
  },
  2: {
    1: hasPrice(state) && hasRules(state),
    2: hasSecurityDeposit(state) && hasCancellationPolicy(state),
    3: hasConditions(state),
  },
  3: {
    1: hasAddress(state),
  },
  4: {
    1: send(state),
  },
});
