import axios from "axios";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import useLogOut from "./useLogOut";
import useToast from "./useToast";
import useUserState from "../mobile/hooks/useUserState";
import useApiUrl from "./useApiUrl";
import useHistory from "../mobile/hooks/useHistory";

export default function useAxios(auth = true) {
  const { userState, jwt } = useUserState();
  const [res, setRes] = useState();
  const [working, setWorking] = useState(true);
  const [logged, setLogged] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const { logout } = useLogOut();

  const url = useApiUrl();

  if (auth) {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${jwt || userState?.jwt}`,
      client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
    };
  }

  const { notify } = useToast([
    {
      position: "top-center",
      type: "success",
      key: "s",
    },
    {
      position: "top-center",
      type: "error",
      key: "e",
    },
  ]);

  const errorStatus = [401];

  const handleError = useCallback(
    (e, errorToast, redirect) => {
      if (errorStatus.includes(e?.response?.status) && redirect) {
        setLogged(false);
        logout(false);
        history(redirect, {
          state: {
            errorMessage: e.message || errorToast,
            from: location?.pathname,
          },
        });
      } else if (errorStatus.includes(e?.response?.status)) {
        setLogged(false);
        logout(false);
        history("/", {
          state: {
            from: `${location?.pathname}${
              location.search ? location.search : ""
            }`,
            // errorMessage: errorToast
          },
        });
      }
      setRes(e.response);
      console.error("ERROR", e);
      setWorking(false);
    },
    [history]
  );

  const handleMultipleAxios = useCallback(
    async ({ config }) => {
      const finalResponse = {};
      for await (const item of config) {
        const {
          path,
          method,
          body = {},
          postFetch = () => {},
          setWork,
          key,
        } = item;
        const urlAxios = `${url}${path}`;
        switch (method) {
          case "get":
            try {
              const response = await axios.get(urlAxios, body);
              finalResponse[key] = response.data;
              setWorking(false);
              if (setWork) setWork(false);
              postFetch(response.data);
            } catch (e) {
              handleError(e);
              if (setWork) setWork(false);
            }
            break;
          case "post":
            try {
              const response = await axios.post(urlAxios, body);
              finalResponse[key] = response.data;
              setWorking(false);
              if (setWork) setWork(false);
              postFetch(response.data);
            } catch (e) {
              handleError(e);
              if (setWork) setWork(false);
            }
            break;
          case "put":
            try {
              const response = await axios.put(urlAxios, body);
              finalResponse[key] = response.data;
              setWorking(false);
              if (setWork) setWork(false);
              postFetch(response.data);
            } catch (e) {
              handleError(e);
              if (setWork) setWork(false);
            }
            break;
          default:
            console.error("Please provide a method, error ocurred in useAxios");
            break;
        }
      }
      setRes(finalResponse);
    },
    [handleError]
  );

  const handleAxios = useCallback(
    async ({
      path,
      method,
      body = {},
      postFetch = () => {},
      postErrorFetch = () => {},
      setWork,
      specialAuth = "",
      errorToast = "",
      redirect = "",
      params = {},
      toast = true,
      isPaginated = false,
      returnValue = false,
    }) => {
      if (specialAuth) {
        axios.defaults.headers.common = {
          Authorization: `Bearer ${specialAuth}`,
        };
      }
      const urlAxios = `${url}${path}`;
      if (setWork) setWork(true);
      switch (method) {
        case "get":
          try {
            const response = await axios.get(urlAxios, body, {
              params: params,
            });
            setWorking(false);
            setRes(response.data);
            postFetch(response.data);
            if (setWork) setWork(false);
            if (isPaginated) return response?.data;
            if (returnValue) return response;
          } catch (e) {
            postErrorFetch(e);
            handleError(e, errorToast, redirect);
            if (setWork) setWork(false);
          }
          break;
        case "post":
          try {
            const response = await axios.post(urlAxios, body, {
              params: params,
            });
            setRes(response.data);
            setWorking(false);
            if (setWork) setWork(false);
            postFetch(response.data);
            if (toast) notify.s("Created Successfully");
            if (isPaginated) return response?.data;
          } catch (e) {
            postErrorFetch(e);
            handleError(e, errorToast, redirect);
            if (setWork) setWork(false);
          }
          break;
        case "put":
          try {
            const response = await axios.put(
              urlAxios,
              body.formData ? body.formData : body,
              { params: params }
            );
            setRes(response.data);
            setWorking(false);
            if (setWork) setWork(false);
            if (toast) notify.s("Updated Successfully");
            postFetch(response.data);
          } catch (e) {
            postErrorFetch(e);
            handleError(e, errorToast, redirect);
            if (setWork) setWork(false);
          }
          break;
        default:
          console.error("Please provide a method, error ocurred in useAxios");
          break;
      }
    },
    [handleError]
  );

  const handleUseEffectAxios = useCallback(
    async (config) => {
      const configAxios = {
        path: config.path,
        method: config.method,
        postFetch: config.postFetch,
      };
      await handleAxios(configAxios);
    },
    [handleAxios]
  );

  return {
    res,
    working,
    handleAxios,
    handleMultipleAxios,
    handleUseEffectAxios,
    logged,
  };
}

//this is used to map the configAxios easily
// search for examples with '//example mapConfig'
export const mapConfig = (path, method, extra) => {
  const configAxios = {
    path: path,
    method: method,
  };

  if (extra) {
    return { ...configAxios, ...extra };
  }

  return configAxios;
};
