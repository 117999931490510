import React, { useMemo, useState } from "react";
import TransactionCompleted from "../../../commons/web3/TransactionCompleted";
import { WEB3_TX_TYPE_TEXT } from "../../../const/Web3Tx";
import useTranslate from "../../hooks/useTranslate";
import useUserState from "../../hooks/useUserState";
import CreateReview from "../../../features/bookingDetails/components/CreateReview";
import { BOOKING_STATUS } from "../../../const/BookingStatus";
import Button from "../../../commons/Button";
import NetworkConnected from "../../../commons/NetworkConnected";
import { DonateButton } from "../commons";

const BookingComplete = ({ booking, recall }) => {
  const { t } = useTranslate();
  const { userStore } = useUserState();
  const transactions = useMemo(() => {
    if (booking?.signed_by_admin)
      return [booking?.transactions?.find((item) => item.type === "payout")];
    return booking?.transactions?.filter(
      (item) => item.created_by === userStore?.id
    );
  }, [booking]);
  const isDisputeComplete = useMemo(
    () => !!booking?.dispute && booking?.status === BOOKING_STATUS.COMPLETE,
    [booking?.status, booking?.dispute]
  );

  const isHost = useMemo(() => {
    if (booking && userStore) {
      const hostId = booking?.listing?.user_id;
      const guestId = booking?.user_id;

      if (hostId === userStore?.id) return true;
      if (guestId === userStore?.id) return false;
    }
  }, [booking, userStore]);

  const [showEditReview, setShowEditReview] = useState(false);

  return (
    <div className="flex justify-center w-full flex-col items-center">
      <p className="text-3xl text-center text-nebula">
        {t("Booking Completed")}
      </p>
      <NetworkConnected chainId={booking?.transactions[0]?.network} />
      <p className="text-lg text-center text-nebula opacity-70">
        {t("The funds have been released")}
      </p>
      <p className="text-lg text-center text-nebula opacity-70">
        {t("We hope you had a great experience")}
      </p>
      <p className="text-lg text-center text-nebula opacity-70">
        {t("Thank you for choosing us as your option of trust")}
      </p>
      <div className="flex justify-center w-full my-5 gap-2">
        {transactions &&
          transactions?.map((item, id) => (
            <div key={id} className="flex justify-center flex-col items-center">
              <p className="text-nebula opacity-50 ">
                {t(WEB3_TX_TYPE_TEXT[item.type])}
              </p>
              <TransactionCompleted
                transactionHash={item.transaction_hash}
                isSign
                chainId={booking?.transactions[0]?.network}
              />
            </div>
          ))}
      </div>
      <DonateButton />
      {!showEditReview && isDisputeComplete && (
        <Button
          onClick={() => setShowEditReview(true)}
          className="px-5 rounded-lg py-2 my-5 text-lg bg-blueContrast"
        >
          {t("Edit Review")}
        </Button>
      )}
      {isDisputeComplete && showEditReview && (
        <CreateReview
          booking={booking}
          isHost={isHost}
          isEdit
          recall={recall}
          onHide={() => setShowEditReview(false)}
        />
      )}
    </div>
  );
};

export default BookingComplete;
