import React from "react";
import { KycComp, Main } from "../components";
import { ComponentTitle } from "../components/commons";

const Kyc = () => {
  return (
    <div
      className={`relative pb-[8em] max-w-[1000px] w-full bg-blueDark min-h-screen`}
    >
      <ComponentTitle title={"KYC"} center />
      <KycComp />
    </div>
  );
};

export default Kyc;
