import React from "react";
import Checkbox from "../../../commons/Checkbox";
import useTranslate from "../../../mobile/hooks/useTranslate";

const SignTermsConds = ({ onchange, value, falseClick }) => {
  const { t } = useTranslate();
  return (
    <div className="w-full flex flex-col justify-center items-center mb-10">
      <Checkbox
        labelPosition="top"
        classNameTextHelper="text-[#ff5959]"
        textHelper={
          falseClick ? t("You need to accept terms and conditions") : ""
        }
        value={value}
        setValue={(value) => onchange(value)}
        classNameInner="justify-center items-center"
        label={t(
          `I’m using BBOP by own risk, I’m paying my taxes and I’m allowing BBOP to sign as the third signature in case of any dispute`
        )}
      />
    </div>
  );
};

export default SignTermsConds;
