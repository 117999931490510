import React from "react";
import { format } from "date-fns";
import { useHistory, useTranslate } from "../../hooks";
import ManageLargeText from "../../../commons/ManageLargeText";

const BookingCard = ({
  imageSrc,
  title,
  user,
  startDate,
  endDate,
  id,
  statusText,
}) => {
  const { t } = useTranslate();
  const history = useHistory();

  return (
    <div
      onClick={() => history(`/booking?id=${id}`)}
      className="bg-blueContrast max-w-[500px] text-nebula flex items-center gap-4 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow w-full"
    >
      <span className="relative flex shrink-0 overflow-hidden rounded-md w-24 h-24 ">
        <img
          className="aspect-square h-full w-full object-cover"
          alt="Listing owner"
          src={imageSrc}
        />
      </span>
      <div className="flex-1 grid gap-1">
        <ManageLargeText
          className="font-semibold text-nebulaOpac"
          value={title}
          cutValue={25}
          maxLength={25}
        />
        <div className="flex items-center justify-between">
          <div className="text-sm text-nebulaOpac dark:text-gray-400 pr-4">
            {user}
          </div>
          {/* <ColorChip label={status} /> */}
        </div>
        <div className="flex items-center gap-2 text-sm text-nebulaOpac dark:text-gray-400">
          {/* <CiCalendar className='text-lg'/> */}
          <span>{format(new Date(startDate), "MMM dd, yyyy")}</span>
          <span>{"-"}</span>
          <span>{format(new Date(endDate), "MMM dd, yyyy")}</span>
        </div>
        <p className="m-0 text-sm opacity-50">{t(statusText)}</p>
      </div>
    </div>
  );
};

export default BookingCard;
