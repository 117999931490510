import { useTranslate } from "../../mobile/hooks";
import Flag from "../Flag";

const LanguageModal = ({ languages, selected, handleClick }) => {
  const { t } = useTranslate();
  return (
    <div className="w-full pb-10">
      <div className="text-xl w-full flex mt-10 gap-2 md:gap-5 overflow-x-scroll scrollbarNone">
        {languages &&
          languages?.map((item) => (
            <div
              key={item.id}
              onClick={() => handleClick(item.id)}
              className={`p-2 flex justify-between items-center w-fit border-2 ${
                Number(selected) === item.id
                  ? "border-lighGreen"
                  : "border-softgray"
              } rounded-lg cursor-pointer`}
            >
              {t(item.name)}
              <Flag countryCode={item.value} className="w-8 h-6 mx-2" />
            </div>
          ))}
      </div>
    </div>
  );
};

export default LanguageModal;
