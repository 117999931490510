import React, { useState, useMemo } from "react";
import gsap from "gsap";
import Header from "./Header";
import Footer from "./Footer";
import styled from "styled-components";
import { SwitchTransition } from "react-transition-group";
import { Transition } from "../commons/Transition";
import { allSteps } from "./utils";
import Starting from "./Presentation/Starting";
import Attributes from "./Presentation/Attributes";
import Photos from "./Presentation/Photos";
import Rules from "./Conditions/Rules";
import Information from "./Conditions/Information";

const stepsHash = {
  1: "presentation",
  2: "conditions",
  3: "address",
  4: "finish",
};

function Wizard() {
  const [path, setPath] = useState({ step: 1, substep: 1 });

  const [listing, setListing] = useState({
    name: "",
    description: "",
    category: 0,
    amenities: [],
    images: [],
    price: 0,
    rules_and_conditions: "",
    cancellation_policy: -1,
    security_deposit: 0,
  });

  const steps = useMemo(() => allSteps(listing), [listing]);

  const onTransition = (node, done, status) => {
    const isEntering = status === "entering";
    const children = node.hasChildNodes ? node.children : node;

    gsap.fromTo(
      [...children],
      {
        x: isEntering ? -10 : 0,
        opacity: isEntering ? 0 : 1,
      },
      {
        duration: 0.25,
        x: isEntering ? 0 : -10,
        opacity: isEntering ? 1 : 0,
        stagger: isEntering ? 0.1 : 0.05,
        onComplete: done,
      }
    );
  };

  const Component = (props) => ({
    presentation: {
      1: <Starting {...props} />,
      2: <Attributes {...props} />,
      3: <Photos {...props} />,
    },
    conditions: {
      1: <Rules {...props} />,
      2: <Information {...props} />,
    },
  });

  return (
    <WizardStyled>
      <Header />
      <SwitchTransition mode="out-in">
        <Transition
          key={path.substep}
          addEndListener={onTransition}
          mountOnEnter
          unmountOnExit
        >
          {
            Component({ listing, setListing })[stepsHash[path.step]][
              path.substep
            ]
          }
        </Transition>
      </SwitchTransition>
      <Footer path={path} setPath={setPath} steps={steps} />
    </WizardStyled>
  );
}

const WizardStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - 30px);
  font-size: 18px;
  .title {
    padding-bottom: 20px;
    font-size: 1.3em;
    font-weight: 500;
    line-height: 1;
  }
  *::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }

  *::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 7px;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  *::-webkit-scrollbar-button {
    display: none;
    width: 0;
    height: 0;
  }

  *::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export default Wizard;
