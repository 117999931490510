import React from "react";
import ButtonComp from "../Cards/ButtonComp";
import { useHistory, useTranslate } from "../../hooks";

const NoBookingCard = ({ title, message, buttonText }) => {
  const { t } = useTranslate();
  const history = useHistory();
  return (
    <div className="mb-4 rounded-lg border bg-blueContrast text-card-foreground text-nebula shadow-sm grid grid-cols-1 items-center justify-items-center gap-6 p-8 text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="h-12 w-12 text-gray-500 dark:text-gray-400"
      >
        <path d="M8 2v4"></path>
        <path d="M16 2v4"></path>
        <path d="M21 13V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8"></path>
        <path d="M3 10h18"></path>
        <path d="M16 19h6"></path>
      </svg>
      <div>
        <h3 className="text-xl font-semibold">{t(title)}</h3>
        <p className="text-gray-500 dark:text-gray-400">{t(message)}</p>
      </div>
      <ButtonComp
        title={t(buttonText)}
        onPress={() => {
          history("/explore");
        }}
      />
    </div>
  );
};

export default NoBookingCard;
