import React from "react";
import useTranslate from "../../../../mobile/hooks/useTranslate";

const HowToConnect = () => {
  const { t } = useTranslate();

  return (
    <div className="w-full">
      <p className="text-nebula text-start text-md md:text-xl">
        {t(
          "To fully harness the potential of BBOP and unlock its myriad benefits, follow these essential steps:"
        )}
      </p>
      <p className="text-nebula text-start text-md md:text-xl mt-5">
        <span className="font-bold">{`1 - ${t(
          "Browser Wallet Extension:"
        )}`}</span>
        {`${t(
          " First, make sure you have a browser extension of your web3 wallet installed. You'll need this extension to connect your wallet to the platform."
        )}`}
      </p>
      <p className="text-nebula text-start text-md md:text-xl mt-5">
        <span className="font-bold">{`2 - ${t(
          "Connect Your Web3 Wallet:"
        )}`}</span>
        {`${t(
          " After ensuring you have the necessary browser extension, connect your web3 wallet. Whether it's Metamask, Trust, or any other compatible wallet, this step is crucial to access all the features of BBOP. Once connected, you'll gain the freedom to book trips and even host fellow travelers."
        )}`}
      </p>
      <p className="text-nebula text-start text-md md:text-xl mt-5">
        <span className="font-bold">{`3 - ${t(
          "Secure Wallet Address Storage:"
        )}`}</span>
        {`${t(
          " Rest assured that your wallet address, provided through your web3 wallet, will be securely saved within your BBOP profile information. This ensures seamless transactions and interactions on the platform."
        )}`}
      </p>
      <p className="text-nebula text-start text-md md:text-xl mt-5">
        <span className="font-bold">{`4 - ${t("No Cost Involved:")}`}</span>
        {`${t(
          " Connecting your wallet is completely free of charge, so you have no need to worry about any hidden fees or expenses."
        )}`}
      </p>
      <p className="text-nebula text-start text-md md:text-xl mt-5">
        <span className="font-bold">{`5 - ${t(
          "Flexibility to Connect Later:"
        )}`}</span>
        {`${t(
          " If you're simply exploring the site or want to connect your wallet at a later time, no problem. Just look for the wallet icon located in the top-right corner. When you're ready, a single click will bring you right back to where you left off."
        )}`}
      </p>
      <p className="text-nebula text-start text-md md:text-xl mt-5">
        {t(
          "Additionally, please be aware that by using BBOP, you do so at your own discretion and risk. It's your responsibility to ensure compliance with any applicable tax regulations. In the event of a dispute, BBOP may act as a third-party mediator, with the authority to sign agreements as necessary to resolve the matter."
        )}
      </p>
      <p className="text-nebula text-start text-md md:text-xl mt-5">
        {t(
          "Your journey with BBOP begins by installing the necessary browser extension and then securely connecting your wallet, opening the door to a world of exciting travel experiences and opportunities."
        )}
      </p>
    </div>
  );
};

export default HowToConnect;
