import React from "react";
import Categories from "./Categories";
import Denomination from "./Denomination";
import styled from "styled-components";

function Starting(props) {
  return (
    <StartingStyled>
      <Denomination {...props} />
      <Categories {...props} />
    </StartingStyled>
  );
}

const StartingStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`;

export default Starting;
