import React from "react";
import styled from "styled-components";

function Footer({ steps, path, setPath }) {
  const stepsEntries = Object.entries(steps);

  const handleBack = () => {
    if (!checkBackConditions) return;
    if (path.substep === 1) {
      setPath({
        step: path.step - 1,
        substep: Object.keys(steps[path.step]).length,
      });
    } else {
      setPath((prev) => ({ ...prev, substep: prev.substep - 1 }));
    }
  };

  const handleNext = () => {
    if (!checkNextConditions) return;
    if (path.substep < Object.keys(steps[path.step]).length) {
      setPath((prev) => ({ ...prev, substep: prev.substep + 1 }));
    } else {
      setPath({ step: path.step + 1, substep: 1 });
    }
  };
  const checkBackConditions = () => {
    const { step, substep } = path;
    return !(step === 1 && substep === 1);
  };

  const checkNextConditions = () => {
    const { step, substep } = path;
    const completedSubsteps = Object.values(steps[step]);
    if (completedSubsteps.every(Boolean)) {
      if (step === stepsEntries.length) return false;
      return true;
    } else {
      const index = completedSubsteps.findIndex((i) => i === false) + 1;
      if (index === substep) return false;
      return true;
    }
  };

  return (
    <FooterStyled>
      <div className="bars">
        {stepsEntries.map(([stepKey, stepValue]) => {
          const values = Object.values(stepValue);
          const completed = values.filter(Boolean).length;
          const progress = (completed * 100) / values.length;

          return (
            <div key={stepKey} className="bar">
              <div className="progress" style={{ width: `${progress}%` }} />
            </div>
          );
        })}
      </div>
      <div className="options mt-4 px-4">
        <button
          className="back"
          disabled={!checkBackConditions()}
          onClick={handleBack}
        >
          Back
        </button>
        <button
          className="next"
          disabled={!checkNextConditions()}
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </FooterStyled>
  );
}

const FooterStyled = styled.div`
  /* position: fixed;
  bottom: 0;
  left: 0;
  width: 100%; */
  margin-top: auto;
  padding: 20px 10px 30px;
  border-radius: 12px 12px 0 0;
  background-color: #1c2c49;
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.2);
  z-index: 20;
  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
    font-weight: 500;
    .back {
      text-decoration: underline;
      transition: color 0.3s linear;
      &:disabled {
        color: rgba(255, 255, 255, 0.3);
      }
    }
    .next {
      padding: 10px 25px;
      border-radius: 12px;
      color: #1c2c49;
      background-color: #fff;
      transition: opacity 0.3s linear;
      &:disabled {
        opacity: 0.3;
      }
    }
  }
  .bars {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    .bar {
      position: relative;
      width: 100%;
      height: 3px;
      border-radius: 3px;
      background-color: rgba(255, 255, 255, 0.2);
      .progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: #fff;
        transition: width 0.3s linear;
      }
    }
  }
`;

export default Footer;
