import React, { useState } from "react";
import useSimpleAxios from "../../../hooks/useSimpleAxios";
import { mapConfig } from "../../hooks/useAxios";
import ManageLoading from "../../../commons/ManageLoading";
import { useTranslate } from "../../hooks";
import { SimpleAccordion } from "../../../commons/Accordion";

const heights = {
  0: {
    default: "75px",
    md: "75px",
  },
  1: {
    default: "200px",
    md: "150px",
  },
  2: {
    default: "220px",
    md: "150px",
  },
  3: {
    default: "150px",
    md: "120px",
  },
  4: {
    default: "150px",
    md: "120px",
  },
  5: {
    default: "75px",
    md: "75px",
  },
};

const EditCancellationPolicy = ({ listPolicy, onChange, disabled }) => {
  const { t } = useTranslate();
  const [open, setOpen] = useState("");
  const { value: policy, isLoaded } = useSimpleAxios(
    mapConfig("/policy/get", "get")
  );
  return (
    <ManageLoading height={"25em"} loading={!isLoaded} type="screen">
      <div className="flex flex-col w-full gap-2">
        {policy &&
          policy?.map((po) => {
            const isSelected = listPolicy?.cancellation_policy_id === po.id;
            return (
              <SimpleAccordion
                title={`${t(po.policy_name)}`}
                titleClassName={
                  "font-bold text-xl md:text-xl text-nebula justify-between"
                }
                height={heights[po.id]}
                select={{
                  isSelected,
                  onClick: () => {
                    onChange("listing_cancellation_policies", [
                      { ...listPolicy, cancellation_policy_id: po.id },
                    ]);
                  },
                }}
                key={po.id}
                id={po.id}
                openIsFromRoot
                openRoot={open === po.policy_name}
                setOpenRoot={() => {
                  setOpen((prev) =>
                    prev !== po.policy_name ? po.policy_name : ""
                  );
                }}
                className={"bg-[#1c2641] p-4 rounded-lg"}
                disabled={disabled}
              >
                <p>{t(po.description)}</p>
              </SimpleAccordion>
            );
          })}
      </div>
    </ManageLoading>
  );
};

export default EditCancellationPolicy;
