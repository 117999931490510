import React from "react";
import useSimpleAxios from "./useSimpleAxios";
import { mapConfig } from "./useAxios";

const useGetCitites = (search, type) => {
  const { value: cities, work } = useSimpleAxios(
    mapConfig(
      `/location/get?value=${search}${type ? `&type=${type}` : ""}`,
      "get"
    ),
    true,
    search,
    !!search
  );

  return {
    cities,
    loading: work,
  };
};

export default useGetCitites;
