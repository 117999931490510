import React, { useMemo } from "react";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useSelector } from "react-redux";

const useCurrentNetwork = () => {
  const wallets = useSelector((state) => state.wallets);
  const { selectedNetworkId } = useWeb3ModalState();
  const c = useMemo(
    // () => ({ ...wallets[selectedNetworkId], chainId: selectedNetworkId }),
    () => wallets[selectedNetworkId],
    [wallets, selectedNetworkId]
  );
  return { ...c, chainId: selectedNetworkId };
};

export default useCurrentNetwork;
