import React from "react";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import ManageLoading from "../../commons/ManageLoading";

export const CardSlider = ({
  children,
  id,
  loading,
  width,
  typeLoading,
  gap = "5",
}) => {
  const slideLeft = () => {
    let slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };
  const slideRight = () => {
    let slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  return (
    <ManageLoading loading={loading} type={typeLoading || ""}>
      <div
        style={{ width: width ? width : "100%" }}
        className={`flex items-center relative`}
      >
        <MdArrowBackIos
          className={`absolute z-[100] -left-[2em] opacity-50 cursor-pointer hover:opacity-100 transition-all text-lighGreen w-[3em] rounded-xl drop-shadow-md rounded-l-none hidden md:flex `}
          onClick={() => slideLeft()}
          size={80}
        />

        <div className="w-full overflow-x-hidden">
          <div
            style={{ display: "-webkit-box" }}
            className={`px-5 w-full overflow-x-scroll gap-${gap} scroll-smooth scrolling-touch items-start no-scrollbar  py-10 scrollbarNone`}
            id="slider"
          >
            {children}
          </div>
        </div>
        <MdArrowForwardIos
          className={`absolute z-[100] -right-[2.5em] opacity-50 cursor-pointer hover:opacity-100 transition-all text-lighGreen w-[3em] rounded-xl drop-shadow-md rounded-r-none hidden md:flex `}
          onClick={() => slideRight()}
          size={80}
        />
      </div>
    </ManageLoading>
  );
};
