import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { mapStyle } from "./MapStyle";

export default function GMapListing({
  coordinates,
  id,
  containerStyle = {
    width: "100%",
    height: "100%",
  },
  children,
  zoom = 10,
}) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLEMAPS_KEY}`,
  });

  if (!isLoaded) return <></>;

  return (
    <>
      <GoogleMap
        id={id}
        options={{
          styles: mapStyle,
          backgroundColor: "#101729",
          disableDefaultUI: true,
        }}
        mapContainerStyle={containerStyle}
        zoom={zoom}
        center={coordinates}
      >
        {children}
      </GoogleMap>
    </>
  );
}
