import React from "react";

var regex = new RegExp(".{1," + 25 + "}", "g");

const ListingInfo = ({ listing }) => {
  if (!listing) return <></>;
  return (
    <div className="w-full px-5 flex items-center justify-start gap-5 py-5">
      <div
        style={{
          backgroundImage: `url(${listing.listingImages[0].path})`,
          width: `8em`,
          height: `8em`,
        }}
        className={`rounded-lg bg-cover bg-center ${
          !listing.listingImages ? "bg-[#a0a0a0]" : ""
        }`}
      ></div>
      <div className="flex flex-col justify-center ">
        <p className="m-0 text-nebula text-2xl">{`${
          listing?.name?.match(regex)[0]
        }${listing?.name?.length > 25 ? "..." : ""}`}</p>
        <span className="text-nebulaOpac text-lg ">{`${listing?.listing_location?.city}, ${listing?.listing_location?.country}`}</span>
        <span className="text-nebulaOpac text-lg opacity-40 leading-4">
          &#9733;
          {` ${listing?.average_rating?.toFixed(1) || 0}`}
        </span>
      </div>
    </div>
  );
};

export default ListingInfo;
