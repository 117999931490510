import React, { useState } from "react";
import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";
import { Transition } from "react-transition-group";
import gsap from "gsap";

const SelectAccordion = ({ items, selected, select }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handle = (index, id) => {
    setActiveIndex((prev) => (prev === index ? null : index));
    select(id);
  };

  const onTransition = (node, done, isEnter) => {
    gsap.to(node, {
      duration: 0.5,
      opacity: isEnter ? 1 : 0,
      maxHeight: isEnter ? "600px" : 0,
      ease: "sine.out",
      onComplete: done,
    });
  };

  return (
    <SelectAccordionStyled>
      {items.map(({ id, policy_name, description }, index) => {
        const isActive = activeIndex === index;
        return (
          <div key={id} className="item" onClick={() => handle(index, id)}>
            <div className="header-item">
              <span>{policy_name}</span>
              <div className="check-wrapper">
                <div
                  className="icon"
                  style={{
                    transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                >
                  <IoIosArrowDown />
                </div>
                <div
                  className={`item-check ${selected === id ? "selected" : ""}`}
                />
              </div>
            </div>
            <Transition
              in={isActive}
              addEndListener={(node, done) =>
                onTransition(node, done, isActive)
              }
            >
              <div className="body-item">
                <div className="hidden-content">{description}</div>
              </div>
            </Transition>
          </div>
        );
      })}
    </SelectAccordionStyled>
  );
};

const SelectAccordionStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  user-select: none;
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 20px;
    border-radius: 14px;
    color: #fff;
    background-color: #1c2641;
    overflow: hidden;
    cursor: pointer;
    .header-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .check-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        .icon {
          transition: transform 0.2s linear;
        }
        .item-check {
          width: 15px;
          height: 15px;
          background: transparent;
          border-radius: 50%;
          border: solid 2px #fff;
          transition: background-color 0.2s linear;
          &.selected {
            background-color: #fff;
          }
        }
      }
    }

    .body-item {
      max-height: 0;
      font-size: 0.75em;
      color: rgba(255, 255, 255, 0.6);
      opacity: 0;
      .hidden-content {
        padding: 20px;
      }
    }
  }
`;

export default SelectAccordion;
