import { useEffect, useState } from "react";
import useAxios from "./useAxios";

//this is used to make simple axio calls returns a value that contains the response
// a recall function to make the fetch again whenever you want and a work to manage the loading
// search for examples with '//example useSimpleAxios'
const useSimpleAxios = (
  config,
  simple = true,
  dependencie = "",
  conditional = true
) => {
  const [value, setValue] = useState();
  const [work, setWork] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const { handleAxios, res } = useAxios();

  if (simple) {
    config.postFetch = (resp) => {
      if (config.formatValue) {
        const resFormated = config.formatValue(resp);
        setValue(resFormated);
        return;
      }
      setValue(resp);
    };
  }

  const recall = async (returnValue) => {
    setWork(true);
    let res;
    if (conditional && !returnValue) await handleAxios(config);
    if (conditional && !!returnValue)
      res = await handleAxios({ ...config, returnValue: true });
    setWork(false);
    setIsLoaded(true);
    if (!!returnValue) return res;
  };

  useEffect(() => {
    recall();
  }, [dependencie]);

  return { value, recall, work, isLoaded, res, setValue };
};

export default useSimpleAxios;
