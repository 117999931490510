import React from "react";

function JacuzziIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="247.313px"
      height="247.313px"
      viewBox="0 0 247.313 247.313"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill={props.color}
        d="M36.833 118.743a4.749 4.749 0 003.125 1.167c1.339 0 2.672-.56 3.615-1.65.74-.854 17.978-21.104 4.907-37.558-7.25-9.129-1.897-14.433-.726-15.422a4.777 4.777 0 00-5.995-7.442c-5.008 3.993-11.617 15.147-.763 28.81 8.139 10.242-4.527 25.214-4.651 25.356-1.72 1.995-1.51 5.015.488 6.739zM78.649 99.626a4.749 4.749 0 003.125 1.167c1.339 0 2.672-.56 3.614-1.65.74-.854 17.978-21.104 4.908-37.559-7.25-9.128-1.897-14.433-.726-15.422a4.78 4.78 0 00.721-6.681 4.776 4.776 0 00-6.716-.761c-5.008 3.993-11.617 15.147-.763 28.81 8.14 10.242-4.527 25.214-4.651 25.356-1.719 1.997-1.509 5.016.488 6.74zM115.669 99.626a4.749 4.749 0 003.125 1.167c1.339 0 2.672-.56 3.612-1.65.742-.854 17.98-21.104 4.91-37.559-7.25-9.128-1.893-14.433-.728-15.422a4.777 4.777 0 00.723-6.681 4.776 4.776 0 00-6.716-.761c-5.008 3.993-11.616 15.147-.763 28.81 8.137 10.242-4.527 25.214-4.651 25.356a4.787 4.787 0 00.488 6.74z"
      />
      <circle fill={props.color} cx={212.865} cy={88.353} r={26.285} />
      <path
        fill={props.color}
        d="M28.67 209.635h189.969c15.816 0 28.675-12.863 28.675-28.675v-27.209c0-5.47-5.396-9.778-13.895-13.189a24.33 24.33 0 01-.686 5.078 95.304 95.304 0 01-1.144 4.224c3.322 1.367 5.367 2.688 6.049 3.846-.094.172-.257.35-.411.531-.159.188-.284.369-.509.565-.247.215-.598.438-.924.658-.294.2-.556.396-.92.606-.401.224-.9.457-1.381.69-.42.21-.812.416-1.307.626-.047.023-.104.042-.149.064a38.399 38.399 0 00-3.528 1.345c-1.135.499-2.371.947-3.585 1.409 2.147-4.756 4.252-10.407 5.806-16.773 3.146-12.815-7.354-24.278-19.503-25.004-2.562-.154-5.358.124-8.229.635-4.779-3.311-14.337-8.09-32.138-11.374 3.436-7.507 5.545-17.826-1.727-26.983-7.248-9.128-1.895-14.433-.729-15.422a4.776 4.776 0 00-5.992-7.439c-5.003 3.99-11.611 15.145-.761 28.808 4.942 6.221 2.208 14.178-.728 19.508-9.549-1.235-20.909-2.056-34.567-2.147h-.068c-5.246 0-9.519 4.24-9.551 9.495-.035 5.278 4.214 9.588 9.496 9.621 26.648.178 43.272 3.244 53.298 6.269-2.311 1.73-4.462 3.57-6.217 5.544-2.674 3.02-5.605 6.329-8.605 9.735-21.567 3.379-34.816-.942-35.181-1.073-4.95-1.778-10.396.774-12.205 5.713-1.815 4.956.733 10.444 5.689 12.255.677.253 9.362 3.268 23.877 3.52a74.123 74.123 0 00-4.126 5.307 424.27 424.27 0 01-8.689-.112c-.453-.056-.915-.107-1.373-.168-2.977.042-5.943.089-9.044.089-4.44 0-8.755-.042-12.951-.121-1.577-.028-3.05-.084-4.592-.127-2.553-.069-5.139-.125-7.593-.219-2.224-.084-4.322-.201-6.461-.304-1.65-.079-3.356-.149-4.957-.237a575.862 575.862 0 01-8.039-.547c-.85-.061-1.752-.111-2.59-.177-41.128-3.221-64.298-10.207-66.839-14.725 3.397-6.03 43.509-16.452 114.019-16.452 14.08 0 26.887.43 38.489 1.139l4.896-5.535c-9.539-2.002-23.573-3.794-43.476-3.92a14.153 14.153 0 01-5.614-1.194C93.456 128.124 0 130.915 0 153.751v27.209c-.005 15.811 12.855 28.675 28.67 28.675z"
      />
    </svg>
  );
}

export default JacuzziIcon;
