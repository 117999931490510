import React from "react";
import { useHistory, useTranslate } from "../../hooks";
import { Card, StarRating } from "../commons";

const ListingCardExplore = ({ listing, onClick, className }) => {
  const { t } = useTranslate();
  const history = useHistory();
  if (!listing) return <></>;
  return (
    <Card
      className={`max-w-[400px] ${className}`}
      classNameInnerCard="m-1 border border-blueDark"
      classNameImage="w-full h-[15em] md:h-[20em] rounded-tr-xl rounded-tl-xl"
      key={listing.id}
      data={listing?.listing_images || listing?.listingImages}
      interval={false}
      onClick={() => {
        if (onClick) {
          onClick();
          return;
        }
        history(`/listing?id=${listing.id}`);
      }}
    >
      <div className="p-4 pb-8 rounded-xl w-full">
        <p className="text-xl text-nebula">{listing.name}</p>
        <p className="text-nebulaOpac opacity-50 text-lg px-1">{`${listing?.location?.city}, ${listing?.location?.country}`}</p>
        <div className="flex items-center justify-between pt-8">
          <p className="text-2xl font-medium text-nebulaOpac">
            {"$" + listing?.price + " "}
            <span className="text-lg opacity-50">{t("night")}</span>
          </p>
          <StarRating
            routeRating={listing.average_rating}
            disabled={true}
            showRating={true}
            oneStar={true}
            classNameRating="text-[1em] !pl-1"
            classNameStars="text-[1.2em]"
          />
        </div>
      </div>
    </Card>
  );
};

export default ListingCardExplore;
