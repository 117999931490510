import React, { useEffect } from "react";
import NotificationItem from "./NotificationItem";
import useTranslate from "../../mobile/hooks/useTranslate";
import useSocket from "../../hooks/useSocket";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import useNotifications from "../../hooks/useNotifications";
import ManageLoading from "../../commons/ManageLoading";
import { useStore } from "../../mobile/hooks/useStore";

const Notifications = ({ onHide }) => {
  const { notifications, setNotifications } = useStore();
  const { t } = useTranslate();

  const {
    get,
    notifications: notis,
    next,
    loading,
    hasMore,
  } = useNotifications();

  useEffect(() => {
    get();
  }, []);

  const { receive } = useSocket();

  const { callNext, work } = useInfiniteScroll({
    isLoading: loading,
    next: () => {
      next((res) => {
        setNotifications([...notifications, ...res?.data]);
      });
    },
    direction: "y",
  });

  useEffect(() => {
    receive("noti", (data) => {
      if (data?.length > 0) {
        setNotifications([...data, ...notifications]);
      }
    });
  }, [notifications]);

  return (
    <div className="px-2 flex flex-col gap-5">
      <div
        className="h-[88vh] overflow-y-scroll flex flex-col gap-4 scrollbarNone pb-[10em]"
        onScroll={(e) => {
          if (hasMore) callNext(e);
        }}
      >
        {notifications &&
          notifications?.map((noti, id) => (
            <NotificationItem
              key={id}
              {...noti}
              onHide={() => {
                if (onHide) onHide();
              }}
            />
          ))}
        <ManageLoading loading={work} className="w-full flex justify-center">
          <></>
        </ManageLoading>
      </div>
    </div>
  );
};

export default Notifications;
