import React, { useEffect, useMemo, useState } from "react";
import TextArea from "../../../commons/TextArea";
import useTranslate from "../../../mobile/hooks/useTranslate";
import useReview from "../../../hooks/useReview";
import Button from "../../../commons/Button";
import useUserState from "../../../mobile/hooks/useUserState";
import useMessages from "../../../hooks/useMessages";
import ManageLoading from "../../../commons/ManageLoading";
import { MdOutlineCancel } from "react-icons/md";
import { StarRating } from "../../../mobile/components/commons";

const CreateReview = ({
  booking,
  setReviewSentRoot,
  isHost,
  isEdit,
  recall,
  onHide,
}) => {
  const { t } = useTranslate();
  const [reviewContent, setReviewContent] = useState();
  const [rating, setRating] = useState(0);
  const review = useReview();
  const [reviewSent, setReviewSent] = useState(false);
  const { userState } = useUserState();
  const [messageContent, setMessageContent] = useState();
  const [error, setError] = useState();
  const message = useMessages();

  useEffect(() => {
    if (booking?.review_sent) {
      setRating(booking?.review_sent?.rating);
      setReviewSent(true);
      setReviewContent(booking?.review_sent?.comment);
      if (setReviewSentRoot) setReviewSentRoot(true);
    }
  }, [booking]);

  const handleSubmitContinue = async () => {
    setError();
    await review.create({
      booking,
      isHost,
      body: {
        rating: rating.toString(),
        comment: reviewContent || "",
      },
      postFetch: () => {
        setReviewContent();
        setReviewSent(true);
        if (setReviewSentRoot) setReviewSentRoot(true);
      },
    });

    if (messageContent) {
      const sendProps = {
        from: userState?.user?.id,
        content: messageContent,
        guest_id: booking?.user_id,
        listingId: booking?.listing?.id,
      };
      if (isHost) {
        sendProps.host_receiver_status = "READ";
        sendProps.guest_receiver_status = "UNREAD";
      }
      if (!isHost) {
        sendProps.host_receiver_status = "UNREAD";
        sendProps.guest_receiver_status = "READ";
      }
      if (userState?.user?.id === booking?.listing?.user_id) {
        sendProps.to = booking?.user_id;
      }
      if (userState?.user?.id === booking?.user_id) {
        sendProps.to = booking?.listing?.user_id;
      }
      message.send(sendProps);
    }
  };

  const handleDisable = () => {
    if (!rating) {
      setError({ is: true, message: "You most select a rating star" });
      return true;
    }
    if (!reviewContent) {
      setError({ is: true, message: "You most write a public comment" });
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (error?.is) {
      const res = handleDisable();
      if (!res) {
        setError();
      }
    }
  }, [reviewContent, rating]);

  const isReviewSent = useMemo(
    () => booking?.review_sent || reviewSent,
    [booking?.review_sent, reviewSent]
  );

  const title = useMemo(() => {
    if (isEdit) return t("Edit your review");
    if (isHost) return t("We hope everything went well");
    if (!isHost) return t("We hope you enjoyed your stay");
  }, [isHost, isEdit]);

  const showEdit = useMemo(
    () => isReviewSent && !isEdit,
    [isReviewSent, isEdit]
  );

  return showEdit ? (
    <></>
  ) : (
    <div className="flex flex-col w-full justify-center items-center">
      {onHide && isEdit && (
        <div className="w-full flex justify-end items-center">
          <MdOutlineCancel
            onClick={onHide}
            className="text-3xl cursor-pointer text-nebula"
          />
        </div>
      )}
      {!isEdit && (
        <p className="text-2xl font-semibold text-center text-nebula">
          {t("Please leave a quick review")}
        </p>
      )}
      <div>
        <StarRating
          editFromParent={{ rating, setRating }}
          disabled={showEdit}
        />
      </div>
      <TextArea
        resize={false}
        limit="350"
        titleInside
        className="my-5"
        rows="6"
        cols="80"
        disabled={showEdit}
        value={
          isEdit
            ? reviewContent
            : booking?.review_sent?.comment || reviewContent
        }
        setValue={setReviewContent}
        title={
          booking?.review_sent || showEdit
            ? t("Comment Sent")
            : t("Leave a public comment")
        }
      />
      {error?.is && !showEdit && (
        <p className="text-cancel">{t(error?.message)}</p>
      )}
      <ManageLoading className="py-5" loading={review?.work}>
        <Button
          className="bg-blueContrast rounded-lg px-10 py-3 text-2xl text-nebula w-fit"
          onClickDisable={handleDisable}
          disable={!rating || !reviewContent}
          onClick={async () => {
            if (isEdit) {
              await review.edit(
                {
                  id: booking?.review_sent?.id,
                  comment: reviewContent,
                  rating,
                },
                () => {
                  recall();
                  onHide();
                }
              );
              return;
            }
            handleSubmitContinue();
          }}
        >
          {isEdit ? t("Save") : t("Submit review")}
        </Button>
      </ManageLoading>
    </div>
  );
};

export default CreateReview;
