import React, { useMemo } from "react";
import { useTranslate } from "../../hooks";
import { IoChevronBackOutline, IoChevronForwardSharp } from "react-icons/io5";
import ManageLoading from "../../../commons/ManageLoading";

const PreviousNext = ({
  pageIndex,
  hasMore,
  next,
  previous,
  loading,
  text = false,
  desktop,
}) => {
  const { t } = useTranslate();
  const showActions = useMemo(() => {
    if (hasMore) return true;
    if (pageIndex > 0) return true;
    return false;
  }, [hasMore, pageIndex]);

  if (!showActions) return <></>;

  if (desktop)
    return (
      <div className="text-nebula w-full flex items-center justify-between gap-2 text-lg">
        <div
          onClick={() => (pageIndex > 0 ? previous() : {})}
          className={`${
            pageIndex > 0 ? "" : "opacity-40"
          } bg-blueContrast cursor-pointer rounded-lg text-nebula py-3 px-7 justify-center flex items-center gap-2`}
        >
          <ManageLoading loading={loading === "prev"} size={19}>
            <IoChevronBackOutline className="text-xl" />
            {text && t("Previous")}
          </ManageLoading>
        </div>
        <div
          onClick={() => (hasMore ? next() : {})}
          className={`${
            hasMore ? "" : "opacity-40"
          } bg-blueContrast cursor-pointer rounded-lg text-nebula py-3 px-7 justify-center flex items-center gap-2`}
        >
          <ManageLoading loading={loading === "next"} size={19}>
            {text && t("Next")}
            <IoChevronForwardSharp className="text-xl" />
          </ManageLoading>
        </div>
      </div>
    );

  return (
    <div className="text-nebula w-fit flex items-center gap-2 text-lg">
      <ManageLoading loading={loading}>
        {pageIndex > 0 && (
          <div
            onClick={() => previous()}
            className={` bg-blueContrast rounded-lg text-nebula py-3 px-5 flex items-center gap-2`}
          >
            <IoChevronBackOutline className="text-xl" />
            {text && t("Previous")}
          </div>
        )}
        {hasMore && (
          <div
            onClick={() => next()}
            className={` bg-blueContrast rounded-lg text-nebula py-3 px-5 flex items-center gap-2`}
          >
            {text && t("Next")}
            <IoChevronForwardSharp className="text-xl" />
          </div>
        )}
      </ManageLoading>
    </div>
  );
};

export default PreviousNext;
