import toast, { Toaster } from "react-hot-toast";
import { MdOutlineCancel } from "react-icons/md";

export default function useToast(props) {
  let notify = {};
  if (props?.length !== 0) {
    props.forEach(({ position, type, key }) => {
      if (type === "success") {
        notify[key] = (title) => {
          // e.preventDefault();
          toast.success(title || "Successfully done", {
            position,
            appearance: "error",
            autoClose: 3000,
            hideProgressBar: true,
            closeButton: true,
            closeOnClick: true,
            pauseOnHover: true,
          });
        };
      } else if (type === "error") {
        notify[key] = (title) => {
          toast((t) => (
            <div className="flex items-center">
              <MdOutlineCancel className="w-[25px] h-[25px] mr-2" />

              <p>{title}</p>
            </div>
          ));
        };
      } else if (type === "warning") {
        notify[key] = (title) => {
          toast((t) => (
            <div className="flex items-center">
              <MdOutlineCancel className="w-[25px] h-[25px] mr-2" />

              <p>{title}</p>
            </div>
          ));
        };
      }
    });
  } else {
    console.log("the props must be an array in useToast");
  }

  return { notify, Toaster };
}
