import React, { useState } from "react";
import Button from "../../src/commons/Button";
import useAxios, { mapConfig } from "../hooks/useAxios";
import useToast from "../hooks/useToast";
import useTranslate from "../mobile/hooks/useTranslate";
import useGlobalComp from "../hooks/useGlobalComp";

function ForgotPasswordModal({ setOpenForgotPassword }) {
  const [email, setEmail] = useState("");
  const { t } = useTranslate();
  const { handleAxios } = useAxios();
  const [error, setError] = useState(false);
  const { loginModal, signUpModal } = useGlobalComp();

  const { notify } = useToast([
    {
      position: "top-center",
      type: "success",
      key: "s",
    },
    {
      position: "top-center",
      type: "error",
      key: "e",
    },
  ]);

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      notify.e(t("All fields are required"));
      return;
    }

    if (!isEmailValid(email)) {
      notify.e(t("Email is invalid"));
      return;
    }

    const data = {
      email,
    };

    handleAxios(
      mapConfig("/forgot/recoverPassword", "post", {
        body: data,
        postFetch: () => {
          setOpenForgotPassword(false);
          loginModal(false);
        },
        postErrorFetch: () => {
          setError(true);
        },
        toast: false,
      })
    );
  };

  return (
    <div className="w-full flex flex-col gap-10 p-4 ">
      <p className="text-center text-md md:text-lg">
        {t(
          `Enter the email address asociated with your account, and we'll email you a link to reset your password.`
        )}
      </p>
      <input
        className="rounded-md p-2 drop-shadow-md"
        type="email"
        placeholder="Email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      {error && (
        <p className="text-cancel">An error occurred. Please try again.</p>
      )}
      <div className="w-full flex justify-center">
        <Button
          className="rounded-full px-[1em] py-2 w-fit mt-5 min-h-fit"
          type="submit"
          onClick={onSubmit}
        >
          {t("Send reset link")}
        </Button>
      </div>
    </div>
  );
}

export default ForgotPasswordModal;
