import React from "react";
import { BiDonateHeart } from "react-icons/bi";
import { useHistory, useTranslate } from "../../hooks";

const DonateButton = () => {
  const { t } = useTranslate();
  const history = useHistory();
  return (
    <div className="w-full flex items-center justify-center">
      <div
        className="border border-nebula rounded-lg py-2 px-8 flex items-center gap-3 bg-nebula cursor-pointer"
        onClick={() => history("/donate")}
      >
        <p className="m-0 text-xl">{t("Donate")}</p>
        <BiDonateHeart className="text-2xl" />
      </div>
    </div>
  );
};

export default DonateButton;
