import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const Portal = ({ children, id }) => {
  const containerRef = useRef(document.createElement("div"));

  useEffect(() => {
    const container = containerRef.current;

    if (id) container.id = id;

    document.body.appendChild(container);

    return () => {
      document.body.removeChild(container);
    };
  }, [id]);

  return ReactDOM.createPortal(children, containerRef.current);
};

export default Portal;
