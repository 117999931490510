import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MainChat from "../../../features/userdashboard/Host/components/Messages/components/LiveChat/MainChat";
import { useMsgs } from "../../hooks";
import MainChatLogic from "../../../features/userdashboard/Host/components/Messages/logic/MainChatLogic";
import useSimpleAxios from "../../hooks/useSimpleAxios";
import { mapConfig } from "../../hooks/useAxios";

const ChatComp = () => {
  const [chatSelected, setChatSelected] = useState();
  const { state } = useLocation();
  const { createChat } = useMsgs();

  const { value } = useSimpleAxios(
    mapConfig(
      `/messages/findChat?listing_id=${state?.listingRes?.id}&user_id=${state?.userRes?.id}`,
      "get"
    )
  );

  useEffect(() => {
    if (value?.docId) {
      setChatSelected({ ...state, chatId: value?.docId });
      return;
    }
    setChatSelected(state);
  }, [state, value]);

  const chatLogic = MainChatLogic({ chatSelected });

  return (
    <MainChat
      {...chatLogic}
      chatSelected={chatSelected}
      createChat={() => {
        createChat(
          chatLogic.chat,
          chatSelected?.userRes?.id,
          chatSelected?.listingRes?.id,
          (res) => {
            setChatSelected((prev) => ({ ...prev, chatId: res.docId }));
            chatLogic.setChat("");
          }
        );
      }}
    />
  );
};

export default ChatComp;
