import React from "react";
import { BsSuitHeart } from "react-icons/bs";
import useToast from "../../../hooks/useToast";
import useUserState from "../../hooks/useUserState";
import FavButtonDetails from "./FavButtonDetails";
import useTranslate from "../../hooks/useTranslate";

const FavButton = ({ listing }) => {
  const { isLogged } = useUserState();
  const { t } = useTranslate();
  const { notify } = useToast([
    {
      position: "top-center",
      type: "error",
      key: "e",
    },
  ]);
  return (
    <>
      {isLogged ? (
        <FavButtonDetails
          listing_id={listing?.id}
          listing_name={listing?.name}
          listing={listing}
        />
      ) : (
        <button
          onClick={(e) => {
            e.stopPropagation();
            notify.e(t("You need to login first"));
          }}
          className={`text-3xl`}
        >
          <BsSuitHeart className="transition-all duration-300 text-[#097F9A]" />
        </button>
      )}
    </>
  );
};

export default FavButton;
