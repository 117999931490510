import { format } from "date-fns";
import React, { useEffect, useMemo, useRef } from "react";
import { MdRefresh } from "react-icons/md";
import useHistory from "../../../../../../../mobile/hooks/useHistory";
import TextMessage from "./TextMessage";
import ChatInput from "./Chat";
import {
  BackButton,
  Divider,
  ProfileImage,
} from "../../../../../../../mobile/components/commons";
import { useStore } from "../../../../../../../mobile/hooks/useStore";
import useAxios, {
  mapConfig,
} from "../../../../../../../mobile/hooks/useAxios";
import { useUserState } from "../../../../../../../mobile/hooks";
import useGetNotReadNotis from "../../../../../../../mobile/hooks/useGetNotReadNotis";
import ManageLargeText from "../../../../../../../commons/ManageLargeText";
import { useLocation } from "react-router-dom";

const LiveChat = ({
  chatSelected,
  createChat,
  handleResponseMsg,
  setChat,
  messages,
  chat,
  refreshData,
  setRefreshData,
}) => {
  const history = useHistory();
  const { isLogged, userStore } = useUserState();
  const { state } = useLocation();
  const lastMsgRef = useRef();

  const { handleAxios } = useAxios();

  const { msgNotRead, setMsgNotRead } = useStore();

  const readMsgs = async (ids) => {
    if (isLogged) {
      await handleAxios(
        mapConfig(`/notifications/read?msg=true`, "put", {
          toast: false,
          postFetch: (res) => {
            const newMsgsNotRead = msgNotRead?.filter(
              (item) => !res.read?.includes(item.id)
            );
            setMsgNotRead(newMsgsNotRead);
          },
          body: { ids },
        })
      );
    }
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  }

  const maxLength = 200;

  useEffect(() => {
    if (messages?.length > 0) {
      lastMsgRef.current.scrollIntoView();
    }
  }, [messages]);

  const { getNotReadNotis } = useGetNotReadNotis();

  const msgsToRead = useMemo(() => {
    const res = msgNotRead
      .filter((m) => m.info.docId === chatSelected?.chatId)
      .map((m) => m.id);

    return res;
  }, [msgNotRead, chatSelected]);

  return (
    <div className="flex flex-col relative overflow-y-scroll scrollbarNone">
      <div className="fixed top-[-2px] max-w-[700px] w-full h-[5em] flex justify-between items-center rounded-br-lg rounded-bl-lg bg-blueContrast px-2">
        <div className="flex  items-center justify-center gap-2 h-full">
          <BackButton
            onClick={() => {
              if (msgsToRead?.length > 0) {
                readMsgs(msgsToRead);
              }
              history(state.from || "/msg");
            }}
            className="cursor-pointer text-2xl p-2  left-[0.5em] z-[1000] rounded-full bg-nebula text-buttonDark"
          />
          <ProfileImage
            url={chatSelected?.userRes?.profile_image_url}
            size="3"
          />
          <div
            className="font-medium text-white text-sm md:text-lg cursor-pointer"
            onClick={() => {
              if (msgsToRead?.length > 0) {
                readMsgs(msgsToRead);
              }
              history(`/user?id=${chatSelected?.userRes?.id}`);
            }}
          >
            {chatSelected?.userRes?.username}
          </div>
          <Divider direction="y" color="blueDark" py="3" px="3" />
          <ManageLargeText
            onClick={() => {
              if (msgsToRead?.length > 0) {
                readMsgs(msgsToRead);
              }
              history(`/listing?id=${chatSelected?.listingRes?.id}`);
            }}
            className="font-medium text-white text-sm md:text-lg cursor-pointer"
            maxLength={13}
            cutValue={13}
            value={chatSelected?.listingRes?.name}
          />
        </div>
        <button
          onClick={() => {
            setRefreshData(!refreshData);
            getNotReadNotis();
          }}
          className="py-2 text-center rounded-full drop-shadow-lg border border-greenDark text-greenDark bg-white"
        >
          <MdRefresh className="w-10 h-6" />
        </button>
      </div>

      <div className="w-full overflow-y-scroll h-full scrollbarNone px-2 pt-[5em]">
        {messages?.map((item, index) => {
          const isMine = item?.send_by_userid === userStore?.id;
          const textPlace = isMine ? "justify-end" : "justify-start";
          return (
            <div
              key={item.createdAt}
              className={`flex w-full mb-0 ${textPlace}`}
            >
              <div className={`w-full max-w-[70%] flex ${textPlace}`}>
                <TextMessage
                  time={
                    item.createdAt
                      ? format(new Date(item.createdAt), "dd/MM/yyyy HH:mm")
                      : ""
                  }
                  text={item?.content}
                  background={isMine ? "bg-lighGreen" : "bg-white"}
                  day={"Hoy"}
                />
              </div>
            </div>
          );
        })}
        <div ref={lastMsgRef}></div>
      </div>
      <div className="fixed bottom-0 max-w-[700px] w-full h-[5em] flex justify-between items-center rounded-tr-lg rounded-tl-lg bg-blueContrast px-2">
        <ChatInput
          onSubmit={(e) => {
            e.preventDefault();
            if (!chat) return;
            if (!chatSelected?.chatId) {
              createChat();
              return;
            }
            handleResponseMsg(chatSelected, () => {
              lastMsgRef.current.scrollIntoView();
            });
          }}
          onChange={(e) => {
            setChat(e.target.value);
          }}
          disabled={chat === "" ? true : false}
          value={chat}
          buttonStyle={chat ? "bg-lighGreen" : "bg-[#abacae] "}
          iconColor={chat ? "text-[#101729]" : "text-[##898b8e]"}
        />
      </div>
    </div>
  );
};

export default LiveChat;
