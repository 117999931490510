import React, { useEffect } from "react";
import { useValidation } from "../../../../hooks/useValidation";
import { InputText } from "../../../../commons/Form";
import { useTranslate } from "../../../hooks";
import TextArea from "../../../../commons/TextArea";
import { useStore } from "../../../hooks/useStore";
import useRejection from "../../../../hooks/useRejection";

const validate = [
  {
    keyValue: "name",
    valid: [{ key: "required" }],
  },
  {
    keyValue: "description",
    valid: [{ key: "required" }],
  },
];

const Presentation = ({ listing, onChange, setChangeRejections }) => {
  const { t } = useTranslate();
  const { setErrorEditListing } = useStore();
  const { handleFindRejectionToUpdate } = useRejection(listing);

  const { errors, hasErrors } = useValidation({
    values: {
      name: listing?.name || "",
      description: listing?.description || "",
    },
    validate,
  });

  useEffect(() => {
    setErrorEditListing(hasErrors);
  }, [hasErrors]);

  return (
    <div className="flex flex-col gap-5">
      <InputText
        className="flex-col !items-start"
        classNameInput={`border-2 rounded-xl ${
          handleFindRejectionToUpdate("title")
            ? "border-cancel"
            : "border-blueDark"
        }`}
        value={listing?.name}
        error={errors?.name}
        label={t("Name")}
        onChange={(v) => {
          const r = handleFindRejectionToUpdate("title");
          if (r) setChangeRejections(r);
          onChange("name", v.target.value);
        }}
      />
      <TextArea
        resize
        className="flex-col"
        classNameInput={`border-2 rounded-xl ${
          handleFindRejectionToUpdate("description") ? "!border-cancel" : ""
        }`}
        error={errors?.description}
        value={listing?.description}
        title={t("Description")}
        setValue={(v) => {
          const r = handleFindRejectionToUpdate("description");
          if (r) setChangeRejections(r);
          onChange("description", v);
        }}
        rows="15"
      />
    </div>
  );
};

export default Presentation;
