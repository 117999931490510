import React from "react";
import useContract, { useFactory, useSendCrypto } from "./useContract";
import useCurrentNetwork from "./useCurrentNetwork";
import { parseEventLogs } from "viem";

const useCreateReservation = (address, args, callback) => {
  const currentNetwork = useCurrentNetwork();
  return useContract(
    address,
    "requestReservation",
    args,
    callback,
    (receipt) => {
      const decodedLogs = parseEventLogs({
        abi: currentNetwork?.abi,
        logs: receipt.logs,
      });
      return decodedLogs.reduce(
        (a, v) => ({
          ...a,
          [v.eventName]: {
            ...v,
            returnValues: {
              ...v.args,
              reservationId: Number(v.args.reservationId),
            },
          },
        }),
        {}
      );
    }
  );
};

const useFinishReservation = (address, args, callback) =>
  useContract(address, "finishReservation", args, callback);

const useAcceptDisputeOffer = (address, args, callback) =>
  useContract(address, "acceptDisputeOffer", args, callback);

const useCancelReservation = (address, args, callback) =>
  useContract(address, "cancelReservation", args, callback);

const useCreateContract = (address, args, callback) => {
  const currentNetwork = useCurrentNetwork();
  return useFactory(address, args, callback, (receipt) => {
    const decodedLogs = parseEventLogs({
      abi: currentNetwork?.factory?.abi,
      logs: receipt.logs,
    });
    return decodedLogs;
  });
};

const useDonate = (address, value, abi, callback) =>
  useSendCrypto(address, value, abi, callback);

export {
  useCreateReservation,
  useFinishReservation,
  useAcceptDisputeOffer,
  useCancelReservation,
  useCreateContract,
  useDonate,
};
