import React, { useEffect, useMemo, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FiX } from "react-icons/fi";
import Divider from "../../../commons/Divider";
import { InputText } from "../../../commons/Form";
import useTranslate from "../../../mobile/hooks/useTranslate";
import { DisputeCardAction } from "./DisputeCardAction";
import useUserState from "../../../mobile/hooks/useUserState";

export const DisputeCard = ({
  users,
  recall,
  bookingId,
  dispute,
  contractAddress,
  ...props
}) => {
  return (
    <div className="flex flex-col justify-center items-center w-full md:w-1/2 my-5">
      <DisputeCardContent
        bookingId={bookingId}
        recall={recall}
        users={users}
        dispute={dispute}
        {...props}
      />
    </div>
  );
};

const DisputeCardContent = ({
  recall,
  users,
  bookingId,
  dispute,
  offer,
  ...props
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [host, setHost] = useState(users?.total / 2);
  const [guest, setGuest] = useState(users?.total / 2);
  const [error, setError] = useState(false);
  const { t } = useTranslate();
  const { user } = useUserState();
  const imHost = useMemo(
    () => user?.id === users?.host_id,
    [user, users?.host_id]
  );

  useEffect(() => {
    if (!offer && props.yours) {
      setIsEdit(true);
    }
  }, []);

  const onChange = (change, value) => {
    if (change === "guest") {
      setGuest(value);
      setHost((users?.total - value).toFixed(2));
    }
    if (change === "host") {
      setGuest((users?.total - value).toFixed(2));
      setHost(value);
    }
  };

  return (
    <div className={`w-full`}>
      <div className="rounded-xl relative flex flex-col items-center py-8 px-5 w-full h-[17em] md:h-[16em] justify-center bg-blueContrast">
        <div className="flex flex-col justify-start items-center w-full">
          <p className="text-nebula text-2xl w-full flex justify-center items-center font-semibold">
            {props.title}
          </p>
          <Divider className="bg-nebula h-[2px] my-5 mx-1 opacity-10" />
        </div>
        <div className="text-nebula h-full flex flex-col justify-center items-center w-full">
          {props.yours && !!props.from && (
            <div className="h-fit w-full flex items-start absolute top-[2em] right-[1em] justify-end pb-5">
              {isEdit ? (
                <>
                  <FiX
                    onClick={() => setIsEdit((prev) => !prev)}
                    className="cursor-pointer  text-nebula text-xl w-6 h-6 rounded-md shadow-md"
                  />
                </>
              ) : (
                <>
                  <AiOutlineEdit
                    onClick={() => setIsEdit((prev) => !prev)}
                    className="cursor-pointer  text-nebula text-xl w-6 h-6 rounded-md shadow-md"
                  />
                </>
              )}
            </div>
          )}

          <div className="flex justify-around w-full">
            <div className="flex flex-col w-full md:w-1/2 text-md items-center">
              <p className="font-medium pb-2">{t("Payment to host")}:</p>
              {isEdit && (
                <InputText
                  value={host}
                  classNameRoot="flex justify-center"
                  onChange={(e) => onChange("host", Number(e.target.value))}
                  className={imHost ? "!bg-blueDark" : "!bg-transparent"}
                  classNameInput={`${
                    imHost ? "!bg-blueDark" : "!bg-transparent !text-nebula"
                  } w-full text-center`}
                  type="number"
                  minValue={0}
                  maxValue={users?.total}
                  disabled={!imHost}
                />
              )}
              {!isEdit && <p>{`${props.amount_to_host} USDT`}</p>}
            </div>
            <div className="flex flex-col w-full md:w-1/2 text-md items-center">
              <p className="font-medium pb-2">{t("Payment to guest")}:</p>
              {isEdit && (
                <InputText
                  value={guest}
                  classNameRoot="flex justify-center"
                  onChange={(e) => onChange("guest", Number(e.target.value))}
                  className={!imHost ? "!bg-blueDark" : "!bg-transparent"}
                  classNameInput={`${
                    !imHost ? "!bg-blueDark" : " !bg-transparent !text-nebula "
                  } w-full text-center`}
                  type="number"
                  minValue={0}
                  maxValue={users?.total}
                  disabled={imHost}
                />
              )}
              {!isEdit && <p>{`${props.amount_to_guest} USDT`}</p>}
            </div>
          </div>
        </div>
      </div>
      {!dispute?.booking?.signed_by_admin && (
        <div className="w-full flex justify-center">
          <DisputeCardAction
            contractAddress={dispute?.listing?.user?.contract_address}
            title={props.title}
            dispute={dispute}
            setIsEdit={setIsEdit}
            showPropose={isEdit}
            offerAmounts={{
              host: parseFloat(host),
              guest: parseFloat(guest),
            }}
            bookingId={bookingId}
            recall={recall}
            {...props}
            users={users}
            disable={isNaN(parseFloat(guest)) || isNaN(parseFloat(host))}
          />
        </div>
      )}
    </div>
  );
};
