import React, { useState } from "react";
import useAxios, { mapConfig } from "./useAxios";
export const SYNC_USER_STATUS = {
  PENDING: 1,
  DONE: 2,
  ERROR: 3,
};

const useSyncUser = () => {
  const { handleAxios } = useAxios();
  const [working, setWorking] = useState(false);

  const create = async (body, callback) => {
    setWorking(true);
    await handleAxios(
      mapConfig("/sync_user", "post", {
        body,
        postFetch: () => {
          if (callback) callback();
        },
      })
    );
    setWorking(false);
  };
  return {
    create,
    working,
  };
};

export default useSyncUser;
