import React, { useRef } from "react";
import { IoMdSend } from "react-icons/io";
import { useTranslate } from "../../../../../../../mobile/hooks";

const ChatInput = ({
  value,
  onChange,
  onClick,
  buttonStyle,
  iconColor,
  disabled,
  onSubmit,
  className,
}) => {
  const { t } = useTranslate();
  const fromRef = useRef();
  return (
    <div className="flex gap-2 items-center w-full ">
      <form
        onSubmit={onSubmit}
        className="w-full flex items-center h-full"
        ref={fromRef}
      >
        <textarea
          rows={1}
          maxLength={200}
          type="text"
          className="flex-grow p-2 rounded-lg outline-none resize-none bg-transparent text-nebula w-[320px] md:w-[460px] h-full"
          onChange={onChange}
          value={value}
          placeholder={t("Write a message")}
        />
        <button
          type="submit"
          disabled={disabled}
          onClick={onClick}
          className={`w-10 h-10 flex justify-center items-center rounded-md bg-white cursor-pointer ${buttonStyle}`}
        >
          <IoMdSend className={`w-6 h-6 ${iconColor}`} />
        </button>
      </form>
    </div>
  );
};

export default ChatInput;
