import { useLocation, useNavigate } from "react-router-dom";

const useHistory = () => {
  const { pathname, search, state } = useLocation();
  const navigate = useNavigate();
  return (path, s) =>
    navigate(path, {
      state: !!s
        ? { from: `${pathname}${search}`, ...s }
        : { from: `${pathname}${search}` },
    });
};

export default useHistory;

export const useHistoryQuery = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const history = (path, query) => {
    let newPath = `${path}`;
    if (query) {
      newPath = `${path}?`;
      let index = 1;
      const queryArrayKeys = Object.keys(query);
      for (let k of queryArrayKeys) {
        const value = query[k];
        let addQuery = "";
        if (index > 1) {
          addQuery = "&";
        }
        addQuery = addQuery + k + "=";
        if (typeof value === "object") {
          newPath = newPath + addQuery + JSON.stringify(value);
        } else {
          newPath = newPath + addQuery + value;
        }
        index = index + 1;
      }
    }
    navigate(newPath, { state: { from: `${pathname}${search}` } });
  };

  return history;
};
