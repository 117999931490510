import React, { useEffect, useMemo, useState } from "react";
import { IoFilterOutline, IoSearch, IoArrowBack } from "react-icons/io5";
import { useHistoryQuery, useQuery, useTranslate } from "../../../hooks";
import EditDates from "./EditDates";
import OpenToEdit from "./OpenToEdit";
import EditGuests from "./EditGuests";
import { format } from "date-fns";
import EditLocation from "./EditLocation";
import styled from "styled-components";
import FilterListing from "./FilterListing";
import { useModalTemplate } from "../ModalTemplate";
import { Link, useLocation } from "react-router-dom";
import { RADIUS_BY_ZOOM } from "../../../../const/Gmap";

const initalSearch = {
  dates: {
    startDate: null,
    endDate: null,
  },
  guests:
    // [
    // { guest_type_id: 1, amount: 0 },
    // { guest_type_id: 2, amount: 0 },
    // { guest_type_id: 3, amount: 0 },
    // { guest_type_id: 4, amount: 0 },
    // ],
    {
      adult: 0,
      children: 0,
      infant: 0,
      pets: 0,
    },
  location: null,
  filter: {
    rooms: [
      {
        room_type_id: 1,
        amount: 0,
      },
      {
        room_type_id: 2,
        amount: 0,
      },
      {
        room_type_id: 3,
        amount: 0,
      },
      {
        room_type_id: 4,
        amount: 0,
      },
      {
        room_type_id: 5,
        amount: 0,
      },
    ],
    amenities: [],
    categories: [],
    price: {
      max: 9999,
      min: 0,
    },
  },
};

const SearchListing = ({
  fixed = false,
  backButton = false,
  isSearch,
  filters,
}) => {
  const { t } = useTranslate();
  const { state, pathname } = useLocation();

  const [open, setOpen] = useState("dates");
  const [showErrors, setShowErrors] = useState(false);
  const [search, setSearch] = useState(initalSearch);
  const {
    Modal: SearchModal,
    open: openSearchModal,
    ...searchModalPropsRest
  } = useModalTemplate();
  const {
    Modal: FilterModal,
    open: openFilterModal,
    ...filterModalPropsRest
  } = useModalTemplate();

  const history = useHistoryQuery();

  const changeSearch = (key, value) => {
    setSearch((prev) => ({ ...prev, [key]: value }));
    setShowErrors(false);
  };

  const { query } = useQuery(["dates", "guests", "location", "filter"]);

  useEffect(() => {
    if (query) {
      const { dates, location, guests } = query;
      if (dates && location && guests)
        setSearch((prev) => ({ ...prev, dates, location, guests }));
    }
  }, [JSON.stringify(query)]);

  const totalGuests = useMemo(() => {
    if (!search.guests) return;
    let res = 0;
    for (let k of Object.keys(search.guests)) {
      const value = search.guests[k];
      res = res + value;
    }
    return res;
  }, [search.guests]);

  const formatDates = useMemo(() => {
    if (!search.dates?.startDate || !search.dates?.endDate) return;
    return `${format(new Date(search.dates?.startDate), "MMM dd")} - ${format(
      new Date(search.dates?.endDate),
      "MMM dd"
    )}`;
  }, [search.dates]);

  const validate = useMemo(() => {
    if (!search.location?.label)
      return { error: "location", message: "Please select a location" };
    if (!formatDates) return { error: "dates", message: "Please select dates" };
    if (!totalGuests)
      return { error: "guests", message: "Please enter a guests amount" };
    return true;
  }, [totalGuests, formatDates, search.location?.label]);

  return (
    <>
      <SearchListingStyled
        className={`${
          fixed ? "fixed z-[940]" : "absolute"
        } top-0 py-3 px-3 w-full max-w-[700px] bg-blueDark flex items-center gap-2`}
      >
        {backButton && (
          <Link
            to={backButton ? backButton : state?.from || "/"}
            state={{ from: pathname }}
            className="p-[0.4em] text-2xl z-[1000] rounded-full bg-nebula text-buttonDark"
          >
            <IoArrowBack />
          </Link>
        )}
        <div
          style={{ boxShadow: "rgb(15, 24, 41) 0px 15px 30px" }}
          onClick={openSearchModal}
          className="w-full flex justify-start items-center text-nebulaOpac bg-blueContrast border border-blueDark px-5 p-3 rounded-full gap-5"
        >
          <IoSearch className="text-3xl" />
          <div>
            <p className="text-[1.3em] leading-5">{t("Find your stay")}</p>
            <p className="text-md opacity-50 m-0">
              {t("Place") + " • " + t("Time") + " • " + t("Guests")}
            </p>
          </div>
        </div>
        {isSearch && (
          <div
            className="p-4 rounded-full text-nebulaOpac bg-blueContrast"
            role="button"
            onClick={openFilterModal}
          >
            <IoFilterOutline fontSize="1.3em" />
          </div>
        )}
      </SearchListingStyled>
      <SearchModal
        id="search-modal"
        type="largeBox"
        title="Search"
        defaultCloseButton
        bodyScroll={false}
        {...searchModalPropsRest}
      >
        <div className="h-full w-full flex flex-col justify-between overflow-y-scroll scrollbarNone">
          <div className="flex flex-col gap-3">
            <OpenToEdit
              open={() => setOpen("dates")}
              isOpen={open === "dates"}
              title={{
                open: t("When is your trip?"),
                close: {
                  left: t("When"),
                  right: formatDates ? formatDates : "Add dates",
                },
              }}
            >
              <EditDates
                onChange={(d, isSecondPick) => {
                  changeSearch("dates", d);
                  if (isSecondPick) setOpen("location");
                }}
                dates={
                  !!search?.dates?.startDate && !!search?.dates?.endDate
                    ? {
                        startDate: new Date(search?.dates?.startDate),
                        endDate: new Date(search?.dates?.endDate),
                      }
                    : {}
                }
              />
            </OpenToEdit>
            <OpenToEdit
              open={() => setOpen("location")}
              isOpen={open === "location"}
              title={{
                open: t("Where is your trip?"),
                close: {
                  left: t("Where"),
                  right: search.location?.label
                    ? search.location?.label
                    : t("Add location"),
                },
              }}
            >
              <EditLocation
                onChange={(v) => {
                  setOpen("guests");
                  changeSearch("location", {
                    ...v,
                    radius: RADIUS_BY_ZOOM[v.zoom],
                  });
                }}
                location={search.location}
              />
            </OpenToEdit>
            <OpenToEdit
              open={() => setOpen("guests")}
              isOpen={open === "guests"}
              title={{
                open: t("Who is coming?"),
                close: {
                  left: t("Who"),
                  right: totalGuests
                    ? `${totalGuests} ${totalGuests > 1 ? "guests" : "guest"}`
                    : t("Add guests"),
                },
              }}
            >
              <EditGuests
                guests={search.guests}
                onChange={(g) => changeSearch("guests", g)}
              />
            </OpenToEdit>
          </div>

          <div className="flex flex-col w-full py-5">
            {showErrors && validate?.error && (
              <p className="text-nebula opacity-50 text-lg">
                {t(validate?.message)}
              </p>
            )}
            <div className="flex w-full justify-between items-center">
              <p
                className="text-nebula text-xl underline underline-offset-4 cursor-pointer"
                onClick={() => setSearch(initalSearch)}
              >
                {t("Clear")}
              </p>
              <div
                onClick={() => {
                  if (validate?.error) {
                    setShowErrors(true);
                    return;
                  }
                  searchModalPropsRest.close();
                  history("/search", search);
                }}
                className="bg-blueDark rounded-lg py-3 px-8 text-nebula text-2xl cursor-pointer"
              >
                <p>{t("Search")}</p>
              </div>
            </div>
          </div>
        </div>
      </SearchModal>
      <FilterModal
        id="filter-modal"
        type="largeBox"
        headerTitle="Filter"
        bodyScroll={false}
        notChildrenWrapper
        {...filterModalPropsRest}
      >
        <FilterListing filters={filters} close={filterModalPropsRest.close} />
      </FilterModal>
    </>
  );
};

const SearchListingStyled = styled.div`
  .filter-wrapper {
    padding: 15px;
    border-radius: 50%;
  }
`;

export default SearchListing;
