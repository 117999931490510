import React from "react";
import { useCheckRole, useGetData } from "../../hooks";
import ListingCardFavorites from "./ListingCardFavorites";
import { ComponentTitle } from "../commons";
import NoBookingCard from "../commons/NoBookingCard";

const FavoritesComp = () => {
  useCheckRole("guest", "/settings");
  const favorites = useGetData(`/listingFavorite/getFavorites`);

  if (!favorites) return <></>;
  return (
    <div className="w-full flex flex-col gap-4">
      <ComponentTitle title={"Favorites"} />
      {favorites?.length === 0 && (
        <div className="px-5">
          <NoBookingCard
            title="No Favorites... yet!"
            message="Time to dust off your travel shoes and start exploring!"
            buttonText="Start Searching"
          />
        </div>
      )}
      <div className="flex flex-col w-full items-center justify-center px-5 gap-5">
        {!!favorites &&
          favorites?.map((listing) => (
            <ListingCardFavorites key={listing.id} listing={listing?.listing} />
          ))}
      </div>
    </div>
  );
};

export default FavoritesComp;
