const Flag = ({ countryCode, className = "w-10 h-8" }) => {
  return (
    <span
      style={{ backgroundPosition: "50%" }}
      className={`fi-${countryCode} ${className} rounded bg-cover bg-no-repeat`}
    ></span>
  );
};

export default Flag;
