import React, { useMemo, useRef, useState } from "react";
import { BsCamera, BsCreditCard2Front } from "react-icons/bs";
import { FaRegAddressCard } from "react-icons/fa";
import selfies from "../../assets/selfies64.png";
import Button from "../../commons/Button";
import ManageLoading from "../../commons/ManageLoading";
import MaxWidthDiv from "../../commons/MaxWidthDiv";
import { APP_FILES_ICONS, APP_FILES_TYPES } from "../../const/AppFiles";
import {
  USER_KYC_STATUS,
  USER_KYC_STATUS_COLOR,
  USER_KYC_STATUS_TEXT,
  USER_KYC_STATUS_WITH_FILE_INPUT,
} from "../../const/KYCStatus";
import useAxios, { mapConfig } from "../../hooks/useAxios";
import useSimpleAxios from "../../hooks/useSimpleAxios";
import useToast from "../../hooks/useToast";
import useUserState from "../../mobile/hooks/useUserState";
import KycInput from "./KycInput";
import { BackButton } from "../../mobile/components/commons";
import { useTranslate } from "../../mobile/hooks";

export default function KYC() {
  const [backID, setbackID] = useState("");
  const [selfieDate, setSelfieDate] = useState("");
  const [frontID, setFrontID] = useState("");
  const [workSave, setWorkSave] = useState(false);
  const { userState } = useUserState();
  const { t } = useTranslate();
  const fileRef = useRef(null);
  const [upload, setUpload] = useState(true);
  const dragFront = useRef();
  const dragOverFront = useRef();
  const dragBack = useRef();
  const dragOverBack = useRef();
  const dragSelfie = useRef();
  const dragOverSelfie = useRef();
  const dragFrontID = (e, position) => {
    dragFront.current = position;
  };
  const dragEnterFrontID = (e, position) => {
    dragOverFront.current = position;
  };

  const dragBackID = (e, position) => {
    dragBack.current = position;
  };
  const dragEnterBackID = (e, position) => {
    dragOverBack.current = position;
  };

  const dragSelfieDate = (e, position) => {
    dragSelfie.current = position;
  };
  const dragEnterSelfie = (e, position) => {
    dragOverSelfie.current = position;
  };

  const { handleAxios } = useAxios();

  const { notify } = useToast([
    {
      position: "top-center",
      type: "success",
      key: "s",
    },
    {
      position: "top-center",
      type: "error",
      key: "e",
    },
  ]);

  const {
    value: files,
    work: workFiles,
    recall: recallFiles,
  } = useSimpleAxios(
    mapConfig(`/app_file/getFilesFromUser?userId=${userState?.user?.id}`, "get")
  );

  const {
    value: userBack,
    work: workUser,
    recall: recallUser,
  } = useSimpleAxios(
    mapConfig(`/users/getUserById?id=${userState?.user?.id}`, "get")
  );

  const user = useMemo(() => userBack?.user, [userBack]);
  const userKYC = useMemo(() => userBack?.kyc_status, [userBack]);
  const userAppFiles = useMemo(() => userBack?.user_files, [userBack]);
  const userRejections = useMemo(() => userBack?.rejections, [userBack]);

  const validateFiles = () => {
    if (userKYC === USER_KYC_STATUS.REJECTED && userAppFiles) {
      for (let key of Object.keys(userAppFiles)) {
        const file = userAppFiles[key];
        const rejectionsAppFilesId = userRejections?.map(
          (item) => item.split(".")[1]
        );
        if (rejectionsAppFilesId.includes(file?.id?.toString())) {
          if (key === "front_id" && !frontID) {
            notify.e(t("Please Select a File for your Front ID"));
            return true;
          }
          if (key === "back_id" && !backID) {
            notify.e(t("Please Select a File for your Back ID"));
            return true;
          }
          if (key === "selfie" && !selfieDate) {
            notify.e(t("Please Select a File for Selfie + date"));
            return true;
          }
        }
      }
    } else {
      if (!frontID) {
        notify.e("Please Select a File for your Front ID");
        return true;
      }
      if (!backID) {
        notify.e(t("Please Select a File for your Back ID"));
        return true;
      }
      if (!selfieDate) {
        notify.e(t("Please Select a File for Selfie + date"));
        return true;
      }
    }
    return false;
  };

  const saveImages = async (e) => {
    e.preventDefault();

    if (validateFiles()) return;

    let formData = new FormData();

    if (frontID !== "") {
      formData.append("fileToUpload", frontID);
      formData.append("type_of_file", "front_id");
    }
    if (backID !== "") {
      formData.append("fileToUpload", backID);
      formData.append("type_of_file", "back_id");
    }
    if (selfieDate !== "") {
      formData.append("fileToUpload", selfieDate);
      formData.append("type_of_file", "selfie");
    }
    const configAxiosSaveFrontID = {
      path: "/users/profile/fileMassiveUpload",
      method: "post",
      body: formData,

      postFetch: (res) => {
        notify.s(res.message);
      },
      postErrorFetch: (err) => notify.e(err),
    };

    await handleAxios(configAxiosSaveFrontID);

    if (userKYC === USER_KYC_STATUS.REJECTED) {
      const rejections = files
        .filter((item) => item.rejection)
        .map((item) => item.rejection.id);
      await handleAxios(
        mapConfig("/rejections/updateRejections", "put", {
          toast: false,
          body: { rejections },
          setWork: setWorkSave,
        })
      );
    }

    await handleAxios(
      mapConfig("/users/updateKYCStatus", "put", {
        toast: false,
        body: { kyc_status: "pending" },
        postFetch: async (res) => {
          await recallFiles();
          recallUser();
        },
      })
    );
  };

  return (
    <>
      <BackButton />
      <ManageLoading loading={workUser && workFiles} type="screen">
        <MaxWidthDiv classNameInner="px-2 pb-10">
          <div className="w-full flex justify-center mt-8">
            <p
              className={`w-fit text-2xl font-semibold text-center text-${USER_KYC_STATUS_COLOR[userKYC]}`}
            >
              {USER_KYC_STATUS_TEXT[userKYC]}
            </p>
          </div>
          <div className="flex flex-col md:flex-row gap-5 py-5">
            {USER_KYC_STATUS_WITH_FILE_INPUT.includes(userKYC) && (
              <>
                <KycInput
                  propsUnverified={{
                    onDragStart: (e, i) => dragFrontID(e, i),
                    onDragEnter: (e, i) => dragEnterFrontID(e, i),
                    value: frontID,
                    upload: upload,
                    setInput: setFrontID,
                    fileRef: fileRef,
                    notify: notify,
                  }}
                  status={userKYC}
                  icon={<FaRegAddressCard size={80} color="#45BFF8" />}
                  appfile={
                    files?.find(
                      (item) => item.type_of_file === APP_FILES_TYPES.FRONT_ID
                    ) || { type_of_file: "front_id" }
                  }
                />
                <KycInput
                  propsUnverified={{
                    onDragStart: (e, i) => dragBackID(e, i),
                    onDragEnter: (e, i) => dragEnterBackID(e, i),
                    value: backID,
                    upload: upload,
                    setInput: setbackID,
                    fileRef: fileRef,
                    notify: notify,
                  }}
                  status={userKYC}
                  icon={<BsCreditCard2Front size={80} color="#45BFF8" />}
                  appfile={
                    files?.find(
                      (item) => item.type_of_file === APP_FILES_TYPES.BACK_ID
                    ) || { type_of_file: "back_id" }
                  }
                />
                <KycInput
                  propsUnverified={{
                    onDragStart: (e, i) => dragSelfieDate(e, i),
                    onDragEnter: (e, i) => dragEnterSelfie(e, i),
                    value: selfieDate,
                    upload: upload,
                    setInput: setSelfieDate,
                    fileRef: fileRef,
                    notify: notify,
                  }}
                  status={userKYC}
                  icon={<BsCamera size={80} color="#45BFF8" />}
                  appfile={
                    files?.find(
                      (item) => item.type_of_file === APP_FILES_TYPES.SELFIE
                    ) || { type_of_file: "selfie" }
                  }
                />
              </>
            )}
            {!USER_KYC_STATUS_WITH_FILE_INPUT.includes(userKYC) &&
              files?.map((item) => (
                <KycInput
                  status={userKYC}
                  appfile={item}
                  icon={APP_FILES_ICONS[item.type_of_file]}
                />
              ))}
          </div>

          {USER_KYC_STATUS_WITH_FILE_INPUT.includes(userKYC) && (
            <div className="flex justify-center pt-5">
              <ManageLoading loading={workSave}>
                <Button
                  className="rounded-lg px-10 py-3 text-center "
                  onClick={saveImages}
                >
                  Save
                </Button>
              </ManageLoading>
            </div>
          )}
        </MaxWidthDiv>
      </ManageLoading>
    </>
  );
}
