import React from "react";
import { useSelector } from "react-redux";
import GenerateSvg from "../../../../../../../commons/GenerateSvg";
import useUserState from "../../../../../../../mobile/hooks/useUserState";
import { useTruncatedAddressFunction } from "../../../../../../../hooks/useTruncatedAddress";
import Button from "../../../../../../../commons/Button";
import useTranslate from "../../../../../../../mobile/hooks/useTranslate";
import { FaExternalLinkAlt } from "react-icons/fa";
import useChainId from "../../../../../../../hooks/useChainId";
import { useAccount, useSwitchChain } from "wagmi";
import { disconnect } from "@wagmi/core";
import { BiCopy } from "react-icons/bi";
import useCopyToClipboard from "../../../../../../../hooks/useCopyToClipboard";
import { useCreateContract } from "../../../../../../../mobile/hooks/web3Interactions";
import { useCurrentNetwork } from "../../../../../../../mobile/hooks";
import useSaveContract from "../../../../../../../mobile/hooks/useSaveContract";
import { config } from "../../../../../../../mobile/WalletConnectProvider";

const ADMIN_ADDRESS = process.env.REACT_APP_ADMIN_CONTRACT_ADDRESS;

const ContractModal = ({ onClose }) => {
  const wallets = useSelector((state) => state.wallets);
  const { userStore, changeUserStateUser } = useUserState();
  const { t } = useTranslate();
  const handleTruncated = useTruncatedAddressFunction();
  const { isConnected, address } = useAccount();
  const { switchChain } = useSwitchChain();
  const currentNetwork = useCurrentNetwork();
  const saveContract = useSaveContract();

  const chain = useChainId();

  const { copyToClipboard } = useCopyToClipboard();

  const { execute } = useCreateContract(
    address,
    [ADMIN_ADDRESS, address],
    (receipt) => {
      saveContract(receipt?.events[0].args?.newContract, address, (res) => {
        changeUserStateUser(res?.data);
      });
    }
  );

  return (
    <div className="flex flex-col gap-2 justify-between items-center w-full h-full">
      {!isConnected && (
        <div className="flex w-full justify-center pb-5">
          <w3m-button />
        </div>
      )}

      <div className="w-full flex flex-col gap-5">
        {!!wallets &&
          !!isConnected &&
          Object.keys(wallets)?.map((keyProp) => {
            const wallet = wallets[keyProp];
            const myContract = userStore?.contract_address[keyProp];
            const addressThatCreatedContract = userStore.address[myContract];
            // const isConnectedEqualToAddressCreation =
            //   userStore.address[myContract] === address;
            return (
              <div className="w-full">
                {!!myContract && (
                  <div className="flex justify-between items-start gap-2 w-full flex-col border border-blueDark rounded-lg px-3 py-5">
                    <div className="flex justify-center items-start w-full flex-col gap-2">
                      <div className="flex justify-between items-center gap-2 w-full pb-2">
                        <p className="text-xl font-semibold">{wallet.name}</p>
                        <GenerateSvg
                          svg={wallet.icon}
                          className="w-[40px] h-[40px] ml-2"
                        />
                      </div>
                      <div className="flex justify-between items-start gap-2 w-full">
                        <p>{"Contract Address"}:</p>
                        <div className="flex items-center gap-2">
                          {handleTruncated(myContract)}
                          <a
                            className="flex justify-center items-center"
                            target="_blank"
                            href={`${wallet.params.add_chain.params[0].blockExplorerUrls[0]}address/${myContract}`}
                          >
                            <FaExternalLinkAlt className="cursor-pointer w-[1.2em] h-[1.2em]" />
                          </a>
                          <BiCopy
                            className={`cursor-pointer w-[1.2em] h-[1.2em] text-nebula`}
                            onClick={(e) =>
                              copyToClipboard(
                                e,
                                myContract,
                                "Address copied successfully"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="flex justify-between items-start w-full">
                        <p>{"Address used"}:</p>
                        <div className="flex items-center gap-2">
                          {handleTruncated(addressThatCreatedContract)}
                          <a
                            className="flex justify-center items-center gap-2"
                            target="_blank"
                            href={`${wallet.params.add_chain.params[0].blockExplorerUrls[0]}address/${addressThatCreatedContract}`}
                          >
                            <FaExternalLinkAlt className="cursor-pointer w-[1.2em] h-[1.2em]" />
                          </a>
                          <BiCopy
                            className={`cursor-pointer w-[1.2em] h-[1.2em] text-nebula`}
                            onClick={(e) =>
                              copyToClipboard(
                                e,
                                addressThatCreatedContract,
                                "Address copied successfully"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!myContract && (
                  <div className="flex justify-between items-start gap-2 w-full flex-col">
                    <div className="flex justify-between items-center w-full">
                      <div className="flex justify-start items-center gap-2">
                        <GenerateSvg
                          svg={wallet.icon}
                          className="w-[40px] h-[40px] mr-2"
                        />
                        <p>{wallet.name}</p>
                      </div>
                      <Button
                        type={
                          Number(wallet?.chain_id) === chain ? "light" : "dark"
                        }
                        className="py-2 px-5 rounded-full"
                        onClick={() => {
                          if (Number(wallet?.chain_id) === chain) {
                            execute();
                          }
                          if (Number(wallet?.chain_id) !== chain) {
                            switchChain({ chainId: Number(wallet?.chain_id) });
                          }
                        }}
                      >
                        {Number(wallet?.chain_id) === chain
                          ? t("Create Contract")
                          : t("Change Network")}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
      {isConnected && (
        <div className="w-full">
          <p className="text-2xl font-semibold pb-2 w-full text-right">
            {t("Wallet connected")}
          </p>
          <div className="w-full justify-end items-center flex gap-5">
            <div className="flex items-center gap-2">
              <GenerateSvg
                svg={currentNetwork?.icon}
                className="w-[30px] h-[40px] ml-2"
              />
              <p className="m-0">{currentNetwork?.name}</p>
            </div>
            <div
              onClick={() => disconnect(config)}
              className="bg-blueDark rounded-lg flex justify-center items-center py-2 px-5"
            >
              <p className="m-0">{t("Disconnect")}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractModal;
