import React, { useEffect, useState } from "react";
import {
  http,
  createConfig,
  getTransactionConfirmations,
  getTransactionReceipt,
} from "@wagmi/core";
import { bsc, bscTestnet, base } from "wagmi/chains";

export const config = createConfig({
  chains: [bsc, bscTestnet, base],
  transports: {
    [bsc.id]: http(),
    [bscTestnet.id]: http(),
    [base.id]: http(),
  },
});

const useConfirmTx = (hash, callback, decodedReceipt) => {
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (hash) {
      runIntervalGetReceipt();
    }
  }, [hash]);

  const runIntervalGetReceipt = () => {
    const intervalId = setInterval(async () => {
      // try{
      setLoading(true);
      const r = await getTransactionReceipt(config, {
        hash,
      });
      const c = await getTransactionConfirmations(config, {
        hash,
      });
      console.log("RECEPIT", r);
      console.log("CONFIRMATION", Number(c));
      if (r.status === "success" && Number(c) >= 2) {
        if (decodedReceipt) {
          const events = decodedReceipt(r);
          r.events = events;
        }
        setConfirm(true);
        setLoading(false);
        clearInterval(intervalId);
        if (callback) callback(r);
      }
    }, 3000);
  };
  return { confirm, loading, setConfirm };
};

export default useConfirmTx;
