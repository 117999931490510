import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import GenerateSvg from "../../../commons/GenerateSvg";
import { useTranslate } from "../../hooks";

const AvailableNetworks = ({ contracts }) => {
  const { t } = useTranslate();

  const wallets = useSelector((state) => state.wallets);

  const networks = useMemo(
    () =>
      !contracts
        ? ""
        : Object.keys(contracts)?.map((item) => wallets[Number(item)]),
    [contracts, wallets]
  );

  if (!networks) return <></>;

  return (
    <div className="w-full">
      {networks && (
        <div className="flex flex-col w-full p-5 justify-center items-start gap-10">
          <span className="text-nebula font-medium text-3xl ">
            {t("Available Networks")}
          </span>
          <div className="flex flex-col items-start gap-5 w-full">
            {networks?.map((item) => (
              <div key={item?.id} className="flex items-center gap-5">
                <GenerateSvg
                  svg={item?.icon}
                  className="w-[35px] h-[35px] mr-2"
                />
                <span className="text-nebulaOpac text-xl">{item?.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AvailableNetworks;
