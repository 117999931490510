import React, { useState } from "react";
import { IoChevronDownOutline } from "react-icons/io5";
import styled from "styled-components";

const Select = ({ values, onChange, initValue }) => {
  const [currentValue, setCurrentValue] = useState(initValue);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleValueChange = (value) => {
    setCurrentValue(value);
  };
  const handleChange = (value) => {
    handleValueChange(value);
    // call method, if it exists
    if (onChange) onChange(value);
    // close, after all tasks are finished
    handleClose();
  };

  return (
    <SelectContainer>
      <SelectLabelButton
        className="text-nebula bg-blueContrast"
        onClick={() => setOpen((prev) => !prev)}
      >
        {currentValue?.label}
        <IoChevronDownOutline
          className={`transition-all text-xl ${open ? "rotate-180" : ""} `}
        />
      </SelectLabelButton>
      <DropdownStyle
        isVisible={open}
        className="text-nebula bg-blueContrast scrollbarNone"
      >
        {values.map((value, index) => (
          <DropdownItem
            className={`text-nebula ${
              value === currentValue ? "" : "opacity-50"
            }`}
            onClick={() => handleChange(value)}
            active={value === currentValue}
            key={index}
          >
            {value?.label}
          </DropdownItem>
        ))}
      </DropdownStyle>
    </SelectContainer>
  );
};

export default Select;

const SelectContainer = styled.div`
  position: relative;
  margin: 0;
  width: 100%;
`;

const SelectLabelButton = styled.button`
  width: 100%;
  padding: 0.75rem 0.5rem;
  min-width: 7rem;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s ease;
`;

const DropdownStyle = styled.div`
  position: absolute;
  top: 3.5em;
  width: 100%;
  left: 0;
  max-height: 40vmax;
  min-width: 10rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  transition: max-height 0.2s ease;
  z-index: 100;
  overflow: auto;
  ${(p) =>
    p.isVisible !== true &&
    `
      max-height: 40px;
      visibility: hidden;
    `}
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.15rem 0;
  padding: 0.8rem 0.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  border-radius: 0.3rem;
  cursor: pointer;
  ${(p) =>
    p.active &&
    `
      font-weight: 500;
    `}
  &:hover, :focus, :focus:hover {
    outline: none;
  }
`;
