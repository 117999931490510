import React, { useEffect, useMemo, useState } from "react";
import { useQuery, useTranslate } from "../../../hooks";
import useDate from "../../../../hooks/useDate";
import { RangeDatePicker } from "../../../../commons/RangeDatePicker";
import { parseISO } from "date-fns";

const EditDatesModal = ({ listing, specialPriceDates, close }) => {
  const [dates, setDates] = useState();
  const { t } = useTranslate();
  const { changeQuery, query } = useQuery(["dates", "id", "guests"]);

  useEffect(() => {
    if (query?.dates) {
      setDates(query?.dates);
    }
  }, []);

  const { formatChecksInOut, tomorrow } = useDate();

  const disableDatesAviabilities = useMemo(
    () =>
      listing?.listingAviability?.map((item) => ({
        start: parseISO(item.not_available_from).toISOString().slice(0, -1),
        end: parseISO(item.not_available_to).toISOString().slice(0, -1),
      })),
    [listing]
  );

  const disableDatesBookings = useMemo(
    () =>
      listing?.listingBookings?.map((item) => ({
        start: parseISO(item.start).toISOString().slice(0, -1),
        end: parseISO(item.end).toISOString().slice(0, -1),
      })),
    [listing]
  );

  const resetDates = () => {
    const datesFormated = formatChecksInOut(listing, new Date(), tomorrow);
    setDates({
      dates: {
        startDate: datesFormated.startDate,
        endDate: datesFormated.endDate,
      },
    });
  };

  const saveChanges = () => {
    changeQuery({ dates });
    close();
  };

  const formatDates = useMemo(() => {
    if (!dates) return;
    return {
      start: new Date(dates.startDate),
      end: new Date(dates.endDate),
    };
  }, [dates]);

  if (!dates) return <></>;
  return (
    <div className="w-full h-full flex flex-col gap-5 items-center justify-between">
      <RangeDatePicker
        changeDates={(dates) => setDates(dates)}
        startDate={formatDates.start}
        endDate={formatDates.end}
        direction="vertical"
        className={`md:p-5 pt-5 w-full flex flex-col items-center justify-start`}
        specialDates={specialPriceDates}
        disableDates={
          disableDatesBookings && disableDatesAviabilities
            ? [...disableDatesBookings, ...disableDatesAviabilities]
            : []
        }
        checkTimes={{
          check_in: listing?.check_in,
          check_out: listing?.check_out,
        }}
      />
      <div className="w-full flex justify-between items-center">
        <p
          onClick={resetDates}
          className="text-nebula text-xl underline underline-offset-2"
        >
          {t("Reset dates")}
        </p>
        <div
          onClick={saveChanges}
          className=" border-2 border-nebula py-3 px-8 text-2xl rounded-lg"
        >
          {t("Save")}
        </div>
      </div>
    </div>
  );
};

export default EditDatesModal;
