import React from "react";
import { useTranslate } from "../../../hooks";

const OpenToEdit = ({ open = () => {}, isOpen, title, children }) => {
  const { t } = useTranslate();
  return (
    <div
      onClick={open}
      className={`border-2 border-blueDark rounded-lg p-3 w-full cursor-pointer ${
        isOpen ? "py-5" : ""
      }`}
    >
      {!isOpen && (
        <div className="flex justify-between">
          <p className="text-nebula text-xl opacity-50">
            {t(title.close.left)}
          </p>
          <p className="text-nebula text-lg">{t(title.close.right)}</p>
        </div>
      )}
      {isOpen && (
        <div className="flex flex-col gap-5">
          <p className="text-nebula text-3xl">{t(title.open)}</p>
          {children}
        </div>
      )}
    </div>
  );
};

export default OpenToEdit;
