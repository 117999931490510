import React from "react";
import { NotificationsComp } from "../components";
import { ComponentTitle } from "../components/commons";

const Notifications = () => {
  return (
    <div
      className={`relative pb-[8em] max-w-[700px] w-full bg-blueDark min-h-screen`}
    >
      <ComponentTitle title={"Notifications"} />
      <NotificationsComp />
    </div>
  );
};

export default Notifications;
