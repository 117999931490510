import React from "react";
import { useState } from "react";

const useInfiniteScroll = ({ isLoading, next, direction }) => {
  const [work, setWork] = useState(false);
  const callNext = async (e) => {
    if (direction === "y") {
      const position = e.target.scrollHeight - Math.round(e.target.scrollTop);
      // console.log("IS LOADING", isLoading);
      // console.log("NEXT", next);
      // console.log("POSITION", position);
      // console.log("CLIENT HEIGTH - 1", e.target.clientHeight - 1);
      // console.log("CLIENT HEIGTH + 1", e.target.clientHeight + 1);
      if (
        position >= e.target.clientHeight - 1 &&
        position <= e.target.clientHeight + 1 &&
        !isLoading &&
        next
      ) {
        setWork(true);
        await next();
        setWork(false);
      }
    }
    if (direction === "x") {
      const position = e.target.offsetWidth + Math.round(e.target.scrollLeft);
      if (
        position >= e.target.scrollWidth - 1 &&
        position <= e.target.scrollWidth + 1 &&
        !isLoading &&
        next
      ) {
        setWork(true);
        await next();
        setWork(false);
      }
    }
  };

  return { callNext, work };
};

export default useInfiniteScroll;
