function addOneDay(date = new Date()) {
  date.setDate(date.getDate() + 1);

  return date;
}
const initialState = {
  user: {},
  paymentOptions: {},
  transaction: {},
  listing: {},
  listingSearch: {},
  listingImages: [],
  listingToEdit: "",
  location: "",
  reduxCategory: 0,
  reduxAttributes: [],
  reduxLocation: {},
  reduxListing: {},
  reduxListingId: 0,
  listingCompletedSection: {
    presentation: false,
    attributes: false,
    media: false,
    policy: false,
    price: false,
    address: false,
    completion: 0,
  },
  listingStatus: "",
  message: {},
  listingCategoriesSearch: {},
  lang: { lang: 2, isNew: false },
  translate: {},
  searchDateRange: {
    endDate: addOneDay(),
    startDate: new Date(),
    key: "selection",
  },
  guests: 1,
  isSearchByRadius: false,
  showSearchOnNavbar: true,
  modalLogin: { open: false },
  modalSignUp: false,
  modalSignWallet: { open: false },
  modalContract: false,
  notifications: [],
  loadingContractInteraction: false,
  notRead: [],
  wallets: "",
};

const MainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      if (action.payload) {
        const { user, token, language } = action.payload;
        state.user = user;
        state.jwt = token;
        state.language = user?.lang || "en";

        window.sessionStorage.setItem("userState", JSON.stringify(state));
        return state;
      }
      break;
    case "SET_PAYMENT_OPTIONS":
      return { ...state, paymentOptions: action.payload };
    case "SET_TRANSACTION":
      if (action.payload) {
        // storing the transaction in session storage for future use
        window.sessionStorage.setItem(
          "bbopTransaction",
          JSON.stringify({
            transaction: action.payload,
          })
        );
        return { ...state, transaction: action.payload };
      }
      break;
    case "UPDATE_USER_AVATAR":
      if (action.payload) {
        // get the user state from session storage
        const userState = JSON.parse(
          window.sessionStorage.getItem("userState")
        );
        const { pathUrl } = action.payload;
        // update the user image profile
        userState.user.profile_image = pathUrl;
        // update the user state in session storage
        window.sessionStorage.setItem("userState", JSON.stringify(state));
        return userState;
      }
      break;
    case "SET_LISTING":
      if (action.payload) {
        Object.assign(state.listing, action.payload);
        return { ...state, listing: action.payload };
      }
      break;
    case "SET_LISTING_SEARCH":
      if (action.payload) {
        Object.assign(state.listingSearch, action.payload);
        return { ...state, listingSearch: action.payload };
      }
      break;
    case "RESET_LISTING":
      return { ...state, listing: {} };

    case "SET_LISTING_IMAGES":
      if (action.payload) {
        return { ...state, listingImages: action.payload };
      }
      break;
    case "RESET_LISTING_IMAGES":
      return { ...state, listingImages: [] };
    case "UPDATE_CURRENT_USER":
      if (action.payload) {
        const newRole = action.payload;
        const localState = JSON.parse(
          window.sessionStorage.getItem("userState")
        );
        localState.user.role = newRole;
        window.sessionStorage.setItem("userState", JSON.stringify(localState));
        state.user = localState.user;
        return state;
      }
      break;
    case "SET_LISTING_TO_EDIT":
      if (action.payload) {
        return { ...state, listingToEdit: action.payload };
      }
      break;
    case "SET_VALUE_LOCATION":
      if (action.payload) {
        Object.assign(state.location, action.payload);
        return { ...state, location: action.payload };
      }
      break;
    case "RESET_LISTING_TO_EDIT":
      return { ...state, listingToEdit: "" };

    case "SET_LISTING_ATTRIBUTES":
      if (action.payload) {
        return { ...state, reduxAttributes: action.payload };
      }
      break;
    case "RESET_LISTING_ATTRIBUTES":
      return { ...state, reduxAttributes: [] };

    case "SET_LISTING_ADDRESS":
      if (action.payload) {
        return { ...state, reduxLocation: action.payload };
      }
      break;
    case "RESET_LISTING_ADDRESS":
      return {
        ...state,
        reduxLocation: {
          country: "",
          city: "",
          zip: 0,
          state: "",
          street_number: "",
          additional_info: "",
          lat: 0,
          lng: 0,
        },
      };
    case "SET_CURRENT_LISTING":
      if (action.payload) {
        return { ...state, reduxListing: action.payload };
      }
      break;
    case "RESET_CURRENT_LISTING":
      return {
        ...state,
        reduxListing: {
          userId: 0,
          price: 0,
          min_duration: 0,
          max_duration: 0,
          cancellation_policy: "2",
          availabilities_updated_at: "",
          calendar_import_url: "",
          calendar_import_date: "",
          number_of_guests: 0,
          description: "",
          name: "",
          rules_and_conditions: "",
          security_deposit_percentage: 0,
          status: "DRAFT",
        },
      };
    case "SET_LISTING_CATEGORY":
      if (action.payload) {
        return { ...state, reduxCategory: action.payload };
      }
      break;
    case "RESET_LISTING_CATEGORY":
      return { ...state, reduxCategory: 0 };

    case "SET_CURRENT_LISTING_ID":
      if (action.payload) {
        return { ...state, reduxListingId: action.payload };
      }
      break;
    case "RESET_CURRENT_LISTING_ID":
      return { ...state, reduxListingId: 0 };

    case "SET_LISTING_COMPLETED_SECTION":
      if (action.payload) {
        return { ...state, listingCompletedSection: action.payload };
      }

    case "RESET_LISTING_COMPLETED_SECTION":
      return {
        ...state,
        listingCompletedSection: {
          presentation: false,
          attributes: false,
          media: false,
          policy: false,
          price: false,
          address: false,
          completion: 0,
        },
      };

    case "SET_LISTING_STATUS":
      if (action.payload) {
        return { ...state, listingStatus: action.payload };
      }
      break;
    case "SET_LANGUAGE":
      return { ...state, lang: action.payload };
    case "SET_TRANSLATE":
      return { ...state, translate: action.payload };
    case "RESET_LISTING_STATUS":
      return { ...state, listingStatus: "" };

    case "SET_MESSAGE":
      return { ...state, message: action.payload };
    case "RESET_MESSAGE":
      return { ...state, message: {} };
    case "RESET_SEARCH_LISTING":
      return { ...state, listingSearch: {} };
    case "SEARCH_DATE_RANGE":
      return { ...state, searchDateRange: action.payload };
    case "SEARCH_GUESTS":
      return { ...state, guests: action.payload };
    case "RESET_SEARCH_GUESTS":
      return { ...state, guests: 1 };
    case "IS_SEARCH_BY_RADIUS":
      return { ...state, isSearchByRadius: action.payload };
    case "SHOW_SEARCH_ON_NAVBAR":
      return { ...state, showSearchOnNavbar: action.payload };
    case "IS_MODAL_LOGIN_OPEN":
      return { ...state, modalLogin: action.payload };
    case "IS_MODAL_SIGN_UP_OPEN":
      return { ...state, modalSignUp: action.payload };
    case "IS_MODAL_SIGN_WALLET_OPEN":
      return { ...state, modalSignWallet: action.payload };
    case "IS_MODAL_CONTRACT":
      return { ...state, modalContract: action.payload };
    case "NOTIFICATIONS":
      return { ...state, notifications: action.payload };
    case "LOADING_CONTRACT_INTERACTION":
      return { ...state, loadingContractInteraction: action.payload };
    case "NOTIFICATION_NOT_READ":
      return { ...state, notRead: action.payload };
    case "WALLETS":
      return { ...state, wallets: action.payload };
    case "CHANGE_USER_STATE":
      return { ...state, user: action.payload };

    default:
      return state;
  }
};

export { MainReducer };
