import Slider from "rc-slider";
import { BiDollar } from "react-icons/bi";
import { InputText } from "./Form";

const DonationSlider = ({ donation, setDonation, className = "mt-14" }) => {
  return (
    <div className={className}>
      <Slider
        dots
        min={0}
        // marks={marks}
        value={donation}
        step={10}
        className=""
        onChange={setDonation}
        defaultValue={20}
      />
      <InputText
        value={donation}
        onChange={(e) => setDonation(e.target.value)}
        type="number"
        minValue={0}
        InputPrefix={<BiDollar className="text-3xl text-greenDark" />}
        className="mt-5 !bg-[#ebebeb]"
        classNameInput="text-3xl !bg-[#ebebeb] !text-greenDark"
      />
    </div>
  );
};

export default DonationSlider;
