import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import {
  handleDrag,
  onWheelSmooth,
  useDrag,
  usePreventBodyScroll,
} from "./utils";
import Loader from "react-loader-spinner";

const wait = (time = 1500) =>
  new Promise((resolve) => setTimeout(resolve, time));

function ArrowInfiniteHorizontal(props) {
  const { isLastItemVisible } = useContext(VisibilityContext);
  const { addPageIndex, hasMore } = props;

  const next = async () => {
    await wait();
    if (isLastItemVisible && hasMore) addPageIndex();
  };

  useEffect(() => {
    next();
    //eslint-disable-next-line
  }, [isLastItemVisible, hasMore]);

  return <ArrowInfiniteHorizontalWrapper />;
}

const ArrowInfiniteHorizontalWrapper = styled.div`
  position: absolute;
`;

function HorizontalScrolling({
  children,
  transitionDuration = 2000,
  hasInfiniteHorizontal = false,
  staticRightArrow = false,
  style = {},
  ...props
}) {
  const { dragStart, dragStop, dragMove } = useDrag();
  const { disableScroll, enableScroll } = usePreventBodyScroll();

  const infiniteScrollProps = {
    RightArrow: <ArrowInfiniteHorizontal {...props} />,
  };

  const staticScrollProps = staticRightArrow
    ? {
        RightArrow: <staticRightArrow.Icon {...props} />,
      }
    : {};

  return (
    <HorizontalScrollingStyled
      onMouseEnter={disableScroll}
      onMouseLeave={enableScroll}
      style={style}
    >
      <ScrollMenu
        onWheel={onWheelSmooth}
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag({ dragMove })}
        options={{ throttle: 0 }}
        transitionDuration={transitionDuration}
        {...(hasInfiniteHorizontal && infiniteScrollProps)}
        {...(staticRightArrow ? staticScrollProps : {})}
      >
        {children}
        {(props.isLoadingMore || props.hasMore) && (
          <Loader styleBox={props.styleLoader} />
        )}
      </ScrollMenu>
    </HorizontalScrollingStyled>
  );
}

const HorizontalScrollingStyled = styled.div`
  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  .react-horizontal-scrolling-menu--scroll-container {
    scrollbar-width: none;
  }
`;

export default HorizontalScrolling;
