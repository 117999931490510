import React from "react";
import { useTranslate } from "../../hooks";
import DynamicIcon from "../../../commons/DynamicIcon";
import { Divider } from "../commons";

const ShowRooms = ({ rooms }) => {
  const { t } = useTranslate();
  if (rooms?.length === 0) return <></>;
  return (
    <>
      <div className="w-full p-5">
        <p className="text-nebula text-3xl font-medium pb-10 text-left">
          {t("Rooms")}
        </p>
        <div className="w-full flex flex-col gap-5">
          {rooms &&
            rooms?.map((item) => (
              <div
                key={item?.id}
                className="w-full flex items-center justify-between"
              >
                <div className="flex justify-start items-center gap-1">
                  <DynamicIcon
                    name={item.room_type.icon}
                    className="text-nebula text-4xl"
                  />
                  <p className="capitalize text-nebula text-xl ">
                    {t(item.room_type.name)}
                  </p>
                </div>
                <p className="capitalize text-nebula text-xl ">{item.amount}</p>
              </div>
            ))}
        </div>
      </div>
      <Divider />
    </>
  );
};

export default ShowRooms;
