import React, { useEffect, useState } from "react";
import useSimpleAxios from "../../../../hooks/useSimpleAxios";
import { mapConfig } from "../../../../hooks/useAxios";
import useTranslate from "../../../../mobile/hooks/useTranslate";
import ManageLoading from "../../../../commons/ManageLoading";
import IconsIntegrations from "./IconsIntegrations";
import IntegrationSelected from "./IntegrationSelected";
import SyncUser from "./SyncUser";

const Integrations = ({ callback }) => {
  const {
    value: myApis,
    recall: recallMyApis,
    isLoaded: isLoadedApis,
  } = useSimpleAxios(mapConfig("/sources/my_apis", "get"));
  const { value, isLoaded } = useSimpleAxios(mapConfig("/sources", "get"));
  const { t } = useTranslate();

  const {
    value: syncUserData,
    isLoaded: isLoadedSyncUser,
    recall: recallSyncUser,
  } = useSimpleAxios(mapConfig("/sync_user/get", "get"));

  const [showSyncUser, setShowSyncUser] = useState(false);
  const [apiSelected, setApiSelected] = useState();

  useEffect(() => {
    if (myApis) {
      setApiSelected(myApis);
    }
    if (syncUserData) {
      setShowSyncUser(true);
    }
  }, [myApis, syncUserData]);

  return (
    <div className="w-full p-4 mb-5 flex flex-col justify-center items-center">
      <ManageLoading loading={!isLoaded || !isLoadedApis || !isLoadedSyncUser}>
        <div className="flex flex-wrap w-full justify-center">
          {apiSelected ? (
            <div className="w-full gap-5 flex flex-col">
              <IntegrationSelected
                api={apiSelected}
                onCancel={() => setApiSelected()}
                recall={() => {
                  recallSyncUser();
                  setApiSelected();
                  if (callback) callback();
                }}
              />
            </div>
          ) : showSyncUser ? (
            <SyncUser
              syncUser={syncUserData}
              onClickTryAgain={() => setShowSyncUser(false)}
            />
          ) : (
            value &&
            value.map((source) => (
              <>
                <div
                  onClick={() =>
                    setApiSelected({
                      source_id: source?.id,
                      name: source?.name,
                    })
                  }
                  className="w-fit h-fit cursor-pointer rounded-[12px] border-2 hover:border-yellowBbop border-transparent "
                >
                  <IconsIntegrations id={source?.id} width="5em" height="5em" />
                </div>
              </>
            ))
          )}
        </div>
      </ManageLoading>
    </div>
  );
};

export default Integrations;
