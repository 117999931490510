import React from "react";

function KitchenIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill={props.color}
        d="M16.202 0v492.557h64.81V512h38.886v-19.443h272.203V512h38.886v-19.443h64.81V0H16.202zm440.709 453.671H55.089V155.544h401.823v298.127zm0-337.013H55.089V38.886h401.823v77.772z"
      />
      <path
        fill={props.color}
        d="M372.658 51.848c-14.294 0-25.924 11.63-25.924 25.924s11.63 25.924 25.924 25.924c14.289 0 25.919-11.627 25.924-25.924 0-14.294-11.627-25.924-25.924-25.924zM294.886 51.848c-14.295 0-25.924 11.63-25.924 25.924s11.63 25.924 25.924 25.924c14.289 0 25.919-11.627 25.924-25.924 0-14.294-11.627-25.924-25.924-25.924zM217.114 51.848c-14.295 0-25.924 11.63-25.924 25.924s11.63 25.924 25.924 25.924c14.289 0 25.919-11.627 25.924-25.924 0-14.294-11.627-25.924-25.924-25.924zM139.342 51.848c-14.295 0-25.924 11.63-25.924 25.924s11.63 25.924 25.924 25.924c14.289 0 25.919-11.627 25.924-25.924 0-14.294-11.627-25.924-25.924-25.924zM93.975 194.43v220.354h324.051V194.43H93.975zm285.164 181.469H132.861V233.316h246.278v142.583z"
      />
      <path
        fill={props.color}
        transform="rotate(-45.001 204.14 304.616)"
        d="M149.152 285.17H259.134V324.05400000000003H149.152z"
      />
      <path
        fill={props.color}
        transform="rotate(-45.001 307.845 304.624)"
        d="M252.856 285.177H362.83799999999997V324.06100000000004H252.856z"
      />
    </svg>
  );
}

export default KitchenIcon;
