import { useWeb3React } from "@web3-react/core";
import React from "react";
import { isMobile } from "react-device-detect";
import { useWeb3ModalState } from "@web3modal/wagmi/react";

const useChainId = () => {
  // const res = useWeb3React();
  const { selectedNetworkId } = useWeb3ModalState();
  return selectedNetworkId || window.ethereum?.chainId;
};

export default useChainId;
