import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";

const SelectNumber = ({
  num,
  onChange,
  max,
  min,
  disablePlus,
  disabled = false,
}) => {
  const change = (sign) => {
    if (disabled) return;
    let v = num;
    if (sign === "+") v = v + 1;
    if (sign === "-") v = v - 1;
    if (min && Number(min) > v) return;
    if (max && Number(max) < v) return;
    onChange(v);
  };
  return (
    <div className="flex items-center text-nebula gap-5">
      <div
        className={`border border-nebula rounded-full text-xs p-2 cursor-pointer ${
          disabled ? "opacity-30" : ""
        } `}
        onClick={() => change("-")}
      >
        <FaMinus />
      </div>
      <p className="text-2xl">{num}</p>
      <div
        className={`border border-nebula rounded-full text-xs p-2 cursor-pointer ${
          disabled || disablePlus ? "opacity-30" : ""
        } `}
        onClick={() => change("+")}
      >
        <FaPlus />
      </div>
    </div>
  );
};

export default SelectNumber;
